import React from 'react'
import { connect } from 'react-redux'
import { selectFundraisingPage, selectIsPageOwner } from '../../../store/fundraisingPage'
import { selectTagPage } from '../../../store/tagPage'
import { selectTeam, selectIsTeamOwner } from '../../../store/team'
import { selectUser } from '../../../store/session'

import Badges from '../Badges'
import Content from '../Content'
import CTAs from '../CTAs'
import Feed from '../Feed'
import IFrame from '../IFrame'
import JoinTeam from '../JoinTeam'
import Leaderboards from '../Leaderboards'
import Metrics from '../Metrics'
import ProfilePicture from '../ProfilePicture'
import ProgressBar from '../ProgressBar'
import Share from '../Share'
import Story from '../Story'
import TagPageDetails from '../TagPageDetails'
import TeamDetails from '../TeamDetails'
import TeamMembers from '../TeamMembers'

const sliceComponents = {
  badges: Badges,
  content: Content,
  ctas: CTAs,
  feed: Feed,
  iframe: IFrame,
  joinTeam: JoinTeam,
  leaderboards: Leaderboards,
  metrics: Metrics,
  profilePicture: ProfilePicture,
  progressBar: ProgressBar,
  share: Share,
  story: Story,
  tagPageDetails: TagPageDetails,
  teamDetails: TeamDetails,
  teamMembers: TeamMembers
}

const Slices = ({
  customSupporterPage,
  fundraisingPage = {},
  isPageOwner,
  isTeamOwner,
  slices = [],
  tagPage = {},
  team = {},
  type,
  user = {}
}) => (
  <div>
    {slices.map((slice, index) => {
      const Component = sliceComponents[slice.type]

      const props = {
        ...slice.primary,
        items: slice.items
      }

      const selectPage = () => {
        switch (type) {
          case 'team':
            return team
          case 'tag':
            return {
              ...customSupporterPage,
              ...tagPage
            }
          default:
            return fundraisingPage
        }
      }

      return (
        <div key={index} id={slice.type}>
          {Component
            ? (
              <Component
                customSupporterPage={customSupporterPage}
                fundraisingPage={selectPage()}
                key={fundraisingPage.slug}
                isPageOwner={type === 'team' ? isTeamOwner : isPageOwner}
                user={user}
                {...props}
              />
            ) : `Unknown slice type: ${slice.type}`}
        </div>
      )
    })}
  </div>
)

const mapStateToProps = (state, props) => ({
  fundraisingPage: selectFundraisingPage(state, props),
  tagPage: selectTagPage(state, props),
  team: selectTeam(state, props),
  isPageOwner: selectIsPageOwner(state, props),
  isTeamOwner: selectIsTeamOwner(state, props),
  user: selectUser(state, props)
})

export default connect(mapStateToProps)(Slices)
