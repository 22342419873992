export default (props, traits) => {
  const { rhythm, scale } = traits

  return {
    root: {
      marginTop: rhythm(1)
    },
    modal: {
      container: {
        textAlign: 'center'
      }
    },
    joinModal: {
      content: {
        textAlign: 'center',
        overflow: 'visible'
      },
      container: {
        overflow: 'visible'
      }
    },
    link: {
      cursor: 'pointer',
      fontSize: scale(-0.5)
    },
    copy: {
      marginBottom: rhythm(1)
    }
  }
}
