import React, { useEffect, useState } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { fetchCharity, deserializeCharity } from 'supporticon/api/charities'
import { selectReportsParams } from '../../../store/site'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import Heading from 'constructicon/heading'
import Loading from '../Loading'
import Section from 'constructicon/section'

const CharityHeader = ({ classNames, charityId, styles }) => {
  const [charity, setCharity] = useState()

  useEffect(() => {
    if (charityId) {
      fetchCharity(charityId)
        .then(deserializeCharity)
        .then(setCharity)
    }
  }, [])

  return (
    <Section tag='header' styles={styles.root}>
      {charity ? (
        <div>
          <img className={classNames.logo} src={charity.logo} />
          <Heading spacing={{ t: 0.25 }} size={1}>{charity.name}</Heading>
        </div>
      ) : (
        <Loading />
      )}
    </Section>
  )
}

const mapState = (state) => selectReportsParams(state)

export default compose(
  connect(mapState),
  withStyles(styles)
)(CharityHeader)
