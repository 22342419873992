export default (props, {
  rhythm,
  scale
}) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    minHeight: '100vh',
    fontSize: scale(2),
    fontWeight: 700
  },

  label: {
    marginBottom: rhythm(1)
  }
})
