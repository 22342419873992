export default (props, { colors }) => ({
  root: {
    position: 'relative',
    paddingBottom: '30%',
    overflow: 'hidden',
    border: `thin solid ${colors.shade}`,
    borderRadius: '2px',
    zIndex: 1,

    '& > iframe': {
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%'
    }
  }
})
