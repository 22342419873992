import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { selectAdminUser } from '../../store/session'

import Reports from '../../components/container/AdminReports'

const AdminReports = ({ router, user = {} }) => {
  useEffect(() => {
    if (!user.token) {
      router.push('/admin/login')
    }
  }, [user])

  return <Reports />
}

const mapState = (state) => ({
  user: selectAdminUser(state)
})

export default connect(mapState)(AdminReports)
