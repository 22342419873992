import React from 'react'
import { connect } from 'react-redux'
import { selectButtonDefaults } from '../../../store/site'

import Button from 'constructicon/button'
import LinkHandler from '../LinkHandler'

const ThemedButton = ({
  borderColor = 'transparent',
  borderWidth,
  dispatch,
  radius,
  size,
  spacing,
  ...props
}) => (
  <Button
    borderColor={borderColor}
    borderWidth={borderWidth}
    radius={radius}
    size={size}
    spacing={spacing}
    tag={LinkHandler}
    {...props}
  />
)

const mapState = (state) => selectButtonDefaults(state)

export default connect(mapState)(ThemedButton)
