import React from 'react'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import Container from 'constructicon/container'
import BaseSection from 'constructicon/section'

const Section = ({
  background,
  children,
  classNames,
  foreground,
  size = 'wide',
  styles,
  tag,
  id,
  role
}) => (
  <BaseSection
    tag={tag}
    id={id}
    role={role}
    background={background}
    foreground={foreground}
    styles={styles.root}>

    <Container width={sizes[size]}>
      {children}
    </Container>

  </BaseSection>
)

const sizes = {
  narrow: 22.5,
  thin: 30,
  medium: 37.5,
  wide: 45,
  full: 9999
}

export default withStyles(styles)(Section)
