export default (props, {
  colors,
  mediaQuery,
  radiuses,
  rhythm,
  treatments
}) => ({
  wrapper: {
    marginTop: rhythm(1),
    border: `thin solid ${colors.shade}`,
    borderRadius: rhythm(radiuses.small),
    overflow: 'auto',

    [mediaQuery('md', 'max-width')]: {
      borderWidth: 0,
      borderRadius: 0,
      marginLeft: rhythm(-1),
      marginRight: rhythm(-1)
    }
  },

  table: {
    width: '100%',

    '& th': {
      whiteSpace: 'nowrap',
      verticalAlign: 'middle',
      padding: rhythm(0.5),
      ...treatments.button,

      [mediaQuery('md', 'max-width')]: {
        display: 'none'
      }
    }
  },

  row: {
    '& td': {
      padding: rhythm(0.5)
    },

    '&:nth-child(odd)': {
      backgroundColor: colors.shade
    },

    [mediaQuery('md', 'max-width')]: {
      display: 'flex',
      flexWrap: 'wrap',
      position: 'relative',
      paddingLeft: rhythm(1.5),

      '& td': {
        flex: `0 1 50%`,
        minWidth: '50%',

        '&:before': {
          content: 'attr(data-label)',
          display: 'block',
          ...treatments.button
        }
      }
    }
  },

  rank: {
    textAlign: 'center',
    ...treatments.button,

    [mediaQuery('md', 'max-width')]: {
      position: 'absolute',
      top: 0,
      left: 0,
      textAlign: 'left',

      '&:before': {
        display: 'none'
      }
    }
  }
})
