import React from 'react'
import { isRichTextEmpty } from '../../../lib/richText'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import Buttons from '../Buttons'
import LayoutDefault from './LayoutDefault'
import LayoutLeftSidebar from './LayoutLeftSidebar'
import LayoutRightSidebar from './LayoutRightSidebar'
import RichText from '../RichText'

const Content = ({
  align,
  buttons = [],
  children,
  classNames,
  copy,
  gridSize,
  layout,
  styles,
  subtitle,
  title
}) => {
  // layout can be either
  // - default: just a single column
  // - left: puts the children to the left of the generic content fields
  // - right: puts the children to the right of the generic content fields
  const layouts = {
    left: LayoutLeftSidebar,
    right: LayoutRightSidebar,
    default: LayoutDefault
  }

  const hasOneOf = items => (
    items.some(item => !isRichTextEmpty(item))
  )

  const LayoutComponent = layouts[layout] || LayoutDefault

  // the generic content fields
  const content = (hasOneOf([title, subtitle, copy]) || buttons.length > 0) ? (
    <div className='ssb-content'>
      {!isRichTextEmpty(title) && <RichText styles={styles.title}>{title}</RichText>}
      {!isRichTextEmpty(subtitle) && <RichText styles={styles.subtitle}>{subtitle}</RichText>}
      {!isRichTextEmpty(copy) && <RichText styles={styles.copy}>{copy}</RichText>}
      {buttons.length > 0 && <Buttons buttons={buttons} className={classNames.buttons} />}
    </div>
  ) : null

  // put our generic content and children into our chosen layout
  return (
    <LayoutComponent
      align={align}
      content={content}
      gridSize={gridSize}>
      {children}
    </LayoutComponent>
  )
}

export default withStyles(styles)(Content)
