import React, { Component } from 'react'
import moment from 'moment'
import numbro from 'numbro'
import find from 'lodash/find'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import keyBy from 'lodash/keyBy'
import merge from 'lodash/merge'
import pickBy from 'lodash/pickBy'
import template from 'lodash/template'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { isJustGiving, isStaging, getEDHClientId } from '../../../lib/environment'
import { isRichTextEmpty, convertToString } from '../../../lib/richText'
import mapTemplate from '../../../lib/mapTemplate'
import { clearSession, clearCharity, fetchSessionPage, fetchSessionUser, selectPreselectedCharity, selectPreselectedTeam, selectPage, selectUser } from '../../../store/session'
import { selectAPIParams, selectButtonDefaults, selectHeader, selectLocalisation, selectTagDefinitions, selectUrl } from '../../../store/site'
import { selectCustomSupporterPagePresent } from '../../../store/customSupporterPages'
import { getUID } from 'supporticon/utils/params'
import { createMetadata } from 'supporticon/api/metadata'
import { createPageTag } from 'supporticon/api/pages'
import { trackEvent } from '../../../lib/analytics'
import { getCurrentUrl, isFacebookApp } from '../../../lib/window'
import withMobile from '../../utils/withMobile'
import withStyles from 'constructicon/with-styles'
import styles from './styles'
import * as validators from 'constructicon/lib/validators'

import Button from '../../ui/Button'
import BaseRichText from 'constructicon/rich-text'
import ButtonGroup from 'constructicon/button-group'
import Content from '../../ui/Content'
import CreatePageForm from 'supporticon/components/create-page-form'
import Divider from '../../ui/Divider'
import FormState from './FormState'
import Heading from 'constructicon/heading'
import JGConnectForm from 'supporticon/components/jg-connect-form'
import Loading from '../../ui/Loading'
import LoginForm from 'supporticon/components/login-form'
import Modal from '../../ui/FlatModal'
import PreselectedCharity from './PreselectedCharity'
import ProviderOauthButton from 'supporticon/components/provider-oauth-button'
import ResetPassword from 'supporticon/components/reset-password-form'
import RichText from '../../ui/RichText'
import Section from '../../ui/Section'
import SignupForm from 'supporticon/components/signup-form'
import Success from '../../ui/Success'
import TeamRegistration from './TeamRegistration'

class RegisterForm extends Component {
  constructor (props) {
    super(props)
    this.handleAuthSuccess = this.handleAuthSuccess.bind(this)
    this.handleCreatePageSuccess = this.handleCreatePageSuccess.bind(this)
    this.handleValidationErrors = this.handleValidationErrors.bind(this)
    this.handleLogout = this.handleLogout.bind(this)
    this.renderAuthForm = this.renderAuthForm.bind(this)
    this.renderAuthProviders = this.renderAuthProviders.bind(this)
    this.renderCreatePageForm = this.renderCreatePageForm.bind(this)
    this.renderSuccess = this.renderSuccess.bind(this)

    this.state = {
      loaded: false,
      view: 'signup',
      modal: !!props.page
    }
  }

  componentDidUpdate ({ header, page, user, location = {} }, { view }) {
    const viewChanged =
      page !== this.props.page ||
      user !== this.props.user ||
      view !== this.state.view

    if (typeof window === 'undefined') {
      return
    }

    if (viewChanged) {
      if (document.querySelector('#header')) {
        const offset = header.sticky === 'yes' ? document.querySelector('#header').offsetHeight : 0
        setTimeout(() => window.scrollTo(0, this.refs.root.offsetTop - offset))
      }

      this.addStepToUrl()
    }

    // Handle in-page links
    if (this.props.location && location.hash !== this.props.location.hash) {
      const view = this.props.location.hash.slice(1)

      if (['login', 'reset-password'].indexOf(view) !== -1) {
        this.setState({ view })
      }
    }
  }

  componentDidMount () {
    this.setState({ loaded: true })

    if (window.location.hash) {
      const view = window.location.hash.slice(1)

      if (['login', 'reset-password'].indexOf(view) !== -1) {
        this.setState({ view })
      }
    } else {
      this.addStepToUrl()
    }
  }

  addStepToUrl () {
    const { page, user } = this.props
    const { view } = this.state

    if (page && user.hasExistingPage) {
      window.location.hash = 'returning-user'
      return
    }

    if (page) {
      window.location.hash = 'register-success'
      return
    }

    if (user) {
      window.location.hash = 'create-page'
      return
    }

    if (view !== 'signup') {
      window.location.hash = view
    }
  }

  handleAuthSuccess (data) {
    return this.props.fetchSessionUser({
      ...data,
      authType: 'Bearer'
    })
  }

  mapFieldName (field, index) {
    return field.name ? field.name : `custom_field_${index}`
  }

  mapCountry (country, fallbackCountry) {
    switch (country) {
      case 'gb':
        return 'uk'
      case 'none':
        return fallbackCountry
      default:
        return country
    }
  }

  handleValidationErrors (validations) {
    Object.keys(validations).map(key => {
      const validation = get(validations, `${key}.0`)
      if (validation) trackEvent('ValidationError', { key, validation })
    })
  }

  handleCreatePageSuccess (page, fields) {
    const {
      fetchSessionPage,
      hasCustomSupporterPages,
      items = [],
      tagDefinitions = [],
      user
    } = this.props
    const fieldNames = items.map(this.mapFieldName)
    const metadata = pickBy(fields, (_, key) => find(fieldNames, field => field === key))
    const tags = !isJustGiving() ? [] : [
      ...tagDefinitions.map(tag => ({
        ...tag,
        value: fields[tag.id]
      })),
      ...items.map((item, index) => ({
        ...item,
        id: fieldNames[index],
        label: convertToString(item.label),
        value: fields[fieldNames[index]]
      }))
        .filter(item => item.tag)
    ].filter(tag => !!tag.value)

    return Promise.resolve()
      .then(() => fetchSessionPage(page, hasCustomSupporterPages))
      .then(data =>
        Promise.all([
          ...tags.map(({ id, label, value }) =>
            createPageTag({
              slug: data.pageShortName,
              label,
              id,
              value,
              aggregation: [
                {
                  segment: data.campaignGuid
                    ? `page:campaign:${data.campaignGuid}`
                    : `page:event:${data.eventId}`,
                  measurementDomains: ['all']
                }
              ]
            })
          ),
          !isEmpty(metadata) && createMetadata({
            authType: user.authType,
            token: user.token,
            id: isJustGiving() ? data.pageShortName : data.uuid,
            app: isStaging() ? process.env.MDS_JG_STAGING_APP_ID : process.env.MDS_JG_PROD_APP_ID,
            metadata
          })
        ].filter(Boolean))
      )
      .then(() => this.setState({ modal: true }))
  }

  handleLogout (data) {
    this.setState({ modal: false, view: 'signup' })
    this.props.clearSession()
  }

  buildDonationUrl (page, amount, ref) {
    if (!amount) return page.donationUrl

    if (isJustGiving()) {
      const subdomain = isStaging() ? 'link.staging' : 'link'
      return `https://${subdomain}.justgiving.com/v1/fundraisingpage/donate/pageId/${page.id}?amount=${amount}&reference=${ref}`
    } else if (page.donationUrl.indexOf('/us/') !== -1) {
      return `${page.donationUrl}?amount=${amount}`
    } else {
      return `${page.donationUrl}/amount?amount=${amount}`
    }
  }

  getCharitySearchOption (value) {
    if (this.props.preselectedCharity) {
      return null
    }

    switch (value) {
      case 'Yes':
        return 'search'
      case 'Yes - Dropdown':
        return 'select'
      default:
        return null
    }
  }

  isCharityPreselected () {
    const { charity, charityId, preselectedCharity } = this.props

    if (charityId || getUID(charity)) {
      return false
    }

    return !!preselectedCharity
  }

  renderAuthProviders (method) {
    const {
      button,
      classNames,
      clientId,
      facebook,
      mapmyfitness,
      oauthMethod,
      redirectUri,
      strava,
      styles
    } = this.props

    if (isJustGiving()) {
      return (
        <div className={classNames.providers}>
          <ProviderOauthButton
            {...button}
            block
            provider='facebook'
            label='Continue with Facebook'
            clientId={process.env.JG_OAUTH_API_KEY}
            redirectUri={process.env.JG_REDIRECT_URI}
            homeUrl={getCurrentUrl()}
            popup={!isFacebookApp()}
            popupWindowFeatures='width=1024,height=900,status=1'
            onSuccess={this.handleAuthSuccess}
            styles={styles.provider}
            useLocalStorage={isFacebookApp()}
          />
        </div>
      )
    }

    const providers = [
      facebook === 'Yes' && 'facebook',
      mapmyfitness === 'Yes' && 'mapmyfitness',
      strava === 'Yes' && 'strava'
    ].filter((key) => key)

    return providers.length > 0 && (
      <div className={classNames.providers}>
        {providers.map((provider, i) => (
          <ProviderOauthButton
            block
            key={provider}
            provider={provider}
            label={`${method} with ${provider}`}
            clientId={clientId || getEDHClientId()}
            redirectUri={redirectUri || process.env.EDH_REDIRECT_URI}
            homeUrl={getCurrentUrl()}
            onSuccess={this.handleAuthSuccess}
            styles={styles.provider}
            useLocalStorage={oauthMethod === 'Advanced'}
            {...button}
          />
        ))}
      </div>
    )
  }

  termsLink (countryCode) {
    return isJustGiving()
      ? 'https://www.justgiving.com/info/terms-of-service'
      : `https://everydayhero.com/${countryCode}/terms`
  }

  privacyLink (countryCode) {
    return isJustGiving()
      ? 'https://www.justgiving.com/info/privacy'
      : `https://everydayhero.com/${countryCode}/terms/privacy`
  }

  renderAuthForm () {
    const {
      button,
      clientId,
      country,
      foreground,
      isMobile,
      jgOauthMethod,
      jgOauthSignUp,
      jgOauthSignIn,
      jgOauthLogo,
      location = {},
      loginLabel,
      loginTitle,
      oauthCopy,
      resetPasswordLabel,
      resetPasswordTitle,
      signupLabel,
      signupTitle,
      styles,
      url,
      useJGOauth
    } = this.props

    const { modal } = this.state

    const platform = isJustGiving() ? 'JustGiving' : 'Everydayhero'
    const platformWithArticle = isJustGiving() ? 'a JustGiving' : 'an Everydayhero'
    const countryCode = this.mapCountry(country)
    const resetPasswordUrl = [url, location.pathname, '#reset-password'].join('')
    const oauthParams = merge({
      SignInTitle: jgOauthSignIn || 'Login to your account',
      SignUpTitle: jgOauthSignUp || 'Create your account'
    }, jgOauthLogo && {
      ThemeColor: '#ad29b6',
      HeaderImage: jgOauthLogo
    })

    const formComponent = {
      styles: styles.form,
      submitProps: {
        ...button,
        background: 'justgiving',
        foreground: 'light'
      }
    }

    if (isJustGiving() && useJGOauth === 'yes') {
      return (
        <FormState
          foreground={foreground}
          state='oauth'>
          <RichText styles={styles.copy}>
            {oauthCopy}
          </RichText>
          {['Yes/No Buttons', 'Email Check'].indexOf(jgOauthMethod) !== -1 ? (
            <JGConnectForm
              clientId={process.env.JG_OAUTH_API_KEY}
              homeUrl={getCurrentUrl()}
              inputField={{ styles: styles.emailCheck }}
              onSuccess={this.handleAuthSuccess}
              oauthParams={oauthParams}
              popup={!isMobile}
              redirectUri={process.env.JG_REDIRECT_URI}
              showButtons={jgOauthMethod === 'Yes/No Buttons'}
            />
          ) : (
            <ProviderOauthButton
              provider='justgiving'
              label='Connect to JustGiving'
              clientId={process.env.JG_OAUTH_API_KEY}
              homeUrl={getCurrentUrl()}
              popup={!isMobile}
              redirectUri={process.env.JG_REDIRECT_URI}
              onSuccess={this.handleAuthSuccess}
              styles={styles.provider}
              {...button}
            />
          )}
        </FormState>
      )
    }

    switch (this.state.view) {
      case 'reset-password':
        return (
          <FormState
            state='reset-password'
            foreground={foreground}
            links={[
              { label: 'Back to login', onClick: () => this.setState({ view: 'login' }) },
              { label: `Need ${platformWithArticle} account?`, onClick: () => this.setState({ view: 'signup' }) }
            ]}>
            <Heading styles={styles.heading}>{resetPasswordTitle || `Reset your ${platform} password`}</Heading>
            <BaseRichText
              styles={styles.copy}
              children='Our online fundraising is powered by <a href="https://www.justgiving.com" target="_blank">JustGiving</a>.'
            />
            <ResetPassword
              clientId={clientId || getEDHClientId()}
              formComponent={formComponent}
              onSuccess={(result) => this.setState({ modal: true })}
              returnTo={url}
              submit={resetPasswordLabel || 'Send reset password instructions'}
            />
            <Modal
              contentLabel='Email sent!'
              isOpen={modal}
              onRequestClose={() => this.setState({ modal: false, view: 'login' })}
              spacing={1.5}>
              <Success title='Email sent!'>
                <RichText children={[
                  { type: 'paragraph', text: `If ${platformWithArticle} account exists with the email address entered, you will receive an email within the next few minutes.` },
                  { type: 'paragraph', text: 'It contains instructions for changing your password.' }
                ]} />
              </Success>
            </Modal>
          </FormState>
        )
      case 'login':
        return (
          <FormState
            state='login'
            foreground={foreground}
            links={[
              { label: `Forgot your ${platform} password?`, onClick: () => this.setState({ view: 'reset-password' }) },
              { label: `Need ${platformWithArticle} account?`, onClick: () => this.setState({ view: 'signup' }) }
            ]}>
            <Heading styles={styles.heading}>{loginTitle || `Login to ${platform}`}</Heading>
            <BaseRichText
              styles={styles.copy}
              children='Our online fundraising is powered by <a href="https://www.justgiving.com" target="_blank">JustGiving</a>.'
            />
            <LoginForm
              clientId={clientId || getEDHClientId()}
              country={countryCode}
              formComponent={formComponent}
              onSuccess={this.handleAuthSuccess}
              submit={loginLabel || 'Log in to JustGiving'}
            />
            <Divider />
            {this.renderAuthProviders('Login')}
          </FormState>
        )
      default:
        const fields = {
          target: {
            label: <span>I agree to {platform}&apos;s <a href={this.termsLink(countryCode)} target='_blank'>{isJustGiving() ? 'terms of service' : 'terms & conditions'}</a> and <a href={this.privacyLink(countryCode)} target='_blank'>privacy policy</a>.</span>,
            type: 'checkbox',
            required: true,
            validators: [
              validators.required(`You must agree to ${isJustGiving() ? 'JustGiving' : 'Everydayhero'}'s terms and conditions`)
            ]
          }
        }

        return (
          <FormState
            state='signup'
            foreground={foreground}
            links={[{ label: `Already have ${platformWithArticle} account?`, onClick: () => this.setState({ view: 'login' }) }]}>
            <Heading styles={styles.heading}>{signupTitle || `Create ${platformWithArticle} account`}</Heading>
            <BaseRichText
              styles={styles.copy}
              children='Our online fundraising is powered by <a href="https://www.justgiving.com" target="_blank">JustGiving</a>.'
            />
            <SignupForm
              clientId={clientId || getEDHClientId()}
              country={countryCode}
              fields={fields}
              formComponent={formComponent}
              onSuccess={this.handleAuthSuccess}
              resetPasswordUrl={resetPasswordUrl}
              resetPasswordTarget='_self'
              submit={signupLabel || 'Sign up (Step 1 of 2)'}
            />
            <Divider />
            {this.renderAuthProviders('Sign up')}
          </FormState>
        )
    }
  }

  splitOnDelimiter (string) {
    const hasDelimiter = delimiter => string.split(delimiter).length > 1

    if (hasDelimiter('\n')) {
      return string.split('\n')
    }

    return string.split(',')
  }

  renderCreatePageForm () {
    const {
      addressCountry,
      addressLabel,
      button,
      campaignId,
      charityId,
      classNames,
      clientId,
      country,
      createPageLabel,
      createPageTitle,
      defaultPageImage,
      defaultPageStory,
      customCode1,
      customCode2,
      customCode3,
      eventId,
      fitnessGoalLabel,
      foreground,
      gdprLabel,
      includeCharitySearch,
      items = [],
      pageEventDateLabel,
      pageNameLabel,
      pageNameValue,
      pageNamePlaceholder,
      pageTargetLabel,
      preselectedCharity = {},
      presetFitnessGoal,
      showAddress,
      showEventDate,
      showFitnessGoal,
      showName,
      showTarget,
      styles,
      tagDefinitions = [],
      terms,
      uom,
      user = {}
    } = this.props

    const hasCustomCodes = isJustGiving() && (customCode1 || customCode2 || customCode3)
    const defaultPageName = template(pageNameValue)(user)
    const pageNameField = isJustGiving() ? 'title' : 'name'

    const fields = merge({}, !isJustGiving() && {
      birthday: {
        showSelects: true,
        placeholder: null,
        order: 2,
        max: moment().subtract(1, 'day').format(moment.HTML5_FMT.DATE),
        validators: [
          validators.required('Please enter your date of birth'),
          val => !!val && moment(val).isAfter(moment().subtract(1, 'day').startOf('day')) && 'Please select a date in the past'
        ]
      }
    },
    (showName === 'Yes' || isJustGiving()) && {
      [pageNameField]: merge({}, {
        label: pageNameLabel || `Page ${pageNameField}`,
        type: 'text',
        placeholder: `${user.firstName ? `${user.firstName}'s` : 'My'} fundraising page`,
        required: true,
        order: 1,
        validators: [
          validators.required(`Please enter a page ${pageNameField}`)
        ]
      }, defaultPageName && {
        [pageNamePlaceholder ? 'placeholder' : 'initial']: defaultPageName
      })
    },
    showTarget === 'Yes' && {
      target: {
        label: pageTargetLabel || 'Fundraising target',
        type: 'number',
        required: true,
        order: 1,
        validators: [
          validators.required('Please enter a fundraising target'),
          val => (val && parseInt(val) <= 0) && `Minimum target is ${numbro(1).formatCurrency('0')}`
        ],
        styles: styles.target
      }
    },
    showEventDate === 'Yes' && {
      [isJustGiving() ? 'eventDate' : 'campaignDate']: {
        label: pageEventDateLabel || 'Event date',
        type: 'date',
        required: true,
        order: 2,
        validators: [
          validators.required('Please enter an event date')
        ]
      }
    },
    (presetFitnessGoal || showFitnessGoal === 'Yes') && {
      [isJustGiving() ? 'summaryWhat' : 'fitnessGoal']: {
        label: fitnessGoalLabel || `Fitness goal (in ${uom})`,
        type: 'number',
        required: true,
        initial: presetFitnessGoal,
        order: 2,
        validators: [
          validators.required('Please enter a fitness goal')
        ],
        styles: styles.fitnessGoal
      },
      summaryWhy: {
        initial: fitnessGoalLabel || 'Fitness goal',
        styles: styles.hiddenField
      }
    },
    defaultPageImage && {
      image: {
        initial: defaultPageImage.url,
        styles: styles.hiddenField
      }
    },
    isJustGiving() && defaultPageStory && {
      story: {
        initial: defaultPageStory,
        styles: styles.hiddenField
      }
    },
    !isEmpty(gdprLabel) && {
      charityOptIn: {
        label: <RichText styles={styles.termsLabel}>{gdprLabel}</RichText>,
        type: 'checkbox',
        order: 3,
        styles: styles.termsCheckbox
      }
    },
    !isRichTextEmpty(terms) && {
      terms: {
        label: <RichText styles={styles.termsLabel}>{terms}</RichText>,
        type: 'checkbox',
        required: true,
        order: 4,
        validators: [
          validators.required('You must agree to the terms & conditions.')
        ],
        styles: styles.termsCheckbox
      }
    },
    hasCustomCodes && {
      customCodes: {
        type: 'hidden',
        initial: {
          customCode1,
          customCode2,
          customCode3
        }
      }
    }, keyBy(tagDefinitions.map(tag => ({
      name: tag.id,
      label: <RichText styles={styles.label}>{tag.label}</RichText>,
      type: 'select',
      order: 4,
      placeholder: 'Please select',
      options: tag.values.map(option => ({ label: option.trim(), value: option.trim() })),
      required: tag.required,
      initial: tag.initial,
      validators: tag.required ? [validators.required('Please select an option')] : []
    })), 'name'),
    keyBy(items.map((field, index) => {
      const fieldProps = {
        name: this.mapFieldName(field, index),
        label: <RichText styles={styles.label}>{field.label}</RichText>,
        type: field.type,
        required: field.required === 'Yes',
        order: field.order,
        initial: field.initial,
        validators: field.required === 'Yes' ? [
          validators.required(field.validationLabel || 'This field is required')
        ] : []
      }

      switch (field.type) {
        case 'label':
          return {
            ...fieldProps,
            children: (
              <div className={classNames.labelWrapper}>
                <RichText styles={styles.label}>{field.label}</RichText>
              </div>
            )
          }
        case 'select':
          return {
            ...fieldProps,
            options: [
              { label: 'Please select', value: '' },
              ...this.splitOnDelimiter(field.options || '').map(option => ({ label: option.trim(), value: option.trim() }))
            ]
          }
        case 'date':
          return {
            ...fieldProps,
            showSelects: true
          }
        case 'birthday':
          return {
            ...fieldProps,
            type: 'date',
            showSelects: true,
            validators: [
              ...fieldProps.validators,
              val => !!val && moment(val).isAfter(moment().subtract(18, 'year').startOf('day')) && 'You must be at least 18 years old'
            ].filter(Boolean)
          }
        default:
          return fieldProps
      }
    }), 'name'))

    return (
      <FormState
        state='create-page'
        foreground={foreground}
        links={[{ label: 'Log out', onClick: this.handleLogout }]}>
        <Heading styles={styles.heading}>{mapTemplate(createPageTitle || 'Create Fundraising Page', { user })}</Heading>
        <CreatePageForm
          authType={user.authType}
          campaignId={campaignId || getUID(this.props.campaign)}
          charityId={charityId || getUID(this.props.charity) || preselectedCharity.id}
          clientId={clientId || getEDHClientId()}
          country={this.mapCountry(addressCountry, country)}
          eventId={eventId || getUID(this.props.event)}
          fields={fields}
          addressSearchLabel={addressLabel}
          formComponent={{ styles: styles.createPageForm, submitProps: button }}
          includeAddress={showAddress === 'Yes'}
          includeCharitySearch={this.getCharitySearchOption(includeCharitySearch)}
          includePhone={!user.phone}
          key={preselectedCharity.id}
          onSubmitError={this.handleValidationErrors}
          onSuccess={this.handleCreatePageSuccess}
          submit={createPageLabel || 'Create Page (Step 2 of 2)'}
          token={user.token}
          user={user}
        />
      </FormState>
    )
  }

  renderSuccess () {
    const {
      button,
      campaignId,
      charityId,
      country,
      foreground,
      hasCustomSupporterPages,
      includeTeam,
      includeSelfDonate,
      page,
      preselectedTeam,
      selfDonateAmount,
      selfDonateButtonLabel,
      selfDonateReference,
      styles,
      successButtonLabel,
      successCopy,
      successTitle,
      successTeamCopy,
      successCreateTeamButtonLabel,
      successJoinTeamButtonLabel,
      user
    } = this.props

    return (
      <Success id='register-success' title={mapTemplate(successTitle || 'Congratulations <%= userName %>!', { user, page })}>
        {successCopy.length > 0 && (
          <RichText styles={styles.copy}>
            {successCopy.map((item) => ({
              ...item,
              text: mapTemplate(item.text, { user, page })
            }))}
          </RichText>
        )}
        <ButtonGroup>
          <Button
            href={[page.url, hasCustomSupporterPages && 'wizard=true'].filter(Boolean).join('?')}
            size={0.5}
            styles={styles.button}
            tag='a'
            {...button}>
            {mapTemplate(successButtonLabel || 'Visit your page', { user, page })}
          </Button>
          {includeSelfDonate === 'Yes' && (
            <Button
              href={this.buildDonationUrl(page, selfDonateAmount, selfDonateReference)}
              tag='a'>
              {mapTemplate(selfDonateButtonLabel || `Donate ${selfDonateAmount ? numbro(selfDonateAmount).formatCurrency('0,0') : ''} to your page`, { user, page })}
            </Button>
          )}
        </ButtonGroup>
        {includeTeam === 'Yes' && (
          <TeamRegistration
            campaign={campaignId || this.props.campaign}
            charity={charityId || this.props.charity}
            country={country}
            page={page}
            preselectedTeam={preselectedTeam}
            successTeamCopy={successTeamCopy}
            successCreateTeamButtonLabel={successCreateTeamButtonLabel}
            successJoinTeamButtonLabel={successJoinTeamButtonLabel}
            user={user}
          />
        )}
        <Button
          background='transparent'
          foreground={foreground}
          onClick={this.handleLogout}
          styles={styles.link}
          tag='button'>
          Log out
        </Button>
      </Success>
    )
  }

  renderReturn () {
    const {
      button,
      foreground,
      page,
      returnCopy,
      returnTitle,
      styles,
      user
    } = this.props

    return (
      <div>
        <Heading spacing={{ b: 1 }}>{returnTitle || 'Welcome back'}</Heading>
        {returnCopy && (
          <RichText styles={styles.copy}>
            {returnCopy.map((item) => ({
              ...item,
              text: mapTemplate(item.text, { user, page })
            }))}
          </RichText>
        )}
        <ButtonGroup>
          <Button
            href={page.url}
            size={0.5}
            styles={styles.button}
            tag='a'
            {...button}>
            View Page
          </Button>
          <Button
            background='transparent'
            foreground={foreground}
            onClick={this.handleLogout}
            styles={styles.button}
            tag='button'>
            Log out
          </Button>
        </ButtonGroup>
      </div>
    )
  }

  render () {
    const {
      alignment,
      background,
      backgroundImage,
      clearCharity,
      copy,
      copyAlignment,
      foreground,
      layout,
      page,
      preselectedCharity,
      size,
      spacing,
      subtitle,
      title,
      titleForeground,
      user
    } = this.props

    const returningUser = get(user, 'hasExistingPage')

    return (
      <div ref='root'>
        <Section
          alignment={alignment}
          backgroundImage={backgroundImage}
          background={background}
          foreground={foreground}
          size={size}
          spacing={spacing}>

          <Content
            copy={!returningUser && copy}
            copyAlignment={copyAlignment}
            layout={layout}
            subtitle={subtitle}
            title={!returningUser && title}
            titleForeground={titleForeground}>

            {this.state.loaded ? (
              <div data-step={page ? 3 : user ? 2 : 1}>
                {this.isCharityPreselected() && !page && (
                  <PreselectedCharity
                    charity={preselectedCharity}
                    onCancel={() => {
                      clearCharity()
                    }}
                  />
                )}

                {page
                  ? returningUser ? this.renderReturn() : this.renderSuccess()
                  : user ? this.renderCreatePageForm() : this.renderAuthForm()
                }
              </div>
            ) : (
              <Loading />
            )}
          </Content>
        </Section>
      </div>
    )
  }
}

RegisterForm.defaultProps = {
  clientId: getEDHClientId(),
  redirectUri: process.env.EDH_REDIRECT_URI,
  includeTeam: 'Yes'
}

const mapState = (store) => {
  const { apiParams } = selectAPIParams(store)

  return {
    ...apiParams,
    ...selectLocalisation(store),
    ...selectCustomSupporterPagePresent(store),
    header: selectHeader(store),
    button: selectButtonDefaults(store),
    user: selectUser(store),
    page: selectPage(store),
    preselectedCharity: selectPreselectedCharity(store),
    preselectedTeam: selectPreselectedTeam(store),
    tagDefinitions: selectTagDefinitions(store),
    url: selectUrl(store)
  }
}

const mapDispatch = { clearSession, clearCharity, fetchSessionUser, fetchSessionPage }

export default compose(
  withRouter,
  connect(mapState, mapDispatch),
  withStyles(styles),
  withMobile
)(RegisterForm)
