import React from 'react'
import Helmet from 'react-helmet'
import compact from 'lodash/compact'
import { stripTags } from 'supporticon/utils/html'
import { withInjectParams } from '../InjectParams'

const Head = ({
  title,
  description,
  keywords,
  author,
  ogType,
  ogTitle,
  ogDescription,
  ogImage = {},
  ogUrl = {},
  url = {},
  rssUrl = {},
  favicon = {},
  appleTouchIcon = {},
  customStyles,
  customStylesheets = [],
  customScripts = [],
  customTags = [],
  injectParams
}) => {
  const tags = [
    title && <title key='title'>{injectParams(title)}</title>,
    description && <meta key='meta-description' name='description' content={injectParams(stripTags(description))} />,
    keywords && <meta key='meta-keywords' name='keywords' content={injectParams(keywords)} />,
    author && <meta key='meta-author' name='author' content={injectParams(author)} />,
    ogType && <meta key='meta-og-type' property='og:type' content={injectParams(ogType)} />,
    ogTitle && <meta key='meta-og-title' property='og:title' content={injectParams(stripTags(ogTitle))} />,
    ogDescription && <meta key='meta-og-description' property='og:description' content={injectParams(stripTags(ogDescription))} />,
    ogImage.url && <meta key='meta-og-image' property='og:image' content={ogImage.url} />,
    ogUrl.url && <meta key='meta-og-url' property='og:url' content={ogUrl.url} />,
    url.url && <link key='rel-canonical' rel='canonical' href={url.url} />,
    rssUrl.url && <link key='rss-url' rel='alternate' href={rssUrl.url} type='application/rss+xml' title='RSS Feed' />,
    favicon.url && <link key='rel-shortcut-icon' rel='shortcut icon' href={favicon.url} />,
    appleTouchIcon.url && <link key='rel-apple-touch-icon' rel='apple-touch-icon' href={appleTouchIcon.url} />,
    customStyles && <style key='custom-styles' type='text/css'>{customStyles}</style>,
    ...customStylesheets.map((link, key) => link.url && <link key={key} rel='stylesheet' href={link.url} />),
    ...customScripts.map((script, key) => script.url && <script {...(script.async === 'yes' ? { async: true } : {})} key={key} type='text/javascript' src={script.url} />),
    ...customTags.map(({ key, property, content }, index) =>
      content ? <meta key={index} {...{ [key]: property, content }} /> : null
    )
  ]

  return (
    <Helmet>
      {compact(tags)}
    </Helmet>
  )
}

export default withInjectParams(Head)
