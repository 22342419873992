export default (props, traits) => {
  const { colors, rhythm } = traits

  return {
    modal: {
      content: {
        maxWidth: rhythm(30)
      }
    },

    root: {
      padding: rhythm(1),

      '& table': {
        '& th, & td': {
          border: `1px solid ${colors.shade}`,
          padding: rhythm(0.5)
        },

        '& th': {
          fontWeight: 700
        }
      }
    }
  }
}
