import { deserializeTeam, fetchTeamBySlug } from 'supporticon/api/teams'
import { createAction } from 'supporticon/utils/actions'
import { createReducer } from 'supporticon/utils/reducers'
import { isJustGiving } from '../../lib/environment'

// Constants
const NAMESPACE = 'app/teams'

// Selectors
export * from './selectors'

// Action
export const fetchTeam = (slug, campaign) => createAction({
  namespace: NAMESPACE,
  fetcher: isJustGiving() ? fetchJGTeam(slug) : fetchEDHTeam(slug, campaign)
})

const fetchJGTeam = (slug) => fetchTeamBySlug(slug, { includeFitness: true, includePages: true })

const fetchEDHTeam = (slug, campaign) =>
  fetchTeamBySlug({
    slug,
    campaignSlug: campaign.slug,
    countryCode: campaign.countryCode
  })

const customDeserializeTeam = data => {
  const team = deserializeTeam(data)

  return {
    ...team,
    raised: team.members.reduce((total, member) => total + member.raised, 0),
    url: `/teams/${team.slug}`
  }
}

// Reducer
export default createReducer({
  namespace: NAMESPACE,
  deserialize: customDeserializeTeam
})
