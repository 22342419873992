import React from 'react'
import { connect } from 'react-redux'
import { convertToMeters, convertToSeconds } from 'supporticon/utils/units'
import { isJustGiving } from 'supporticon/utils/client'
import { formatCustomFitnessType } from '../../../../lib/fitness'
import { refetchFundraisingPage } from '../../../../store/fundraisingPage'
import { selectLocalisation } from '../../../../store/site'

import Activity from '../Activity'
import Button from '../../../ui/Button'
import CreateFitnessForm from 'supporticon/components/create-fitness-form'
import Modal from '../../../ui/FlatModal'
import Section from 'constructicon/section'
import Success from '../../../ui/Success'
import Title from '../../../ui/Title'

class CreateFitness extends React.Component {
  constructor () {
    super()
    this.state = {
      data: {},
      modal: false,
      success: false
    }
  }

  handleClose () {
    this.setState({ modal: false, success: false })
  }

  render () {
    const { data, modal, success } = this.state
    const {
      customFitnessType,
      customFitnessTypePlural,
      fitnessTypes,
      isCustom,
      onSuccess,
      page,
      refetchFundraisingPage,
      uom,
      user
    } = this.props

    const label = isCustom ? `Log ${formatCustomFitnessType(customFitnessType, customFitnessTypePlural)}` : 'Log fitness'

    return (
      <div>
        <Button onClick={() => this.setState({ modal: true })}>
          {label}
        </Button>
        <Modal
          contentLabel={label}
          isOpen={modal}
          spacing={1.5}
          onRequestClose={() => this.handleClose()}
        >
          {success ? (
            <Success title='Logged successfully'>
              <Section spacing={{ b: 0.5 }}>
                <Activity
                  {...data}
                  activityType='fitness'
                  distance={convertToMeters(data.distance, data.unit)}
                  duration={convertToSeconds(data.duration, data.durationUnit)}
                  elevation={convertToMeters(data.elevation, data.elevationUnit)}
                  customFitnessType={customFitnessType}
                  isCustom={isCustom}
                />
              </Section>
              <Button onClick={() => this.handleClose()}>
                Close
              </Button>
            </Success>
          ) : (
            <div>
              <Title spacing={{ b: 1 }}>{label}</Title>
              <CreateFitnessForm
                distanceLabel={isCustom ? formatCustomFitnessType(customFitnessType, customFitnessTypePlural) : 'Distance'}
                type={fitnessTypes.length > 0 && fitnessTypes[0]}
                types={fitnessTypes.filter(type => type !== 'manual')}
                includeUnit={!isCustom}
                includeDuration={!isCustom}
                includeElevation={!isCustom}
                includeType={fitnessTypes.length > 1}
                inputField={{
                  styles: {
                    label: {
                      '&:first-letter': {
                        textTransform: 'capitalize'
                      }
                    }
                  }
                }}
                onSuccess={(_, data) => {
                  this.setState({ data, success: true }, () => setTimeout(onSuccess, 1000))
                  refetchFundraisingPage(page.slug)
                }}
                pageId={isJustGiving() ? page.uuid : page.id}
                pageSlug={page.slug}
                uom={uom}
                userId={user.uuid}
                submit={label}
                token={user.token}
              />
            </div>
          )}
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  ...selectLocalisation(state)
})

const mapDispatchToProps = { refetchFundraisingPage }

export default connect(mapStateToProps, mapDispatchToProps)(CreateFitness)
