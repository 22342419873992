export default (props, { mediaQuery, rhythm }) => {
  return {
    root: {
      textAlign: 'center'
    },
    form: {
      maxWidth: rhythm(16),
      margin: '1rem auto 0',
      justifyContent: 'center',
      '& > *': {
        margin: rhythm(0.25),
        flex: 1
      },
      select: {
        width: 'auto'
      },
      [mediaQuery('sm')]: {
        display: 'flex',
        flexWrap: 'wrap'
      }
    },
    checkbox: {
      root: {
        flex: '0 1 auto'
      }
    },
    buttons: {
      maxWidth: rhythm(12),
      margin: `0 auto ${rhythm(1)}`,
      '& > :first-child': {
        marginBottom: rhythm(0.5)
      }
    }
  }
}
