export default ({ color = 'success' }, { rhythm, colors }, keyframes) => ({
  root: {
    textAlign: 'center',
    padding: rhythm(1)
  },
  icon: {
    display: 'block',
    width: rhythm(3.5),
    height: rhythm(3.5),
    lineHeight: rhythm(3.333),
    margin: `0 auto ${rhythm(1)}`,
    textAlign: 'center',
    border: `2px solid ${colors[color]}`,
    borderRadius: '50%',
    color: colors[color],
    animation: `${keyframes.success} 750ms forwards`,
    animationTimingFunction: 'cubic-bezier(0.175, 0.885, 0.32, 1.275)'
  }
})

export const keyframes = {
  success: {
    from: {
      opacity: 0.25,
      transform: 'scale(0.25) rotate(-180deg)'
    },
    to: {
      opacity: 1,
      transform: 'scale(1) rotate(0)'
    }
  }
}
