export default ({
  fontSize = 1,
  fontStyle,
  foreground,
  hoverEffect
}, {
  colors,
  effects,
  rhythm,
  scale,
  treatments
}) => ({
  link: {
    display: 'inline-block',
    position: 'relative',
    overflow: 'hidden',
    maxWidth: '100%'
  },

  image: {
    ...effects[hoverEffect]
  },

  copy: {
    position: 'absolute',
    bottom: rhythm(0.5),
    left: rhythm(0.75),
    color: colors[foreground],
    fontSize: scale(fontSize),
    pointerEvents: 'none',
    ...treatments[fontStyle],

    '> span > *:first-child': {
      paddingBottom: 0
    }
  }
})
