import React from 'react'
import Error from '../../../ui/Error'
import FacebookPage from '../FacebookPage'
import TwitterTimeline from '../TwitterTimeline'

const Feed = ({
  handle,
  network,
  title
}) => {
  switch (network) {
    case 'facebook':
      return (
        <FacebookPage
          width={500}
          href={`https://www.facebook.com/${handle}`}
          tabs={[ 'timeline' ]}
        />
      )
    case 'twitter':
      return (
        <TwitterTimeline
          chrome='noheader transparent nofooter'
          user={`@${handle}`}
          limit={6}
        />
      )
    default:
      return <Error>Unknown social feed type</Error>
  }
}

export default Feed
