export default (props, traits) => {
  return {
    root: {
      textAlign: 'center'
    },

    copy: {
      marginBottom: traits.rhythm(1)
    },

    link: {
      marginTop: traits.rhythm(0.75),
      fontSize: traits.scale(-0.5)
    }
  }
}
