export default ({
  image = {},
  includeGradient = 'yes'
}, {
  colors,
  rhythm
}) => ({
  tile: {
    display: 'flex',
    alignItems: 'flex-end',
    position: 'relative',
    minHeight: rhythm(8),
    padding: rhythm(1),
    marginBottom: rhythm(1),
    backgroundColor: colors.shade,
    backgroundImage: image.url && `url(${image.url})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',

    '&:before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: includeGradient === 'yes' && 'linear-gradient(to top, rgba(0,0,0,0.75) 0%, rgba(0,0,0,0.0) 75%)'
    }
  },

  title: {
    position: 'relative',
    color: colors.light,
    marginBottom: 0
  }
})
