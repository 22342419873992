import React from 'react'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import Error from '../../../ui/Error'
import TotalDonations from '../TotalDonations'
import TotalProjects from '../TotalProjects'
import TotalRaised from '../TotalRaised'

const Metric = ({
  classNames,
  donations,
  format,
  label,
  metric,
  multiplier,
  offset,
  projects,
  status,
  styles,
  uom
}) => {
  const metricComponents = {
    donations: {
      Component: TotalDonations,
      format: '0,0',
      label: 'Donations'
    },
    funded: {
      Component: TotalProjects,
      format: '0,0',
      props: {
        funded: true
      }
    },
    notFunded: {
      Component: TotalProjects,
      format: '0,0',
      props: {
        notFunded: true
      }
    },
    projects: {
      Component: TotalProjects,
      format: '0,0'
    },
    raised: {
      Component: TotalRaised,
      format: '0,0',
      label: 'Amount Raised'
    }
  }

  const {
    Component,
    props,
    ...defaults
  } = metricComponents[metric] || {}

  if (!metric) {
    return <Error>Unspecified metric type</Error>
  }

  if (!Component) {
    return <Error>{`Unknown metric type "${metric}"`}</Error>
  }

  return (
    <Component
      donations={donations}
      format={format || defaults.format}
      label={label || defaults.label}
      multiplier={multiplier || 1}
      metric={{ styles }}
      offset={offset}
      projects={projects}
      status={status}
      {...props}
    />
  )
}

export default withStyles(styles)(Metric)
