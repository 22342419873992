import React from 'react'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import FlatModal from '../../ui/FlatModal'
import Heading from 'constructicon/heading'
import ProfilePicture from './ProfilePicture'
import SelfDonate from './SelfDonate'
import SharePage from './SharePage'

class OnboardingWizard extends React.Component {
  constructor () {
    super()
    this.state = {
      step: 'picture'
    }
  }

  render () {
    const { step } = this.state

    const {
      classNames,
      customSupporterPage,
      fundraisingPage,
      onClose,
      styles,
      user
    } = this.props

    const steps = {
      picture: {
        Component: ProfilePicture,
        heading: 'Step 1: Upload a profile picture',
        onNext: () => this.setState({ step: 'share' })
      },
      share: {
        Component: SharePage,
        heading: 'Step 2: Share your page',
        onNext: () => this.setState({ step: 'donate' })
      },
      donate: {
        Component: SelfDonate,
        heading: 'Step 3: Get the ball rolling',
        onNext: onClose
      }
    }

    const {
      Component,
      heading,
      onNext
    } = steps[step]

    return (
      <FlatModal
        contentLabel='Setup your page'
        isOpen
        onRequestClose={onClose}
        styles={styles.root}>
        <Heading>Setup your page</Heading>
        <div className={classNames.stepHeading}>{heading}</div>
        <Component
          customSupporterPage={customSupporterPage}
          fundraisingPage={fundraisingPage}
          onNext={onNext}
          user={user}
        />
        <button
          className={classNames.skip}
          onClick={onNext}>
          Skip
        </button>
      </FlatModal>
    )
  }
}

export default withStyles(styles)(OnboardingWizard)
