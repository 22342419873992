import merge from 'lodash/merge'

export default ({
  styles = {},
  textAlign = 'left'
}, {
  colors,
  rhythm
}) => ({
  root: merge({
    content: {
      borderWidth: 0,
      borderRadius: 0,
      textAlign
    },

    container: {
      maxHeight: `calc(100vh - 6rem)`
    },

    close: {
      height: rhythm(1.25),
      width: rhythm(1.25),
      borderRadius: '50%',
      textAlign: 'center',
      backgroundColor: colors.light,
      color: colors.dark
    }
  }, styles)
})
