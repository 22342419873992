import React, { Component } from 'react'
import debounce from 'lodash/debounce'
import throttle from 'lodash/throttle'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import Container from 'constructicon/container'
import Section from 'constructicon/section'

class HeaderContainer extends Component {
  constructor (props) {
    super(props)
    this.state = { isTransparent: props.isOverlaid }
    this.handleScroll = throttle(this.handleScroll.bind(this), 100)
    this.handleResize = debounce(this.handleResize.bind(this), 100)
  }

  componentDidMount () {
    const { isOverlaid, isSticky } = this.props

    window.addEventListener('resize', this.setAppHeight)
    this.setAppHeight()

    if (isSticky && isOverlaid) {
      window.addEventListener('scroll', this.handleScroll)
    } else if (isSticky) {
      setTimeout(this.handleResize, 1000)
      window.addEventListener('resize', this.handleResize)
    }
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.handleResize)

    if (this.shouldApplyScroll) {
      window.removeEventListener('scroll', this.handleScroll)
    }
  }

  setAppHeight () {
    const doc = document.documentElement
    doc.style.setProperty('--document-height', `${window.innerHeight}px`)
  }

  handleScroll () {
    const scrollPos = window.pageYOffset
    const { isTransparent } = this.state

    if (!isTransparent && scrollPos < 50) {
      this.handleScroll = throttle(this.handleScroll.bind(this), 100)
      this.setState({ isTransparent: true })
    }

    if (isTransparent && scrollPos > 50) {
      this.handleScroll = debounce(this.handleScroll.bind(this), 100)
      this.setState({ isTransparent: false })
    }
  }

  handleResize () {
    const headerEl = this.refs.root.querySelector('#header')

    if (this.refs.root) {
      this.refs.root.style.marginTop = headerEl.clientHeight + 'px'
    }
  }

  render () {
    const {
      background,
      children,
      classNames,
      foreground,
      isOverlaid,
      isSticky,
      styles
    } = this.props

    const { isTransparent } = this.state

    return (
      <div className={classNames.wrapper} ref='root'>
        <Section
          tag='header'
          id='header'
          role='banner'
          aria-label='Site header'
          background={isTransparent ? 'transparent' : background}
          foreground={foreground}
          styles={{
            ...styles.root,
            ...(isOverlaid && styles.overlaid),
            ...(isSticky && styles.sticky),
            ...(isTransparent && styles.transparent)
          }}
        >
          <Container>{children}</Container>
        </Section>
      </div>
    )
  }
}

export default withStyles(styles)(HeaderContainer)
