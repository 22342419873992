import React, { Component } from 'react'
import compact from 'lodash/compact'
import moment from 'moment'
import numbro from 'numbro'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { refetchFundraisingPage } from '../../../../store/fundraisingPage'
import { selectLocalisation } from '../../../../store/site'
import { isJustGiving } from '../../../../lib/environment'
import { formatDistance, formatDuration, formatElevation } from 'supporticon/utils/fitness'
import { formatCustomFitnessType } from '../../../../lib/fitness'
import { fetchOembedUrl } from 'constructicon/lib/oembed'
import currencySymbol, { symbolFromCurrencyCode } from '../../../../lib/currencySymbol'
import { currencyCode } from 'supporticon/utils/currencies'
import withToggle from 'constructicon/with-toggle'
import withStyles from 'constructicon/with-styles'
import styles, { keyframes } from './styles'

import Button from 'constructicon/button'
import Confirm from '../../../ui/Confirm'
import FitnessElevation from '../../../ui/FitnessElevation'
import FitnessMap from '../../../ui/FitnessMap'
import Heading from 'constructicon/heading'
import Icon from 'constructicon/icon'
import Modal from '../../../ui/FlatModal'
import ReplyToDonation from '../ReplyToDonation'
import RichText from 'constructicon/rich-text'

class Activity extends Component {
  constructor (props) {
    super(props)
    this.state = {
      embed: {},
      modal: false,
      reply: props.reply,
      status: 'fetched'
    }
  }

  componentDidMount () {
    const { activityType, video } = this.props

    if (activityType === 'post' && video) {
      fetchOembedUrl(video)
        .then(embed => this.setState({ embed }))
    }
  }

  fitnessType () {
    const { customFitnessType, type } = this.props

    if (customFitnessType) {
      return customFitnessType
    }

    switch (type) {
      case 'bike':
        return 'ride'
      case 'walk':
      case 'run':
      case 'ride':
      case 'swim':
      case 'hike':
        return type
      default:
        return 'run'
    }
  }

  render () {
    const {
      embed,
      modal,
      reply,
      status
    } = this.state

    const {
      activityType,
      amount,
      classNames,
      country,
      createdAt,
      customFitnessType,
      customFitnessTypePlural,
      currency,
      description,
      distance,
      duration,
      elevation,
      elevationSeries,
      id,
      isPageOwner,
      image,
      isCustom,
      legacyId,
      message,
      name,
      offline,
      onRemove,
      onToggleOn,
      page = {},
      polyline,
      refetchFundraisingPage,
      source,
      sourceUrl,
      styles,
      title,
      uom,
      user
    } = this.props

    const handleRemove = () =>
      Promise.resolve()
        .then(() => this.setState({ status: 'fetching' }))
        .then(() => onRemove(id || legacyId, activityType, { useLegacy: !id }))
        .then(() => refetchFundraisingPage(page.slug))
        .catch(() => this.setState({ status: 'failed' }))

    const renderIcon = () => {
      switch (activityType) {
        case 'fitness':
          return (
            <div className={classNames.icon}>
              <Icon color='light' size={customFitnessType ? 1 : 1.333} name={customFitnessType ? 'heart' : this.fitnessType()} />
            </div>
          )
        case 'post':
          return (
            <div className={classNames.icon}>
              <Icon color='light' name='chat' />
            </div>
          )
        default:
          const symbol = currencySymbol(country)
          const icon = symbol === '£' ? 'pound' : symbol === '€' ? 'euro' : 'dollar'

          return (
            <div className={classNames.icon}>
              <Icon color='light' name={icon} />
            </div>
          )
      }
    }

    const renderContent = () => {
      switch (activityType) {
        case 'fitness':
          return (
            <div className={classNames.content}>
              <Heading tag='span' size={0} styles={styles.heading}>
                {sourceUrl && (
                  <a href={sourceUrl} target='_blank' className={classNames.source}>
                    <Icon name={source} color={source} />
                  </a>
                )}
                {isCustom ? (
                  <span>
                    Logged{' '}
                    {numbro(distance).format('0,0')}{' '}
                    {formatCustomFitnessType(customFitnessType, customFitnessTypePlural, distance)}
                    {'. '}
                  </span>
                ) : (
                  <span>
                    Logged a{' '}
                    {!!distance && formatDistance(distance, uom === 'mi')}{' '}
                    {this.fitnessType()}{'. '}
                  </span>
                )}
                <small className={classNames.details}>
                  {!!duration && `Time: ${formatDuration(duration)}. `}
                  {!!elevation && `Elevation: ${formatElevation(elevation, uom === 'mi')}`}
                </small>
              </Heading>
              {(!!description || !!title) && (
                <article className={classNames.description} onClick={() => onToggleOn()}>
                  <RichText>{description || title}</RichText>
                </article>
              )}
              {polyline && (
                <a className={classNames.fitnessWidget} href={sourceUrl} target='_blank'>
                  <FitnessMap polyline={polyline} sourceUrl={sourceUrl} />
                </a>
              )}
              {elevationSeries && (
                <a className={classNames.fitnessWidget} href={sourceUrl} target='_blank'>
                  <FitnessElevation elevation={elevationSeries} sourceUrl={sourceUrl} />
                </a>
              )}
              {isPageOwner && (
                <footer className={classNames.actions}>
                  {status === 'fetching' ? (
                    <Icon name='loading' spin />
                  ) : (
                    <Confirm
                      tag={Button}
                      background='transparent'
                      foreground='dark'
                      size={-1}
                      spacing={0}
                      onConfirm={handleRemove}
                    >
                      <Icon name='trash' />
                      <span>Delete activity</span>
                    </Confirm>
                  )}
                </footer>
              )}
            </div>
          )
        case 'post':
          return (
            <div className={classNames.content}>
              <Heading tag='span' size={0} styles={styles.heading}>
                {`${page.name} posted an update`}
              </Heading>
              {!!message && (
                <article className={classNames.message} onClick={() => onToggleOn()}>
                  <RichText>{message}</RichText>
                </article>
              )}
              {image && (
                <React.Fragment>
                  <button
                    className={classNames.image}
                    onClick={() => this.setState({ modal: true })}
                  >
                    <img src={image} />
                  </button>
                  <Modal
                    contentLabel='Post image'
                    isOpen={modal}
                    onRequestClose={() => this.setState({ modal: false })}
                  >
                    <img className={classNames.modalImage} src={image} />
                  </Modal>
                </React.Fragment>
              )}
              {embed.html && (
                <figure
                  className={classNames.video}
                  dangerouslySetInnerHTML={{ __html: embed.html }}
                />
              )}
              {isPageOwner && (
                <footer className={classNames.actions}>
                  {status === 'fetching' ? (
                    <Icon name='loading' spin />
                  ) : (
                    <Confirm
                      tag={Button}
                      background='transparent'
                      foreground='dark'
                      size={-1}
                      spacing={0}
                      onConfirm={handleRemove}
                    >
                      <Icon name='trash' />
                      <span>Delete post</span>
                    </Confirm>
                  )}
                </footer>
              )}
            </div>
          )
        default:
          const renderAmount = amount => {
            const defaultCurrency = currencyCode(country)

            return currency !== defaultCurrency
              ? `${currency} ${symbolFromCurrencyCode(currency)}${numbro(amount).format('0,0.[00]')}`
              : numbro(amount).formatCurrency('0,0.[00]')
          }

          return (
            <div className={classNames.content}>
              <Heading size={0} styles={styles.heading}>
                {compact([
                  name || 'Anonymous',
                  'donated',
                  amount && renderAmount(amount),
                  offline && 'offline'
                ]).join(' ')}
              </Heading>
              {!!message && (
                <article className={classNames.message} onClick={() => onToggleOn()}>
                  <RichText>{message}</RichText>
                </article>
              )}
              {!!reply && (
                <div className={classNames.reply}>
                  {reply}
                </div>
              )}
              {isPageOwner && !offline && !reply && !isJustGiving() && (
                <footer className={classNames.actions}>
                  <ReplyToDonation
                    background='transparent'
                    donationId={id}
                    donorName={name}
                    foreground='dark'
                    onSuccess={reply => this.setState({ reply })}
                    pageId={page.id}
                    size={-1}
                    spacing={0}
                    tag={Button}
                    token={user.token}>
                    <Icon name='reply' />
                    <span>Send reply</span>
                  </ReplyToDonation>
                </footer>
              )}
            </div>
          )
      }
    }

    return (
      <li className={classNames.root}>
        {renderIcon()}
        {renderContent()}
        <span className={classNames.date}>{!!createdAt && moment(createdAt).fromNow()}</span>
      </li>
    )
  }
}

const mapStateToProps = (state) => ({
  ...selectLocalisation(state)
})

const mapDispatchToProps = { refetchFundraisingPage }

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withToggle,
  withStyles(styles, keyframes)
)(Activity)
