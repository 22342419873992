import React from 'react'
import Grid from 'constructicon/grid'
import GridColumn from 'constructicon/grid-column'
import ProgressTile from '../ProgressTile'
import Section from 'constructicon/section'

const ProgressTiles = ({
  barColor,
  buttonBackground,
  buttonForeground,
  data = [],
  metric,
  titleForeground,
  titleSize
}) => (
  <Section spacing={{ t: 1 }}>
    <Grid spacing={1} justify='flex-start' align='stretch'>
      {data.map((tile, index) => (
        <GridColumn key={index} sm={6} md={4}>
          <ProgressTile
            actions={tile.actions}
            barColor={barColor}
            buttonBackground={buttonBackground}
            buttonForeground={buttonForeground}
            hideFundraising={tile.hideFundraising}
            image={tile.image}
            metric={metric}
            name={tile.name}
            raised={tile.raised}
            status={tile.status}
            story={tile.story}
            target={tile.target}
            targetDonations={tile.targetDonations}
            titleForeground={titleForeground}
            titleSize={titleSize}
            totalDonations={tile.totalDonations}
          />
        </GridColumn>
      ))}
    </Grid>
  </Section>
)

export default ProgressTiles
