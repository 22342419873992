import moment from 'moment'
import { deserializePage, fetchPage } from 'supporticon/api/pages'
import { isJustGiving } from '../../lib/environment'
import { stripTags } from 'supporticon/utils/html'
import { selectAPIParams, selectCampaign } from '../site'

// Constants
const c = {
  CLEAR: 'app/fundraisingPages/CLEAR',
  FETCH: 'app/fundraisingPages/FETCH',
  FETCH_SUCCESS: 'app/fundraisingPages/FETCH_SUCCESS',
  FETCH_FAILURE: 'app/fundraisingPages/FETCH_FAILURE',
  PERSIST: 'app/fundraisingPages/PERSIST'
}

// Selectors
export * from './selectors'

// Action
export const fetchFundraisingPage = (slug, campaign) => (dispatch, getState) => {
  const state = getState()
  const { apiParams } = selectAPIParams(state)

  return Promise.resolve()
    .then(() => dispatch({ type: c.FETCH }))
    .then(() => isJustGiving() ? fetchJGPage(slug, apiParams) : fetchEDHPage(slug, campaign))
    .then(data => dispatch({ type: c.FETCH_SUCCESS, payload: data }))
    .catch(error => {
      dispatch({ type: c.FETCH_FAILURE })
      return Promise.reject(error)
    })
}

export const refetchFundraisingPage = (slug) => (dispatch, getState) => {
  const state = getState()
  const campaign = selectCampaign(state)
  const { apiParams } = selectAPIParams(state)

  return new Promise(resolve => setTimeout(resolve, 500))
    .then(() => isJustGiving() ? fetchJGPage(slug, apiParams) : fetchEDHPage(slug, campaign))
    .then(data => dispatch({ type: c.FETCH_SUCCESS, payload: data }))
}

export const persistPageData = data => ({
  type: c.PERSIST,
  payload: data
})

export const clearPage = () => ({
  type: c.CLEAR
})

const fetchJGPage = (slug, params = {}) =>
  fetchPage(slug, true, {
    includeFitness: true,
    includeTags: true,
    fitnessParams: {
      startDate: params.startDateTime,
      endDate: params.endDateTime
    }
  })
    .then(data => ({ ...params, ...data }))

const fetchEDHPage = (slug, campaign) => {
  const params = {
    slug,
    campaignSlug: campaign.slug,
    countryCode: campaign.countryCode
  }

  return Promise.resolve()
    .then(() => fetchPage(params))
    .then(page => fetchPage(page.id, { includeFitness: true }))
}

const hasUpdatedStory = (defaultStory, story) =>
  !!defaultStory && stripTags(story) !== stripTags(defaultStory)

// Reducer
const expandedDeserializePage = page => {
  const deserializedPage = deserializePage(page)

  return {
    ...deserializedPage,
    hasUpdatedStory: isJustGiving()
      ? hasUpdatedStory(page.defaultPageStory, deserializedPage.story)
      : deserializedPage.hasUpdatedStory,
    url: `/fundraising/${deserializedPage.slug}`,
    adminUrl: deserializedPage.url.replace('/fundraising/', '/fundraising-edit/'),
    formattedCampaignDate: deserializedPage.campaignDate &&
      moment(deserializedPage.campaignDate).format('MMMM Do YYYY'),
    fitnessGoal: isJustGiving()
      ? parseFloat(page.pageSummaryWhat)
      : deserializedPage.fitnessGoal
  }
}

export default (state = {}, { type, payload }) => {
  switch (type) {
    case c.CLEAR:
      return {}
    case c.FETCH:
      return { status: 'fetching' }
    case c.FETCH_FAILURE:
      return { status: 'failed' }
    case c.PERSIST:
      return {
        ...state,
        data: {
          ...state.data,
          ...payload
        }
      }
    case c.FETCH_SUCCESS:
      return {
        data: expandedDeserializePage(payload),
        status: 'fetched'
      }
    default:
      return state
  }
}
