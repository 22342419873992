export default ({ barHeight = 1 }, { rhythm, scale }) => ({
  progress: {
    root: {
      height: rhythm(barHeight),
      overflow: 'hidden'
    },
    fill: {
      height: rhythm(barHeight)
    }
  },
  metric: {
    label: {
      fontSize: scale(0),
      margin: 0
    },
    amount: {
      fontSize: scale(3)
    }
  },
  unit: {
    fontSize: '90%'
  }
})
