export default (props, traits) => {
  const { colors, rhythm } = traits

  return {
    root: {
      display: 'inline-block',
      padding: rhythm([0.5, 1]),
      marginTop: rhythm(0.5),
      backgroundColor: colors.success,
      color: colors.light
    }
  }
}
