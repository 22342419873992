import React from 'react'
import withStyles from 'constructicon/with-styles'
import styles from './styles'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { selectCampaign, selectButtonDefaults, selectLocalisation } from '../../../store/site'
import { persistPageData } from '../../../store/fundraisingPage'
import { fetchSessionTeam, fetchSessionUser, selectPage, selectUser, persistUserData } from '../../../store/session'
import { withRouter } from 'react-router'
import { isJustGiving } from '../../../lib/environment'

import Button from 'constructicon/button'
import EditAccountForm from './EditAccountForm'
import EditFitnessForm from './EditFitnessForm'
import FlatModal from '../../ui/FlatModal'
import Grid from 'constructicon/grid'
import GridColumn from 'constructicon/grid-column'
import Heading from 'constructicon/heading'
import ManagePage from './ManagePage'
import ManageTeam from './ManageTeam'
import PoweredBy from '../../ui/PoweredBy'
import Section from 'constructicon/section'

class EditPage extends React.Component {
  constructor (props) {
    super(props)
    const { query } = props.location

    this.state = {
      open: !!query.edit,
      selected: query.edit || props.selected || 'page'
    }
  }

  componentDidMount () {
    const { fetchSessionUser, page, user } = this.props

    if (!page) {
      fetchSessionUser(user)
    }
  }

  componentWillReceiveProps (nextProps) {
    const { edit: previous } = this.props.location.query
    const { edit: next } = nextProps.location.query

    if (previous !== next) {
      this.setState({ open: true, selected: next })
    }
  }

  render () {
    const { open, selected } = this.state

    const {
      buttonProps,
      buttonLabel = 'Edit Page',
      campaign = {},
      classNames,
      country,
      customSupporterPage,
      fetchSessionTeam,
      page,
      persistPageData,
      persistUserData,
      styles,
      user
    } = this.props

    const tabs = {
      page: {
        label: 'Page',
        heading: 'Edit Page',
        Component: ManagePage
      },
      fitness: {
        hide: !customSupporterPage.fitness.allow,
        label: 'Fitness',
        heading: 'Fitness Providers',
        Component: EditFitnessForm
      },
      team: {
        hide: !customSupporterPage.allowTeams,
        label: 'Team',
        Component: ManageTeam
      },
      account: {
        label: 'Account',
        heading: 'Edit Account',
        Component: EditAccountForm
      }
    }

    const { Component, heading } = tabs[selected]

    if (!page) return null

    return (
      <div>
        <Button
          background='transparent'
          borderWidth={0}
          foreground='dark'
          onClick={() => this.setState({ open: true })}
          radius='none'
          spacing={{ x: 0.666, y: 0.5 }}
        >
          {buttonLabel}
        </Button>
        <FlatModal
          contentLabel='Edit Page'
          isOpen={open}
          onRequestClose={() => this.setState({ open: false })}
          styles={styles.modal}>
          <Grid
            align='stretch'
            styles={styles.grid}>
            <GridColumn sm={4} md={3}>
              <nav className={classNames.nav}>
                <ol>
                  {Object.keys(tabs).map(key => {
                    const tab = tabs[key]
                    const isSelected = key === selected

                    if (tab.hide) return null

                    return (
                      <li key={key}>
                        <button
                          className={isSelected ? classNames.current : ''}
                          onClick={() => this.setState({ selected: key })}>
                          {tab.label}
                        </button>
                      </li>
                    )
                  })}
                </ol>
              </nav>
            </GridColumn>
            <GridColumn sm={8} md={9}>
              <Section>
                {heading && (
                  <Heading spacing={{ b: 1 }}>
                    {heading}
                  </Heading>
                )}
                <Component
                  buttonProps={buttonProps}
                  campaign={campaign.uid}
                  country={country}
                  fetchSessionTeam={fetchSessionTeam}
                  fitnessOptions={customSupporterPage.fitness}
                  fundraisingPage={page}
                  pageId={isJustGiving() ? page.slug : page.id}
                  persistPageData={persistPageData}
                  persistUserData={persistUserData}
                  user={user}
                />
              </Section>
              <Section>
                <PoweredBy
                  align='center'
                  includeCookies={false}
                />
              </Section>
            </GridColumn>
          </Grid>
        </FlatModal>
      </div>
    )
  }
}

const mapState = state => ({
  ...selectLocalisation(state),
  buttonProps: selectButtonDefaults(state),
  campaign: selectCampaign(state),
  page: selectPage(state),
  user: selectUser(state)
})

const mapDispatch = { fetchSessionTeam, fetchSessionUser, persistPageData, persistUserData }

export default compose(
  withRouter,
  connect(mapState, mapDispatch),
  withStyles(styles)
)(EditPage)
