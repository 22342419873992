export default ({
  ratio
}, traits) => ({
  root: ratio === '16:9' && {
    position: 'relative',

    '&:before': {
      content: '""',
      display: 'block',
      paddingTop: '56.25%'
    },

    '& > iframe': {
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%'
    }
  }
})
