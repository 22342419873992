import React from 'react'
import Content from '../../ui/Content'
import IFrame from '../../ui/IFrame'
import Section from '../../ui/Section'

const IFrameSlice = ({
  alignment,
  background,
  foreground,
  forwardQueryParams,
  ratio,
  size,
  spacing,
  title,
  url
}) => (
  <Section
    alignment={alignment}
    background={background}
    foreground={foreground}
    size={size}
    spacing={spacing}>
    <Content title={title}>
      <IFrame
        url={url}
        forwardQueryParams={forwardQueryParams}
        ratio={ratio}
      />
    </Content>
  </Section>
)

export default IFrameSlice
