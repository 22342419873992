export default (props, {
  colors,
  rhythm
}) => ({
  root: {
    borderTop: `1px solid ${colors.shade}`,

    '& li': {
      padding: `${rhythm(0.5)} ${rhythm(1)}`,
      borderBottom: `1px solid ${colors.shade}`
    }
  },

  title: {
    marginBottom: rhythm(1)
  }
})
