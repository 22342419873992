import React from 'react'
import omitBy from 'lodash/omitBy'
import isObjectLike from 'lodash/isObjectLike'
import queryString from 'querystringify'

import IFrame from '../../ui/IFrame'
import Section from '../../ui/Section'

const SupporterIFrame = ({
  background,
  forwardQueryParams,
  fundraisingPage,
  ratio,
  spacing,
  url
}) => {
  const [path, query = ''] = url.split('?')
  const pageParams = omitBy(fundraisingPage, param => isObjectLike(param) || !param)
  const combinedQuery = { ...queryString.parse(query), ...pageParams }
  const urlWithParams = `${path}?${queryString.stringify(combinedQuery)}`

  return (
    <Section
      background={background}
      spacing={spacing}>
      <IFrame
        url={urlWithParams}
        ratio={ratio}
        forwardQueryParams={forwardQueryParams}
      />
    </Section>
  )
}

export default SupporterIFrame
