import React from 'react'
import withStyles from 'constructicon/with-styles'
import styles from './styles'
import { Link } from 'react-router'

import FlatModal from '../../../ui/FlatModal'
import Heading from 'constructicon/heading'
import InputField from 'constructicon/input-field'

const CookieConsentPreferences = ({
  acceptConsent,
  classNames,
  consent,
  content = {},
  denyConsent,
  styles
}) => (
  <FlatModal
    contentLabel='Manage cookie preferences'
    isOpen
    onRequestClose={() => { window.location.href = '/' }}
    styles={styles.modal}>
    <div className={classNames.root}>
      <Heading>Manage Cookie Preferences</Heading>
      <p className={classNames.message}>
        {content.message || 'We use cookies to help make this website better, to improve our services and for advertising purposes.'}
        {' '}
        <Link
          className={classNames.link}
          to='/?cookies=policy'>
          {content.link || 'Learn More'}
        </Link>
      </p>
      <div className={classNames.options}>
        <InputField
          label='Accept all cookies'
          name='cookies'
          onChange={acceptConsent}
          type='radio'
          value={consent.accepted}
        />
        <InputField
          label='Deny all cookies'
          name='cookies'
          onChange={denyConsent}
          type='radio'
          value={!consent.accepted}
        />
      </div>
    </div>
  </FlatModal>
)

export default withStyles(styles)(CookieConsentPreferences)
