import React from 'react'
import numbro from 'numbro'
import Metric from '../Metric'

const Target = ({
  amountColor,
  format,
  fundraisingPage,
  icon,
  iconColor,
  iconSize,
  label,
  labelColor,
  offset,
  multiplier,
  numberStyle
}) => {
  const amount = fundraisingPage.target * (multiplier || 1) + (offset || 0)

  return (
    <Metric
      amount={numbro(amount).format(format || '$0,0')}
      amountColor={amountColor}
      icon={icon}
      iconSize={iconSize || 'small'}
      labelColor={iconColor}
      numberStyle={numberStyle}
      label={label}
    />
  )
}

export default Target
