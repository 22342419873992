import React from 'react'
import withStyles from 'constructicon/with-styles'
import styles from './styles'
import Button from '../../../ui/Button'

const SearchResult = ({
  action,
  buttonBackground,
  buttonForeground,
  classNames,
  donationUrl,
  name,
  url
}) => (
  <div className={classNames.root}>
    <div className={classNames.content}>
      <div>
        {name}
      </div>
      <Button
        background={buttonBackground}
        foreground={buttonForeground}
        to={action === 'view' ? url : donationUrl}>
        {action === 'view' ? 'View Project' : 'Donate Now'}
      </Button>
    </div>
  </div>
)

export default withStyles(styles)(SearchResult)
