const axios = require('axios')
const { updateClient } = require('supporticon/utils/client')

const apiUrls = {
  // For backwards compatibility:
  'production': 'https://everydayhero.com',
  'staging': 'https://everydayhero-staging.com',
  'justgiving': 'https://api.justgiving.com',

  'edh-production': 'https://everydayhero.com',
  'edh-staging': 'https://everydayhero-staging.com',
  'justgiving-production': 'https://api.justgiving.com',
  'justgiving-staging': 'https://api.staging.justgiving.com'
}

const client = axios.create({ baseURL: apiUrls['edh-production'] })

const updateEnvironment = (environment = 'edh-production') => {
  const baseURL = apiUrls[environment]
  client.defaults.baseURL = baseURL

  return updateClient({
    baseURL,
    headers: /justgiving/.test(environment) && {
      accept: 'application/json, text/plain, */*',
      'x-api-key': process.env.JG_API_KEY
    }
  })
}

const isJustGiving = () => /justgiving/.test(client.defaults.baseURL)
const isStaging = () => /staging/.test(client.defaults.baseURL)

const getEDHClientId = () => isStaging()
  ? process.env.EDH_STAGING_CLIENT_ID
  : process.env.EDH_PRODUCTION_CLIENT_ID

module.exports = {
  getEDHClientId,
  isJustGiving,
  isStaging,
  updateEnvironment
}
