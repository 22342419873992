import React, { Component } from 'react'
import moment from 'moment'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { clearPrismicToken, selectPreviewStatus } from '../../../store/prismic'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import Grid from 'constructicon/grid'
import Icon from 'constructicon/icon'
import Section from 'constructicon/section'

class PreviewStatus extends Component {
  constructor (props) {
    super(props)
    this.state = { diff: null }
    this.countdown = this.countdown.bind(this)
    this.handleDismiss = this.handleDismiss.bind(this)
  }

  componentDidMount () {
    const { expiry, previewToken } = this.props
    if (previewToken) {
      const diff = moment(expiry).diff()
      this.setState({ diff })
      this.interval = setInterval(this.countdown, 1000)
    }
  }

  componentWillUnmount () {
    clearInterval(this.interval)
  }

  countdown () {
    const diff = this.state.diff - 1000
    if (diff < 0) {
      clearInterval(this.interval)
      this.handleDismiss()
    }
    return this.setState({ diff })
  }

  handleDismiss () {
    this.props.clearPrismicToken()
    window.location.reload()
  }

  render () {
    const {
      classNames,
      styles,
      previewToken
    } = this.props

    if (!previewToken) {
      return null
    }

    const duration = moment(this.state.diff)
    const minutes = duration.format('m')
    const seconds = duration.format('ss')

    return (
      <Section
        background='primary'
        foreground='light'
        spacing={{ y: 0.5, x: 1 }}
        styles={styles.root}>

        <Grid spacing={0.5} align='center'>
          <div>
            <Icon name='info' size={2} />
          </div>
          <div>
            <p className={classNames.caption}>{`You are currently viewing a preview version, which expires in ${minutes}:${seconds}`}</p>
            <button className={classNames.button} onClick={this.handleDismiss}>Leave Preview</button>
          </div>
        </Grid>
      </Section>
    )
  }
}

const mapState = (state) => selectPreviewStatus(state)

export default compose(
  connect(mapState, { clearPrismicToken }),
  withStyles(styles)
)(PreviewStatus)
