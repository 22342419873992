import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { selectButtonDefaults } from '../../../store/site'
import { selectStatus } from '../../../store/session'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import Heading from 'constructicon/heading'
import Loading from '../../ui/Loading'
import LoginForm from 'supporticon/components/login-form'
import Section from 'constructicon/section'

const AdminLogin = ({ buttonProps, onSuccess, status, styles }) => (
  <Section spacing={{ t: 0, x: 1.5, b: 2 }} styles={styles.root}>
    <Heading
      align='center'
      size={0}
      spacing={{ b: 1 }}>
      Login with your JustGiving Charity Account
    </Heading>
    {status === 'fetching' ? (
      <Section spacing={3}>
        <Loading />
      </Section>
    ) : (
      <LoginForm
        authType='Basic'
        clientId={process.env.JG_API_KEY}
        formComponent={{ submitProps: buttonProps }}
        onSuccess={onSuccess}
      />
    )}
  </Section>
)

const mapState = state => ({
  buttonProps: selectButtonDefaults(state),
  status: selectStatus(state)
})

export default compose(
  connect(mapState),
  withStyles(styles)
)(AdminLogin)
