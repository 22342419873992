export default ({
  alignment = 'left',
  backgroundImage,
  position = 'static',
  overflow = 'visible',
  spacing
}, {
  mediaQuery,
  rhythm
}) => {
  const calculateSpacing = (spacing, baseSize = 1) => {
    switch (spacing) {
      case 'condensed':
        return `${rhythm(baseSize * 0.5)} ${rhythm(baseSize)}`
      case 'expanded':
        return `${rhythm(baseSize * 2)} ${rhythm(baseSize)}`
      case 'bottom':
        return `0 ${rhythm(baseSize)} ${rhythm(baseSize)}`
      case 'expanded bottom':
        return `0 ${rhythm(baseSize)} ${rhythm(baseSize * 2)}`
      case 'top':
        return `${rhythm(baseSize)} ${rhythm(baseSize)} 0`
      case 'expanded top':
        return `${rhythm(baseSize * 2)} ${rhythm(baseSize)} 0`
      case 'horizontal':
        return `0 ${rhythm(baseSize)}`
      case 'vertical':
        return `${rhythm(baseSize)} 0`
      case 'none':
        return 0
      default:
        return rhythm(baseSize)
    }
  }

  return {
    root: {
      position,
      overflow,
      textAlign: alignment,
      padding: calculateSpacing(spacing),
      ...backgroundImage && {
        backgroundImage: backgroundImage.url && `url(${backgroundImage.url})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
      },

      [mediaQuery('lg')]: {
        padding: calculateSpacing(spacing, 2)
      }
    }
  }
}
