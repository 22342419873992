export default (props, { rhythm, colors, measures, radiuses, scale, transitions, treatments }) => ({
  card: {
    display: 'flex',
    fontSize: scale(-1),
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: rhythm(0.5),
    background: colors.light,
    color: colors.dark,
    border: `thin solid ${colors.shade}`,
    borderRadius: rhythm(radiuses.medium),
    textAlign: 'left',
    transition: transitions.easeOut,

    '&:hover': {
      opacity: 0.75
    },

    '&:not(:last-child)': {
      marginBottom: rhythm(0.5)
    }
  },

  image: {
    flex: '0 0 auto',
    width: rhythm(1.5),
    height: rhythm(1.5),
    backgroundColor: colors.shade,
    borderRadius: '50%',
    marginRight: rhythm(0.5)
  },

  name: {
    flex: 1,
    minWidth: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },

  amount: {
    flex: '0 0 auto',
    textAlign: 'right'
  }
})
