
export default ({
  barColor,
  image,
  metric,
  raised,
  target,
  targetDonations,
  titleForeground,
  titleSize,
  totalDonations
}, {
  colors,
  radiuses,
  rhythm,
  scale,
  shadows,
  treatments
}) => {
  const targetSet = metric === 'donations'
    ? !!targetDonations
    : !!target

  const targetMet = metric === 'donations'
    ? totalDonations >= targetDonations
    : raised >= target

  const scales = {
    normal: 0,
    small: -1,
    large: 1
  }

  return {
    root: {
      position: 'relative',
      flexDirection: 'column',
      overflow: 'hidden',
      height: '100%',
      borderRadius: rhythm(radiuses.small),
      background: colors.light,
      boxShadow: shadows.light,

      '&:after': targetSet && targetMet && {
        content: '"Funded"',
        position: 'absolute',
        top: 0,
        right: 0,
        background: colors.primary,
        color: colors.light,
        transform: 'translate(2rem, 5rem) rotate(45deg)',
        transformOrigin: 'top right',
        width: '10rem',
        textAlign: 'center',
        height: rhythm(1.5),
        lineHeight: rhythm(1.5),
        ...treatments.button
      },

      '& > *': targetSet && targetMet && {
        opacity: 0.75
      }
    },

    image: {
      height: rhythm(10),
      backgroundImage: image && `url(${image})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center'
    },

    content: {
      display: 'flex',
      flexDirection: 'column',
      height: `calc(100% - ${rhythm(10)})`
    },

    footer: {
      marginTop: 'auto'
    },

    name: {
      position: 'relative',
      marginBottom: rhythm(0.5),
      paddingBottom: rhythm(0.5),
      color: colors[titleForeground],
      fontSize: scale(scales[titleSize]),
      lineHeight: 1.25,

      '&:after': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        opacity: 0.25
      }
    },

    story: {
      position: 'relative',
      overflow: 'hidden',
      maxHeight: rhythm(12),
      fontSize: scale(-1),
      opacity: 0.75,

      '&:after': {
        content: '""',
        position: 'absolute',
        height: rhythm(2),
        top: rhythm(10),
        left: 0,
        right: 0,
        backgroundImage: 'linear-gradient(rgba(255,255,255,0), rgba(255,255,255,1))'
      }
    },

    metrics: {
      display: 'flex',
      justifyContent: 'space-between',
      flexPack: 'justify',
      margin: `${rhythm(0.75)} 0`,
      '& li': {
        textAlign: 'left',
        '&:last-child': {
          textAlign: 'right'
        },
        '&:first-child:last-child': {
          textAlign: 'center',
          margin: '0 auto'
        }
      },
      '& dd': {
        fontWeight: 700
      }
    },

    progress: {
      position: 'relative',
      overflow: 'hidden',
      height: rhythm(1),
      marginBottom: rhythm(1),
      backgroundColor: colors.shade
    },

    bar: {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      overflow: 'hidden',
      textIndent: '-9999px',
      whiteSpace: 'nowrap',
      backgroundColor: colors[barColor]
    },

    percentage: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      fontSize: scale(-1),
      color: 'rgba(255,255,255,0.75)'
    },

    button: {
      width: 'auto'
    }
  }
}
