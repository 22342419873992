import React from 'react'
import { connect } from 'react-redux'
import { isJustGiving } from '../../lib/environment'
import { selectReportsParams } from '../../store/site'

import CharityHeader from '../../components/ui/CharityHeader'
import FlatModal from '../../components/ui/FlatModal'
import Home from '../Home'

const Admin = ({ charityId, children, eventId, router }) => {
  if (!isJustGiving() || !eventId || !charityId) {
    router.push('/')
    return null
  }

  return (
    <div>
      <Home />
      <FlatModal
        isOpen
        shouldCloseOnOverlayClick={false}
        onRequestClose={() => router.push('/')}
        contentLabel='Admin'
        width={20}
      >
        <CharityHeader />
        {children}
      </FlatModal>
    </div>
  )
}

const mapState = (state) => selectReportsParams(state)

export default connect(mapState)(Admin)
