import React from 'react'
import { compose } from 'redux'
import withToggle from 'constructicon/with-toggle'
import withStyles from 'constructicon/with-styles'
import styles from './styles'
import FlatModal from '../../../ui/FlatModal'
import RichText from '../../../ui/RichText'

const GalleryItem = ({
  classNames,
  description,
  image: {
    alt,
    url
  } = {},
  onToggleOff,
  onToggleOn,
  styles,
  toggled
}) => (
  <div>
    <button
      aria-label='Toggle gallery item'
      onClick={onToggleOn}
      className={classNames.thumb}
    />
    <FlatModal
      isOpen={toggled}
      contentLabel={alt || 'Gallery item'}
      onRequestClose={onToggleOff}>

      <img src={url} alt={alt || 'Gallery item'} />
      <RichText styles={styles.copy}>{description}</RichText>
    </FlatModal>
  </div>
)

export default compose(
  withToggle,
  withStyles(styles)
)(GalleryItem)
