import React from 'react'
import { deserializeCharity, fetchCharity } from 'supporticon/api/charities'
import template from 'lodash/template'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import ButtonGroup from 'constructicon/button-group'
import Button from '../../../ui/Button'
import Grid from 'constructicon/grid'
import GridColumn from 'constructicon/grid-column'
import Heading from 'constructicon/heading'
import Loading from '../../../ui/Loading'

class SelectedCharity extends React.Component {
  constructor () {
    super()
    this.state = {
      status: 'fetching',
      data: {}
    }
  }

  componentDidMount () {
    const { id, onLoad } = this.props

    fetchCharity(id)
      .then(data => deserializeCharity(data))
      .then(data => {
        this.setState({ data, status: 'fetched' })
        onLoad(data)
      })
  }

  getCtaUrl (cta) {
    const { data } = this.state

    switch (cta.action) {
      case 'custom':
        return template(cta.url)(data)
      case 'register':
        return data.getStartedUrl
      default:
        return data.donateUrl
    }
  }

  render () {
    const { classNames, ctas, onCancel } = this.props
    const { data, status } = this.state

    return (
      <div className='ssb-selected-charity'>
        {status !== 'fetched' ? <Loading /> : (
          <Grid
            align='center'
            spacing={0.5}>
            <GridColumn sm={4}>
              <img
                src={data.logo}
                alt={data.name}
                className={classNames.image}
              />
            </GridColumn>
            <GridColumn sm={8} xsAlign='left'>
              <div>You are supporting</div>
              <Heading spacing={0}>{data.name}</Heading>
              <button
                className={classNames.clear}
                onClick={onCancel}>
                clear
              </button>
              <ButtonGroup>
                {ctas.map((cta, index) => (
                  <Button
                    background={cta.background}
                    foreground={cta.foreground}
                    id={`charity-cta-${index}`}
                    key={index}
                    to={this.getCtaUrl(cta)}>
                    {cta.label}
                  </Button>
                ))}
              </ButtonGroup>
            </GridColumn>
          </Grid>
        )}
      </div>
    )
  }
}

export default withStyles(styles)(SelectedCharity)
