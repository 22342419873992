import React from 'react'
import Grid from 'constructicon/grid'
import GridColumn from 'constructicon/grid-column'

const LayoutLeftSidebar = ({
  align = 'center',
  children,
  content,
  gridSize = {
    children: 6,
    content: 6
  }
}) => (
  <Grid spacing={{ x: 1.5, y: 1 }} align={align}>
    <GridColumn md={gridSize.children}>
      {children}
    </GridColumn>
    <GridColumn md={gridSize.content}>
      {content}
    </GridColumn>
  </Grid>
)

export default LayoutLeftSidebar
