export default (props, traits) => {
  const { colors, rhythm, treatments } = traits

  return {
    modal: {
      content: {
        width: rhythm(34),
        maxWidth: 'calc(100vw - 2rem)',
        overflow: 'auto'
      },
      container: {
        overflow: 'visible'
      }
    },

    grid: {
      minHeight: rhythm(10)
    },

    nav: {
      height: '100%',
      borderRight: `thin solid ${colors.lightGrey}`,

      '& li': {
        borderBottom: `thin solid ${colors.lightGrey}`
      },

      '& button': {
        ...treatments.button,
        display: 'block',
        width: '100%',
        padding: rhythm([0.75, 1])
      }
    },

    current: {
      backgroundColor: colors.shade
    }
  }
}
