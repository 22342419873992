import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { provideHooks } from 'redial'
import { selectCustomSupporterPages, selectCustomSupporterPagePresent } from '../../store/customSupporterPages'
import { fetchFundraisingPage, selectFundraisingPage, selectFundraisingPageStatus } from '../../store/fundraisingPage'
import { selectCampaign, selectMaintenanceMode } from '../../store/site'

import NotFound from '../../components/container/NotFound'
import Status from '../../components/container/Status'
import SupporterPage from '../../components/container/SupporterPage'
import Theme from '../../components/utils/Theme'

const CustomSupporterPage = ({
  children,
  customSupporterPages,
  location,
  router,
  status = 'fetching'
}) => (
  <Theme>
    <Status status={status}>
      <div>
        {customSupporterPages.length > 0
          ? <SupporterPage customSupporterPages={customSupporterPages} key={router.params.slug} />
          : <NotFound />}
        {children}
      </div>
    </Status>
  </Theme>
)

const mapState = (state, props) => ({
  customSupporterPages: selectCustomSupporterPages(state, props),
  status: selectFundraisingPageStatus(state, props)
})

const hooks = {
  fetch: ({ dispatch, params, state }) => {
    const { hasCustomSupporterPages } = selectCustomSupporterPagePresent(state)
    const campaign = selectCampaign(state)
    const page = selectFundraisingPage(state)
    const status = selectFundraisingPageStatus(state)
    const maintenanceMode = selectMaintenanceMode(state)

    if (maintenanceMode || !hasCustomSupporterPages) {
      return Promise.resolve()
    } else if (status === 'fetched' && page.slug === params.slug) {
      return Promise.resolve(page)
    } else {
      return dispatch(fetchFundraisingPage(params.slug, campaign))
    }
  }
}

export default compose(
  connect(mapState),
  provideHooks(hooks)
)(CustomSupporterPage)
