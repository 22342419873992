import moment from 'moment'
import find from 'lodash/find'
import merge from 'lodash/merge'
import get from 'lodash/get'
import { isJustGiving } from '../../lib/environment'
import { selectBasicAPIParams, selectReportsParams } from '../site'
import { selectCustomSupporterPagePresent } from '../customSupporterPages'
import { deserializeUser, fetchCurrentUser } from 'supporticon/api/me'
import { fetchPage, fetchUserPages, deserializePage } from 'supporticon/api/pages'
import { authenticateUser } from '../../lib/dataApi'

// Constants
const c = {
  FETCH: 'app/session/FETCH',
  USER_SUCCESS: 'app/session/USER_SUCCESS',
  PAGE_SUCCESS: 'app/session/PAGE_SUCCESS',
  TEAM_SUCCESS: 'app/session/TEAM_SUCCESS',
  ADMIN_USER_SUCCESS: 'app/session/ADMIN_USER_SUCCESS',
  PRESELECT_CHARITY: 'app/session/PRESELECT_CHARITY',
  PRESELECT_TEAM: 'app/session/PRESELECT_TEAM',
  REFRESH_CREDENTIALS: 'app/session/REFRESH_CREDENTIALS',
  CLEAR_CHARITY: 'app/session/CLEAR_CHARITY',
  CLEAR_ADMIN_USER: 'app/session/CLEAR_ADMIN_USER',
  FAILURE: 'app/session/FAILURE',
  CLEAR: 'app/session/CLEAR',
  PERSIST_USER_DATA: 'app/session/PERSIST_USER_DATA'
}

// Selectors
export * from './selectors'

// Actions
export const refreshUser = (data) => ({
  type: c.REFRESH_CREDENTIALS,
  payload: { data }
})

export const fetchSessionUser = (params) => (dispatch, getState) => {
  dispatch({ type: c.FETCH })

  const state = getState()
  const apiParams = selectBasicAPIParams(state)
  const supporterPages = selectCustomSupporterPagePresent(state)
  const isCustom = supporterPages.hasCustomSupporterPages

  return Promise.all([
    fetchCurrentUser(params),
    fetchUserPages({ ...params, ...apiParams }).catch(() => ([]))
  ])
    .then(([user, pages]) =>
      Promise.resolve()
        .then(() => getFirstActivePage(pages))
        .then(page => isJustGiving() && get(page, 'pageId') ? fetchPage(page.pageId) : page)
        .then(page => ({ user, page }))
    )
    .then(({ user, page }) => {
      const data = merge(user, {
        refreshToken: params.refreshToken,
        token: params.token,
        tokenExpiry: params.expiresAt || moment().add(600, 'seconds').toISOString(),
        authType: params.authType
      })

      dispatch({
        type: c.USER_SUCCESS,
        payload: { data, page, isCustom }
      })

      return Promise.resolve(data)
    })
    .catch((error) => {
      dispatch({
        type: c.FAILURE,
        payload: { error }
      })

      return Promise.reject(error)
    })
}

const getFirstActivePage = pages => {
  return find(pages, page => {
    const deserializedPage = deserializePage(page)
    return deserializedPage.active
  })
}

export const fetchSessionPage = (response, isCustom) => (dispatch) =>
  Promise.resolve()
    .then(() => isJustGiving() ? fetchPage(response.pageId) : response)
    .then(data => {
      dispatch({
        type: c.PAGE_SUCCESS,
        payload: { data, isCustom }
      })

      return data
    })

export const fetchSessionTeam = (data) => (dispatch, getState) => {
  dispatch({
    type: c.TEAM_SUCCESS,
    payload: { data }
  })

  return Promise.resolve(data)
}

export const fetchAdminUser = data => (dispatch, getState) => {
  const state = getState()
  const { charityId } = selectReportsParams(state)

  return Promise.resolve()
    .then(() => dispatch({ type: c.FETCH }))
    .then(() => authenticateUser(charityId, data.token))
    .then(() => {
      dispatch({
        type: c.ADMIN_USER_SUCCESS,
        payload: { data }
      })

      return Promise.resolve(data)
    })
    .catch((error) => {
      dispatch({
        type: c.FAILURE,
        payload: { error }
      })

      return Promise.reject(error)
    })
}

export const preselectCharity = charity => ({
  type: c.PRESELECT_CHARITY,
  payload: charity
})

export const clearCharity = () => ({
  type: c.CLEAR_CHARITY
})

export const preselectTeam = team => ({
  type: c.PRESELECT_TEAM,
  payload: team
})

export const clearAdminUser = () => ({
  type: c.CLEAR_ADMIN_USER
})

export const clearSession = () => (dispatch) => (
  dispatch({ type: c.CLEAR })
)

export const persistUserData = data => ({
  type: c.PERSIST_USER_DATA,
  payload: data
})

// Reducer
export default (state = {}, { type, payload }) => {
  switch (type) {
    case c.FETCH:
      return {
        ...state,
        status: 'fetching'
      }
    case c.USER_SUCCESS:
      const userPage = payload.page && deserializePage(payload.page)

      return {
        ...state,
        status: 'fetched',
        page: payload.page && {
          ...userPage,
          url: payload.isCustom ? `/fundraising/${userPage.slug}` : userPage.url
        },
        user: merge(deserializeUser(payload.data), {
          hasExistingPage: !!userPage,
          refreshToken: payload.data.refreshToken,
          token: payload.data.token,
          tokenExpiry: payload.data.tokenExpiry,
          authType: payload.data.authType
        })
      }
    case c.REFRESH_CREDENTIALS:
      return {
        ...state,
        user: {
          ...state.user,
          ...payload.data
        }
      }
    case c.PAGE_SUCCESS:
      const page = deserializePage(payload.data)

      return {
        ...state,
        status: 'fetched',
        page: {
          ...page,
          url: payload.isCustom ? `/fundraising/${page.slug}` : page.url
        }
      }
    case c.TEAM_SUCCESS:
      return {
        ...state,
        status: 'fetched',
        page: { ...state.page, teamPageId: payload.data.id },
        team: payload.data
      }
    case c.ADMIN_USER_SUCCESS:
      return {
        ...state,
        status: 'fetched',
        adminUser: payload.data
      }
    case c.FAILURE:
      return {
        ...state,
        status: 'failed'
      }
    case c.PRESELECT_CHARITY:
      return {
        ...state,
        charity: payload
      }
    case c.CLEAR_CHARITY:
      return {
        ...state,
        charity: undefined
      }
    case c.PRESELECT_TEAM:
      return {
        ...state,
        preselectedTeam: payload
      }
    case c.CLEAR_ADMIN_USER:
      return {
        ...state,
        adminUser: undefined
      }
    case c.PERSIST_USER_DATA:
      return {
        ...state,
        user: {
          ...state.user,
          ...payload
        }
      }
    case c.CLEAR:
      return {
        status: 'empty'
      }
    default:
      return state
  }
}
