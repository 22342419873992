import React from 'react'
import Grid from 'constructicon/grid'
import GridColumn from 'constructicon/grid-column'
import Section from '../../ui/Section'

import Content from './Content'
import HTMLContent from './HTMLContent'
import Links from './Links'
import PoweredBy from '../../ui/PoweredBy'
import Share from './Share'

const sliceComponents = {
  content: Content,
  htmlContent: HTMLContent,
  links: Links,
  poweredBy: PoweredBy,
  share: Share
}

const Footer = ({
  background = 'shade',
  foreground = 'dark',
  content = []
}) => (
  <Section
    tag='footer'
    id='footer'
    alignment='center'
    background={background}
    foreground={foreground}
  >
    <Grid spacing={0.5}>
      {content.map((slice, i) => {
        const Component = sliceComponents[slice.type]
        const props = { items: slice.items, ...slice.primary }

        return (
          <GridColumn key={i}>
            {Component ? (
              <Component {...props} />
            ) : (
              `Unknown slice type: ${slice.type}`
            )}
          </GridColumn>
        )
      })}
    </Grid>
  </Section>
)

export default Footer
