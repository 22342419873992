import currencySymbol from '../../../lib/currencySymbol'

export default ({
  country,
  showFitnessGoal
}, {
  colors,
  rhythm,
  scale,
  treatments
}) => ({
  heading: {
    fontSize: scale(1.5),
    marginBottom: rhythm(0.75)
  },
  form: {
    root: {
      paddingBottom: 0
    }
  },
  createPageForm: {
    root: {
      paddingBottom: rhythm(0.5)
    }
  },
  target: {
    root: {
      position: 'relative',
      '&:before': {
        content: `"${currencySymbol(country)}"`,
        position: 'absolute',
        top: rhythm(1.175),
        left: 0,
        width: rhythm(1.125),
        height: rhythm(1.666),
        paddingRight: rhythm(0.25),
        lineHeight: rhythm(1.666),
        textAlign: 'right',
        color: colors.dark,
        opacity: 0.5,
        whiteSpace: 'nowrap',
        pointerEvents: 'none'
      }
    },
    field: {
      paddingLeft: rhythm(1.125)
    }
  },
  fitnessGoal: {
    root: {
      display: showFitnessGoal !== 'Yes' && 'none'
    }
  },
  hiddenField: {
    root: {
      display: 'none'
    }
  },
  labelWrapper: {
    marginBottom: rhythm(0.75)
  },
  label: {
    fontSize: 'inherit',
    fontWeight: 'inherit',
    display: 'inline',
    '& p': {
      display: 'inline'
    }
  },
  termsLabel: {
    display: 'inline',
    '& p': {
      display: 'inline'
    }
  },
  termsCheckbox: {
    root: {
      order: 99
    }
  },
  copy: {
    marginBottom: rhythm(1),
    '& a': {
      textDecoration: 'underline'
    }
  },
  link: {
    marginTop: rhythm(0.5),
    border: 0,
    opacity: 0.85,
    fontSize: scale(-0.5)
  },
  emailCheck: {
    field: {
      padding: rhythm(1)
    }
  }
})
