export default ({
  labelFont = 'head'
}, {
  treatments
}) => ({
  label: {
    ...treatments[labelFont]
  },
  item: {
    '& strong': {
      opacity: 0.75
    }
  }
})
