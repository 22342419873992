import React from 'react'
import { Route, IndexRoute, IndexRedirect } from 'react-router'

import Admin from './Admin'
import AdminLeaderboardDefinitions from './AdminLeaderboardDefinitions'
import AdminLogin from './AdminLogin'
import AdminReports from './AdminReports'
import Container from './Container'
import CustomSupporterPage from './CustomSupporterPage'
import CustomSupporterPageAdmin from './CustomSupporterPageAdmin'
import CustomSupporterPageLogin from './CustomSupporterPageLogin'
import CustomTagPage from './CustomTagPage'
import CustomTeamPage from './CustomTeamPage'
import Home from './Home'
import Page from './Page'
import Preview from './Preview'
import StravaConnect from './StravaConnect'

export default (
  <Route path='/' component={Container}>
    <IndexRoute component={Home} />
    <Route path='admin' component={Admin}>
      <IndexRedirect to='login' />
      <Route path='login' component={AdminLogin} />
      <Route path='reports' component={AdminReports} />
    </Route>
    <Route path='admin/leaderboard-definitions' component={AdminLeaderboardDefinitions} />
    <Route path='preview' component={Preview} />
    <Route path='fundraising/:slug/connect' component={StravaConnect} />
    <Route path='fundraising/:slug' component={CustomSupporterPage}>
      <Route path='admin' component={CustomSupporterPageAdmin} />
      <Route path='login' component={CustomSupporterPageLogin} />
    </Route>
    <Route path='teams/:slug' component={CustomTeamPage} />
    <Route path=':tag/:slug' component={CustomTagPage} />
    <Route path=':slug' component={Page} />
  </Route>
)
