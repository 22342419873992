import React from 'react'
import Login from '../../components/container/Login'

const CustomSupporterPageLogin = ({
  location,
  router
}) => {
  const { redirect = `/fundraising/${router.params.slug}` } = location.query

  return (
    <Login
      onDismiss={() => router.push(`/fundraising/${router.params.slug}`)}
      onSuccess={() => router.push(redirect)}
    />
  )
}

export default CustomSupporterPageLogin
