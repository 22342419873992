import React from 'react'
import numbro from 'numbro'
import Icon from 'constructicon/icon'
import Metric from 'constructicon/metric'

const TotalRaised = ({
  label,
  format,
  funded,
  metric,
  multiplier,
  notFunded,
  offset,
  projects = [],
  status,
  ...props
}) => {
  const getAmount = () => {
    switch (status) {
      case 'fetching':
        return <Icon name='loading' spin />
      case 'failed':
        return <Icon name='warning' />
      default:
        const raised = projects.reduce((total, project) => total + project.raised, 0)
        return numbro((raised + offset / 100) * multiplier).formatCurrency(format)
    }
  }

  return (
    <Metric
      amount={getAmount()}
      label={label}
      {...metric}
      {...props}
    />
  )
}

export default TotalRaised
