import React from 'react'
import { connect } from 'react-redux'
import { selectIFrame } from '../../../store/site'
import Helmet from 'react-helmet'

const AllowIFrame = ({
  iFrame
}) => (
  iFrame === 'yes' ? (
    <Helmet
      script={[{
        src: 'https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/3.5.6/iframeResizer.contentWindow.min.js'
      }]}
    />
  ) : null
)

const mapState = (state) => ({
  iFrame: selectIFrame(state)
})

export default connect(mapState)(AllowIFrame)
