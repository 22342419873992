import React from 'react'
import numbro from 'numbro'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { isJustGiving } from '../../../lib/environment'
import { selectCustomSupporterPagePresent } from '../../../store/customSupporterPages'
import { selectAPIParams, selectButtonDefaults } from '../../../store/site'
import { deserializePage } from 'supporticon/api/pages'
import { withRouter } from 'react-router'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import Section from '../../ui/Section'
import PageSearch from 'supporticon/components/page-search'
import SupporterSearchContainer from './SupporterSearchContainer'

const mapSubtitle = subtitle => {
  switch (subtitle) {
    case 'raised':
      return page => !isNaN(page.raised) ? numbro(page.raised).formatCurrency('0,0') : null
    case 'charity':
      return isJustGiving() ? null : subtitle
    default:
      return subtitle
  }
}

const customDeserializePage = (data, isCustom) => {
  const page = deserializePage(data)

  if (isCustom) {
    return {
      ...page,
      url: `/${page.type === 'team' ? 'teams' : 'fundraising'}/${page.slug}`
    }
  }

  return page
}

const SupporterSearch = ({
  apiParams,
  backgroundImage,
  background,
  buttonBackground,
  buttonForeground,
  buttonProps,
  campaign,
  charity,
  event,
  foreground,
  hasCustomSupporterPages,
  label,
  modal,
  placeholder,
  router,
  size,
  spacing,
  styles,
  subtitle,
  type
}) => (
  <SupporterSearchContainer modal={modal} router={router}>
    <Section
      backgroundImage={backgroundImage}
      background={background}
      foreground={foreground}
      size={size}
      spacing={spacing}>
      <PageSearch
        campaign={campaign || apiParams.campaignIds}
        charity={charity || apiParams.charityIds}
        event={event || apiParams.event}
        subtitle={mapSubtitle(subtitle)}
        type={type || 'individual'}
        deserializeMethod={page => customDeserializePage(page, hasCustomSupporterPages)}
        button={{
          ...buttonProps,
          background: buttonBackground,
          foreground: buttonForeground
        }}
        searchForm={{
          expanded: true,
          placeholder: placeholder || 'Find a fundraiser',
          styles: styles.searchForm,
          title: label || 'Looking for someone?'
        }}
        searchResults={{
          styles: styles.results
        }}
        searchResult={{
          button: {
            ...buttonProps,
            background: buttonBackground,
            foreground: buttonForeground
          },
          styles: styles.result
        }}
      />
    </Section>
  </SupporterSearchContainer>
)

const mapState = (state) => ({
  buttonProps: selectButtonDefaults(state),
  ...selectAPIParams(state),
  ...selectCustomSupporterPagePresent(state)
})

export default compose(
  withRouter,
  connect(mapState),
  withStyles(styles)
)(SupporterSearch)
