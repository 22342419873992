// Constants
import c from './constants'

// Actions
export * from './actions'

// Selectors
export * from './selectors'

// Reducer
const initialState = {
  data: [],
  status: 'fetching'
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case c.SUCCESS:
      return {
        status: 'fetched',
        ...payload
      }
    case c.FAILURE:
      return {
        status: 'failed'
      }
    default:
      return state
  }
}
