export default (props, {
  colors,
  mediaQuery,
  rhythm,
  shadows,
  transitions
}) => ({
  wrapper: {
    [mediaQuery('lg', 'max-width')]: {
      overflowX: 'hidden',
      overflowY: 'auto',
      'max-height': 'calc(100vh - 180px)', // IE11 Fallback
      maxHeight: 'calc(var(--document-height) - 180px)',
      marginBottom: '15px'
    }
  },
  root: {
    position: 'relative',
    paddingBottom: rhythm(0.5),
    margin: `${rhythm(-1)} ${rhythm(-0.5)}`,

    '& > *': {
      margin: `${rhythm(1)} ${rhythm(0.5)}`
    },

    '& li': {
      [mediaQuery('lg')]: {
        '& > div': {
          transform: 'translateY(0.8em)',
          opacity: 0,
          pointerEvents: 'none'
        },

        '&:hover': {
          '& > div': {
            transform: 'translateY(1em)',
            pointerEvents: 'all',
            opacity: 1
          }
        }
      }
    },

    '& a': {
      transition: transitions.easeOut,
      '&:hover': {
        opacity: 0.75
      }
    },

    [mediaQuery('lg')]: {
      padding: 0,
      '& > *': {
        display: 'inline-block',
        verticalAlign: 'middle'
      }
    }
  },

  dropDown: {
    paddingTop: '0.33em',
    [mediaQuery('lg')]: {
      paddingTop: '0',
      borderRadius: '0.1em',
      zIndex: 2,
      transition: 'opacity 0.2s ease, transform 0.6s ease',
      color: colors.dark,
      background: colors.light,
      display: 'block',
      minWidth: '10em',
      position: 'absolute',
      boxShadow: shadows.light,

      '&:before': {
        content: '""',
        zIndex: -1,
        top: rhythm(-1),
        left: rhythm(-1),
        right: rhythm(-1),
        bottom: rhythm(-1),
        position: 'absolute'
      },

      '&:after': {
        content: '""',
        position: 'absolute',
        left: '0.5em',
        top: '-0.3em',
        width: 0,
        height: 0,
        borderStyle: 'solid',
        borderWidth: `0 0.5em 0.5em 0.5em`,
        borderColor: 'transparent transparent white transparent'
      },

      '& > ul': {
        overflow: 'auto',
        maxHeight: '70vh'
      },

      '& li:not(:last-child)': {
        '& a': {
          borderBottom: `1px solid ${colors.shade}`
        }
      }
    },

    '& a': {
      width: '100%',
      display: 'inline-block',
      padding: rhythm(0.33)
    }
  }
})
