export default (props, { colors, radiuses, rhythm, scale, treatments }) => {
  return {
    header: {
      backgroundColor: colors.paleGrey,
      borderBottom: `thin solid ${colors.lightGrey}`,
      padding: rhythm([0.666, 1]),
      margin: rhythm([0, -1, 0.75])
    },
    flex: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between'
    },
    title: {
      fontWeight: 'bold',
      lineHeight: '1.25em',
      small: {
        display: 'block',
        marginTop: rhythm(0.25),
        fontSize: '75%',
        lineHeight: '1.25em',
        opacity: 0.5
      }
    },
    button: {
      ...treatments.button,
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: `thin solid ${colors.lightGrey}`,
      height: rhythm(1.666),
      lineHeight: rhythm(1.666),
      textAlign: 'center',
      padding: rhythm([0, 0.5]),
      marginBottom: rhythm(0.25),
      borderRadius: rhythm(radiuses.small),
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      '& svg': {
        marginRight: rhythm(0.25)
      },
      '& span': {
        fontSize: scale(-0.5)
      },
      '& label': {
        margin: 0
      },
      '&:first-child': {
        marginRight: rhythm(0.25)
      },
      '&:last-child': {
        marginLeft: rhythm(0.25)
      }
    },
    columns: {
      display: 'flex',
      flexWrap: 'wrap',
      fieldset: {
        flex: '50%',
        margin: rhythm([0.5, 0]),
        paddingRight: '2rem'
      }
    },
    input: {
      root: {
        marginBottom: 0
      },
      label: {
        fontWeight: 'normal'
      }
    },
    status: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      background: 'white',
      padding: rhythm(1.5),
      transform: 'translate(-50%, -50%)',
      textAlign: 'center',
      boxShadow: '0 0 0 100rem rgba(0,0,0,0.25), 0 0 1rem rgba(0,0,0,0.25)',
      width: 'calc(100% - 3rem)',
      maxWidth: rhythm(22),
      maxHeight: '100%',
      overflow: 'auto',
      zIndex: 1,
      '& ol': {
        textAlign: 'left',
        '& li': {
          display: 'flex',
          fontSize: scale(-1),
          justifyContent: 'space-between',
          alignItems: 'center',
          margin: rhythm([0.5, 0]),
          '& svg': {
            flexShrink: 0,
            marginLeft: rhythm(0.5)
          }
        }
      }
    },
    progress: {
      root: {
        margin: rhythm([0, 1]),
        height: rhythm(0.75)
      }
    }
  }
}
