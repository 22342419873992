import Cookies from 'js-cookie'
import { selectCountry } from '../site'
import { getCurrentDomain } from '../../lib/window'

// Constants
const c = {
  ACCEPT: 'app/consent/ACCEPT',
  REJECT: 'app/consent/REJECT'
}

const REQUIRED_COUNTRIES = ['gb', 'ie']

// Actions
export const acceptConsent = () => ({
  type: c.ACCEPT
})

export const denyConsent = () => {
  const domain = getCurrentDomain()
  Object.keys(Cookies.get()).map(key => Cookies.remove(key, { domain }))

  return {
    type: c.REJECT
  }
}

// Selectors
export const selectConsent = state => {
  const country = selectCountry(state)
  const required = REQUIRED_COUNTRIES.indexOf(country) > -1
  const allowed = !required || state.consent.accepted

  return {
    ...state.consent,
    allowed,
    required
  }
}

// Reducer
const initialState = {
  accepted: false,
  touched: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case c.ACCEPT:
      return {
        accepted: true,
        touched: true
      }
    case c.REJECT:
      return {
        accepted: false,
        touched: true
      }
    default:
      return state
  }
}
