import numbro from 'numbro'
import enAU from 'numbro/languages/en-AU'
import enGB from 'numbro/languages/en-GB'
import enNZ from 'numbro/languages/en-NZ'
import enZA from 'numbro/languages/en-ZA'
import enIE from './en-IE'
import { connect } from 'react-redux'
import { selectLocalisation } from '../../../store/site'

numbro.culture('en-AU', enAU)
numbro.culture('en-GB', enGB)
numbro.culture('en-NZ', enNZ)
numbro.culture('en-IE', enIE)
numbro.culture('en-ZA', enZA)

const Localisation = ({
  country = 'au'
}) => {
  const culture = `en-${country.toUpperCase()}`

  try {
    numbro.culture(culture)
  } catch (e) {
    console.log(`Numbro culture ${culture} is not supported`)
  }

  return null
}

const mapState = (state) => selectLocalisation(state)

export default connect(mapState)(Localisation)
