import { createSelector } from 'reselect'
import find from 'lodash/find'

export const selectSlug = (_, { params }) => params.slug
export const selectPages = (store) => store.pages.data

export const selectPage = createSelector(
  [ selectPages, selectSlug ],
  (pages = [], slug) => find(pages, (page) => page.slug === slug)
)

export const selectPageMeta = createSelector(
  [ selectPage ],
  (page = {}) => page.meta || {}
)

export const selectPagesRegistration = createSelector(
  [ selectPages ],
  (pages = []) => {
    const pageWithRegistrationSlice = find(pages, ({ content = [] }) => find(content, ({ type }) => type === 'registration'))

    if (pageWithRegistrationSlice) {
      const registrationSlice = find(pageWithRegistrationSlice.content, ({ type }) => type === 'registration')

      return {
        hasPageRegistrationForm: true,
        registrationPageSlug: pageWithRegistrationSlice.slug,
        customFields: registrationSlice.items.length,
        campaignId: registrationSlice.primary.campaignId,
        charityId: registrationSlice.primary.charityId,
        eventId: registrationSlice.primary.eventId,
        includeAddress: registrationSlice.primary.showAddress,
        includeEventDate: registrationSlice.primary.showEventDate,
        includeFitnessGoal: registrationSlice.primary.showFitnessGoal,
        includeName: registrationSlice.primary.showName,
        includeTarget: registrationSlice.primary.showTarget,
        includeSelfDonate: registrationSlice.primary.includeSelfDonate,
        includeTeam: registrationSlice.primary.includeTeam,
        jgOauthMethod: registrationSlice.primary.jgOauthMethod
      }
    }

    return {
      hasPageRegistrationForm: false
    }
  }
)
