export default (props, {
  colors,
  scale,
  rhythm,
  treatments
}) => ({
  root: {
    margin: `${rhythm(0.75)} auto`,
    textAlign: 'center',
    overflow: 'hidden',
    padding: rhythm([0, 1])
  },

  inner: {
    ...treatments.button,
    position: 'relative',
    display: 'inline-block',
    fontSize: scale(-0.5),
    padding: rhythm([0, 1]),

    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      borderTop: `thin solid ${colors.shade}`,
      width: '100vw'
    },

    '&:before': {
      right: '100%'
    },

    '&:after': {
      left: '100%'
    }
  }
})
