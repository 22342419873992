export default ({
  align,
  content
}, {
  rhythm
}) => ({
  container: {
    textAlign: align
  },

  root: {
    marginTop: content && rhythm(1)
  }
})
