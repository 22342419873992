import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { selectStatus } from '../../../store/site'
import withStyles from 'constructicon/with-styles'
import styles from './styles'
import Loading from '../../ui/Loading'

const DocumentLoadStatus = ({
  children,
  classNames,
  status
}) => (
  status === 'fetched'
    ? children
    : (
      <div className={classNames.root}>
        <p className={classNames.label}>Loading your site</p>
        <Loading />
      </div>
    )
)

const mapState = (state) => ({
  status: selectStatus(state)
})

export default compose(
  connect(mapState),
  withStyles(styles)
)(DocumentLoadStatus)
