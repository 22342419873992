import React from 'react'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

const Divider = ({ children = 'or', classNames, styles }) => (
  <div className={classNames.root}>
    <span className={classNames.inner}>
      {children}
    </span>
  </div>
)

export default withStyles(styles)(Divider)
