import React from 'react'
import numbro from 'numbro'
import { formatDuration } from 'supporticon/utils/fitness'

import Metric from '../Metric'

const Duration = ({
  amountColor,
  format,
  fundraisingPage,
  icon,
  iconColor,
  iconSize,
  label,
  labelColor,
  offset,
  multiplier,
  numberStyle,
  uom
}) => {
  const amount = multiplier
    ? numbro(fundraisingPage.fitnessDurationTotal * multiplier + (offset || 0)).format(format || '0,0')
    : formatDuration(fundraisingPage.fitnessDurationTotal + (offset || 0))

  return (
    <Metric
      amount={amount}
      amountColor={amountColor}
      icon={icon}
      iconSize={iconSize || 'small'}
      labelColor={iconColor}
      numberStyle={numberStyle}
      label={label}
    />
  )
}

export default Duration
