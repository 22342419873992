import { isRichTextEmpty } from '../../../lib/richText'

const hasOneOf = items => (
  items.some(item => !isRichTextEmpty(item))
)

export default ({
  copyAlignment,
  subtitle,
  title,
  copy,
  titleForeground
}, {
  colors,
  rhythm
}) => ({
  title: {
    color: colors[titleForeground]
  },

  subtitle: {
    marginTop: rhythm(0.25)
  },

  copy: {
    textAlign: copyAlignment,
    marginTop: hasOneOf([title, subtitle]) && rhythm(1)
  },

  buttons: {
    paddingTop: rhythm(1),
    '&:first-child': {
      paddingTop: 0
    }
  }
})
