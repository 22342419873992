import React, { useEffect, useState } from 'react'
import template from 'lodash/template'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { selectFundraisingPage } from '../../../store/fundraisingPage'
import { selectTagPage } from '../../../store/tagPage'
import { selectTeam } from '../../../store/team'

export const withInjectParams = ComponentToWrap => {
  const Component = ({
    router: {
      location: {
        query
      } = {}
    } = {},
    ...props
  }) => {
    const {
      fundraisingPage,
      tagPage,
      team,
      injectData
    } = props

    const defaultParams = {
      amount: null,
      amount_x2: null,
      amount_x3: null,
      amount_x4: null,
      name: null,
      first_name: null,
      answer_1: null,
      answer_2: null,
      answer_3: null,
      ...tagPage,
      ...team,
      ...fundraisingPage,
      ...injectData
    }

    const [params, setParams] = useState(defaultParams)

    useEffect(() => {
      setParams({
        amount: query.amount,
        amount_x2: (query.amount || 0) * 2,
        amount_x3: (query.amount || 0) * 3,
        amount_x4: (query.amount || 0) * 4,
        name: query.name,
        first_name: query.first_name,
        answer_1: query.answer_1,
        answer_2: query.answer_2,
        answer_3: query.answer_3,
        ...tagPage,
        ...team,
        ...fundraisingPage,
        ...injectData
      })
    }, [query, fundraisingPage, tagPage, team, injectData])

    const injectParams = (str) => template(str)(params)

    return (
      <ComponentToWrap
        injectParams={injectParams}
        {...props}
      />
    )
  }

  const mapStateToProps = (state, props) => ({
    fundraisingPage: selectFundraisingPage(state, props),
    tagPage: selectTagPage(state, props),
    team: selectTeam(state, props)
  })

  return compose(
    connect(mapStateToProps),
    withRouter
  )(Component)
}

const InjectParams = withInjectParams(
  ({
    children,
    injectParams
  }) => (
    <span>
      {injectParams(children)}
    </span>
  )
)

export default InjectParams
