export default (props, traits) => {
  const { rhythm } = traits

  return {
    modal: {
      content: {
        maxWidth: rhythm(20)
      }
    },

    root: {
      padding: traits.rhythm(1)
    },

    message: {
      marginBottom: rhythm(1),
      lineHeight: 1.5,

      '& a': {
        textDecoration: 'underline'
      }
    },

    options: {
      display: 'flex',
      margin: rhythm(-0.5),

      '& > *': {
        margin: rhythm(0.5)
      }
    }
  }
}
