import React from 'react'
import numbro from 'numbro'
import withStyles from 'constructicon/with-styles'
import styles from './styles'
import { convertMetersToUnit } from 'supporticon/utils/units'

import Grid from 'constructicon/grid'
import GridColumn from 'constructicon/grid-column'
import Metric from 'constructicon/metric'
import Progress from 'constructicon/progress-bar'
import Section from '../../ui/Section'

const ProgressBar = ({
  alignment,
  background,
  barBackground = 'shade',
  barForeground = 'primary',
  barRadius = 'small',
  classNames,
  customSupporterPage,
  foreground,
  fundraisingPage,
  raisedLabel,
  spacing,
  styles,
  target,
  targetLabel,
  type,
  units
}) => {
  const { fitness } = customSupporterPage
  const formatMethod = units ? 'formatCurrency' : 'format'
  const showFitnessUnits = units && fitness.goalUom !== 'custom'

  const totals = type === 'distance'
    ? {
      amount: (
        <span>
          {numbro(convertMetersToUnit(fundraisingPage.fitnessDistanceTotal, fitness.goalUom)).format('0,0.[0]')}
          {showFitnessUnits && <small className={classNames.unit}> {fitness.goalUom}</small>}
        </span>
      ),
      target: (
        <span>
          {numbro(fundraisingPage.fitnessGoal).format('0,0.[0]')}
          {showFitnessUnits && <small className={classNames.unit}> {fitness.goalUom}</small>}
        </span>
      ),
      progress: convertMetersToUnit(fundraisingPage.fitnessDistanceTotal, fitness.goalUom) / fundraisingPage.fitnessGoal * 100
    } : {
      amount: numbro(fundraisingPage.raised)[formatMethod]('0,0'),
      target: numbro(fundraisingPage.target)[formatMethod]('0,0'),
      progress: fundraisingPage.raised / fundraisingPage.target * 100
    }

  return (
    <Section
      alignment={alignment}
      background={background}
      foreground={foreground}
      spacing={spacing}
    >
      <Grid spacing={0.25}>
        {raisedLabel && (
          <GridColumn xs={6}>
            <Metric
              align='left'
              amount={totals.amount}
              label={raisedLabel}
              styles={styles.metric}
            />
          </GridColumn>
        )}
        {targetLabel && (
          <GridColumn xs={6}>
            <Metric
              align='right'
              amount={totals.target}
              label={targetLabel}
              styles={styles.metric}
            />
          </GridColumn>
        )}
        <GridColumn>
          <Progress
            alt='<%= progress %>% there'
            background={barBackground}
            progress={totals.progress}
            fill={barForeground}
            radius={barRadius}
            styles={styles.progress}
          />
        </GridColumn>
      </Grid>
    </Section>
  )
}

export default withStyles(styles)(ProgressBar)
