import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { selectUrl } from '../../../store/site'
import { selectTeam, selectIsTeamOwner } from '../../../store/team'
import { selectUser } from '../../../store/session'
import { withInjectParams } from '../../utils/InjectParams'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import Container from 'constructicon/container'
import EditPage from '../../supporters/EditPage'
import Grid from 'constructicon/grid'
import GridColumn from 'constructicon/grid-column'
import Head from '../../utils/Head'
import Image from 'constructicon/image'
import InactivePage from '../../supporters/InactivePage'
import Slices from '../../supporters/Slices'
import UserMenu from '../UserMenu'

const TeamPage = ({
  classNames,
  customSupporterPage,
  team,
  injectParams,
  isTeamOwner,
  siteUrl,
  styles,
  user
}) => {
  const { teamBanner, meta = {} } = customSupporterPage

  if (!team.active) {
    return <InactivePage />
  }

  return (
    <div>
      <Head
        title={injectParams(meta.title || '<%= name %>')}
        description={injectParams(meta.description || '<%= story %>')}
        ogType='profile'
        ogTitle={injectParams(meta.ogTitle || '<%= name %>')}
        ogDescription={injectParams(meta.ogDescription || '<%= story %>')}
        ogImage={{ url: team.image }}
        ogUrl={{ url: `${siteUrl}/teams/${team.slug}` }}
      />
      {teamBanner.url && (
        <div className={classNames.banner}>
          <Image
            alt={teamBanner.alt || 'Team banner'}
            src={teamBanner.url}
            styles={styles.image}
          />
        </div>
      )}
      <Container spacing={{ y: 2, x: 1 }}>
        {customSupporterPage.teamSidebar.length > 0 ? (
          <Grid spacing={1}>
            <GridColumn md={4}>
              <aside className={classNames.sidebar}>
                <Slices
                  type='team'
                  customSupporterPage={customSupporterPage}
                  slices={customSupporterPage.teamSidebar}
                />
              </aside>
            </GridColumn>
            <GridColumn md={8}>
              <main className={classNames.main}>
                <Slices
                  type='team'
                  customSupporterPage={customSupporterPage}
                  slices={customSupporterPage.teamMain}
                />
              </main>
            </GridColumn>
          </Grid>
        ) : (
          <main className={classNames.main}>
            <Slices
              type='team'
              customSupporterPage={customSupporterPage}
              slices={customSupporterPage.teamMain}
            />
          </main>
        )}
      </Container>
      {isTeamOwner && (
        <UserMenu hidePageLink showOnOpen>
          <EditPage
            buttonLabel='Edit Team'
            customSupporterPage={customSupporterPage}
            selected='team'
          />
        </UserMenu>
      )}
    </div>
  )
}

const mapState = (state, props) => ({
  team: selectTeam(state, props),
  isTeamOwner: selectIsTeamOwner(state, props),
  user: selectUser(state, props),
  siteUrl: selectUrl(state)
})

export default compose(
  withInjectParams,
  withStyles(styles),
  connect(mapState)
)(TeamPage)
