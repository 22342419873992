export default (props, traits) => {
  const { achieved, badge = {} } = props
  const { colors, rhythm, scale, shadows, transitions } = traits

  return {
    root: {
      position: 'relative',

      '& > div:first-child': {
        position: 'absolute',
        display: badge.tooltip ? 'block' : 'none',
        left: '50%',
        bottom: '100%',
        transform: 'translateX(-50%)',
        width: '100%',
        maxWidth: rhythm(16),
        padding: rhythm([0.25, 0.5]),
        backgroundColor: colors.light,
        fontSize: scale(-1),
        textAlign: 'center',
        boxShadow: shadows.light,
        borderRadius: '0.25em',
        opacity: 0,
        visibility: 'hidden',
        transition: transitions.easeOut
      },

      '&:hover > div:first-child': {
        opacity: 1,
        visibility: 'visible'
      }
    },

    badge: {
      '& p': {
        marginTop: rhythm(0.25),
        textAlign: 'center'
      },
      '& img': {
        width: '100%'
      },
      ...!achieved ? {
        filter: 'saturate(0)',
        opacity: 0.2
      } : {
        cursor: 'pointer'
      }
    }
  }
}
