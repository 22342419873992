import React from 'react'
import DefaultButton from '../../utils/ThemedButton'

const Button = ({
  children = '',
  ...props
}) => (
  <DefaultButton {...props}>
    {children}
  </DefaultButton>
)

export default Button
