import React from 'react'
import { disconnectFitness } from 'supporticon/api/fitness'

import Button from '../../../../ui/Button'
import Icon from 'constructicon/icon'
import Section from 'constructicon/section'

class DisconnectStrava extends React.Component {
  constructor () {
    super()
    this.state = { status: null }
  }

  render () {
    const { onChange, page, user } = this.props
    const { status } = this.state

    return (
      <div>
        <Button
          background='strava'
          onClick={() => {
            this.setState({ status: 'fetching' })
            disconnectFitness({ pageId: page.uuid, token: user.token })
              .then(() => onChange())
              .catch(error => {
                this.setState({ status: 'failed' })
                return Promise.reject(error)
              })
          }}>
          <Icon
            name={status === 'fetching' ? 'loading' : 'strava'}
            size={1.5}
            spin={status === 'fetching'}
          />
          <span>Disconnect from Strava</span>
        </Button>
        {status === 'failed' && (
          <Section
            background='danger'
            foreground='light'
            margin={{ t: 0.5 }}
            radius='small'
            spacing={{ y: 0.5, x: 1 }}>
            There was an unexpected error
          </Section>
        )}
      </div>
    )
  }
}

export default DisconnectStrava
