import React from 'react'
import withStyles from 'constructicon/with-styles'
import styles from './styles'
import { Link } from 'react-router'

import Button from '../../../ui/Button'
import ButtonGroup from 'constructicon/button-group'

const CookieConsentBanner = ({
  acceptConsent,
  classNames,
  consent,
  content = {},
  denyConsent,
  styles
}) => (
  <div className={classNames.root}>
    <p className={classNames.message}>
      {content.message || 'We use cookies to help make this website better, to improve our services and for advertising purposes.'}
      {' '}
      <Link
        className={classNames.link}
        to='/?cookies=policy'>
        {content.link || 'Learn More'}
      </Link>
    </p>
    <ButtonGroup styles={styles.buttons}>
      <Button
        {...content.button}
        onClick={acceptConsent}>
        {content.dismiss || 'Accept'}
      </Button>
      <Button
        background='transparent'
        foreground='dark'
        onClick={denyConsent}>
        Reject
      </Button>
    </ButtonGroup>
  </div>
)

export default withStyles(styles)(CookieConsentBanner)
