import React from 'react'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import FlatModal from '../../../ui/FlatModal'
import Heading from 'constructicon/heading'
import RichText from '../../../ui/RichText'
import Section from 'constructicon/section'

class Badge extends React.Component {
  constructor () {
    super()
    this.state = { open: false }
  }

  render () {
    const {
      achieved,
      badge,
      classNames
    } = this.props

    return (
      <div className={classNames.root}>
        <div>{badge.tooltip}</div>
        <button
          className={classNames.badge}
          onClick={() => this.setState({ open: achieved })}>
          <img
            alt={badge.image.alt || badge.name}
            src={badge.image.url}
          />
          <p>
            {badge.name}
          </p>
        </button>
        <FlatModal
          contentLabel={badge.name}
          isOpen={this.state.open}
          onRequestClose={() => this.setState({ open: false })}>
          <Section>
            <Heading>
              {badge.name}
            </Heading>
            <RichText>
              {badge.description}
            </RichText>
          </Section>
        </FlatModal>
      </div>
    )
  }
}

export default withStyles(styles)(Badge)
