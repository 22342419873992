export default (props, {
  colors,
  rhythm,
  shadows
}) => ({
  searchForm: {
    form: {
      maxWidth: 'auto',
      padding: 0
    },
    results: {
      paddingTop: 0
    }
  },

  results: {
    root: {
      position: props.modal === 'Yes' ? 'static' : 'absolute',
      zIndex: 12,
      top: '100%',
      left: 0,
      right: 0,
      maxHeight: rhythm(12),
      overflow: 'auto',
      backgroundColor: colors.light,
      color: colors.dark,
      boxShadow: props.modal !== 'Yes' && shadows.light
    }
  },

  result: {
    root: {
      '&:nth-child(even)': {
        backgroundColor: colors.shade
      }
    }
  }
})
