import { createSelector } from 'reselect'

export const selectTeam = state => state.team.data
export const selectTeamStatus = state => state.team.status
export const selectLoggedInUser = state => state.session.user

export const selectIsTeamOwner = createSelector(
  [ selectTeam, selectLoggedInUser ],
  (team = {}, user = {}) => {
    if (team.owner === user.uuid) return true
    if (team.owner === user.id) return true

    return false
  }
)
