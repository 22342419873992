export default (props, {
  colors,
  shadows
}) => ({
  root: {
    position: 'relative'
  },

  results: {
    position: 'absolute',
    zIndex: 12,
    top: '100%',
    left: 0,
    right: 0,
    backgroundColor: colors.light,
    boxShadow: shadows.light
  },

  searchForm: {
    form: {
      maxWidth: 'auto',
      padding: 0
    }
  }
})
