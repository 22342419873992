import React from 'react'
import moment from 'moment'
import startCase from 'lodash/startCase'
import { connect } from 'react-redux'
import { getBaseURL, isJustGiving, isStaging } from 'supporticon/utils/client'
import { selectButtonDefaults, selectAPIParams } from '../../../../../store/site'
import { getFitnessTypes } from '../../../../../lib/fitness'

import DisconnectStrava from '../DisconnectStrava'
import Icon from 'constructicon/icon'
import ProviderOauthButton from 'supporticon/components/provider-oauth-button'
import SingleSignOnLink from 'supporticon/components/single-sign-on-link'

const FitnessButton = ({
  buttonStyles,
  fitnessOptions,
  isConnected,
  onChange,
  provider,
  page,
  user,
  apiParams = {}
}) => {
  const getButtonProperties = () => {
    if (isJustGiving()) {
      if (isConnected) {
        return {
          Component: DisconnectStrava,
          onChange,
          page,
          user
        }
      } else {
        const fitnessTypes = getFitnessTypes(fitnessOptions.types)
          .map(type => type.toUpperCase())
          .filter(type => type !== 'MANUAL')

        const formatDate = date => moment(date).toISOString().substring(0, 19)

        const dateRange = [
          apiParams.startDate && `startTime=${formatDate(apiParams.startDate)}`,
          apiParams.endDate && `endTime=${formatDate(apiParams.endDate)}`
        ]
          .filter(Boolean)
          .join('&')

        return {
          Component: ProviderOauthButton,
          clientId: isStaging() ? process.env.STRAVA_STAGING_CLIENT_ID : process.env.STRAVA_PRODUCTION_CLIENT_ID,
          label: `Connect to Strava`,
          redirectUri: `${getBaseURL()}/${process.env.JG_API_KEY}/v1/fitness/auth/${page.slug}/METRIC/${fitnessTypes.join(',')}?${dateRange}`,
          onSuccess: onChange,
          provider: 'strava',
          token: user.token
        }
      }
    } else {
      return {
        Component: SingleSignOnLink,
        background: provider,
        target: isConnected ? '_blank' : '_top',
        token: user.token,
        url: isConnected ? `${getBaseURL()}/supporter/connections` : `${getBaseURL().replace('https://', 'https://passport.')}/auth/${provider}/?auth_type=rerequest`,
        label: (
          <span>
            <Icon name={provider} />
            <span style={{ marginLeft: '0.5rem' }}>
              {`${isConnected ? 'Disconnect from' : 'Connect to'} ${startCase(provider)}`}
            </span>
          </span>
        )
      }
    }
  }

  const { Component, ...props } = getButtonProperties()

  return (
    <Component
      {...props}
      {...buttonStyles}
    />
  )
}

const mapState = (state) => ({
  buttonStyles: selectButtonDefaults(state),
  ...selectAPIParams(state)
})

export default connect(mapState)(FitnessButton)
