import React from 'react'
import moment from 'moment'
import Metric from 'constructicon/metric'

const CountdownMetric = ({
  endDate,
  label,
  metric,
  multiplier,
  ...props
}) => {
  const today = moment()
  const eventMoment = moment(endDate)
  const daysDiff = eventMoment.diff(today, 'days')
  const days = Math.max(0, daysDiff)
  const amount = days * (multiplier || 1)

  return (
    <Metric
      amount={amount}
      label={label}
      {...metric}
      {...props}
    />
  )
}

export default CountdownMetric
