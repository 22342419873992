import randomColor from 'randomcolor'
import { stringify } from 'querystringify'

export const defaultAvatarRegex = new RegExp('assets/users/images|missing')

export const isDefaultPlatformAvatar = image => defaultAvatarRegex.test(image)

export const getAvatar = (image, defaultAvatar) => {
  if (!defaultAvatar) return image
  if (!image || isDefaultPlatformAvatar(image)) return defaultAvatar
  return image
}

export const generateAvatar = (seed = '', option, staticImage) => {
  const name = encodeURIComponent(seed)
  const hash = parseInt(
    typeof seed === 'string'
      ? seed.toLowerCase().replace(/[^a-z0-9]/g, '')
      : Math.random().toString(36).slice(-6),
    36
  )

  switch (option) {
    case 'robot-alt':
      return `https://robohash.org/${name}?bgset=bg1&set=set1&size=500x500`
    case 'alien':
      return `https://robohash.org/${name}?bgset=bg1&set=set2&size=500x500`
    case 'robot':
      return `https://robohash.org/${name}?bgset=bg1&set=set3&size=500x500`
    case 'cat':
      return `https://robohash.org/${name}?bgset=bg1&set=set4&size=500x500`
    case 'person':
      return `https://robohash.org/${name}?bgset=bg1&set=set5&size=500x500`
    case 'monster':
      return `https://api.adorable.io/avatar/${name}`
    case 'face':
      return `https://api.adorable.io/avatars/500/${name}`
    case 'identicon':
    case 'retro':
      return `https://www.gravatar.com/avatar/${hash}?d=${option}&f=y&s=500`
    case 'staticImage':
      return staticImage.url
    default:
      const options = stringify({
        name,
        background: randomColor({ seed, luminosity: 'light' }),
        bold: true,
        color: randomColor({ seed, luminosity: 'bright' }),
        length: 3,
        size: 500
      })

      return `https://ui-avatars.com/api/?${options}`
  }
}
