import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { Link } from 'react-router'
import { fetchSessionTeam, selectTeam, selectPage } from '../../../../store/session'
import { selectButtonDefaults, selectLocalisation } from '../../../../store/site'
import { selectCustomSupporterPagePresent } from '../../../../store/customSupporterPages'
import withStyles from 'constructicon/with-styles'
import styles from './styles'
import mapTemplate from '../../../../lib/mapTemplate'

import Button from '../../../ui/Button'
import ButtonGroup from 'constructicon/button-group'
import ConfirmTeamModal from '../../../supporters/ConfirmTeamModal'
import CreateTeamForm from 'supporticon/components/create-team-form'
import Heading from 'constructicon/heading'
import JoinTeamForm from 'supporticon/components/join-team-form'
import Modal from '../../../ui/FlatModal'
import Section from 'constructicon/section'
import Success from '../../../ui/Success'
import RichText from '../../../ui/RichText'

class TeamRegistration extends Component {
  constructor (props) {
    super(props)
    this.closeModal = this.closeModal.bind(this)
    this.renderModal = this.renderModal.bind(this)
    this.handleSuccess = this.handleSuccess.bind(this)

    this.state = {
      modal: !!props.preselectedTeam,
      successMessage: null,
      view: props.preselectedTeam ? 'preselected' : 'initial'
    }
  }

  handleSuccess (team) {
    const { fetchSessionTeam } = this.props

    this.setState({
      modal: true,
      view: 'success',
      successMessage: `You are now part of ${team.name}`
    })

    return fetchSessionTeam(team)
  }

  closeModal () {
    this.setState({
      errors: [],
      loading: false,
      modal: false,
      page: 1,
      view: 'initial'
    })
  }

  renderModal () {
    const {
      button,
      campaign,
      classNames,
      country,
      hasCustomSupporterPages,
      page,
      preselectedTeam,
      styles,
      team,
      user
    } = this.props

    const {
      modal,
      successMessage,
      view
    } = this.state

    const modalProps = {
      isOpen: modal,
      onRequestClose: this.closeModal,
      spacing: 1.5
    }

    switch (view) {
      case 'create':
        return (
          <Modal
            contentLabel='Create team'
            styles={styles.modal}
            {...modalProps}>
            <Section spacing={{ b: 1 }}>
              <Heading>Create a team</Heading>
              <CreateTeamForm
                campaign={campaign}
                country={country}
                pageId={page.id}
                pageSlug={page.slug}
                onSuccess={this.handleSuccess}
                token={user.token}
              />
            </Section>
            <a className={classNames.link} onClick={this.closeModal}>Cancel</a>
          </Modal>
        )
      case 'join':
        return (
          <Modal
            contentLabel='Join a team'
            styles={styles.joinModal}
            {...modalProps}>
            <Section spacing={{ b: 1 }}>
              <Heading>Join a team</Heading>
              <JoinTeamForm
                campaign={campaign}
                pageId={page.uuid}
                pageSlug={page.slug}
                onSuccess={this.handleSuccess}
                token={user.token}
              />
            </Section>
            <a className={classNames.link} onClick={this.closeModal}>Cancel</a>
          </Modal>
        )
      case 'preselected':
        return (
          <ConfirmTeamModal
            campaign={campaign}
            modal={modalProps}
            pageId={page.uuid}
            pageSlug={page.slug}
            onSuccess={this.handleSuccess}
            teamId={preselectedTeam}
            token={user.token}
          />
        )
      case 'success':
        return team && (
          <Modal
            contentLabel='Success!'
            styles={styles.modal}
            {...modalProps}>
            <Success title={successMessage}>
              <Button
                tag={Link}
                to={hasCustomSupporterPages ? `/teams/${team.slug}` : team.url}
                {...button}>
                Visit your team
              </Button>
            </Success>
            <a className={classNames.link} onClick={this.closeModal}>Close</a>
          </Modal>
        )
      default:
        return null
    }
  }

  render () {
    const {
      button,
      classNames,
      hasCustomSupporterPages,
      page,
      styles,
      successCreateTeamButtonLabel,
      successJoinTeamButtonLabel,
      successTeamCopy = [],
      team,
      user
    } = this.props

    return (
      <div className={classNames.root}>
        {team ? (
          <Button
            tag={Link}
            to={hasCustomSupporterPages ? `/teams/${team.slug}` : team.url}
            {...button}>
            {`Visit your team (${team.name})`}
          </Button>
        ) : (
          <div>
            <RichText styles={styles.copy}>
              {successTeamCopy.map((item) => ({
                ...item,
                text: item.text ? mapTemplate(item.text, { user, page }) : 'Fundraising with others? Find a team or create your own'
              }))}
            </RichText>
            <ButtonGroup styles={styles.buttons}>
              <Button tag='a' onClick={() => this.setState({ modal: true, view: 'create' })}>{mapTemplate(successCreateTeamButtonLabel || 'Create team', { user, page })}</Button>
              <Button tag='a' onClick={() => this.setState({ modal: true, view: 'join' })}>{mapTemplate(successJoinTeamButtonLabel || 'Join team', { user, page })}</Button>
            </ButtonGroup>
          </div>
        )}
        {this.renderModal()}
      </div>
    )
  }
}

TeamRegistration.defaultProps = {}

const mapState = (store) => ({
  ...selectLocalisation(store),
  ...selectCustomSupporterPagePresent(store),
  button: selectButtonDefaults(store),
  team: selectTeam(store),
  page: selectPage(store)
})

const mapDispatch = { fetchSessionTeam }

export default compose(
  connect(mapState, mapDispatch),
  withStyles(styles)
)(TeamRegistration)
