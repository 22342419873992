import React from 'react'
import withForm from 'constructicon/with-form'
import form from './form'
import { updateCurrentUser } from 'supporticon/api/me'

import Form from 'constructicon/form'
import InputField from 'constructicon/input-field'
import SuccessMessage from '../SuccessMessage'

class EditAccountForm extends React.Component {
  constructor () {
    super()
    this.handleSubmit = this.handleSubmit.bind(this)
    this.state = { status: null }
  }

  handleSubmit (e) {
    e.preventDefault()

    const { form, persistUserData, user } = this.props

    const prepareUserData = data => ({
      authType: user.authType,
      firstName: data.firstName,
      lastName: data.lastName,
      name: `${data.firstName} ${data.lastName}`,
      email: data.email,
      uuid: user.uuid || user.id,
      token: user.token
    })

    return form
      .submit()
      .then(data =>
        Promise.resolve()
          .then(() => this.setState({ status: 'fetching' }))
          .then(() => prepareUserData(data))
          .then(params => updateCurrentUser(params))
          .then(() => persistUserData(data))
          .then(() => this.setState({ status: 'fetched' }))
          .catch(error => {
            this.setState({ status: 'failed' })
            return Promise.reject(error)
          })
      )
  }

  render () {
    const { buttonProps, form } = this.props
    const { status } = this.state

    const errors = status === 'failed'
      ? [{ message: 'There was an error updating your account' }]
      : []

    return (
      <div>
        <Form
          errors={errors}
          icon={false}
          isLoading={status === 'fetching'}
          noValidate
          onSubmit={this.handleSubmit}
          styles={{
            submit: {
              display: 'inline-block',
              width: 'auto'
            }
          }}
          submit='Update'
          submitProps={buttonProps}>
          <InputField {...form.fields.firstName} />
          <InputField {...form.fields.lastName} />
          <InputField {...form.fields.email} />
        </Form>
        <SuccessMessage visible={status === 'fetched'}>
          Account successfully updated
        </SuccessMessage>
      </div>
    )
  }
}

export default withForm(form)(EditAccountForm)
