import React from 'react'
import { isJustGiving } from '../../lib/environment'

import LeaderboardDefinitions from '../../components/container/AdminLeaderboardDefinitions'
import FlatModal from '../../components/ui/FlatModal'
import Home from '../Home'

const AdminLeaderboardDefinitions = ({ router }) => {
  if (!isJustGiving()) {
    router.push('/')
    return null
  }

  return (
    <div>
      <Home />
      <FlatModal
        isOpen
        shouldCloseOnOverlayClick={false}
        onRequestClose={() => router.push('/')}
        contentLabel='Admin leaderboards'
        width={26}
      >
        <LeaderboardDefinitions />
      </FlatModal>
    </div>
  )
}

export default AdminLeaderboardDefinitions
