import React, { useEffect } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { selectUrl } from '../../../store/site'
import { selectFundraisingPage, selectIsPageOwner } from '../../../store/fundraisingPage'
import { selectUser } from '../../../store/session'
import { withInjectParams } from '../../utils/InjectParams'
import { getSegmentedCustomSupporterPage } from '../../../lib/segmentation'
import { withRouter } from 'react-router'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import Container from 'constructicon/container'
import EditPage from '../../supporters/EditPage'
import Grid from 'constructicon/grid'
import GridColumn from 'constructicon/grid-column'
import Head from '../../utils/Head'
import Image from 'constructicon/image'
import InactivePage from '../../supporters/InactivePage'
import OnboardingWizard from '../../supporters/OnboardingWizard'
import Slices from '../../supporters/Slices'
import UserMenu from '../UserMenu'

const SupporterPage = ({
  classNames,
  customSupporterPages,
  fundraisingPage,
  injectParams,
  isPageOwner,
  location,
  router,
  siteUrl,
  styles,
  user
}) => {
  const customSupporterPage = getSegmentedCustomSupporterPage(customSupporterPages, fundraisingPage)
  const { banner, meta = {} } = customSupporterPage

  useEffect(() => {
    if (fundraisingPage.type === 'team') {
      router.replace(`/teams/${fundraisingPage.slug}`)
    }
  }, [fundraisingPage])

  if (!fundraisingPage.active) {
    return <InactivePage />
  }

  return (
    <div>
      <Head
        title={meta.title || '<%= name %>'}
        description={meta.description || '<%= story %>'}
        ogType='profile'
        ogTitle={meta.ogTitle || '<%= name %>'}
        ogDescription={meta.ogDescription || '<%= story %>'}
        ogImage={{ url: fundraisingPage.image }}
        ogUrl={{ url: `${siteUrl}/fundraising/${fundraisingPage.slug}` }}
      />
      {banner.url && (
        <div className={classNames.banner}>
          <Image
            alt={banner.alt || 'Page banner'}
            src={banner.url}
            styles={styles.image}
          />
        </div>
      )}
      <Container spacing={{ y: 2, x: 1 }}>
        {customSupporterPage.sidebar.length > 0 ? (
          <Grid spacing={1}>
            <GridColumn md={4}>
              <aside className={classNames.sidebar}>
                <Slices
                  customSupporterPage={customSupporterPage}
                  slices={customSupporterPage.sidebar}
                />
              </aside>
            </GridColumn>
            <GridColumn md={8}>
              <main className={classNames.main}>
                <Slices
                  customSupporterPage={customSupporterPage}
                  slices={customSupporterPage.main}
                />
              </main>
            </GridColumn>
          </Grid>
        ) : (
          <main className={classNames.main}>
            <Slices
              customSupporterPage={customSupporterPage}
              slices={customSupporterPage.main}
            />
          </main>
        )}
      </Container>
      {isPageOwner && (
        <div>
          <UserMenu hidePageLink showOnOpen>
            <EditPage customSupporterPage={customSupporterPage} />
          </UserMenu>
          {location.query.wizard && (
            <OnboardingWizard
              customSupporterPage={customSupporterPage}
              fundraisingPage={fundraisingPage}
              onClose={() => router.push(`/fundraising/${fundraisingPage.slug}`)}
              user={user}
            />
          )}
        </div>
      )}
    </div>
  )
}

const mapState = (state, props) => ({
  fundraisingPage: selectFundraisingPage(state, props),
  isPageOwner: selectIsPageOwner(state, props),
  user: selectUser(state, props),
  siteUrl: selectUrl(state)
})

export default compose(
  withInjectParams,
  withRouter,
  withStyles(styles),
  connect(mapState)
)(SupporterPage)
