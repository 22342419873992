import { getAvatar } from '../../../lib/avatar'

export default ({ defaultImage = {}, fundraisingPage }, traits) => {
  const image = getAvatar(fundraisingPage.image, defaultImage.url, fundraisingPage.type)
    .replace('CrowdfundingOwnerAvatar', 'FundraisingPageGalleryL')

  return {
    wrapper: {
      margin: 'auto',
      maxWidth: '20em'
    },
    image: {
      position: 'relative',
      paddingBottom: '100%',
      overflow: 'hidden',
      background: `url(${image}) no-repeat center`,
      backgroundSize: 'cover',
      img: {
        display: 'none'
      }
    }
  }
}
