import React, { useState } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { withInjectParams } from '../../utils/InjectParams'
import { fetchSessionTeam, preselectTeam, selectPage, selectUser } from '../../../store/session'
import { selectTeam } from '../../../store/team'

import Button from '../../ui/Button'
import ConfirmTeamModal from '../ConfirmTeamModal'

const JoinTeam = ({
  background,
  fetchSessionTeam,
  foreground,
  injectParams,
  label,
  page = {},
  preselectTeam,
  router,
  team,
  url,
  user
}) => {
  const [confirm, setConfirm] = useState(false)

  const button = {
    children: injectParams(label),
    background,
    foreground
  }

  return (
    <React.Fragment>
      {!page.teamPageId && (
        <Button
          {...button}
          onClick={() => {
            preselectTeam(team.id)
            page.id ? setConfirm(true) : router.push(url)
          }}
        />
      )}
      {confirm && (
        <ConfirmTeamModal
          campaign={team.campaign}
          modal={{ isOpen: confirm, onRequestClose: () => setConfirm(false) }}
          pageId={page.uuid}
          pageSlug={page.slug}
          onSuccess={team => fetchSessionTeam(team)
            .then(() => window.location.reload())}
          teamId={team.id}
          token={user.token}
        />
      )}
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  page: selectPage(state),
  team: selectTeam(state),
  user: selectUser(state)
})

const mapDispatchToProps = { fetchSessionTeam, preselectTeam }

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withInjectParams,
  withRouter
)(JoinTeam)
