export const getCurrentUrl = () => {
  if (typeof window !== 'undefined') {
    return `${window.location.href}`
  }
}

export const isFacebookApp = () => {
  if (typeof window !== 'undefined') {
    return navigator.userAgent.match(/FBAV|FBAN/i)
  }
}

export const getCurrentDomain = () => {
  if (typeof window !== 'undefined') {
    return window.location.hostname
      .split('.')
      .slice(-2)
      .join('.')
  }
}
