import React from 'react'
import ContentSection from '../../ui/ContentSection'

const Buttons = ({
  items = [],
  ...props
}) => (
  <ContentSection
    buttons={items}
    {...props}
  />
)

export default Buttons
