import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { selectCustomSupporterPagePresent } from '../../../store/customSupporterPages'
import { selectAPIParams, selectLocalisation } from '../../../store/site'
import { deserializeLeaderboard } from 'supporticon/api/leaderboard'
import { deserializeFitnessLeaderboard } from 'supporticon/api/fitness-leaderboard'
import { currencyCode } from 'supporticon/utils/currencies'
import { getAmountByCurrency } from 'supporticon/utils/totals'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import Leaderboard from 'supporticon/components/leaderboard'
import FitnessLeaderboard from 'supporticon/components/fitness-leaderboard'
import Section from '../../ui/Section'

class Leaderboards extends Component {
  constructor () {
    super()
    this.state = {
      tab: 0
    }
  }

  render () {
    const {
      alignment,
      apiParams,
      background,
      classNames,
      country,
      foreground,
      fundraisingPage = {},
      hasCustomSupporterPages,
      hideCharity,
      items = [{ type: 'fundraising' }],
      pageSize = 20,
      spacing,
      uom
    } = this.props

    const leaderboardItemProps = hideCharity === 'Yes' ? { subtitle: null } : undefined
    const selectedItem = items[this.state.tab]

    const LeaderboardTag = props => {
      let deserializeMethod

      switch (selectedItem.type) {
        case 'distance':
        case 'duration':
        case 'elevation':
          deserializeMethod = (item, index) => {
            const page = deserializeFitnessLeaderboard(item, index)
            return {
              ...page,
              url: hasCustomSupporterPages ? `/fundraising/${page.slug}` : page.url
            }
          }

          return (
            <FitnessLeaderboard
              {...props}
              sortBy={selectedItem.type}
              deserializeMethod={deserializeMethod}
            />
          )
        default:
          deserializeMethod = (item, index) => {
            const page = deserializeLeaderboard(item, index)
            return {
              ...page,
              raised: getAmountByCurrency(item.amounts, currencyCode(country)),
              url: hasCustomSupporterPages ? `/fundraising/${page.slug}` : page.url
            }
          }

          return <Leaderboard {...props} deserializeMethod={deserializeMethod} />
      }
    }

    return (
      <Section
        alignment={alignment}
        background={background}
        foreground={foreground}
        spacing={spacing}
      >
        {items.length > 1 && (
          <div className={classNames.toggles}>
            {items.map((item, index) => {
              const changeTab = () => this.setState({ tab: index })
              const isSelected = this.state.tab === index

              return (
                <div
                  onClick={changeTab}
                  onKeyDown={changeTab}
                  role='button'
                  key={index}
                  tabIndex={index}
                  aria-label={`Show ${item.label} leaderboard`}
                  aria-pressed={isSelected}
                  className={isSelected ? classNames.toggleOn : classNames.toggleOff}>
                  {item.label}
                </div>
              )
            })}
          </div>
        )}

        <div className={[classNames.leaderboard, `tag-${selectedItem.type}-leaderboard`].join(' ')}>
          <LeaderboardTag
            campaign={apiParams.campaign}
            filter={false}
            leaderboardItem={leaderboardItemProps}
            limit={selectedItem.limit ? parseInt(selectedItem.limit) : 20}
            multiplier={selectedItem.multiplier || 1}
            miles={uom === 'mi'}
            offset={selectedItem.offset}
            pageSize={parseInt(pageSize)}
            refreshInterval={apiParams.refreshInterval}
            tagId={fundraisingPage.tagId}
            tagValue={fundraisingPage.name}
            type='group'
            units={selectedItem.units}
          />
        </div>
      </Section>
    )
  }
}

const mapState = (store) => ({
  ...selectAPIParams(store),
  ...selectCustomSupporterPagePresent(store),
  ...selectLocalisation(store)
})

export default compose(
  withStyles(styles),
  connect(mapState)
)(Leaderboards)
