import React, { useEffect, useState } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { selectLocalisation, selectTraits } from '../../../store/site'
import { getForegroundColor } from 'constructicon/lib/color'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

const encodeJSON = json => window.btoa(JSON.stringify(json))

const FitnessElevation = ({ classNames, colors, elevation, sourceUrl, uom }) => {
  const [options, setOptions] = useState('')
  const color = getForegroundColor('white', colors.primary)

  useEffect(() => {
    setOptions(
      encodeJSON({
        color,
        elevation,
        imperial: uom === 'mi',
        url: sourceUrl
      })
    )
  }, [])

  return (
    <div className={classNames.root}>
      <iframe
        src={`https://fitness-elevation.blackbaud-sites.com/?options=${options}`}
        scrolling='no'
      />
    </div>
  )
}

const mapState = (state) => ({
  ...selectTraits(state),
  ...selectLocalisation(state)
})

export default compose(
  connect(mapState),
  withStyles(styles)
)(FitnessElevation)
