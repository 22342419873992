import React from 'react'
import withStyles from 'constructicon/with-styles'
import styles, { keyframes } from './styles'

import Heading from 'constructicon/heading'
import Icon from 'constructicon/icon'

const Success = ({
  title,
  children,
  classNames,
  id,
  styles
}) => (
  <div className={classNames.root} id={id}>
    <div className={classNames.icon}>
      <Icon name='check' size={3} />
    </div>
    {title && <Heading size={1.5} children={title} />}
    {children}
  </div>
)

export default withStyles(styles, keyframes)(Success)
