import get from 'lodash/get'
import keys from 'lodash/keys'
import accounts from '../../../accounts.json'

export const sites = keys(accounts).reduce((acc, key) => {
  const domains = get(accounts[key], 'domains', [])

  return {
    ...acc,
    ...domains.reduce((acc, domain) => ({
      ...acc,
      [domain]: key
    }), {})
  }
}, {})

export default sites
