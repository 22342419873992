export default (props, traits) => {
  const { rhythm } = traits

  return {
    modal: {
      content: {
        padding: rhythm(1),
        textAlign: 'center'
      }
    },

    message: {
      lineHeight: 1.5,
      marginBottom: rhythm(1)
    },

    team: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontWeight: 700,
      marginBottom: rhythm(1),

      '& img': {
        maxHeight: rhythm(2),
        marginRight: rhythm(0.5),
        borderRadius: '50%'
      }
    }
  }
}
