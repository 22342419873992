export default (props, traits) => {
  const {
    image: {
      thumbnail = {}
    } = {}
  } = props

  const {
    rhythm,
    transitions
  } = traits

  return {
    thumb: {
      display: 'block',
      width: '100%',
      position: 'relative',
      paddingBottom: '66.6%',
      cursor: 'pointer',
      overflow: 'hidden',

      '&:after': {
        content: '""',
        position: 'absolute',
        top: 0,
        right: 0,
        left: 0,
        bottom: 0,
        backgroundImage: thumbnail.url && `url(${thumbnail.url})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        transition: transitions.easeOut
      },

      '&:hover:after': {
        transform: 'scale(1.05)'
      }
    },

    copy: {
      padding: rhythm(1),
      '& div *:last-child': {
        paddingBottom: 0
      }
    }
  }
}
