import React from 'react'
import { connect } from 'react-redux'
import { selectTraits } from '../../../store/site'
import TraitsProvider from 'constructicon/traits-provider'

const Theme = ({ children, traits }) => {
  return (
    <TraitsProvider traits={traits}>
      {children}
    </TraitsProvider>
  )
}

const mapState = (state) => ({
  traits: selectTraits(state)
})

export default connect(mapState)(Theme)
