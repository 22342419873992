import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { selectAPIParams } from '../../../store/site'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import Ticker from 'supporticon/components/donation-ticker'

const DonationTicker = ({
  apiParams,
  background,
  currencyFormat,
  direction,
  foreground,
  format,
  includeOffline,
  label,
  labelBackground,
  labelForeground,
  page,
  pauseOnHover,
  speed,
  styles
}) => (
  <Ticker
    {...apiParams}
    campaign={apiParams.campaignIds || apiParams.campaignIds}
    event={apiParams.eventIds || apiParams.event}
    page={page || apiParams.page}
    layout={mapLayout(format)}
    limit={3}
    fetchAll
    format={currencyFormat}
    includeOffline={includeOffline === 'yes'}
    label={label}
    ticker={{
      background,
      direction: direction ? 'toRight' : 'toLeft',
      foreground,
      labelBackground,
      labelForeground,
      pauseOnHover,
      speed,
      styles
    }}
  />
)

const mapLayout = format => {
  switch (format) {
    case 'amount and donor name':
      return 'amount-name'
    case 'amount only':
      return 'amount-only'
    case 'donor name and amount':
      return 'name-amount'
    case 'donor name and message':
      return 'name-message'
    case 'donor name, message and amount':
      return 'name-message-amount'
    case 'donor message and amount':
      return 'message-amount'
    case 'donor message only':
      return 'message-only'
    default:
      return 'name-only'
  }
}

const mapStateToProps = store => selectAPIParams(store)

export default compose(
  connect(mapStateToProps),
  withStyles(styles)
)(DonationTicker)
