import React from 'react'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import Image from '../../../ui/Image'
import LinkHandler from '../../../utils/LinkHandler'
import RichText from '../../../ui/RichText'

const ImageLink = ({
  classNames,
  copy,
  image,
  url,
  styles
}) => (
  <div className={classNames.link}>
    <LinkHandler to={url}>
      <Image image={image} styles={styles.image} />
    </LinkHandler>
    {copy && (
      <RichText styles={styles.copy}>{copy}</RichText>
    )}
  </div>
)

export default withStyles(styles)(ImageLink)
