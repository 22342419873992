export default (props, {
  colors,
  rhythm
}) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexPack: 'center',
    flexWrap: 'wrap',
    margin: rhythm(-1)
  },

  section: {
    flex: `1 1 ${rhythm(14)}`,
    minWidth: rhythm(14),
    margin: rhythm(1)
  },

  feed: {
    marginTop: rhythm(0.5),
    backgroundColor: colors.light
  }
})
