import React from 'react'
import withForm from 'constructicon/with-form'
import form from './form'
import last from 'lodash/last'
import { uploadImage } from 'supporticon/api/images'
import { updateTeam } from 'supporticon/api/teams'
import { isJustGiving } from 'supporticon/utils/client'

import Form from 'constructicon/form'
import Grid from 'constructicon/grid'
import GridColumn from 'constructicon/grid-column'
import InputField from 'constructicon/input-field'
import InputImage from 'constructicon/input-image'
import SuccessMessage from '../../SuccessMessage'

class EditTeamForm extends React.Component {
  constructor () {
    super()
    this.handleSubmit = this.handleSubmit.bind(this)
    this.state = {
      errors: [],
      status: null
    }
  }

  handleSubmit (e) {
    e.preventDefault()

    const { currency, form, team, user } = this.props

    const prepareTeamData = (data, image) => ({
      image: image || null,
      authType: user.authType,
      currency,
      name: data.name,
      story: data.story,
      target: parseInt(data.target),
      token: user.token
    })

    const prepareImage = (image) => {
      if (image && form.fields.image.dirty) {
        return uploadImage(image)
          .then(image => isJustGiving() ? last(image.split('/')) : image)
      } else {
        return null
      }
    }

    return form
      .submit()
      .then(data =>
        Promise.resolve()
          .then(() => this.setState({ status: 'fetching' }))
          .then(() => prepareImage(data.image))
          .then(image => prepareTeamData(data, image))
          .then(params => updateTeam(team.id, params))
          .then(() => this.setState({ errors: [], status: 'fetched' }))
          .catch(error => {
            this.setState({
              errors: [{ message: 'There was an error updating your team' }],
              status: 'failed'
            })

            return Promise.reject(error)
          })
      )
  }

  render () {
    const { buttonProps, form } = this.props
    const { errors, status } = this.state

    return (
      <div>
        <Form
          errors={errors}
          icon={false}
          isLoading={status === 'fetching'}
          noValidate
          onSubmit={this.handleSubmit}
          styles={{
            submit: {
              display: 'inline-block',
              width: 'auto'
            }
          }}
          submit='Update'
          submitProps={buttonProps}>
          <Grid spacing={0.5}>
            <GridColumn md={6}>
              <InputField {...form.fields.name} />
              <InputField {...form.fields.target} />
              <InputField {...form.fields.story} />
            </GridColumn>
            <GridColumn md={6}>
              <InputImage {...form.fields.image} />
            </GridColumn>
          </Grid>
        </Form>
        <SuccessMessage visible={status === 'fetched'}>
          Team successfully updated
        </SuccessMessage>
      </div>
    )
  }
}

export default withForm(form)(EditTeamForm)
