import merge from 'lodash/merge'

const calculateSpacing = (spacing, baseSize = 1) =>
  (spacing === 'condensed' ? 0.5 : spacing === 'expanded' ? 2 : 1) * baseSize

export default ({
  image,
  imageSize,
  layout,
  spacing
}, {
  mediaQuery,
  rhythm
}) => ({
  image: merge({
    textAlign: 'center'
  }, imageSize === 'full' ? layout === 'default' ? {
    position: 'relative',
    left: '50%',
    width: '100vw',
    maxWidth: '100vw',
    transform: 'translateX(-50vw)',
    marginBottom: rhythm(0 - calculateSpacing(spacing)),
    '& img': {
      width: '100%'
    },
    [mediaQuery('lg')]: {
      marginBottom: rhythm(0 - calculateSpacing(spacing, 2))
    }
  } : {
    [mediaQuery('md', 'max-width')]: {
      marginLeft: rhythm(-1),
      marginRight: rhythm(-1),
      marginBottom: layout === 'right' && rhythm(0 - calculateSpacing(spacing)),
      marginTop: layout === 'left' && rhythm(0 - calculateSpacing(spacing))
    },
    [mediaQuery('md')]: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: layout === 'left' && 0,
      right: layout === 'right' && 0,
      width: '50%',
      backgroundImage: `url(${image.url})`,
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      '& img': {
        opacity: 0
      }
    }
  } : {})
})
