export default (props, { rhythm }) => ({
  carousel: {
    '& .slick-slider': {
      padding: 0
    }
  },

  slide: {
    paddingLeft: rhythm(2),
    paddingRight: rhythm(2),

    '& > *': {
      maxWidth: rhythm(20),
      margin: '0 auto'
    }
  },

  image: {
    margin: '0 auto',
    maxWidth: '100%'
  },

  staticImage: {
    width: '100%'
  }
})
