import { createDocumentsAction, createDocumentsReducer } from 'prismic-utils'
import { Predicates } from 'prismic-javascript'

// Constants
const NAMESPACE = 'app/pages'

// Selectors
export * from './selectors'

// Action
export const fetchPages = (repo, siteID, { previewToken } = {}) => (
  createDocumentsAction(NAMESPACE, {
    repository: repo,
    type: 'internal_page',
    token: previewToken,
    limit: 999,
    pageSize: 100,
    predicates: [
      Predicates.at('my.internal_page.site', siteID)
    ]
  })
)

// Reducer

// If there are no internal pages, instead of returning a response with no matching
// documents, it actually returns a 400. Meaning during the build, pages status is
// "failed", so it tries again in the client causing issues.
const fetchFailure = (state) => ({
  status: 'fetched',
  data: []
})

export default createDocumentsReducer(NAMESPACE, { fetchFailure })
