export default ({
  direction
}, {
  rhythm
}) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexPack: 'center',
    flexWrap: 'wrap',
    flexDirection: direction === 'vertical' && 'column',
    margin: rhythm(-0.75),

    '& > *': {
      margin: rhythm(0.75)
    }
  }
})
