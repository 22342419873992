export default ({
  amountColor,
  amountFont,
  iconSize = 'small',
  labelColor,
  numberStyle
}, {
  colors,
  rhythm,
  scale,
  treatments
}) => {
  const sizes = {
    large: rhythm(2),
    small: rhythm(1.2),
    left: rhythm(1.2)
  }

  const iconSizes = {
    small: rhythm(1.5),
    medium: rhythm(2),
    large: rhythm(4),
    'extra-large': rhythm(6)
  }

  return ({
    root: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: numberStyle === 'left' ? 'row' : 'column'
    },

    label: {
      order: 2,
      marginTop: rhythm(0.25),
      marginBottom: 0,
      color: colors[labelColor],
      fontSize: scale(1)
    },

    amount: {
      order: 1,
      color: colors[amountColor],
      fontSize: sizes[numberStyle],
      marginRight: numberStyle === 'left' ? '0.5em' : '0',
      ...treatments[amountFont]
    },

    icon: {
      width: iconSizes[iconSize],
      height: iconSizes[iconSize],
      marginBottom: rhythm(0.666)
    }
  })
}
