import React from 'react'

import Buttons from '../../slices/Buttons'
import CampaignTiles from '../../slices/CampaignTiles'
import Carousel from '../../slices/Carousel'
import CharitySearch from '../../slices/CharitySearch'
import Content from '../../slices/Content'
import ContentImage from '../../slices/ContentImage'
import Countdown from '../../slices/Countdown'
import DonationTicker from '../../slices/DonationTicker'
import DynamicBanner from '../../slices/DynamicBanner'
import FAQs from '../../slices/FAQs'
import FitnessLeaderboards from '../../slices/FitnessLeaderboards'
import FundraisingLeaderboards from '../../slices/FundraisingLeaderboards'
import Gallery from '../../slices/Gallery'
import GridContent from '../../slices/GridContent'
import HTMLContent from '../../slices/HTMLContent'
import IFrame from '../../slices/IFrame'
import ImageLinks from '../../slices/ImageLinks'
import Links from '../../slices/Links'
import Metrics from '../../slices/Metrics'
import Modal from '../../slices/Modal'
import PageProgressTiles from '../../slices/PageProgressTiles'
import ProgressBar from '../../slices/ProgressBar'
import ProjectLeaderboard from '../../slices/ProjectLeaderboard'
import ProjectMetrics from '../../slices/ProjectMetrics'
import ProjectSearch from '../../slices/ProjectSearch'
import ProjectTiles from '../../slices/ProjectTiles'
import Registration from '../../slices/Registration'
import Resources from '../../slices/Resources'
import SocialFeedCurator from '../../slices/SocialFeedCurator'
import SocialFeedJuicer from '../../slices/SocialFeedJuicer'
import SocialFeedDefault from '../../slices/SocialFeedDefault'
import SocialShare from '../../slices/SocialShare'
import StaticBanner from '../../slices/StaticBanner'
import SupporterSearch from '../../slices/SupporterSearch'
import SupporterTiles from '../../slices/SupporterTiles'
import Video from '../../slices/Video'

const sliceComponents = {
  buttons: Buttons,
  campaignTiles: CampaignTiles,
  carousel: Carousel,
  charitySearch: CharitySearch,
  content: Content,
  contentImage: ContentImage,
  countdown: Countdown,
  donationTicker: DonationTicker,
  dynamicBanner: DynamicBanner,
  faqs: FAQs,
  fitnessLeaderboards: FitnessLeaderboards,
  fundraisingLeaderboards: FundraisingLeaderboards,
  gallery: Gallery,
  gridContent: GridContent,
  htmlContent: HTMLContent,
  iframe: IFrame,
  imageLinks: ImageLinks,
  links: Links,
  metrics: Metrics,
  modal: Modal,
  pageProgressTiles: PageProgressTiles,
  progressBar: ProgressBar,
  projectLeaderboard: ProjectLeaderboard,
  projectMetrics: ProjectMetrics,
  projectSearch: ProjectSearch,
  projectTiles: ProjectTiles,
  registration: Registration,
  resources: Resources,
  socialFeedCurator: SocialFeedCurator,
  socialFeedJuicer: SocialFeedJuicer,
  socialFeedDefault: SocialFeedDefault,
  socialShare: SocialShare,
  staticBanner: StaticBanner,
  supporterSearch: SupporterSearch,
  supporterTiles: SupporterTiles,
  video: Video
}

const SliceProcessor = ({
  meta = {},
  page,
  slices = []
}) => (
  <main id='main' role='main'>
    {slices.map((slice, index) => {
      const Component = sliceComponents[slice.type]

      const duplicateCount = slices.slice(0, index).filter(({ type }) => type === slice.type).length
      const id = `${slice.type}${duplicateCount > 0 ? `-${index}` : ''}`

      const props = {
        meta,
        ...slice.primary,
        items: slice.items
      }

      return (
        <div key={`${page}-${id}`} id={id}>
          {Component
            ? <Component {...props} />
            : `Unknown slice type: ${slice.type}`}
        </div>
      )
    })}
  </main>
)

export default SliceProcessor
