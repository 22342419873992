export default (props, traits) => {
  const { scale, rhythm } = traits

  return {
    root: {
      position: 'fixed',
      zIndex: 999,
      bottom: rhythm(1),
      left: rhythm(1),
      boxShadow: '0 0 25px rgba(0,0,0,0.25)',
      fontSize: scale(-1)
    },

    caption: {
      maxWidth: rhythm(10),
      marginBottom: rhythm(0.5),
      lineHeight: 1.5
    },

    button: {
      textDecoration: 'underline'
    }
  }
}
