import React from 'react'
import kebabCase from 'lodash/kebabCase'
import template from 'lodash/template'

import BaseSection from 'constructicon/section'
import Button from '../../ui/Button'
import RichText from '../../ui/RichText'
import Section from '../../ui/Section'
import Title from '../../ui/Title'

const TagPageDetails = ({
  alignment,
  background,
  buttonBackground,
  buttonForeground,
  copy,
  cta,
  foreground,
  fundraisingPage,
  spacing,
  tag = {},
  tagDefinition,
  title
}) => {
  const tagValue = fundraisingPage.segmentation[tag.slug]

  if (!tagValue) {
    return null
  }

  const data = {
    id: tag.slug,
    name: tagValue,
    slug: kebabCase(tagValue)
  }

  return (
    <Section
      alignment={alignment}
      background={background}
      foreground={foreground}
      spacing={spacing}
    >
      {title && (
        <Title injectData={data}>
          {title}
        </Title>
      )}
      {copy && (
        <BaseSection spacing={{ b: 0.5 }}>
          <RichText injectData={data}>
            {copy}
          </RichText>
        </BaseSection>
      )}
      {cta && (
        <Button
          background={buttonBackground}
          foreground={buttonForeground}
          to={`/${data.id}/${data.slug}`}>
          {template(cta)(data)}
        </Button>
      )}
    </Section>
  )
}

export default TagPageDetails
