import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { selectUrl } from '../../../../store/site'
import { withMobile } from '../../../utils/withMobile'
import { withInjectParams } from '../../../utils/InjectParams'

import Button from '../../../ui/Button'
import ButtonGroup from 'constructicon/button-group'
import ButtonShare from 'constructicon/button-share'
import Section from 'constructicon/section'

const desktopProviders = [
  'facebook',
  'twitter',
  'mail'
]

const mobileProviders = [
  'facebook',
  'twitter',
  'whatsapp',
  'sms'
]

const SharePage = ({
  customSupporterPage,
  fundraisingPage,
  injectParams,
  isMobile,
  onNext,
  siteUrl
}) => {
  const providers = isMobile ? mobileProviders : desktopProviders

  return (
    <div>
      <ButtonGroup>
        {providers.map(provider => {
          if (provider === 'mail') {
            const subject = injectParams(customSupporterPage.meta.emailSubject || customSupporterPage.meta.ogTitle)
            const body = customSupporterPage.meta.emailBody ? injectParams(customSupporterPage.meta.emailBody) : `${siteUrl}/fundraising/${fundraisingPage.slug}`

            return (
              <ButtonShare
                key={provider}
                type='mail'
                share
                title={subject}
                url={body}
              />
            )
          }

          return (
            <ButtonShare
              caption={injectParams(customSupporterPage.meta.ogDescription)}
              key={provider}
              share
              type={provider}
              title={injectParams(customSupporterPage.meta.ogTitle)}
              url={`${siteUrl}/fundraising/${fundraisingPage.slug}`}
            />
          )
        })}
      </ButtonGroup>
      <Section spacing={{ t: 1 }}>
        <Button onClick={onNext}>
          Done
        </Button>
      </Section>
    </div>
  )
}

const mapState = state => ({
  siteUrl: selectUrl(state)
})

export default compose(
  connect(mapState),
  withMobile,
  withInjectParams
)(SharePage)
