import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import { prismicRichTextAsHTML } from 'prismic-utils'
import { stripTags } from 'supporticon/utils/html'

export const isRichTextEmpty = data =>
  isEmpty(data) || filter(data, item => !!item.text || !!item.url).length === 0

export const isRichText = data => Array.isArray(data)

export const convertToString = data => isRichText(data)
  ? stripTags(prismicRichTextAsHTML(data))
  : data
