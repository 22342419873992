import React from 'react'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import BaseMetric from 'constructicon/metric'

const Metric = ({
  classNames,
  styles,
  ...props
}) => (
  <BaseMetric
    styles={styles.metric}
    {...props}
  />
)

export default withStyles(styles)(Metric)
