import React from 'react'
import isEmpty from 'lodash/isEmpty'
import { connect } from 'react-redux'
import { selectProjectsStatus, selectProjectsByOrder } from '../../../store/projects'

import Content from '../../ui/Content'
import Metric from './Metric'
import MetricGroup from './MetricGroup'
import Section from '../../ui/Section'

const ProjectMetrics = ({
  alignment,
  amountColor,
  amountFont,
  backgroundImage,
  background,
  copy,
  copyAlignment,
  direction,
  donations,
  foreground,
  iconColor,
  items = [],
  labelColor,
  layout,
  offset,
  projects,
  size,
  spacing,
  status,
  subtitle,
  title,
  titleForeground
}) => (
  <Section
    alignment={alignment}
    backgroundImage={backgroundImage}
    background={background}
    foreground={foreground}
    size={size}
    spacing={spacing}>

    <Content
      copy={copy}
      copyAlignment={copyAlignment}
      layout={layout}
      subtitle={subtitle}
      title={title}
      titleForeground={titleForeground}>

      {!isEmpty(items) && (
        <MetricGroup direction={direction}>
          {items.map((item, index) => (
            <Metric
              amountColor={amountColor}
              amountFont={amountFont}
              format={item.format}
              icon={item.icon}
              iconColor={iconColor}
              key={index}
              label={item.label}
              labelColor={labelColor}
              metric={item.metric}
              multiplier={item.multiplier}
              offset={item.offset}
              projects={projects}
              status={status}
            />
          ))}
        </MetricGroup>
      )}

    </Content>
  </Section>
)

const mapState = (state) => ({
  projects: selectProjectsByOrder(state),
  status: selectProjectsStatus(state)
})

export default connect(mapState)(ProjectMetrics)
