import React from 'react'
import get from 'lodash/get'
import withForm from 'constructicon/with-form'
import form from './form'
import { isJustGiving } from '../../../../../lib/environment'
import { uploadToFilestack } from '../../../../../lib/filestack'
import { updatePage } from 'supporticon/api/pages'

import Form from 'constructicon/form'
import Grid from 'constructicon/grid'
import GridColumn from 'constructicon/grid-column'
import InputField from 'constructicon/input-field'
import InputImage from 'constructicon/input-image'
import SuccessMessage from '../../SuccessMessage'

class EditPageForm extends React.Component {
  constructor () {
    super()
    this.handleSubmit = this.handleSubmit.bind(this)
    this.state = {
      errors: [],
      status: null
    }
  }

  handleSubmit (e) {
    e.preventDefault()

    const {
      fitnessOptions,
      form,
      fundraisingPage,
      pageId,
      persistPageData,
      user
    } = this.props

    const preparePageData = (data, image) => ({
      image,
      authType: user.authType,
      name: data.name,
      story: data.story,
      target: parseFloat(data.target),
      token: user.token,
      fitnessGoal: data.fitnessGoal,
      ...isJustGiving() && {
        summaryWhat: data.fitnessGoal,
        summaryWhy: fitnessOptions.label || 'Fitness goal'
      }
    })

    const prepareImage = (image) => {
      if (image && form.fields.image.dirty) {
        return uploadToFilestack(image, pageId)
      }
    }

    return form
      .submit()
      .then(data =>
        Promise.resolve()
          .then(() => this.setState({ status: 'fetching' }))
          .then(() => prepareImage(data.image))
          .then(image => preparePageData(data, image))
          .then(params => {
            return updatePage(pageId, params)
              .then(() => persistPageData({
                ...params,
                image: params.image || fundraisingPage.image
              }))
          })
          .then(() => this.setState({ errors: [], status: 'fetched' }))
          .catch(error => {
            const message = get(error, 'data[0].desc') || 'There was an error updating your page'

            this.setState({
              errors: [{ message }],
              status: 'failed'
            })
            return Promise.reject(error)
          })
      )
  }

  render () {
    const { buttonProps, fitnessOptions, form } = this.props
    const { errors, status } = this.state

    return (
      <div>
        <Form
          errors={errors}
          icon={false}
          isLoading={status === 'fetching'}
          noValidate
          onSubmit={this.handleSubmit}
          styles={{
            submit: {
              display: 'inline-block',
              width: 'auto'
            }
          }}
          submit='Update'
          submitProps={buttonProps}>
          <Grid spacing={0.5}>
            <GridColumn md={6}>
              <InputField {...form.fields.name} />
              <InputField {...form.fields.target} />
              {fitnessOptions.showGoal && (
                <InputField {...form.fields.fitnessGoal} />
              )}
              <InputField {...form.fields.story} />
            </GridColumn>
            <GridColumn md={6}>
              <InputImage {...form.fields.image} />
            </GridColumn>
          </Grid>
        </Form>
        <SuccessMessage visible={status === 'fetched'}>
          Page successfully updated
        </SuccessMessage>
      </div>
    )
  }
}

export default withForm(form)(EditPageForm)
