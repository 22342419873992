export default (props, { rhythm, radiuses }) => ({
  root: {
    position: 'relative'
  },
  image: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: rhythm(1.25),
    height: rhythm(1.25),
    borderRadius: radiuses.large
  },
  input: {
    label: {
      height: rhythm(1.25),
      lineHeight: rhythm(1.25),
      paddingLeft: rhythm(1.75),
      marginBottom: rhythm(0.5),
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden'
    }
  }
})
