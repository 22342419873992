import React, { useState } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { getCurrentUrl, isFacebookApp } from '../../../../lib/window'
import { selectUseOauth } from '../../../../store/site'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import Divider from '../../../ui/Divider'
import Heading from 'constructicon/heading'
import LoginForm from 'supporticon/components/login-form'
import Modal from '../../../ui/FlatModal'
import ProviderOauthButton from 'supporticon/components/provider-oauth-button'
import ResetPassword from 'supporticon/components/reset-password-form'
import RichText from 'constructicon/rich-text'
import Success from '../../../ui/Success'

const JustGiving = ({
  buttonProps,
  classNames,
  onSuccess,
  styles,
  useJGOauth
}) => {
  if (useJGOauth) {
    return (
      <div className={classNames.root}>
        <Heading>Login</Heading>
        <RichText styles={styles.copy}>
          <p>Login by connecting to your existing JustGiving account below.</p>
        </RichText>
        <ProviderOauthButton
          {...buttonProps}
          provider='justgiving'
          label='Connect to JustGiving'
          homeUrl={getCurrentUrl()}
          clientId={process.env.JG_OAUTH_API_KEY}
          redirectUri={process.env.JG_REDIRECT_URI}
          popup={!isFacebookApp()}
          onSuccess={data => onSuccess({
            ...data,
            authType: 'Bearer'
          })}
        />
      </div>
    )
  }

  const [view, setView] = useState('login')
  const [modalOpen, setModalOpen] = useState(false)

  switch (view) {
    case 'reset-password':
      return (
        <div className={classNames.root}>
          <Heading>Reset your JustGiving password</Heading>
          <RichText styles={styles.copy}>
            <p>Our online fundraising is powered by <a href='https://www.justgiving.com' target='_blank'>JustGiving</a>.</p>
          </RichText>
          <ResetPassword
            formComponent={{
              submitProps: {
                ...buttonProps,
                background: 'justgiving',
                foreground: 'light'
              }
            }}
            onSuccess={() => setModalOpen(true)}
          />
          <button
            className={classNames.link}
            onClick={() => setView('login')}
          >
            Back to login
          </button>
          <Modal
            contentLabel='Email sent!'
            isOpen={modalOpen}
            onRequestClose={() => {
              setView('login')
              setModalOpen(false)
            }}
            spacing={1.5}>
            <Success title='Email sent!'>
              <RichText styles={styles.copy}>
                <p>If a JustGiving account exists with the email address entered, you will receive an email within the next few minutes.</p>
                <p>It contains instructions for changing your password.</p>
              </RichText>
            </Success>
          </Modal>
        </div>
      )
    default:
      return (
        <div className={classNames.root}>
          <Heading>Login</Heading>
          <RichText styles={styles.copy}>
            <p>Our online fundraising is powered by <a href='https://www.justgiving.com' target='_blank'>JustGiving</a>.</p>
          </RichText>
          <LoginForm
            formComponent={{
              submit: 'Log in to JustGiving',
              submitProps: {
                ...buttonProps,
                background: 'justgiving',
                foreground: 'light'
              }
            }}
            onSuccess={data => onSuccess({
              ...data,
              authType: 'Bearer'
            })}
          />
          <Divider />
          <ProviderOauthButton
            {...buttonProps}
            block
            provider='facebook'
            label='Continue with Facebook'
            homeUrl={getCurrentUrl()}
            clientId={process.env.JG_OAUTH_API_KEY}
            redirectUri={process.env.JG_REDIRECT_URI}
            popup={!isFacebookApp()}
            popupWindowFeatures='width=1024,height=900,status=1'
            onSuccess={data => onSuccess({
              ...data,
              authType: 'Bearer'
            })}
            useLocalStorage={isFacebookApp()}
          />
          <button
            className={classNames.link}
            onClick={() => setView('reset-password')}
          >
            Forgot your password?
          </button>
        </div>
      )
  }
}

const mapState = state => ({
  useJGOauth: selectUseOauth(state)
})

export default compose(
  connect(mapState),
  withStyles(styles)
)(JustGiving)
