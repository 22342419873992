import React from 'react'
import renderEmbedHtml from '../../../lib/embedHtml'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import Content from '../../ui/Content'
import Section from '../../ui/Section'

const Video = ({
  alignment,
  backgroundImage,
  background,
  classNames,
  copy,
  copyAlignment,
  foreground,
  items,
  layout,
  size,
  spacing,
  styles,
  subtitle,
  title,
  titleForeground,
  video = {}
}) => (
  <Section
    alignment={alignment}
    backgroundImage={backgroundImage}
    background={background}
    foreground={foreground}
    size={size}
    spacing={spacing}>
    <Content
      buttons={items}
      copy={copy}
      copyAlignment={copyAlignment}
      layout={layout}
      subtitle={subtitle}
      title={title}
      titleForeground={titleForeground}>

      <div
        className={classNames.video}
        dangerouslySetInnerHTML={{ __html: renderEmbedHtml(video) }}
      />

    </Content>
  </Section>
)

export default withStyles(styles)(Video)
