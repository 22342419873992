import React, { Component } from 'react'
import PropTypes from 'prop-types'

const loadSdk = locale =>
  ((d, s, id) => {
    const element = d.getElementsByTagName(s)[0]
    const fjs = element
    let js = element
    if (d.getElementById(id)) {
      return
    }
    js = d.createElement(s)
    js.id = id
    js.src = `//connect.facebook.net/${locale}/sdk.js`
    fjs.parentNode.insertBefore(js, fjs)
  })(document, 'script', 'facebook-jssdk')

class FacebookPage extends Component {
  componentDidMount () {
    const { appId, locale, version, xfbml } = this.props

    typeof window.fbAsyncInit === 'function'
      ? window.FB && window.FB.init({ appId, xfbml, version })
      : window.fbAsyncInit = () => window.FB.init({ appId, xfbml, version })

    loadSdk(locale)
  }

  componentDidUpdate () {
    if (window.FB) {
      window.FB.XFBML.parse()
    }
  }

  render () {
    const {
      adaptContainerWidth,
      height,
      hideCover,
      hideCta,
      href,
      showFacepile,
      smallHeader,
      tabs,
      width
    } = this.props

    const isGroup = href.indexOf('https://www.facebook.com/groups/') === 0

    return (
      <span>
        <div id='fb-root' />
        <div
          className={isGroup ? 'fb-group' : 'fb-page'}
          data-href={href}
          data-width={width}
          data-height={height}
          data-adapt-container-width={adaptContainerWidth}
          data-tabs={tabs.join()}
          data-hide-cover={hideCover}
          data-show-facepile={showFacepile}
          data-hide-cta={hideCta}
          data-small-header={smallHeader}
        />
      </span>
    )
  }
}

FacebookPage.propTypes = {
  adaptContainerWidth: PropTypes.bool,
  appId: PropTypes.string,
  height: PropTypes.number,
  hideCover: PropTypes.bool,
  hideCta: PropTypes.bool,
  href: PropTypes.string.isRequired,
  locale: PropTypes.string,
  showFacepile: PropTypes.bool,
  smallHeader: PropTypes.bool,
  tabs: PropTypes.arrayOf(PropTypes.string),
  version: PropTypes.string,
  width: PropTypes.number,
  xfbml: PropTypes.bool
}

FacebookPage.defaultProps = {
  adaptContainerWidth: true,
  appId: '249959688736682',
  height: 500,
  hideCover: false,
  hideCta: false,
  locale: 'en_US',
  showFacepile: true,
  smallHeader: false,
  tabs: ['timeline'],
  version: 'v2.5',
  width: 340,
  xfbml: true
}

export default FacebookPage
