export default (props, { colors, rhythm, radiuses }) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginBottom: rhythm(0.75)
  },
  activities: {
    paddingBottom: '1em'
  },
  footer: {
    textAlign: 'center',
    padding: rhythm([2, 1]),
    marginBottom: rhythm(1),
    border: `thin solid ${colors.shade}`,
    borderRadius: rhythm(radiuses.medium)
  }
})
