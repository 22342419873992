import React from 'react'
import numbro from 'numbro'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import ContentSection from '../../ui/ContentSection'
import Heading from 'constructicon/heading'
import LinkHandler from '../../utils/LinkHandler'

const TeamMembers = ({
  alignment,
  background,
  classNames,
  copy,
  foreground,
  fundraisingPage,
  spacing,
  styles,
  title
}) => {
  return (
    <ContentSection
      alignment={alignment}
      background={background}
      copy={copy}
      foreground={foreground}
      spacing={spacing}
      title={title}
    >
      {fundraisingPage.members.length > 0 ? (
        <div className={classNames.root}>
          {fundraisingPage.members.map(member => (
            <LinkHandler
              key={member.id}
              className={classNames.card}
              to={`/fundraising/${member.slug}`}
            >
              {member.image && (
                <img
                  src={member.image}
                  alt={member.name}
                  className={classNames.image}
                />
              )}
              <Heading size={0} spacing={0} tag='span' styles={styles.name}>
                {member.name}
              </Heading>
              <Heading size={0} spacing={{ x: 0.333 }} tag='span' styles={styles.amount}>
                {numbro(member.raised).formatCurrency('0,0[.]00')}
              </Heading>
            </LinkHandler>
          ))}
        </div>
      ) : (
        <p className={classNames.card}>This team currently has no members.</p>
      )}
    </ContentSection>
  )
}

export default withStyles(styles)(TeamMembers)
