import React from 'react'
import FlatModal from '../../../ui/FlatModal'

const SupporterSearchContainer = ({
  children,
  modal,
  router
}) => modal !== 'Yes' ? children : (
  <FlatModal
    children={children}
    contentLabel='Supporter Search Modal'
    isOpen={router.location.query.search}
    onRequestClose={() => { window.location = window.location.pathname }}
  />
)

export default SupporterSearchContainer
