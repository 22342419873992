import React from 'react'
import Button from '../Button'
import ButtonGroup from 'constructicon/button-group'

const Buttons = ({
  buttons = [],
  className,
  onClick
}) => (
  <div className={className}>
    {buttons.length > 0 ? (
      <ButtonGroup>
        {buttons.filter((button) => button.label).map((button, i) => (
          <Button
            key={i}
            to={button.url}
            background={button.background}
            block={button.block}
            foreground={button.foreground}
            linkType={button.linkType}
            onClick={() => {
              button.onClick && button.onClick()
              onClick && onClick()
            }}>
            {button.label}
          </Button>
        ))}
      </ButtonGroup>
    ) : null}
  </div>
)

export default Buttons
