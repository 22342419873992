import React from 'react'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import Content from '../../ui/Content'
import Image from '../../ui/Image'
import Section from '../../ui/Section'

const ContentImage = ({
  alignment,
  backgroundImage,
  background,
  classNames,
  copy,
  copyAlignment,
  foreground,
  image = {},
  imageSize,
  items,
  layout,
  size,
  spacing,
  styles,
  subtitle,
  title,
  titleForeground
}) => (
  <Section
    alignment={alignment}
    backgroundImage={backgroundImage}
    background={background}
    foreground={foreground}
    size={size}
    overflow={imageSize === 'full' && 'hidden'}
    position={imageSize === 'full' && 'relative'}
    spacing={spacing}>
    <Content
      buttons={items}
      copy={copy}
      copyAlignment={copyAlignment}
      layout={layout}
      subtitle={subtitle}
      title={title}
      titleForeground={titleForeground}>

      {image.url && (
        <div className={classNames.image}>
          <Image image={image} />
        </div>
      )}
    </Content>
  </Section>
)

export default withStyles(styles)(ContentImage)
