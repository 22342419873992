import React from 'react'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

const LayoutDefault = ({
  children,
  classNames,
  content
}) => (
  <div className={classNames.container}>
    {content}
    {children && (
      <div className={classNames.root}>
        {children}
      </div>
    )}
  </div>
)

export default withStyles(styles)(LayoutDefault)
