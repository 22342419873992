import moment from 'moment'
import { fetchDocument } from 'prismic-utils'
import { fetchSiteContent } from '..'

// Constants
const c = {
  SET_TOKEN: 'app/prismic/SET_TOKEN',
  CLEAR_TOKEN: 'app/prismic/CLEAR_TOKEN'
}

// Actions
export const setPrismicToken = (token, repo) => dispatch => {
  const expiry = moment().add(30, 'minutes').format()

  return fetchDocument({ repository: repo, token })
    .then(doc => doc.type === 'custom_site' ? doc.uid : doc.data.site.uid)
    .then(uid => {
      return Promise.all([
        dispatch({ type: c.SET_TOKEN, payload: { expiry, token, repo, uid } }),
        dispatch(fetchSiteContent(repo, uid, token))
      ])
    })
}

export const clearPrismicToken = () => ({
  type: c.CLEAR_TOKEN
})

// Selectors
export const selectPreviewStatus = (state) => state.prismic

// Reducer
export default (state = {}, { type, payload }) => {
  switch (type) {
    case c.SET_TOKEN:
      return {
        previewToken: payload.token,
        repo: payload.repo,
        expiry: payload.expiry,
        previewUID: payload.uid
      }
    case c.CLEAR_TOKEN:
      return { previewToken: null }
    default:
      return state
  }
}
