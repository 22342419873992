import React, { useState } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { selectMaintenanceMode } from '../../../store/site'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import Button from '../../ui/Button'
import Heading from 'constructicon/heading'
import Modal from '../../ui/FlatModal'
import RichText from 'constructicon/rich-text'

const MaintenanceMode = ({
  children,
  maintenanceMode,
  styles
}) => {
  const [open, setOpen] = useState(maintenanceMode)

  return (
    <React.Fragment>
      {open && (
        <Modal
          contentLabel='Under maintenance'
          isOpen
          onRequestClose={() => setOpen(false)}
          styles={styles.modal}>
          <Heading>
            Under maintenance
          </Heading>
          <RichText styles={styles.copy}>
            <p>The site is currently undergoing planned maintenance. You may continue to use the site, but certain features may not work as desired.</p>
          </RichText>
          <Button onClick={() => setOpen(false)}>
            Continue
          </Button>
        </Modal>
      )}
      {children}
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  maintenanceMode: selectMaintenanceMode(state)
})

export default compose(
  withStyles(styles),
  connect(mapStateToProps)
)(MaintenanceMode)
