import React from 'react'
import Heading from 'constructicon/heading'
import InjectParams from '../../utils/InjectParams'

const Title = ({
  children,
  injectData,
  ...props
}) => (
  <Heading {...props}>
    <InjectParams injectData={injectData}>
      {children}
    </InjectParams>
  </Heading>
)

export default Title
