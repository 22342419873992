import React from 'react'
import moment from 'moment'
import { deserializePost } from 'supporticon/api/posts'
import { isJustGiving } from '../../../../lib/environment'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import Activity from '../Activity'
import Button from '../../../ui/Button'
import CreatePostForm from 'supporticon/components/create-post-form'
import Modal from '../../../ui/FlatModal'
import Section from 'constructicon/section'
import Success from '../../../ui/Success'

class CreatePost extends React.Component {
  constructor () {
    super()
    this.state = {
      data: {},
      success: false
    }
  }

  handleClose () {
    this.setState({ success: false })
  }

  handleCreatePost (response) {
    const { onSuccess } = this.props

    const data = {
      ...deserializePost(response),
      createdAt: moment().toISOString(),
      activityType: 'post'
    }

    this.setState({ data, success: true })

    return onSuccess(data)
  }

  render () {
    const { data, success } = this.state
    const { classNames, page, styles, user } = this.props

    return success ? (
      <Modal
        contentLabel='Post an update to your page'
        isOpen
        spacing={1.5}
        onRequestClose={() => this.handleClose()}
      >
        <Success title='Logged successfully'>
          <Section spacing={{ b: 0.5 }}>
            <Activity {...data} page={page} />
          </Section>
          <Button onClick={() => this.handleClose()}>Close</Button>
        </Success>
      </Modal>
    ) : (
      <Section spacing={{ t: 1.5 }}>
        <div className={classNames.root}>
          <img className={classNames.image} src={user.image} />
          <CreatePostForm
            inputProps={{ styles: styles.input }}
            pageId={isJustGiving() ? page.uuid : page.id}
            token={user.token}
            userId={user.uuid}
            onSuccess={data => this.handleCreatePost(data)}
          />
        </div>
      </Section>
    )
  }
}

export default withStyles(styles)(CreatePost)
