import React from 'react'
import withForm from 'constructicon/with-form'
import withStyles from 'constructicon/with-styles'
import form from './form'
import styles from './styles'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { updatePage } from 'supporticon/api/pages'
import { isJustGiving } from '../../../../lib/environment'
import { uploadToFilestack } from '../../../../lib/filestack'
import { persistPageData } from '../../../../store/fundraisingPage'
import { selectButtonDefaults } from '../../../../store/site'

import Form from 'constructicon/form'
import InputImage from 'constructicon/input-image'

class ProfilePicture extends React.Component {
  constructor () {
    super()
    this.handleSubmit = this.handleSubmit.bind(this)
    this.state = { status: null }
  }

  handleSubmit (e) {
    e.preventDefault()

    const {
      form,
      fundraisingPage,
      onNext,
      persistPageData,
      user
    } = this.props

    return form.submit()
      .then(data =>
        Promise.resolve()
          .then(() => this.setState({ status: 'fetching' }))
          .then(() => uploadToFilestack(data.image, fundraisingPage.id))
          .then(image => {
            const pageId = isJustGiving() ? fundraisingPage.slug : fundraisingPage.id

            const payload = {
              authType: user.authType,
              image,
              token: user.token
            }

            return updatePage(pageId, payload)
              .then(() => persistPageData(payload))
          })
          .then(() => this.setState({ status: 'fetched' }))
          .then(() => onNext())
          .catch(error => {
            this.setState({ status: 'failed' })
            return Promise.reject(error)
          })
      )
  }

  render () {
    const { status } = this.state

    const {
      buttonProps,
      form,
      styles
    } = this.props

    const errors = status === 'failed'
      ? [{ message: 'There was an error updating your profile picture' }]
      : []

    return (
      <Form
        errors={errors}
        icon={false}
        isLoading={status === 'fetching'}
        noValidate
        onSubmit={this.handleSubmit}
        styles={styles.form}
        submit='Upload'
        submitProps={buttonProps}>
        <InputImage
          {...form.fields.image}
          styles={styles.image}
        />
      </Form>
    )
  }
}

const mapState = state => ({
  buttonProps: selectButtonDefaults(state)
})

const mapDispatch = { persistPageData }

export default compose(
  connect(mapState, mapDispatch),
  withStyles(styles),
  withForm(form)
)(ProfilePicture)
