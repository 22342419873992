export default ({
  amountColor,
  amountFont,
  labelColor
}, {
  colors,
  rhythm,
  scale,
  treatments
}) => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },

  label: {
    order: 2,
    marginTop: rhythm(0.25),
    marginBottom: 0,
    color: colors[labelColor],
    fontSize: scale(1)
  },

  amount: {
    order: 1,
    color: colors[amountColor],
    fontSize: scale(6),
    ...treatments[amountFont]
  }
})
