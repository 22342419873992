import React, { Component } from 'react'
import { CSSTransition } from 'react-transition-group'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import Button from '../../../ui/Button'

class FormState extends Component {
  constructor () {
    super()

    this.state = {
      animate: true
    }
  }

  componentWillUpdate (nextProps) {
    // Trigger animation
    if (nextProps.state !== this.props.state) {
      this.setState({ animate: false })
      setTimeout(() => this.setState({ animate: true }), 200)
    }
  }

  render () {
    const {
      children,
      classNames,
      foreground,
      links = [],
      state,
      styles
    } = this.props

    return (
      <CSSTransition
        in={this.state.animate}
        timeout={200}
        classNames={{
          enter: classNames.enter,
          enterActive: classNames.enterActive,
          exit: classNames.exit,
          exitActive: classNames.exitActive
        }}>
        <div
          className={classNames.root}
          data-state={state}
          id={state}>
          {children}
          <footer className={classNames.footer}>
            {links.map(({ label, onClick }, i) => (
              <Button
                background='transparent'
                foreground={foreground}
                key={i}
                onClick={onClick}
                styles={styles.button}
                tag='button'>
                {label}
              </Button>
            ))}
          </footer>
        </div>
      </CSSTransition>
    )
  }
}

export default withStyles(styles)(FormState)
