import find from 'lodash/find'
import get from 'lodash/get'
import kebabCase from 'lodash/kebabCase'

export const selectCustomTagPages = store => store.customTagPages.data
export const selectCustomTagPageStatus = store => store.customTagPages.status

export const selectCustomTagPage = (state, props) => {
  const customTagPages = state.customTagPages.data || []

  return find(customTagPages, ({ slug }) => kebabCase(slug) === get(props, 'router.params.tag'))
}
