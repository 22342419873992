import React from 'react'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

const Time = ({
  classNames,
  days,
  hours,
  minutes,
  seconds
}) => (
  <div className={classNames.root}>
    <div className={classNames.segment}>
      <div className={classNames.label}>Days</div>
      <div className={classNames.tile}>
        <span>{days}</span>
      </div>
    </div>
    <div className={classNames.segment}>
      <div className={classNames.label}>Hours</div>
      <div className={classNames.tile}>
        <span>{hours}</span>
      </div>
    </div>
    <div className={classNames.segment}>
      <div className={classNames.label}>Minutes</div>
      <div className={classNames.tile}>
        <span>{minutes}</span>
      </div>
    </div>
    <div className={classNames.segment}>
      <div className={classNames.label}>Seconds</div>
      <div className={classNames.tile}>
        <span>{seconds}</span>
      </div>
    </div>
  </div>
)

export default withStyles(styles)(Time)
