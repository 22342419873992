import React from 'react'
import withStyles from 'constructicon/with-styles'
import styles from './styles'
import { deserializePage, fetchPage } from 'supporticon/api/pages'
import { deserializeTeam, fetchTeam } from 'supporticon/api/teams'
import { currencyCode } from 'supporticon/utils/currencies'

import Button from '../../../ui/Button'
import ButtonGroup from 'constructicon/button-group'
import CreateTeamForm from 'supporticon/components/create-team-form'
import EditTeamForm from './EditTeamForm'
import Heading from 'constructicon/heading'
import JoinTeamForm from 'supporticon/components/join-team-form'
import Loading from '../../../ui/Loading'
import Section from 'constructicon/section'
import TeamDetails from './TeamDetails'

class ManageTeam extends React.Component {
  constructor (props) {
    super()
    this.state = {
      state: 'fetching',
      team: null
    }
  }

  componentDidMount () {
    this.checkTeamState(this.props, true)
  }

  componentWillReceiveProps (nextProps) {
    if (this.props.fundraisingPage !== nextProps.fundraisingPage && this.state === 'fetching') {
      this.checkTeamState(nextProps, false)
    }
  }

  checkTeamState (props, showEdit) {
    const { fundraisingPage, user } = props

    fetchPage(fundraisingPage.id)
      .then(page => deserializePage(page))
      .then(page => {
        if (page.teamPageId) {
          fetchTeam(page.teamPageId)
            .then(team => deserializeTeam(team))
            .then(team => {
              const isCaptain = team.owner === user.uuid || team.leader === fundraisingPage.id
              this.setState({ state: (isCaptain && showEdit) ? 'captain' : 'member', team })
            })
        } else {
          this.setState({ state: 'select' })
        }
      })
  }

  render () {
    const {
      state,
      team
    } = this.state

    const {
      buttonProps,
      campaign,
      classNames,
      country,
      fetchSessionTeam,
      fundraisingPage,
      persistPageData,
      styles,
      user
    } = this.props

    switch (state) {
      case 'captain':
        return (
          <div>
            <Heading>Edit Team</Heading>
            <EditTeamForm
              currency={currencyCode(country)}
              buttonProps={buttonProps}
              team={team}
              user={user}
            />
          </div>
        )
      case 'member':
        return (
          <div>
            <Heading>Your Team</Heading>
            <TeamDetails team={team} />
          </div>
        )
      case 'create':
        return (
          <div>
            <Heading>Create Team</Heading>
            <CreateTeamForm
              campaign={campaign}
              country={country}
              formProps={{
                actions: [{ label: 'Back', onClick: () => this.setState({ state: 'select' }) }],
                styles: styles.form,
                submitProps: buttonProps
              }}
              pageId={fundraisingPage.id}
              pageSlug={fundraisingPage.slug}
              onSuccess={team => {
                persistPageData({ teamPageId: team.id })
                fetchSessionTeam(team)
                this.setState({ state: 'member', team })
              }}
              token={user.token}
            />
          </div>
        )
      case 'join':
        return (
          <div>
            <Heading>Join Team</Heading>
            <JoinTeamForm
              campaign={campaign}
              formProps={{
                actions: [{ label: 'Back', onClick: () => this.setState({ state: 'select' }) }],
                styles: styles.form,
                submitProps: buttonProps
              }}
              pageId={fundraisingPage.uuid}
              pageSlug={fundraisingPage.slug}
              onSuccess={team => {
                persistPageData({ teamPageId: team.id })
                fetchSessionTeam(team)
                this.setState({ state: 'member', team })
              }}
              token={user.token}
            />
          </div>
        )
      case 'select':
        return (
          <div className={classNames.select}>
            <Section spacing={{ b: 1 }}>
              You are not yet part of a team.
            </Section>
            <ButtonGroup>
              <Button onClick={() => this.setState({ state: 'create' })}>
                Create a team
              </Button>
              <Button onClick={() => this.setState({ state: 'join' })}>
                Join a team
              </Button>
            </ButtonGroup>
          </div>
        )
      default:
        return (
          <Section spacing={{ x: 1, y: 5 }}>
            <Loading />
          </Section>
        )
    }
  }
}

export default withStyles(styles)(ManageTeam)
