import React from 'react'
import { compose } from 'redux'
import { isRichTextEmpty } from '../../../lib/richText'
import { withInjectParams } from '../../utils/InjectParams'
import withStyles from 'constructicon/with-styles'
import styles from './styles'
import Buttons from '../../ui/Buttons'
import RichText from '../../ui/RichText'
import Section from '../../ui/Section'

const CTAs = ({
  alignment,
  background,
  foreground,
  injectParams,
  items,
  spacing,
  styles,
  title
}) => {
  const buttons = items.map(item => ({
    ...item,
    label: injectParams(item.label),
    url: injectParams(item.url)
  }))

  return (
    <Section
      alignment={alignment}
      background={background}
      foreground={foreground}
      spacing={spacing}
    >
      {!isRichTextEmpty(title) && (
        <RichText styles={styles.title}>
          {title}
        </RichText>
      )}
      <Buttons buttons={buttons} />
    </Section>
  )
}

export default compose(
  withInjectParams,
  withStyles(styles)
)(CTAs)
