export default ({
  margin = 0,
  maxWidth,
  maxHeight,
  minWidth,
  minHeight,
  width,
  height,
  styles
}, {
  calculateSpacing,
  rhythm
}) => ({
  root: {
    maxWidth: maxWidth && rhythm(maxWidth),
    maxHeight: maxHeight && rhythm(maxHeight),
    minWidth: minWidth && rhythm(minWidth),
    minHeight: minHeight && rhythm(minHeight),
    width: width && rhythm(width),
    height: height && rhythm(height),
    ...calculateSpacing(margin),
    ...styles
  }
})
