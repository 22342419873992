import { jgLight, jgDark } from '../../../assets'

export default (
  { poweredByTheme, sticky, include, bannerOverlay },
  { colors, background }
) => {
  const jgLogo = poweredByTheme === 'purple' ? jgLight : jgDark

  const backgroundColor =
    poweredByTheme === 'purple' ? colors.justgiving : 'white'

  return {
    root: {
      display: 'block',
      position: 'relative',
      height: '3rem'
    },

    inner: {
      height: '3rem',
      position: sticky === 'yes' ? 'fixed' : 'absolute',
      top: 0,
      right: 0,
      left: 0,
      zIndex: 5,
      ...background(jgLogo, '7rem'),
      backgroundColor,
      backgroundPosition: 'center 1rem'
    }
  }
}
