import React from 'react'
import { isRichTextEmpty } from '../../../../lib/richText'
import SocialShare from '../../../ui/SocialShare'
import Title from '../../../ui/Title'

const Share = ({ title, ...props }) => (
  <div>
    {!isRichTextEmpty(title) && <Title>{title}</Title>}
    <SocialShare {...props} />
  </div>
)

export default Share
