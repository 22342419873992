export default (props, traits) => {
  const { colors, rhythm, scale } = traits

  return {
    root: {
      border: `1px solid ${colors.shade}`
    },

    clear: {
      fontSize: scale(-1),
      textDecoration: 'underline'
    },

    image: {
      width: rhythm(6)
    }
  }
}
