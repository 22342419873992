import React from 'react'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import ContentSection from '../../ui/ContentSection'
import Grid from 'constructicon/grid'
import GridColumn from 'constructicon/grid-column'
import ImageLink from './ImageLink'

const ImageLinks = ({
  alignment,
  background,
  copy,
  copyAlignment,
  divider = 'none',
  foreground,
  gridColumns = 2,
  hoverEffect,
  imageTextForeground,
  imageTextSize,
  imageTextStyle,
  items = [],
  layout,
  size,
  spacing,
  styles,
  subtitle,
  title,
  titleForeground
}) => {
  const calculateColumnSize = (gridColumns, factor) => 12 / Math.ceil(gridColumns * factor)

  return (
    <ContentSection
      alignment={alignment}
      background={background}
      copy={copy}
      copyAlignment={copyAlignment}
      foreground={foreground}
      layout={layout}
      size={size}
      spacing={spacing}
      title={title}
      titleForeground={titleForeground}
      subtitle={subtitle}>

      <Grid
        align='center'
        justify='center'
        spacing={parseFloat(dividerSizes[divider])}
        styles={styles.root}>

        {items.map((item, i) => (
          <GridColumn
            key={i}
            sm={calculateColumnSize(gridColumns, 0.5)}
            md={calculateColumnSize(gridColumns, 1)}>
            <ImageLink
              foreground={imageTextForeground}
              fontSize={imageTextSize}
              fontStyle={imageTextStyle}
              hoverEffect={hoverEffect}
              copy={item.copy}
              image={item.image}
              url={item.url}
            />
          </GridColumn>
        ))}
      </Grid>
    </ContentSection>
  )
}

const dividerSizes = {
  none: 0,
  small: 0.125,
  medium: 0.5,
  thick: 1
}

export default withStyles(styles)(ImageLinks)
