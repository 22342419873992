export default (props, {
  colors,
  radiuses,
  rhythm,
  scale,
  treatments
}) => ({
  root: {
    margin: `${rhythm(1.5)} auto`,
    maxWidth: rhythm(24)
  },
  footer: {
    marginTop: rhythm(0.75),
    textAlign: 'center'
  },
  button: {
    border: 0,
    opacity: 0.85,
    fontSize: scale(-0.5)
  },
  enter: {
    opacity: 0.75,
    transform: 'scale(1.05)',
    transition: 'all 200ms ease'
  },
  enterActive: {
    opacity: 1,
    transform: 'scale(1)',
    transition: 'all 200ms ease'
  },
  exit: {
    opacity: 1,
    transform: 'scale(1)',
    transition: 'all 150ms ease'
  },
  exitActive: {
    opacity: 0.75,
    transform: 'scale(1.05)',
    transition: 'all 150ms ease'
  },
  progressBar: {
    marginBottom: rhythm(1.5)
  },
  progressButton: {
    borderRadius: 0,
    padding: `${rhythm(0.333)} ${rhythm(0.75)}`,
    borderColor: colors.shade,
    borderLeftWidth: 0,
    pointerEvents: 'none',
    '&:first-child': {
      borderLeftWidth: 'thin',
      borderTopLeftRadius: rhythm(radiuses.large),
      borderBottomLeftRadius: rhythm(radiuses.large)
    },
    '&:last-child': {
      borderTopRightRadius: rhythm(radiuses.large),
      borderBottomRightRadius: rhythm(radiuses.large)
    }
  }
})
