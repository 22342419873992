import React from 'react'
import { connect } from 'react-redux'
import { selectConsent } from '../../../store/consent'
import Helmet from 'react-helmet'

const GTM = ({ consent, gtmId }) => {
  if (!consent.allowed) return null

  return (
    <span>
      <Helmet>
        <script>
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${gtmId || 'GTM-PN6K34'}');`}
        </script>
      </Helmet>
      <noscript dangerouslySetInnerHTML={{
        __html: `<iframe src='https://www.googletagmanager.com/ns.html?id=${gtmId || 'GTM-PN6K34'}' height='0' width='0' style='display:"none";visibility:"hidden"' />`
      }} />
    </span>
  )
}

const mapStateToProps = state => ({
  consent: selectConsent(state)
})

export default connect(mapStateToProps)(GTM)
