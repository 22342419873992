import React from 'react'
import { withMobile } from '../../utils/withMobile'

import ButtonGroup from 'constructicon/button-group'
import ButtonShare from 'constructicon/button-share'
import Content from '../../ui/Content'
import Section from '../../ui/Section'

const SocialShare = ({
  alignment,
  apiParams,
  background,
  backgroundImage,
  copy,
  copyAlignment,
  foreground,
  isMobile,
  items = [],
  layout,
  meta,
  size,
  spacing,
  subtitle,
  title,
  titleForeground
}) => (
  <Section
    alignment={alignment}
    backgroundImage={backgroundImage}
    background={background}
    foreground={foreground}
    size={size}
    spacing={spacing}>
    <Content
      copy={copy}
      copyAlignment={copyAlignment}
      layout={layout}
      subtitle={subtitle}
      title={title}
      titleForeground={titleForeground}>
      <ButtonGroup>
        {items
          .filter(item => item.network)
          .filter(item => {
            if (['whatsapp', 'messenger'].indexOf(item.network) > -1) {
              return isMobile
            }

            return true
          })
          .map((item, index) => (
            <ButtonShare
              key={index}
              background={item.background === 'default' ? item.network : item.background}
              foreground={item.foreground === 'default' ? 'light' : item.foreground}
              type={item.network === 'email' ? 'mail' : item.network}
              share
              caption={meta.ogDescription}
              title={meta.ogTitle}
              url={meta.ogUrl && meta.ogUrl.url ? meta.ogUrl.url : undefined}
            />
          ))}
      </ButtonGroup>
    </Content>
  </Section>
)

export default withMobile(SocialShare)
