import React, { Component } from 'react'
import get from 'lodash/get'
import { fetchCampaignData } from '../../../lib/campaigns'

import Content from '../../ui/Content'
import Error from '../../ui/Error'
import Loading from '../../ui/Loading'
import ProgressTiles from '../../ui/ProgressTiles'
import Section from '../../ui/Section'

class CampaignTiles extends Component {
  constructor () {
    super()
    this.state = {
      status: 'fetching',
      data: []
    }
  }

  componentDidMount () {
    this.fetchCampaignDetails()
      .then((data) => this.setState({ status: 'fetched', data }))
      .catch(error => {
        this.setState({ status: 'failed' })
        return Promise.reject(error)
      })
  }

  fetchCampaignDetails () {
    const { items = [] } = this.props

    return Promise.all(
      items.map(campaign => (
        fetchCampaignData(campaign.uid)
          .then(data => this.mergeCampaignContent(campaign, data))
      ))
    )
  }

  mergeCampaignContent (campaign, data) {
    const actions = [{
      label: campaign.donateLabel,
      to: campaign.donateUrl
    }, {
      label: campaign.getStartedLabel,
      to: campaign.getStartedUrl
    }]

    return {
      ...data,
      actions,
      status: 'fetched',
      image: get(campaign, 'image.url') || data.image,
      story: get(campaign, 'story[0].text') ? campaign.story : data.summary,
      name: campaign.title || data.name,
      target: campaign.target ? campaign.target / 100 : data.target,
      hideFundraising: campaign.hideFundraising === 'yes'
    }
  }

  render () {
    const {
      alignment,
      backgroundImage,
      background,
      barColor,
      buttonBackground,
      buttonForeground,
      copy,
      copyAlignment,
      foreground,
      size,
      spacing,
      subtitle,
      title,
      titleForeground
    } = this.props

    const {
      data,
      status
    } = this.state

    return (
      <Section
        alignment={alignment}
        backgroundImage={backgroundImage}
        background={background}
        foreground={foreground}
        size={size}
        spacing={spacing}>

        <Content
          copy={copy}
          copyAlignment={copyAlignment}
          subtitle={subtitle}
          title={title}
          titleForeground={titleForeground}>

          {status === 'fetching' && <Loading />}
          {status === 'failed' && <Error>There was an error loading the data.</Error>}
          {status === 'fetched' && (
            <ProgressTiles
              barColor={barColor}
              buttonBackground={buttonBackground}
              buttonForeground={buttonForeground}
              data={data}
              titleForeground={titleForeground}
            />
          )}

        </Content>
      </Section>
    )
  }
}

export default CampaignTiles
