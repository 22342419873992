import * as validators from 'constructicon/lib/validators'

export default () => {
  return {
    fields: {
      caption: {
        label: 'Message',
        type: 'textarea',
        validators: [
          validators.required('Please enter your message')
        ]
      }
    }
  }
}
