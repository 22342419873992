import { convertMetersToUnit } from 'supporticon/utils/units'

const percentage = (a, b) => Math.floor(a / b * 100)

const isBadgeAchieved = (badge, fundraisingPage, fitnessGoalUom, allBadges, campaign) => {
  switch (badge.requirement) {
    case 'hasSetImage':
      return fundraisingPage.hasUpdatedImage
    case 'hasSelfDonated':
      return fundraisingPage.hasSelfDonated
    case 'hasSetStory':
      return fundraisingPage.hasUpdatedStory
    case 'raised':
      return fundraisingPage.raised >= badge.amount
    case 'raisedPercentage':
      return percentage(fundraisingPage.raised, fundraisingPage.target) >= badge.amount
    case 'distance':
      return fundraisingPage.fitnessDistanceTotal >= badge.amount
    case 'distancePercentage':
      if (isNaN(fundraisingPage.fitnessGoal)) return false
      const totalInUnit = convertMetersToUnit(fundraisingPage.fitnessDistanceTotal, fitnessGoalUom)
      return percentage(totalInUnit, fundraisingPage.fitnessGoal) >= badge.amount
    case 'duration':
      return fundraisingPage.fitnessDurationTotal >= badge.amount
    case 'elevation':
      return fundraisingPage.fitnessElevationTotal >= badge.amount
    case 'campaignRaised':
      return campaign.raised >= badge.amount
    case 'campaignDistance':
      return campaign.distance >= badge.amount
    case 'campaignDonations':
      return campaign.donations >= badge.amount
    case 'campaignDuration':
      return campaign.duration >= badge.amount
    case 'campaignElevation':
      return campaign.elevation >= badge.amount
    case 'hasAllBadges':
      const otherBadges = allBadges.filter(badge => badge.requirement !== 'hasAllBadges')
      const achievedBadges = otherBadges.filter(badge => isBadgeAchieved(badge, fundraisingPage, fitnessGoalUom))
      return otherBadges.length === achievedBadges.length
    default:
      return false
  }
}

export default isBadgeAchieved
