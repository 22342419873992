export const trackEvent = (name, params) => {
  window.heap
    ? window.heap.track(name, params)
    : console.log(`Heap not available to track event: ${name}`, params)
}

export const setUser = (id, params) => {
  window.heap
    ? window.heap.identify(id) || window.heap.addUserProperties(params)
    : console.log(`Heap not available to track user: ${id}`)
}

export const setConfig = (config) => {
  if (window.heap && window.heap.loaded) {
    window.heap.clearEventProperties()
    window.heap.addEventProperties(config)
  } else {
    console.log(`Heap not available to add event properties:`, config)
  }
}
