export default (props, { colors, rhythm, scale, treatments }) => {
  return {
    header: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      marginRight: rhythm(1.75),
      marginBottom: rhythm(0.75)
    },
    nav: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      margin: rhythm([0, -1]),
      padding: rhythm([0, 1]),
      overflow: 'auto',
      ':before': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        borderBottom: `1px solid ${colors.lightGrey}`
      }
    },
    tab: {
      ...treatments.button,
      padding: rhythm([0.5, 0.75]),
      backgroundColor: 'white',
      border: `1px solid #eee`,
      borderBottomColor: 'transparent',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: 'grey',
      flex: 1,
      fontSize: scale(-1),
      '&:hover': {
        color: 'black'
      }
    },
    active: {
      color: 'black',
      position: 'relative',
      backgroundColor: colors.paleGrey,
      borderColor: colors.lightGrey,
      borderBottomColor: colors.paleGrey,
      boxShadow: '0 0 5px rgba(0,0,0,0.1), 0 1px 0 1px white',
      outline: 0
    }
  }
}
