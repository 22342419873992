import React from 'react'
import numbro from 'numbro'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

const Projects = ({
  classNames,
  metric,
  projects = []
}) => (
  <div className={classNames.wrapper}>
    <table className={classNames.table}>
      <thead>
        {metric === 'donations' ? (
          <tr>
            <th />
            <th>Project Name</th>
            <th>Donations</th>
            <th>Goal</th>
            <th>% To Goal</th>
          </tr>
        ) : (
          <tr>
            <th />
            <th>Project Name</th>
            <th>Raised</th>
            <th>Goal</th>
            <th>% To Goal</th>
          </tr>
        )}
      </thead>
      <tbody>
        {projects.map(({
          name,
          raised,
          target,
          targetDonations,
          totalDonations
        }, index) => metric === 'donations' ? (
          <tr key={index} className={classNames.row}>
            <td className={classNames.rank}>{++index}.</td>
            <td data-label='Project Name'>{name}</td>
            <td data-label='Donations'>{numbro(totalDonations).format('0,0')}</td>
            <td data-label='Goal'>{numbro(targetDonations).format('0,0')}</td>
            <td data-label='% To Goal'>{numbro(totalDonations / targetDonations).format('0%')}</td>
          </tr>
        ) : (
          <tr key={index} className={classNames.row}>
            <td className={classNames.rank}>{++index}.</td>
            <td data-label='Project Name'>{name}</td>
            <td data-label='Raised'>{numbro(raised).formatCurrency('0,0')}</td>
            <td data-label='Goal'>{numbro(target).formatCurrency('0,0')}</td>
            <td data-label='% To Goal'>{numbro(raised / target).format('0%')}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
)

export default withStyles(styles)(Projects)
