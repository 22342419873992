import React from 'react'
import { connect } from 'react-redux'
import { selectFundraisingPage } from '../../store/fundraisingPage'

import FlatModal from '../../components/ui/FlatModal'
import Loading from '../../components/ui/Loading'
import Section from 'constructicon/section'

class CustomSupporterPageAdmin extends React.Component {
  componentDidMount () {
    window.location.href = this.props.fundraisingPage.adminUrl
  }

  render () {
    return (
      <FlatModal
        closeIcon={false}
        contentLabel='Redirecting'
        isOpen>
        <Section
          spacing={{ y: 3, x: 1 }}
          styles={{ textAlign: 'center' }}>
          <Loading />
        </Section>
      </FlatModal>
    )
  }
}

const mapState = (state, props) => ({
  fundraisingPage: selectFundraisingPage(state, props)
})

export default connect(mapState)(CustomSupporterPageAdmin)
