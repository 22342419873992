import React, { Component } from 'react'
import ReactDOM from 'react-dom'

class TwitterTimeline extends Component {
  constructor (props) {
    super(props)
    this.state = ({ initialized: false })
  }

  componentDidMount () {
    if (this.state.initialized) return

    const twittertimeline = ReactDOM.findDOMNode(this.refs.twittertimeline)
    const twitterscript = document.createElement('script')
    twitterscript.src = '//platform.twitter.com/widgets.js'
    twitterscript.async = true
    twitterscript.id = 'twitter-wjs'
    twittertimeline.parentNode.appendChild(twitterscript)

    this.setState({ initialized: true })
  }

  render () {
    const {
      user,
      chrome
    } = this.props

    return (
      <a
        ref='twittertimeline'
        className='twitter-timeline'
        aria-label='Twitter feed'
        href={`https://twitter.com/${user}`}
        data-chrome={chrome}
        data-height='500'
      />
    )
  }
}

export default TwitterTimeline
