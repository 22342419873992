import React from 'react'
import groupBy from 'lodash/groupBy'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import Content from '../../ui/Content'
import Grid from 'constructicon/grid'
import GridColumn from 'constructicon/grid-column'
import Resource from './Resource'
import Section from '../../ui/Section'
import Title from '../../ui/Title'

const Resources = ({
  alignment,
  backgroundImage,
  background,
  buttonBackground,
  buttonForeground,
  classNames,
  copy,
  copyAlignment,
  foreground,
  includeGradient,
  items = [],
  layout,
  size,
  spacing,
  subtitle,
  title,
  titleForeground
}) => {
  const categorisedResources = groupBy(items, 'category')
  const categories = Object.keys(categorisedResources)

  return (
    <Section
      alignment={alignment}
      backgroundImage={backgroundImage}
      background={background}
      foreground={foreground}
      size={size}
      spacing={spacing}>

      <Content
        copy={copy}
        copyAlignment={copyAlignment}
        layout={layout}
        subtitle={subtitle}
        title={title}
        titleForeground={titleForeground}>

        {categories.map((category, index) => {
          const resources = categorisedResources[category]

          return (
            <div key={index} className={classNames.category}>
              {category !== 'null' && (
                <Title color={titleForeground}>{category}</Title>
              )}
              <Grid spacing={1} justify='flex-start'>
                {resources.map((resource, index) => (
                  <GridColumn key={index} sm={6} md={4} lg={3}>
                    <Resource
                      button={resource.button}
                      buttonBackground={buttonBackground}
                      buttonForeground={buttonForeground}
                      image={resource.image}
                      includeGradient={includeGradient}
                      resource={resource.resource}
                      title={resource.title}
                    />
                  </GridColumn>
                ))}
              </Grid>
            </div>
          )
        })}

      </Content>
    </Section>
  )
}

export default withStyles(styles)(Resources)
