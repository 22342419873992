import React from 'react'
import Content from '../../ui/Content'
import CuratorFeed from './CuratorFeed'
import Section from '../../ui/Section'

const SocialFeedCurator = ({
  alignment,
  apiParams,
  backgroundImage,
  background,
  curatorContainerId,
  curatorID,
  foreground,
  size,
  spacing,
  subtitle,
  title,
  titleForeground
}) => (
  <Section
    alignment={alignment}
    backgroundImage={backgroundImage}
    background={background}
    foreground={foreground}
    size={size}
    spacing={spacing}>

    <Content
      subtitle={subtitle}
      title={title}
      titleForeground={titleForeground}>

      <CuratorFeed
        containerId={curatorContainerId}
        id={curatorID}
      />

    </Content>
  </Section>
)

export default SocialFeedCurator
