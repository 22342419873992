import React from 'react'
import RichText from '../../../ui/RichText'

const Content = ({
  copy
}) => (
  <RichText>{copy}</RichText>
)

export default Content
