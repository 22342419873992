import React from 'react'
import { connect } from 'react-redux'
import { selectHome, selectMeta } from '../../store/site'

import Head from '../../components/utils/Head'
import SliceProcessor from '../../components/utils/SliceProcessor'
import Theme from '../../components/utils/Theme'
import UserMenu from '../../components/container/UserMenu'

const Home = ({
  home,
  meta
}) => (
  <Theme>
    <div>
      <Head />
      <SliceProcessor
        meta={meta}
        page='home'
        slices={home}
      />
      <UserMenu />
    </div>
  </Theme>
)

const mapState = (store) => ({
  home: selectHome(store),
  meta: selectMeta(store)
})

export default connect(mapState)(Home)
