import React from 'react'
import withStyles from 'constructicon/with-styles'
import styles from './styles'
import Loading from '../../ui/Loading'

const Status = ({
  children,
  classNames,
  status
}) => (
  status === 'fetched'
    ? children
    : (
      <div className={classNames.root}>
        <p className={classNames.label}>Loading</p>
        <Loading />
      </div>
    )
)

export default withStyles(styles)(Status)
