import React from 'react'
import Content from '../Content'
import Section from '../Section'

const ContentSection = ({
  alignment,
  background,
  backgroundImage,
  buttons = [],
  children,
  copy,
  copyAlignment,
  foreground,
  layout,
  size,
  spacing,
  styles,
  subtitle,
  title,
  titleForeground
}) => (
  <Section
    backgroundImage={backgroundImage}
    background={background}
    foreground={foreground}
    alignment={alignment}
    size={size}
    spacing={spacing}>

    <Content
      buttons={buttons}
      children={children}
      copy={copy}
      copyAlignment={copyAlignment}
      layout={layout}
      subtitle={subtitle}
      title={title}
      titleForeground={titleForeground}
    />
  </Section>
)

export default ContentSection
