import React, { Component } from 'react'
import debounce from 'lodash/debounce'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { selectPage, selectUser, clearSession } from '../../../store/session'
import onClickOutside from 'react-onclickoutside'
import withToggle from 'constructicon/with-toggle'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import Button from 'constructicon/button'
import ButtonGroup from 'constructicon/button-group'
import LinkHandler from '../../utils/LinkHandler'

class UserMenu extends Component {
  constructor () {
    super()
    this.state = { isDesktop: false }
    this.handleResize = debounce(this.handleResize.bind(this), 100)
  }

  componentDidMount () {
    this.handleResize()
    window.addEventListener('scroll', this.handleResize)

    if (this.props.showOnOpen && document.body.clientWidth >= 1024) {
      this.props.onToggleOn()
    }
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this.handleResize)
  }

  handleResize () {
    this.setState({
      isDesktop: document.body.clientWidth >= 1024
    })
  }

  handleClickOutside () {
    if (!this.props.showOnOpen) {
      this.props.onToggleOff()
    } else if (!this.state.isDesktop) {
      this.props.onToggleOff()
    }
  }

  render () {
    const {
      children,
      classNames,
      clearSession,
      hidePageLink,
      onToggle,
      page = {},
      styles,
      user = {}
    } = this.props

    if (!user.token) {
      return null
    }

    return (
      <div id='user-menu' className={classNames.root}>
        <div className={classNames.user} onClick={onToggle}>
          <img className={classNames.avatar} src={page.image || user.image} />
        </div>
        <div>
          <ButtonGroup spacing={0} styles={styles.buttons}>
            {children}
            {!hidePageLink && page.url && (
              <Button
                background='transparent'
                foreground='dark'
                tag={LinkHandler}
                to={page.url}
                radius='none'
                spacing={{ x: 0.666, y: 0.5 }}
              >
                View page
              </Button>
            )}
            <Button
              background='transparent'
              foreground='dark'
              onClick={() => clearSession()}
              radius='none'
              spacing={{ x: 0.666, y: 0.5 }}
              styles={styles.button}
            >
              Log out
            </Button>
          </ButtonGroup>
        </div>
      </div>
    )
  }
}

const mapState = (state, props) => ({
  page: selectPage(state),
  user: selectUser(state, props)
})

export default compose(
  withToggle,
  withStyles(styles),
  connect(mapState, { clearSession }),
  onClickOutside
)(UserMenu)
