import React from 'react'
import { getEDHClientId } from '../../../../lib/environment'
import { getCurrentUrl } from '../../../../lib/window'
import ButtonGroup from 'constructicon/button-group'
import Heading from 'constructicon/heading'
import LoginForm from 'supporticon/components/login-form'
import ProviderOauthButton from 'supporticon/components/provider-oauth-button'

const providers = [
  'Facebook',
  'Fitbit',
  'MapMyFitness',
  'Strava'
]

const Everydayhero = ({ buttonProps, onSuccess }) => (
  <div>
    <Heading
      size={1}
      spacing={{ b: 1 }}>
      Login with a provider
    </Heading>
    <ButtonGroup>
      {providers.map(provider => (
        <ProviderOauthButton
          block
          clientId={getEDHClientId()}
          label={`Login with ${provider}`}
          key={provider}
          homeUrl={getCurrentUrl()}
          onSuccess={result => onSuccess({ token: result.access_token })}
          provider={provider.toLowerCase()}
          redirectUri={process.env.EDH_REDIRECT_URI}
          {...buttonProps}
        />
      ))}
    </ButtonGroup>
    <Heading
      size={1}
      spacing={{ t: 2, b: 1 }}>
      Login with your email
    </Heading>
    <LoginForm
      clientId={getEDHClientId()}
      formComponent={{ submitProps: buttonProps }}
      onSuccess={onSuccess}
    />
  </div>
)

export default Everydayhero
