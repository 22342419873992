import find from 'lodash/find'
import kebabCase from 'lodash/kebabCase'
import { currencyCode } from 'supporticon/utils/currencies'
import { fetchTotals, deserializeTotals } from 'supporticon/utils/totals'
import { isJustGiving } from '../../lib/environment'
import { generateAvatar } from '../../lib/avatar'
import { selectBasicAPIParams, selectLocalisation, selectTagDefinitions } from '../site'
import { selectCustomTagPages } from '../customTagPages'

// Constants
const c = {
  FETCH: 'app/tagPages/FETCH',
  FETCH_SUCCESS: 'app/tagPages/FETCH_SUCCESS',
  FETCH_FAILURE: 'app/tagPages/FETCH_FAILURE'
}

// Selectors
export * from './selectors'

// Action
export const fetchTagPage = (tagId, slug) => (dispatch, getState) => {
  const state = getState()
  const { campaign } = selectBasicAPIParams(state)
  const { country } = selectLocalisation(state)
  const customTagPages = selectCustomTagPages(state)
  const tagDefinitions = selectTagDefinitions(state)
  const tagDefinition = find(tagDefinitions, { id: tagId })
  const tagValue = find(tagDefinition.values, val => kebabCase(val) === slug)
  const customTagPage = find(customTagPages, ({ slug }) => kebabCase(slug) === tagId)
  const currency = currencyCode(country)

  if (!isJustGiving() || !tagValue) {
    dispatch({ type: c.FETCH_FAILURE })
    return
  }

  return Promise.resolve()
    .then(() => dispatch({ type: c.FETCH }))
    .then(() => fetchTotals({ segment: `page:campaign:${campaign}`, tagId, tagValue }))
    .then(totals => deserializeTotals(totals, currency))
    .then(data => ({
      ...data,
      label: tagDefinition.name,
      name: tagValue,
      image: customTagPage ? generateAvatar(slug, customTagPage.avatarOption, customTagPage.staticAvatarOption) : null,
      slug,
      tagId
    }))
    .then(data => dispatch({ type: c.FETCH_SUCCESS, payload: data }))
    .catch(error => {
      dispatch({ type: c.FETCH_FAILURE })
      return Promise.reject(error)
    })
}

export default (state = {}, { type, payload }) => {
  switch (type) {
    case c.CLEAR:
      return {}
    case c.FETCH:
      return { status: 'fetching' }
    case c.FETCH_FAILURE:
      return { status: 'failed' }
    case c.FETCH_SUCCESS:
      return {
        data: payload,
        status: 'fetched'
      }
    default:
      return state
  }
}
