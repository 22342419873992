import React from 'react'
import numbro from 'numbro'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import Button from '../Button'
import ButtonGroup from 'constructicon/button-group'
import RichText from '../RichText'
import Section from 'constructicon/section'
import Title from '../Title'

const ProgressTile = ({
  actions = [],
  buttonBackground,
  buttonForeground,
  classNames,
  hideFundraising,
  image,
  metric,
  name,
  raised,
  status,
  story,
  styles,
  target,
  targetDonations,
  totalDonations
}) => (
  <div className={classNames.root}>
    {image && <div className={classNames.image} />}
    <Section spacing={1} styles={styles.content}>
      <Title size={1} styles={styles.name}>{name}</Title>
      <RichText styles={styles.story}>{story}</RichText>
      <div className={classNames.footer}>
        {status && !hideFundraising && (
          target ? (
            metric === 'donations' ? (
              <div>
                <dl className={classNames.metrics}>
                  <li>
                    <dt>Donations</dt>
                    <dd>{numbro(totalDonations).format('0,0')}</dd>
                  </li>
                  <li>
                    <dt>Target</dt>
                    <dd>{numbro(targetDonations).format('0,0')}</dd>
                  </li>
                </dl>
                <div className={classNames.progress}>
                  <span className={classNames.bar} style={{ width: `${totalDonations / targetDonations * 100}%` }} />
                  <span className={classNames.percentage}>{numbro(totalDonations / targetDonations).format('0%')}</span>
                </div>
              </div>
            ) : (
              <div>
                <dl className={classNames.metrics}>
                  <li>
                    <dt>Raised</dt>
                    <dd>{numbro(raised).formatCurrency('0,0')}</dd>
                  </li>
                  <li>
                    <dt>Target</dt>
                    <dd>{numbro(target).formatCurrency('0,0')}</dd>
                  </li>
                </dl>
                <div className={classNames.progress}>
                  <span className={classNames.bar} style={{ width: `${raised / target * 100}%` }} />
                  <span className={classNames.percentage}>{`${numbro(raised / target).format('0%')} funded`}</span>
                </div>
              </div>
            )
          ) : (
            metric === 'donations' ? (
              <div>
                <dl className={classNames.metrics}>
                  <li>
                    <dt>Donations</dt>
                    <dd>{numbro(totalDonations).format('0,0')}</dd>
                  </li>
                </dl>
              </div>
            ) : (
              <div>
                <dl className={classNames.metrics}>
                  <li>
                    <dt>Raised</dt>
                    <dd>{numbro(raised).formatCurrency('0,0')}</dd>
                  </li>
                </dl>
              </div>
            )
          )
        )}

        <ButtonGroup>
          {actions.filter((action) => action.label).map((action, index) => (
            <Button
              background={buttonBackground}
              foreground={buttonForeground}
              key={index}
              styles={styles.button}
              to={action.to}
              block>
              {action.label}
            </Button>
          ))}
        </ButtonGroup>
      </div>
    </Section>
  </div>
)

export default withStyles(styles)(ProgressTile)
