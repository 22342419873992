import React from 'react'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import Content from '../../ui/Content'
import LinkHandler from '../../utils/LinkHandler'
import Section from '../../ui/Section'
import RichText from '../../ui/RichText'

const Links = ({
  alignment,
  backgroundImage,
  background,
  buttonBackground,
  buttonForeground,
  classNames,
  copy,
  copyAlignment,
  foreground,
  includeGradient,
  items = [],
  layout,
  linksTitle,
  size,
  spacing,
  styles,
  subtitle,
  title,
  titleForeground
}) => (
  <Section
    alignment={alignment}
    backgroundImage={backgroundImage}
    background={background}
    foreground={foreground}
    size={size}
    spacing={spacing}>

    <Content
      align='flex-start'
      copy={copy}
      copyAlignment={copyAlignment}
      gridSize={{ children: 4, content: 8 }}
      layout={layout}
      subtitle={subtitle}
      title={title}
      titleForeground={titleForeground}>

      {linksTitle && <RichText styles={styles.title}>{linksTitle}</RichText>}

      <nav>
        <ul className={classNames.root}>
          {items.map((link, index) => (
            <li key={index}>
              <LinkHandler to={link.url}>
                {link.label}
              </LinkHandler>
            </li>
          ))}
        </ul>
      </nav>

    </Content>
  </Section>
)

export default withStyles(styles)(Links)
