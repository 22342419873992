import React from 'react'
import routes from './routes'
import { createClient } from 'boiler-room-runner'
import ReactDOM from 'react-dom'
import { setAppElement } from 'react-modal'
import { configureClientStore, fetchSiteContent } from './store'
import { updateEnvironment } from './lib/environment'
import { parseJSON } from './lib/json'
import createLocals from './lib/createLocals'
import sites from './lib/siteDomains'
import 'constructicon/lib/css/reset.css'

if (process.env.NODE_ENV !== 'production') {
  require('react-a11y').default(React, ReactDOM)
}

setAppElement('#mount')

const basepath = process.env.BASE_PATH
const initialState = parseJSON(document.getElementById('initial-state').innerHTML)
const store = configureClientStore(initialState)

const previewSettings = store.getState().prismic || {}
const uid = previewSettings.previewUID || process.env.DOCUMENT_UID || window.location.host
const repo = previewSettings.repo || process.env.PRISMIC_REPO || sites[uid]
const token = previewSettings.previewToken || null

store.dispatch(fetchSiteContent(repo, uid, token))
  .then(() => {
    const state = store.getState()
    const App = createClient({ basepath, routes, store, createLocals })

    if (state.site) {
      updateEnvironment(state.site.data.environment)
    }

    ReactDOM.render(<App />, document.getElementById('mount'))
  })
