import * as validators from 'constructicon/lib/validators'

export default ({ team }) => {
  return {
    fields: {
      name: {
        label: 'Team Name',
        type: 'text',
        required: true,
        initial: team.name,
        validators: [validators.required('Please enter a page title')]
      },
      target: {
        label: 'Fundraising Target',
        type: 'number',
        required: true,
        initial: team.target ? parseInt(team.target) : undefined,
        validators: [
          validators.required('Please enter an amount'),
          validators.number('Must be a number'),
          val => parseInt(val) <= 0 && 'Please enter an amount greater than 0',
          val => parseInt(val) > 10000000 && 'Please enter an amount less than 10,000,000'
        ]
      },
      story: {
        label: 'Story',
        type: 'textarea',
        initial: team.story
      },
      image: {
        label: 'Team Photo',
        initial: team.image
      }
    }
  }
}
