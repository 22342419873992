import React from 'react'
import queryString from 'querystringify'
import Url from 'url-parse'
import { compose } from 'redux'
import { withRouter } from 'react-router'
import { prismicRichTextAsHTML } from 'prismic-utils'
import { withInjectParams } from '../../utils/InjectParams'
import renderEmbedHtml from '../../../lib/embedHtml'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import BaseRichText from 'constructicon/rich-text'

const RichText = ({
  classNames,
  children,
  injectParams,
  router,
  styles,
  ...props
}) => {
  const html = typeof children === 'object'
    ? prismicRichTextAsHTML(children.map(serializeElement), linkResolver)
    : children

  const handleModalOpen = (e) => {
    if (e.target.tagName === 'A' && e.target.href.indexOf('?modal=') > -1) {
      e.preventDefault()

      const { pathname, hash, query } = new Url(e.target.href)

      router.push(`${pathname}?modal=${queryString.parse(query).modal}${hash}`)
    }
  }

  return (
    <BaseRichText
      {...props}
      styles={styles.root}
      onClick={handleModalOpen}
    >
      {injectParams(html)}
    </BaseRichText>
  )
}

const serializeElement = item => {
  if (item.type === 'embed' && item.oembed) {
    return {
      ...item,
      oembed: {
        ...item.oembed,
        html: renderEmbedHtml(item.oembed)
      }
    }
  }

  return item
}

const linkResolver = link => {
  switch (link.type) {
    case 'custom_site':
      return `https://${link.uid}`
    case 'internal_page':
      return `/${link.slug}`
    default:
      return '#'
  }
}

export default compose(
  withInjectParams,
  withRouter,
  withStyles(styles)
)(RichText)
