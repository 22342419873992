import { Predicates } from 'prismic-javascript'
import { createDocumentAction, createDocumentReducer } from 'prismic-utils'
import { updateEnvironment } from '../../lib/environment'

// Constants
const NAMESPACE = 'app/site'

// Selectors
export * from './selectors'

// Action
const handleFetchSuccess = (constants, doc) => {
  updateEnvironment(doc.data.environment)

  return {
    type: constants.FETCH_SUCCESS,
    payload: { data: doc }
  }
}

export const fetchSite = (repo, uid, { previewToken, previewUID } = {}) => (
  createDocumentAction(NAMESPACE, {
    handleFetchSuccess,
    repository: repo,
    token: previewToken,
    predicates: [ Predicates.at('my.custom_site.uid', previewUID || uid) ]
  })
)

// Reducer
export default createDocumentReducer(NAMESPACE)
