import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { provideHooks } from 'redial'
import { selectCustomTagPage } from '../../store/customTagPages'
import { clearPage } from '../../store/fundraisingPage'
import { fetchTagPage, selectTagPage, selectTagPageStatus } from '../../store/tagPage'

import NotFound from '../../components/container/NotFound'
import Status from '../../components/container/Status'
import TagPage from '../../components/container/TagPage'
import Theme from '../../components/utils/Theme'

const CustomTagPage = ({
  children,
  customTagPage,
  location,
  router,
  status = 'fetching'
}) => (
  <Theme>
    {status === 'failed' ? <NotFound /> : (
      <Status status={status}>
        <div>
          {customTagPage
            ? <TagPage key={[router.params.tag, router.params.slug].join('/')} />
            : <NotFound />}
          {children}
        </div>
      </Status>
    )}
  </Theme>
)

const mapState = (state, props) => ({
  customTagPage: selectCustomTagPage(state, props),
  status: selectTagPageStatus(state, props)
})

const hooks = {
  fetch: ({ dispatch, params, state }) => {
    const tagPage = selectTagPage(state)
    const status = selectTagPageStatus(state)

    if (status === 'fetched' && tagPage.slug === params.slug) {
      return Promise.resolve(tagPage)
    } else {
      return Promise.all([
        dispatch(clearPage()),
        dispatch(fetchTagPage(params.tag, params.slug))
      ])
    }
  }
}

export default compose(
  connect(mapState),
  provideHooks(hooks)
)(CustomTagPage)
