import React from 'react'
import { withRouter } from 'react-router'

import Content from '../../ui/Content'
import FlatModal from '../../ui/FlatModal'
import IFrame from '../../ui/IFrame'
import Section from '../../ui/Section'

export const ModalSlice = ({
  alignment,
  backgroundImage,
  background,
  copy,
  copyAlignment,
  foreground,
  id,
  iframeUrl,
  items = [],
  router,
  size,
  onRequestClose,
  spacing,
  subtitle,
  title,
  titleForeground
}) => (
  <FlatModal
    onRequestClose={() => {
      if (typeof onRequestClose === 'function') return onRequestClose()
      router.push(window.location.pathname + window.location.hash)
    }}
    contentLabel='Modal'
    isOpen={router.location.query.modal === id}>

    <Section
      alignment={alignment}
      backgroundImage={backgroundImage}
      background={background}
      foreground={foreground}
      size={size}
      spacing={spacing}>

      <Content
        buttons={items}
        copy={copy}
        copyAlignment={copyAlignment}
        subtitle={subtitle}
        title={title}
        titleForeground={titleForeground}
      />

      {iframeUrl && <IFrame url={iframeUrl} />}
    </Section>
  </FlatModal>
)

export default withRouter(ModalSlice)
