import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { selectAPIParams, selectLocalisation } from '../../../store/site'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import Content from '../../ui/Content'
import Progress from 'supporticon/components/progress-bar'
import FitnessProgress from 'supporticon/components/fitness-progress-bar'
import Section from '../../ui/Section'

const ProgressBar = ({
  alignment,
  apiParams,
  background,
  backgroundImage,
  barBackground = 'shade',
  barForeground = 'primary',
  barRadius = 'small',
  campaign,
  charity,
  copy,
  country,
  uom,
  event,
  eventDate,
  groupValue,
  type,
  foreground,
  fundedLabel,
  offset,
  raisedLabel,
  remainingLabel,
  size,
  spacing,
  styles,
  subtitle,
  target,
  targetLabel,
  title,
  titleForeground
}) => (
  <Section
    alignment={alignment}
    backgroundImage={backgroundImage}
    background={background}
    foreground={foreground}
    size={size}
    spacing={spacing}
  >
    <Content
      copy={copy}
      subtitle={subtitle}
      title={title}
      titleForeground={titleForeground}
    >
      <div>
        {type === 'Distance Travelled' ? (
          <FitnessProgress
            unit={uom}
            campaign={campaign || apiParams.campaignIds}
            charity={charity || apiParams.charityIds}
            endDate={apiParams.endDate}
            eventDate={eventDate}
            travelledLabel={fundedLabel}
            distanceLabel={raisedLabel}
            refreshInterval={apiParams.refreshInterval}
            remainingLabel={remainingLabel}
            startDate={apiParams.startDate}
            target={target}
            targetLabel={targetLabel}
            metric={{ styles: styles.metric }}
            offset={offset}
            progressBar={{
              background: barBackground,
              fill: barForeground,
              radius: barRadius,
              styles: styles.progress
            }}
          />
        ) : (
          <Progress
            campaign={campaign || apiParams.campaignIds}
            charity={charity || apiParams.charityIds}
            country={country}
            endDate={apiParams.endDate}
            event={event ? event.split(',') : apiParams.eventIds}
            eventDate={eventDate}
            excludeOffline={apiParams.excludeOffline}
            fundedLabel={fundedLabel}
            group={groupValue}
            raisedLabel={raisedLabel}
            refreshInterval={apiParams.refreshInterval}
            remainingLabel={remainingLabel}
            startDate={apiParams.startDate}
            target={target}
            targetLabel={targetLabel}
            useDonationCount={type === 'Donations Received'}
            metric={{ styles: styles.metric }}
            offset={offset}
            progressBar={{
              background: barBackground,
              fill: barForeground,
              radius: barRadius,
              styles: styles.progress
            }}
          />
        )}
      </div>
    </Content>
  </Section>
)

const mapState = store => ({
  ...selectAPIParams(store),
  ...selectLocalisation(store)
})

export default compose(connect(mapState), withStyles(styles))(ProgressBar)
