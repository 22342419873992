export default (props, {
  rhythm,
  scale,
  treatments
}) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexPack: 'center',
    padding: rhythm(3)
  },

  error: {
    opacity: 0.25
  },

  flippy: {
    front: {
      padding: '0 !important'
    }
  },

  front: {
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundPosition: '50% 50%'
  },

  detail: {
    width: '100%'
  },

  name: {
    marginBottom: rhythm(0.25),
    fontSize: scale(-1),
    width: '100%',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },

  amount: {
    ...treatments.button,
    fontSize: scale(2)
  }
})
