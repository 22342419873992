import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { selectCountry, selectCookieConsentParams } from '../../../store/site'
import { isJustGiving } from '../../../lib/environment'
import { Link } from 'react-router'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import { edhLight, edhDark, jgLight, jgDark, bbp2pLight, bbp2pDark } from '../../../assets'

const platformConfig = (platform, theme) => {
  switch (platform) {
    case 'justgiving':
      return {
        image: {
          alt: 'JustGiving',
          src: theme === 'light' ? jgLight : jgDark
        },
        link: {
          title: 'Visit JustGiving',
          href: 'https://www.justgiving.com'
        }
      }
    case 'bbp2p':
      return {
        image: {
          alt: 'Blackbaud Peer-to-Peer Fundraising, powered by JustGiving',
          src: theme === 'light' ? bbp2pLight : bbp2pDark
        },
        link: {
          title: 'Visit Blackbaud Peer-to-Peer Fundraising, powered by JustGiving',
          href: 'https://www.justgiving.com'
        }
      }
    default:
      return {
        image: {
          alt: 'Everydayhero',
          src: theme === 'light' ? edhLight : edhDark
        },
        link: {
          title: 'Visit Everydayhero',
          href: 'https://everydayhero.com'
        }
      }
  }
}

const PoweredBy = ({
  classNames,
  cookies = {},
  country,
  environment = 'default',
  includeCookies = true,
  theme
}) => {
  const defaultEnvironment = isJustGiving() ? country === 'us' ? 'bbp2p' : 'justgiving' : 'everydayhero'
  const platform = environment === 'default' ? defaultEnvironment : environment
  const { image, link } = platformConfig(platform, theme)

  return (
    <div className={classNames.root}>
      {platform !== 'bbp2p' && <div className={classNames.label}>Powered by</div>}
      <a {...link}>
        <img className={classNames.logo} {...image} />
      </a>
      {cookies.display === 'Yes' && includeCookies && (
        <div className={classNames.cookies}>
          <Link to='/?cookies=policy'>
            Cookie Policy
          </Link>
          <Link to='/?cookies=preferences'>
            My Preferences
          </Link>
        </div>
      )}
    </div>
  )
}

const mapState = (state) => ({
  cookies: selectCookieConsentParams(state),
  country: selectCountry(state)
})

export default compose(
  connect(mapState),
  withStyles(styles)
)(PoweredBy)
