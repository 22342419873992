export default (props, traits) => {
  return {
    root: {
      textAlign: 'center',
      '& strong': {
        fontWeight: 700
      }
    }
  }
}
