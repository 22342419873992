/*
  DataRequirements

  Checks if the site specified any extra data requirements, and if so it will handle
  firing off the necessary Redux actions.
*/

import { Component } from 'react'
import { connect } from 'react-redux'
import { selectDataRequirements, selectPrimaryCampaign, selectStatus } from '../../../store/site'
import { fetchProjects } from '../../../store/projects'

class DataRequirements extends Component {
  componentDidMount () {
    if (this.props.status === 'fetched') {
      this.fetchData(this.props)
    }
  }

  componentWillReceiveProps (nextProps) {
    const { status } = nextProps

    if (status !== this.props.status && status === 'fetched') {
      this.fetchData(nextProps)
    }
  }

  fetchData (props) {
    const {
      campaign,
      fetchProjects,
      projects
    } = props

    projects && fetchProjects(projects, campaign)
  }

  render () {
    return null
  }
}

const mapState = (state) => ({
  status: selectStatus(state),
  ...selectPrimaryCampaign(state),
  ...selectDataRequirements(state)
})

const mapActions = { fetchProjects }

export default connect(mapState, mapActions)(DataRequirements)
