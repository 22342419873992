import React from 'react'
import get from 'lodash/get'
import { isJustGiving } from 'supporticon/utils/client'
import { fetchPage } from 'supporticon/api/pages'
import { fetchCurrentUser } from 'supporticon/api/me'

import ButtonGroup from 'constructicon/button-group'
import FitnessButton from './FitnessButton'
import Loading from '../../../ui/Loading'
import Section from 'constructicon/section'
import SuccessMessage from '../SuccessMessage'

class EditFitnessForm extends React.Component {
  constructor () {
    super()
    this.state = {
      connectedApps: [],
      status: 'fetching',
      success: false
    }
  }

  componentDidMount () {
    this.fetchConnectedFitnessApps()
  }

  fetchConnectedFitnessApps () {
    Promise.resolve()
      .then(() => this.setState({ status: 'fetching' }))
      .then(() => isJustGiving() ? this.jgConnectedApps() : this.edhConnectedApps())
      .then(connectedApps => this.setState({ status: 'fetched', connectedApps }))
  }

  jgConnectedApps () {
    const { slug } = this.props.fundraisingPage

    return fetchPage(slug, null, { includeFitness: true })
      .then(page => {
        const subscriptions = get(page, 'fitness.pageFitnessSettings.subscribedActivities', [])
        return subscriptions.length > 0 ? ['strava'] : []
      })
  }

  edhConnectedApps () {
    const { token } = this.props.user

    return fetchCurrentUser({ token })
      .then(user => user.connected_apps)
  }

  render () {
    const { fitnessOptions, fundraisingPage, user } = this.props
    const { connectedApps, status, success } = this.state

    const providers = ['strava', 'mapmyfitness', 'fitbit'].filter(provider => fitnessOptions[provider])

    if (status === 'fetching') {
      return (
        <Section>
          <Loading />
        </Section>
      )
    }

    return (
      <ButtonGroup>
        {providers.map(provider => (
          <FitnessButton
            key={provider}
            fitnessOptions={fitnessOptions}
            isConnected={connectedApps.indexOf(provider) !== -1}
            onChange={() => {
              this.fetchConnectedFitnessApps()
              this.setState({ success: true })
            }}
            page={fundraisingPage}
            provider={provider}
            user={user}
          />
        ))}
        <SuccessMessage visible={success}>
          Success! Please allow a couple of minutes for your fitness apps to sync
        </SuccessMessage>
      </ButtonGroup>
    )
  }
}

export default EditFitnessForm
