import React, { Component } from 'react'

class CuratorFeed extends Component {
  componentDidMount () {
    const { containerId, id } = this.props
    window.curatorConfig = { container: `#${containerId || 'curator-feed'}` }

    if (id) {
      setTimeout(function () {
        const script = document.createElement('script')
        script.src = `https://cdn.curator.io/published/${id.toLowerCase()}.js`
        document.body.appendChild(script)
      }, 0)
    }
  }

  render () {
    const { containerId } = this.props

    return <div id={containerId || 'curator-feed'} />
  }
}

export default CuratorFeed
