export default (
  { activityType, image, message, toggled },
  { mediaQuery, rhythm, colors, radiuses, scale, transitions },
  keyframes
) => ({
  root: {
    position: 'relative',
    display: 'flex',
    fontSize: scale(-1),
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    padding: rhythm(1),
    background: colors.light,
    color: colors.dark,
    border: `thin solid ${colors.shade}`,
    borderRadius: rhythm(radiuses.medium),
    marginBottom: rhythm(0.5),
    textAlign: 'left'
  },
  icon: {
    position: 'relative',
    width: rhythm(1.25),
    height: rhythm(1.25),
    flex: `0 0 ${rhythm(1.25)}`,
    backgroundColor: colors.shade,
    borderRadius: '50%',
    color: colors.light,
    marginRight: rhythm(0.75),
    svg: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)'
    },
    [mediaQuery('sm', 'max-width')]: {
      display: 'none'
    }
  },
  content: {
    flex: 1,
    marginTop: '0.333rem'
  },
  source: {
    float: 'right',
    marginLeft: '0.5rem'
  },
  details: {
    fontSize: scale(-0.5),
    fontWeight: 'normal'
  },
  heading: {
    display: 'block',
    paddingRight: rhythm(4)
  },
  description: {
    position: 'relative',
    marginTop: rhythm(0.333),
    overflow: 'hidden',
    maxHeight: toggled ? '100vh' : '4.5em',
    transition: transitions.easeOut,
    cursor: (message && message.length > 75 && !toggled) ? 'pointer' : 'default',
    lineHeight: '1.5em',
    '&:before': {
      content: '""',
      opacity: toggled ? 0 : 1,
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      pointerEvents: 'none',
      background: `linear-gradient(rgba(255,255,255,0) 1.5em, ${colors.light})`
    }
  },
  image: {
    display: 'block',
    width: '100%',
    height: 'auto',
    backgroundImage: `url(${image})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    paddingBottom: '75%',
    marginTop: rhythm(0.333),
    cursor: 'pointer',
    img: {
      display: 'none'
    }
  },
  fitnessWidget: {
    display: 'block',
    marginTop: rhythm(0.333),
    img: {
      display: 'block',
      width: '100%'
    }
  },
  modalImage: {
    display: 'block',
    width: '100%'
  },
  video: {
    marginTop: rhythm(0.333),
    position: 'relative',
    background: 'black',
    color: 'transparent',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: '50%',
      left: '50%',
      width: '60px',
      height: '60px',
      margin: '-30px',
      border: '2px solid transparent',
      borderRadius: '50%',
      borderLeftColor: 'rgba(255,255,255,0.5)',
      animation: `${keyframes.spin} 1s linear infinite`
    },
    '& > div': {
      position: 'relative',
      animation: `${keyframes.fadeIn} 1s ease forwards`,
      opacity: 0,
      animationDelay: '1s'
    }
  },
  date: {
    color: colors.primary,
    marginTop: '0.375rem',
    marginLeft: rhythm(0.75),
    fontSize: scale(-2),
    textAlign: 'right',
    flex: '1 1 100%',
    [mediaQuery('sm')]: {
      flex: '0 0 auto',
      ...(activityType === 'post' && {
        position: 'absolute',
        top: 0,
        right: 0,
        padding: rhythm(1)
      })
    }
  },
  actions: {
    marginTop: rhythm(0.5)
  },
  reply: {
    display: 'inline-block',
    marginTop: rhythm(0.5),
    padding: rhythm([0.5, 1]),
    backgroundColor: colors.paleGrey,
    borderRadius: rhythm([0, 1, 1])
  }
})

export const keyframes = {
  fadeIn: {
    '0%': {
      opacity: 0
    },
    '100%': {
      opacity: 1
    }
  },
  spin: {
    '0%': {
      transform: 'rotate(0deg)'
    },
    '100%': {
      transform: 'rotate(360deg)'
    }
  }
}
