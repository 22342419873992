import React from 'react'
import { compose } from 'redux'
import { withMobile } from '../../utils/withMobile'
import { withInjectParams } from '../../utils/InjectParams'
import { getCurrentUrl } from '../../../lib/window'

import ButtonGroup from 'constructicon/button-group'
import ButtonShare from 'constructicon/button-share'
import Content from '../../ui/Content'
import Section from '../../ui/Section'

const Share = ({
  alignment,
  background,
  customSupporterPage,
  emailBody,
  emailSubject,
  foreground,
  fundraisingPage = {},
  injectParams,
  isMobile,
  items = [],
  spacing,
  title
}) => (
  <Section
    alignment={alignment}
    background={background}
    foreground={foreground}
    spacing={spacing}>
    <Content title={title}>
      <ButtonGroup>
        {items
          .filter(item => item.network)
          .filter(item => ['whatsapp', 'messenger'].indexOf(item.network) > -1 ? isMobile : true)
          .map((item, index) => {
            if (item.network === 'email') {
              const subject = emailSubject ? injectParams(emailSubject) : injectParams(customSupporterPage.meta.ogTitle || fundraisingPage.name)
              const body = emailBody ? injectParams(emailBody) : `${fundraisingPage.name} - ${getCurrentUrl()}`

              return (
                <ButtonShare
                  key={index}
                  background={item.background === 'default' ? 'primary' : item.background}
                  foreground={item.foreground === 'default' ? 'light' : item.foreground}
                  type='mail'
                  share
                  title={subject}
                  url={body}
                />
              )
            }

            return (
              <ButtonShare
                key={index}
                background={item.background === 'default' ? item.network : item.background}
                foreground={item.foreground === 'default' ? 'light' : item.foreground}
                type={item.network}
                share
                title={injectParams(customSupporterPage.meta.ogTitle)}
                caption={injectParams(customSupporterPage.meta.ogDescription)}
              />
            )
          })}
      </ButtonGroup>
    </Content>
  </Section>
)

export default compose(
  withMobile,
  withInjectParams
)(Share)
