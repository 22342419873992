import React, { Component } from 'react'
import queryString from 'querystringify'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { selectTraits, selectWebFonts } from '../../../store/site'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import IFrameResizer from 'react-iframe-resizer-super'

class IFrame extends Component {
  constructor (props) {
    super(props)
    this.state = {
      fonts: '',
      traits: ''
    }
  }

  encodeJSON (json) {
    return window.btoa(JSON.stringify(json))
  }

  componentDidMount () {
    this.setState({
      fonts: this.encodeJSON(this.props.fonts),
      traits: this.encodeJSON(this.props.traits)
    })
  }

  generateUrl () {
    const { fonts, traits } = this.state
    const { forwardQueryParams, location, url } = this.props

    if (forwardQueryParams === 'yes') {
      const [ path, query = '' ] = url.split('?')
      const existingQuery = queryString.parse(query)
      const combinedQuery = {
        ...existingQuery,
        ...location.query,
        fonts,
        traits
      }

      return `${path}?${queryString.stringify(combinedQuery)}`
    }

    return url
  }

  render () {
    const { classNames } = this.props

    return (
      <div className={classNames.root}>
        <IFrameResizer
          src={this.generateUrl()}
          scrolling='no'
          style={{ width: '100%', border: 'none' }}
        />
      </div>
    )
  }
}

const mapState = state => ({
  fonts: selectWebFonts(state),
  traits: selectTraits(state)
})

export default compose(
  withRouter,
  connect(mapState),
  withStyles(styles)
)(IFrame)
