export default ({
  direction,
  metricAlignment = 'center'
}, {
  mediaQuery,
  rhythm
}) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexPack: 'center',
    flexWrap: 'wrap',
    flexDirection: direction === 'vertical' && 'column',
    margin: rhythm(-0.75),

    [mediaQuery('sm')]: {
      justifyContent: alignments[metricAlignment]
    },

    '& > *': {
      margin: rhythm(0.75)
    }
  }
})

const alignments = {
  stretch: 'space-between',
  even: 'space-around',
  center: 'center'
}
