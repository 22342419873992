export default ({
  background,
  navForeground,
  navStyle,
  toggled
}, {
  colors,
  mediaQuery,
  rhythm,
  shadows,
  transitions,
  treatments
}) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexPack: 'center',
    flexDirection: 'column',
    paddingRight: rhythm(1.25),
    minHeight: rhythm(1.666),

    [mediaQuery('xs')]: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      flexPack: 'justify'
    },

    [mediaQuery('sm')]: {
      paddingRight: rhythm(1.5)
    },

    [mediaQuery('lg')]: {
      paddingRight: 0
    }
  },

  logo: {
    display: 'block',
    flexShrink: 1,

    [mediaQuery('xs')]: {
      marginRight: rhythm(0.25)
    },

    [mediaQuery('lg')]: {
      marginRight: rhythm(0.5)
    }
  },

  nav: {
    ...treatments[navStyle],
    position: 'absolute',
    top: '100%',
    left: 0,
    right: 0,
    color: colors[navForeground],
    padding: `${rhythm(1)} ${rhythm(1)}`,
    backgroundColor: colors[background],
    borderTop: `1px solid ${colors.shade}`,
    boxShadow: shadows.bottom,
    transition: transitions.easeOut,
    visibility: toggled ? 'visible' : 'hidden',
    opacity: toggled ? 1 : 0,

    [mediaQuery('sm')]: {
      padding: `${rhythm(1)} ${rhythm(1.5)}`
    },

    [mediaQuery('lg')]: {
      position: 'relative',
      top: 0,
      display: 'flex',
      border: 0,
      boxShadow: 'none',
      transition: 'none',
      backgroundColor: 'transparent',
      alignItems: 'center',
      whiteSpace: 'nowrap',
      padding: 0,
      margin: rhythm(-0.5),
      visibility: 'visible',
      opacity: 1,
      '& > *': {
        margin: rhythm(0.5)
      }
    }
  },

  mobileCta: {
    marginTop: rhythm(0.5),

    [mediaQuery('xs')]: {
      flexShrink: 0,
      textAlign: 'right',
      margin: rhythm([-0.125, 0.5]),
      marginLeft: rhythm(0.5)
    },

    [mediaQuery('lg')]: {
      display: 'none'
    }
  },

  toggle: {
    position: 'absolute',
    right: rhythm(0.525),
    top: '50%',
    transform: 'translateY(-50%)',
    padding: rhythm(0.5),
    color: colors[navForeground],

    [mediaQuery('sm')]: {
      right: rhythm(1)
    },

    [mediaQuery('lg')]: {
      display: 'none'
    }
  }
})
