import React, { useRef, useState } from 'react'
import chunk from 'lodash/chunk'
import moment from 'moment'
import numbro from 'numbro'
import flattenDeep from 'lodash/flattenDeep'
import groupBy from 'lodash/groupBy'
import keys from 'lodash/keys'
import startCase from 'lodash/startCase'
import { createLeaderboardDefinition, fetchLeaderboardDefinition } from 'supporticon/utils/leaderboards'
import { measurementDomains } from 'supporticon/utils/tags'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import Button from 'constructicon/button'
import Form from 'constructicon/form'
import Heading from 'constructicon/heading'
import Icon from 'constructicon/icon'
import InputField from 'constructicon/input-field'
import Progress from 'constructicon/progress-bar'
import Section from 'constructicon/section'

const DefinitionForm = ({
  campaign,
  classNames,
  id,
  label,
  styles,
  values = []
}) => {
  const [selected, setSelected] = useState([])
  const [progress, setProgress] = useState(0)
  const [startTime, setStartTime] = useState(moment())
  const [status, setStatus] = useState('empty')
  const [existing, setExisting] = useState(
    measurementDomains.reduce(
      (acc, dom) => ({
        ...acc,
        [dom]: {
          values: {},
          status: 'empty'
        }
      }),
      {}
    )
  )

  const existingRef = useRef(existing)

  existingRef.current = existing

  const getLabel = domain => {
    switch (domain) {
      case 'fundraising:donations_received':
        return 'Funds Raised'
      case 'fundraising:donations_made':
        return 'Number of Donations'
      case 'any:activities':
      case 'hike:activities':
      case 'ride:activities':
      case 'swim:activities':
      case 'walk:activities':
        return 'Number of Activities'
      default:
        return startCase(domain.split(':')[1])
    }
  }

  const handleCreate = (e) => {
    e.preventDefault()

    return Promise.resolve()
      .then(() => setProgress(1))
      .then(() => setStartTime(moment()))
      .then(() => setStatus('fetching'))
      .then(() => {
        const definitions = flattenDeep(
          selected.map(measurementDomain =>
            values.map(value => ({
              id: campaign,
              measurementDomain,
              name: [label, value].join(' '),
              conditions: [{
                tagDefinition: { id, label },
                value
              }]
            }))
          )
        )

        const chunkSize = 6

        return chunk(definitions, chunkSize).reduce((prev, next) => {
          return prev.then(() => {
            setProgress(definitions.indexOf(next[chunkSize - 1]))
            return Promise.all(next.map(createLeaderboardDefinition))
          })
        }, Promise.resolve())
      })
      .then(() => setStatus('fetched'))
  }

  const isDisabled = !selected.length

  const handleChange = (domain, checked) => setSelected(
    checked
      ? selected.concat(domain)
      : selected.filter(item => item !== domain)
  )

  const grouped = groupBy(measurementDomains, domain => domain.split(':')[0])
  const groups = keys(grouped).map(group => ({
    name: group === 'any' ? 'All Fitness' : startCase(group),
    domains: grouped[group]
  }))

  const downloadCsv = () => {
    const link = document.createElement('a')
    const blob = new window.Blob([values.join('\n')], { type: 'text/csv;charset=utf-8;' })
    const url = window.URL.createObjectURL(blob)

    link.href = url
    link.setAttribute('download', `${id}.csv`)
    link.click()
  }

  const formatDuration = duration => {
    return [
      duration.asHours() >= 1 && `${duration.hours()}h`,
      duration.asMinutes() >= 1 && `${duration.minutes()}m`,
      `${duration.seconds()}s`
    ]
      .filter(Boolean)
      .join(' ')
  }

  const allSelected = selected.length && selected.length === measurementDomains.length
  const leaderboardCount = selected.length * values.length
  const timeTaken = moment.duration(moment().diff(startTime))
  const timeRemaining = moment.duration((leaderboardCount - progress) * (timeTaken.asSeconds() / progress), 'seconds')
  const submit = `Configure ${numbro(leaderboardCount).format('0,0')} leaderboards (${selected.length} of ${measurementDomains.length})`

  const fetchExisting = (measurementDomain, open = false) =>
    Promise.resolve()
      .then(() => setExisting({
        ...existingRef.current,
        [measurementDomain]: {
          values: {},
          status: 'fetching'
        }
      }))
      .then(() =>
        Promise.all(
          values.map(value =>
            fetchLeaderboardDefinition({
              id: campaign,
              measurementDomain,
              name: [label, value].join(' '),
              conditions: [
                {
                  tagDefinition: { id, label },
                  value
                }
              ]
            })
              .then(() => ({ value, fetched: true }))
              .catch(() => ({ value, fetched: false }))
          )
        )
      )
        .then(data =>
          setExisting({
            ...existingRef.current,
            [measurementDomain]: {
              values: data.reduce((acc, item) => ({
                ...acc,
                [item.value]: item.fetched
              }), {}),
              open,
              status: 'fetched'
            }
          })
        )

  const renderFetchButton = domain => {
    const data = existingRef.current[domain]

    switch (data.status) {
      case 'fetched':
        const created = values.map(val => data.values[val]).filter(Boolean).length
        const success = created === values.length

        const renderIcon = () =>
          created > 0
            ? success
              ? <Icon name='check' color='success' />
              : <Icon name='warning' color='orange' />
            : <Icon name='close' color='danger' />

        return (
          <div>
            <button
              type='button'
              title={`${created} of ${values.length} setup.`}
              onClick={e => {
                e.preventDefault()
                setExisting({
                  ...existingRef.current,
                  [domain]: {
                    ...data,
                    open: !data.open
                  }
                })
              }}
            >
              {renderIcon()}
            </button>
            {data.open && (
              <div className={classNames.status}>
                {renderIcon()}
                <Heading size={0} spacing={{ t: 0.5, b: 1 }} styles={styles.title}>
                  Existing leaderboard definitions
                  <small>{getLabel(domain)}</small>
                </Heading>
                <ol>
                  {values.map(val => {
                    const hasDef = data.values[val]

                    return (
                      <li key={val}>
                        {val}
                        <Icon
                          name={hasDef ? 'check' : 'close'}
                          color={hasDef ? 'success' : 'danger'}
                        />
                      </li>
                    )
                  })}
                </ol>
                <Button
                  background='grey'
                  type='button'
                  onClick={e => {
                    e.preventDefault()
                    setExisting({
                      ...existingRef.current,
                      [domain]: {
                        ...data,
                        open: false
                      }
                    })
                  }}
                >
                  Close
                </Button>
              </div>
            )}
          </div>
        )
      case 'fetching':
        return (
          <button title='Fetching...' disabled>
            <Icon name='loading' spin />
          </button>
        )
      default:
        return (
          <button
            type='button'
            onClick={e => {
              e.preventDefault()
              fetchExisting(domain, true)
            }}
            title='Fetch existing definitions'
          >
            <Icon name='info' color='lightGrey' />
          </button>
        )
    }
  }

  const isFetching =
    keys(existingRef.current)
      .map(dom => existingRef.current[dom].status === 'fetching')
      .filter(Boolean).length > 0

  return (
    <Section styles={styles.root} spacing={{ b: 1 }}>
      <header className={classNames.header}>
        <Heading size={0} styles={styles.title} title={label}>
          {label}
          <small title={id}>ID: {id}</small>
        </Heading>
        <Button
          background='grey'
          size={-1}
          onClick={downloadCsv}>
          {numbro(values.length).format('0,0')} values (Download CSV)
        </Button>
      </header>
      <Form
        icon={false}
        isDisabled={isDisabled}
        isLoading={status === 'fetching'}
        noValidate
        onSubmit={handleCreate}
        submit={submit}
      >
        <header className={classNames.flex}>
          <button
            className={classNames.button}
            onClick={e => {
              e.preventDefault()
              setSelected(allSelected ? [] : measurementDomains)
            }}
          >
            <InputField
              type='checkbox'
              name='Select all'
              label={allSelected ? 'Select None' : 'Select All'}
              value={allSelected}
              onChange={() => setSelected(allSelected ? [] : measurementDomains)}
              styles={styles.input}
            />
          </button>
          <button
            className={classNames.button}
            onClick={e => {
              e.preventDefault()
              measurementDomains.reduce((prev, next) => {
                return prev.then(() => fetchExisting(next))
              }, Promise.resolve())
            }}
          >
            <Icon name={isFetching ? 'loading' : 'info'} spin={isFetching} />
            <span>Fetch all existing</span>
          </button>
        </header>
        <div className={classNames.columns}>
          {groups.map(group => (
            <fieldset key={group.name}>
              <Heading
                tag='legend'
                size={0}
                spacing={{ b: 0.666 }}
                styles={styles.title}
              >
                {group.name}
              </Heading>
              <div>
                {group.domains.map(domain => (
                  <div className={classNames.flex}>
                    <InputField
                      type='checkbox'
                      key={domain}
                      name={domain}
                      label={getLabel(domain)}
                      value={selected.indexOf(domain) > -1}
                      onChange={checked => handleChange(domain, checked)}
                      styles={styles.input}
                    />
                    {renderFetchButton(domain)}
                  </div>
                ))}
              </div>
            </fieldset>
          ))}
        </div>
      </Form>
      {status === 'fetching' && (
        <div className={classNames.status}>
          <Heading size={0.5} color='danger' spacing={{ b: 1 }}>DO NOT CLOSE THIS WINDOW</Heading>
          <Heading size={0.5} spacing={{ b: 1 }}>
            {`Creating leaderboard ${numbro(progress).format('0,0')} of ${numbro(leaderboardCount).format('0,0')}`}
          </Heading>
          <Progress
            alt='<%= progress %>% complete'
            progress={progress / leaderboardCount * 100}
            styles={styles.progress}
          />
          <Heading size={-0.5} spacing={{ t: 1, b: 0.5 }}>
            {`Running for ${formatDuration(timeTaken)}.`}
          </Heading>
          <Heading size={-1} spacing={0}>
            {`Estimated time remaining: ${formatDuration(timeRemaining)}.`}
          </Heading>
        </div>
      )}
      {status === 'fetched' && (
        <div className={classNames.status}>
          <Heading size={0.5} spacing={{ b: 1 }}>
            {`Finished in ${formatDuration(timeTaken)}. ${numbro(leaderboardCount).format('0,0')} leaderboards created/updated.`}
          </Heading>
          <Button onClick={() => setStatus('empty')}>Clear</Button>
        </div>
      )}
    </Section>
  )
}

export default withStyles(styles)(DefinitionForm)
