import React from 'react'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import Grid from 'constructicon/grid'
import GridColumn from 'constructicon/grid-column'
import Heading from 'constructicon/heading'
import Section from 'constructicon/section'

const PreselectedCharity = ({
  charity,
  classNames,
  onCancel,
  styles
}) => (
  <Section
    background='light'
    margin={{ b: 1 }}
    styles={styles.root}>
    <Grid
      align='center'
      spacing={0.5}>
      <GridColumn sm={3} xsAlign='left'>
        <img
          src={charity.logo}
          alt={charity.name}
          className={classNames.image}
        />
      </GridColumn>
      <GridColumn sm={9} xsAlign='left'>
        <div>You are supporting</div>
        <Heading spacing={0}>{charity.name}</Heading>
        <button
          className={classNames.clear}
          onClick={onCancel}>
          clear
        </button>
      </GridColumn>
    </Grid>
  </Section>
)

export default withStyles(styles)(PreselectedCharity)
