import camelCase from 'lodash/camelCase'
import mapKeys from 'lodash/mapKeys'
import template from 'lodash/template'

export default (string, properties) => {
  const variables = Object.keys(properties).reduce((result, property) => ({
    ...result,
    ...mapKeys(properties[property], (val, key) => camelCase([property, key].join()))
  }), {})

  return template(string)(variables)
}
