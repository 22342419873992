export default (
  { poweredBy, background, hasShadow, height, isNavOpen, isSticky, isOverlaid },
  { colors, mediaQuery, rhythm, shadows, transitions, treatments }
) => {
  const verticalPadding = 1
  const stickyPlaceholderSize =
    isSticky && !isOverlaid ? height + verticalPadding : 0

  return {
    wrapper: {
      marginTop: rhythm(stickyPlaceholderSize)
    },

    root: {
      position: 'relative',
      zIndex: 4,
      boxShadow: hasShadow && shadows.bottom,
      padding: `${rhythm(0.5)} ${rhythm(1)}`,
      minHeight: rhythm(stickyPlaceholderSize),
      transition: transitions.easeOut,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      [mediaQuery('md')]: {
        padding: `${rhythm(0.5)} ${rhythm(1.5)}`
      },
      [mediaQuery('lg', 'max-width')]: {
        backgroundColor: isNavOpen && colors[background]
      }
    },

    overlaid: {
      position: 'absolute',
      top: poweredBy ? '3rem' : 0,
      left: 0,
      right: 0
    },

    sticky: {
      position: 'fixed',
      top: poweredBy ? '3rem' : 0,
      left: 0,
      right: 0
    },

    transparent: {
      boxShadow: 'none'
    }
  }
}
