import React from 'react'
import Helmet from 'react-helmet'

const HostedFonts = ({
  fonts = []
}) => {
  const stylesheets = fonts.map((font) => ({
    rel: 'stylesheet',
    href: font.primary.url
  }))

  return (
    <Helmet link={stylesheets} />
  )
}

export default HostedFonts
