import React from 'react'
import { connect } from 'react-redux'
import { selectWebFonts } from '../../../store/site'

import GoogleFonts from '../GoogleFonts'
import HostedFonts from '../HostedFonts'

const WebFonts = ({
  fonts = []
}) => {
  const googleFonts = fonts.filter((font) => font.type === 'google_font')
  const externalFonts = fonts.filter((font) => font.type === 'external')

  return (
    <span>
      {googleFonts.length > 0 && <GoogleFonts fonts={googleFonts} />}
      {externalFonts.length > 0 && <HostedFonts fonts={externalFonts} />}
    </span>
  )
}

const mapState = (state) => ({
  fonts: selectWebFonts(state)
})

export default connect(mapState)(WebFonts)
