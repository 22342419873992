import React from 'react'
import LinkHandler from '../../utils/LinkHandler'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

const Navigation = ({
  classNames,
  items = [],
  onClick
}) => {
  let categories = {}
  let children = []

  items.map(item => {
    item.parent
      ? children.push(item)
      : categories[item.label] = { ...item, children: [] }
  })

  children.forEach(child => {
    if (!categories[child.parent]) throw new Error(`Parent nav item does not exist: ${child.parent}`)
    categories[child.parent].children.push(child)
  })

  const navigation = Object.keys(categories).map(category => (
    categories[category]
  ))

  return (
    <nav className={classNames.wrapper} role='navigation' aria-label='Site navigation'>
      {(navigation.length > 0) && (
        <ul className={classNames.root}>
          {navigation.map((item, i) => (
            <li key={i}>
              <LinkHandler
                linkType={item.linkType}
                onClick={onClick}
                to={item.url}>
                {item.label}
              </LinkHandler>
              {(item.children.length > 0) && (
                <div className={classNames.dropDown}>
                  <ul>
                    {item.children.map((child, i) => (
                      <li key={i}>
                        <LinkHandler
                          onClick={onClick}
                          to={child.url}>
                          {child.label}
                        </LinkHandler>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </li>
          ))}
        </ul>
      )}
    </nav>
  )
}

export default withStyles(styles)(Navigation)
