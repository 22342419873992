export default (props, traits) => {
  const { colors, rhythm } = traits

  return {
    root: {
      maxWidth: rhythm(10),
      margin: '0 auto',
      padding: rhythm(1),
      backgroundColor: colors.paleGrey,
      border: `1px solid ${colors.shade}`,
      borderRadius: '0.5em',
      textAlign: 'center'
    },

    message: {
      margin: rhythm([1, 0]),
      opacity: 0.5
    }
  }
}
