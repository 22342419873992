export default country => {
  switch (country) {
    case 'ae':
      return 'د.إ'
    case 'gb':
      return '£'
    case 'hk':
      return 'HK$'
    case 'ie':
      return '€'
    case 'za':
      return 'R'
    default:
      return '$'
  }
}

export const symbolFromCurrencyCode = code => {
  switch (code) {
    case 'AED':
      return 'د.إ'
    case 'GBP':
      return '£'
    case 'HKD':
      return 'HK$'
    case 'EUR':
      return '€'
    case 'ZAR':
      return 'R'
    case 'AUD':
    case 'USD':
      return '$'
    default:
      return '£'
  }
}
