import React from 'react'
import withStyles from 'constructicon/with-styles'
import styles from './styles'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { selectButtonDefaults } from '../../../store/site'
import { fetchSessionUser, selectUser } from '../../../store/session'
import { isJustGiving } from '../../../lib/environment'

import Everydayhero from './Everydayhero'
import JustGiving from './JustGiving'

import FlatModal from '../../../components/ui/FlatModal'
import Section from 'constructicon/section'

class Login extends React.Component {
  componentDidMount () {
    this.checkLoggedIn(this.props)
  }

  componentWillReceiveProps (nextProps) {
    this.checkLoggedIn(nextProps)
  }

  checkLoggedIn (props) {
    const {
      onSuccess,
      user = {}
    } = props

    if (user.token) {
      onSuccess()
    }
  }

  render () {
    const {
      buttonProps,
      fetchSessionUser,
      onDismiss,
      styles
    } = this.props

    return (
      <FlatModal
        contentLabel='Login'
        isOpen
        onRequestClose={onDismiss}
        styles={styles.modal}>
        <Section spacing={{ x: 1, y: 1.5 }}>
          {isJustGiving() ? (
            <JustGiving
              buttonProps={buttonProps}
              onSuccess={fetchSessionUser}
            />
          ) : (
            <Everydayhero
              buttonProps={buttonProps}
              onSuccess={fetchSessionUser}
            />
          )}
        </Section>
      </FlatModal>
    )
  }
}

const mapState = state => ({
  buttonProps: selectButtonDefaults(state),
  user: selectUser(state)
})

const mapDispatch = { fetchSessionUser }

export default compose(
  connect(mapState, mapDispatch),
  withStyles(styles)
)(Login)
