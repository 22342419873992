import { createSelector } from 'reselect'
import find from 'lodash/find'
import first from 'lodash/first'
import moment from 'moment'
import kebabCase from 'lodash/kebabCase'

export const selectStatus = (store) => store.site.status
export const selectDocument = (store) => store.site.data

export const selectSite = createSelector(
  [ selectDocument ],
  (document = {}) => document
)

export const selectUrl = createSelector(
  [ selectSite ],
  (site = {}) => `https://${site.uid}`
)

export const selectMeta = createSelector(
  [ selectSite ],
  (site = {}) => site.meta
)

export const selectHeader = createSelector(
  [ selectSite ],
  (site = {}) => site.header
)

export const selectFooter = createSelector(
  [ selectSite ],
  (site = {}) => site.footer
)

export const selectHome = createSelector(
  [ selectSite ],
  (site = {}) => site.home
)

export const selectDesign = createSelector(
  [ selectSite ],
  (site = {}) => site.design
)

export const selectTraits = createSelector(
  [ selectDesign ],
  (design = {}) => {
    const {
      colors: {
        primary,
        secondary,
        tertiary,
        dark,
        light
      } = {},
      fonts: {
        head = {},
        body = {},
        button = {}
      }
    } = design

    return {
      colors: {
        primary,
        secondary,
        tertiary,
        dark: dark || '#272727',
        light: light || 'white',
        transparent: 'transparent'
      },
      fonts: {
        head: head.family,
        body: body.family,
        button: button.family
      },
      treatments: {
        head: {
          fontFamily: head.family,
          fontWeight: head.weight || 400,
          textTransform: head.uppercase === 'yes' && 'uppercase',
          lineHeight: 1.1,
          letterSpacing: head.spacing === 'condensed' ? '-0.1rem'
            : head.spacing === 'expanded' && '0.1rem'
        },
        body: {
          fontFamily: body.family,
          fontWeight: body.weight || 400,
          textTransform: body.uppercase === 'yes' && 'uppercase'
        },
        button: {
          fontFamily: button.family,
          fontWeight: button.weight || 400,
          textTransform: button.uppercase === 'yes' && 'uppercase'
        },
        container: {
          maxWidth: '67.5rem'
        },
        form: {
          paddingBottom: 0,
          '& button > :first-child:last-child': {
            display: 'inline-block'
          }
        },
        input: {
          '&[type=textarea]': {
            padding: '0.75rem',
            minHeight: '8rem',
            lineHeight: 1.25
          }
        }
      },
      breakpoints: {
        xs: '360px',
        sm: '480px',
        md: '768px',
        lg: '960px',
        xl: '1280px'
      },
      shadows: {
        full: '0 5px 10px rgba(0,0,0,0.125)',
        bottom: '0 5px 5px rgba(0,0,0,0.125)'
      }
    }
  }
)

export const selectWebFonts = createSelector(
  [ selectSite ],
  (site = {}) => site.design && site.design.webfonts
)

export const selectButtonDefaults = createSelector(
  [ selectSite ],
  (site = {}) => {
    const { design = {} } = site
    const { buttons = {} } = design

    return {
      spacing: { y: buttons.spacingY || 0.5, x: buttons.spacingX || 1 },
      radius: buttons.radius || 'small',
      size: buttons.fontSize || 0,
      borderWidth: parseInt(buttons.borderWidth || 0),
      borderColor: buttons.borderColor
    }
  }
)

export const selectLocalisation = createSelector(
  [ selectSite ],
  (site = {}) => site.localisation
)

export const selectCountry = createSelector(
  [ selectLocalisation ],
  (localisation = {}) => localisation.country
)

export const selectIFrame = createSelector(
  [ selectSite ],
  (site = {}) => site.iframe
)

export const selectMaintenanceMode = createSelector(
  [ selectSite ],
  (site = {}) => site.maintenanceMode
)

export const selectUseOauth = createSelector(
  [ selectSite ],
  (site = {}) => site.useJGOauth !== false
)

export const selectData = createSelector(
  [ selectSite ],
  (site = {}) => site.data
)

export const selectDataRequirements = createSelector(
  [ selectData ],
  (data = {}) => {
    const { other = [] } = data
    return other.reduce((reqs, req) => ({
      ...reqs,
      [req.type]: {
        ...req.primary,
        items: req.items
      }
    }), {})
  }
)

export const selectAPIParams = createSelector(
  [ selectDataRequirements ],
  (data = {}) => {
    const {
      everydayhero,
      justgiving,
      refresh = {}
    } = data

    const interval = refresh.interval || 30
    const refreshEnabled = refresh.enabled === 'On'
    const refreshInterval = refreshEnabled ? parseInt(interval) * 1000 : undefined

    if (everydayhero) {
      return {
        apiParams: {
          ...selectEverydayheroAPIParams(everydayhero),
          refreshInterval
        }
      }
    }

    if (justgiving) {
      return {
        apiParams: {
          ...selectJustGivingAPIParams(justgiving),
          refreshInterval
        }
      }
    }

    return { apiParams: {} }
  }
)

const selectEverydayheroAPIParams = (data) => {
  const {
    clientId,
    endDate,
    excludeOffline,
    items = [],
    redirectUri,
    startDate
  } = data

  const campaign = items.filter((item) => item.type === 'campaign').map((campaign) => campaign.uid)
  const charity = items.filter((item) => item.type === 'charity').map((charity) => charity.uid)

  return {
    campaign,
    campaignIds: campaign,
    charity,
    charityIds: charity,
    clientId,
    endDate,
    excludeOffline: excludeOffline === 'Yes',
    redirectUri,
    startDate
  }
}

const selectJustGivingAPIParams = (data) => {
  const {
    endDate,
    startDate,
    defaultPageStory,
    excludeOffline,
    items = [],
    utcOffset
  } = data

  const campaign = find(items, (item) => item.type === 'campaign')
  const charity = find(items, (item) => item.type === 'charity')
  const event = find(items, (item) => item.type === 'event')
  const eventIds = items.filter(({ type }) => type === 'event').map(event => event.uid)
  const campaignIds = items.filter(({ type }) => type === 'campaign').map(campaign => campaign.uid)
  const charityIds = items.filter(({ type }) => type === 'charity').map(charity => charity.uid)
  const page = find(items, (item) => item.type === 'page')
  const pageIds = items.filter(({ type }) => type === 'page').map(page => page.uid)

  const calculateDateTime = (date, utcOffset) =>
    date && moment.utc(date).subtract(parseInt(utcOffset) || 0, 'hours').toISOString()

  return {
    campaign,
    campaignIds,
    charity,
    charityIds,
    defaultPageStory,
    endDate,
    endDateTime: calculateDateTime(endDate, utcOffset),
    event,
    eventIds,
    excludeOffline: excludeOffline === 'Yes',
    page,
    pageIds,
    startDate,
    startDateTime: calculateDateTime(startDate, utcOffset)
  }
}

export const selectBasicAPIParams = createSelector(
  [ selectAPIParams ],
  ({ apiParams = {} }) => ({
    campaign: first(apiParams.campaignIds),
    charity: first(apiParams.charityIds),
    event: first(apiParams.eventIds)
  })
)

export const selectCampaign = createSelector(
  [ selectDataRequirements ],
  (data = {}) => {
    const {
      everydayhero,
      justgiving
    } = data

    if (everydayhero) {
      const { items = [] } = everydayhero
      return find(items, item => item.type === 'campaign')
    }

    if (justgiving) {
      const { items = [] } = justgiving
      return find(items, item => item.type === 'campaign')
    }
  }
)

export const selectAnalyticsParams = createSelector(
  [ selectDataRequirements ],
  (data = {}) => {
    const { analytics = {} } = data
    return analytics
  }
)

export const selectCookieConsentParams = createSelector(
  [ selectDataRequirements ],
  (data = {}) => {
    const { cookieConsent = {} } = data
    return cookieConsent
  }
)

export const selectReportsParams = createSelector(
  [ selectDataRequirements ],
  (data = {}) => {
    const { reports = {} } = data
    return reports
  }
)

export const selectTagsParams = createSelector(
  [ selectDataRequirements ],
  (data = {}) => {
    const { tags = {} } = data
    return tags
  }
)

export const selectTagDefinitions = createSelector(
  [ selectTagsParams ],
  (tags = {}) => {
    const tagDefinitions = tags.items || []
    return tagDefinitions.map(tag => ({
      id: kebabCase(tag.name),
      label: tag.label || tag.name,
      required: tag.required,
      initial: tag.initial,
      values: tag.values.split('\n')
    }))
  }
)

export const selectPrimaryCampaign = createSelector(
  [ selectDataRequirements ],
  (data = {}) => {
    const { justgiving = {}, everydayhero = {} } = data
    const jgItems = justgiving.items || []
    const edhItems = everydayhero.items || []
    const dataSources = [ ...jgItems, ...edhItems ]
    const primarySource = find(dataSources, source => source.type === 'campaign')

    return {
      campaign: primarySource && primarySource.uid
    }
  }
)

export const selectSiteRegistration = createSelector(
  [ selectHome ],
  (home = []) => {
    const registrationSlice = find(home, ({ type }) => type === 'registration')

    if (registrationSlice) {
      return {
        hasHomeRegistrationForm: true,
        customFields: registrationSlice.items.length,
        campaignId: registrationSlice.primary.campaignId,
        charityId: registrationSlice.primary.charityId,
        eventId: registrationSlice.primary.eventId,
        includeAddress: registrationSlice.primary.showAddress,
        includeEventDate: registrationSlice.primary.showEventDate,
        includeFitnessGoal: registrationSlice.primary.showFitnessGoal,
        includeName: registrationSlice.primary.showName,
        includeTarget: registrationSlice.primary.showTarget,
        includeSelfDonate: registrationSlice.primary.includeSelfDonate,
        includeTeam: registrationSlice.primary.includeTeam,
        jgOauthMethod: registrationSlice.primary.jgOauthMethod
      }
    }

    return {
      hasHomeRegistrationForm: false
    }
  }
)
