import React from 'react'
import withStyles from 'constructicon/with-styles'
import styles from './styles'
import Modal from 'constructicon/modal'

const FlatModal = ({
  classNames,
  styles,
  ...props
}) => (
  <Modal
    styles={styles.root}
    spacing={0}
    {...props}
  />
)

export default withStyles(styles)(FlatModal)
