import React from 'react'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

const MetricGroup = ({
  children,
  classNames
}) => (
  <div className={classNames.root}>
    {children}
  </div>
)

export default withStyles(styles)(MetricGroup)
