import React from 'react'
import orderBy from 'lodash/orderBy'
import { connect } from 'react-redux'
import { fetchPages, deserializePage } from 'supporticon/api/pages'
import { selectCustomSupporterPagePresent } from '../../../store/customSupporterPages'
import { selectAPIParams } from '../../../store/site'

import Content from '../../ui/Content'
import Error from '../../ui/Error'
import Filter from 'constructicon/filter'
import Grid from 'constructicon/grid'
import Loading from '../../ui/Loading'
import Pagination from 'constructicon/pagination'
import PaginationLink from 'constructicon/pagination-link'
import ProgressTiles from '../../ui/ProgressTiles'
import Section from '../../ui/Section'

class PageProgressTiles extends React.Component {
  constructor () {
    super()
    this.deserializePage = this.deserializePage.bind(this)
    this.state = {
      filter: null,
      pages: [],
      status: 'fetching'
    }
  }

  sortPages (pages) {
    const { order, sort } = this.props

    switch (order) {
      case 'amount':
        return orderBy(pages, ['raised'], [sort])
      case 'target':
        return orderBy(pages, ['target'], [sort])
      case 'date':
        return orderBy(pages, ['campaignDate'], [sort])
      case 'id':
        return orderBy(pages, ['id'], [sort])
      default:
        return orderBy(pages, ['name'], [sort])
    }
  }

  deserializePage (data) {
    const page = deserializePage(data)

    if (this.props.hasCustomSupporterPages) {
      return {
        ...page,
        url: `/${page.type === 'team' ? 'teams' : 'fundraising'}/${page.slug}`
      }
    }

    return page
  }

  componentDidMount () {
    const {
      apiParams,
      campaignOverride,
      excludePageIds,
      group,
      limit,
      pageIds
    } = this.props

    const allPages = (pageIds && pageIds.split(',').length > 0) || apiParams.event

    const params = {
      ...apiParams,
      allPages,
      ids: pageIds,
      campaign: campaignOverride || apiParams.campaignIds,
      group,
      limit
    }

    fetchPages(params)
      .then(pages => pages.map(this.deserializePage))
      .then((data) => this.removeExcludedPages(data, excludePageIds))
      .then(pages => this.sortPages(pages))
      .then(pages => this.setState({ pages, status: 'fetched' }))
      .catch(error => {
        this.setState({ status: 'failed' })
        return Promise.reject(error)
      })
  }

  removeExcludedPages (pages, excludePageIds) {
    if (!excludePageIds) return pages
    return pages.filter(page => excludePageIds.indexOf(page.id) === -1)
  }

  render () {
    const {
      action,
      alignment,
      backgroundImage,
      background,
      barColor,
      buttonBackground,
      buttonForeground,
      copy,
      copyAlignment,
      donationUrlParams,
      foreground,
      fundedAction,
      includeFilter,
      limit,
      metric,
      pageSize,
      size,
      spacing,
      subtitle,
      title,
      titleForeground,
      titleSize
    } = this.props

    const {
      filter,
      pages,
      status
    } = this.state

    const data = pages
      .map(page => {
        const isFunded = page.raised >= page.target
        const isDisabled = isFunded && fundedAction === 'disable'

        return {
          ...page,
          raised: page.raised,
          target: page.target,
          status: 'fetched',
          actions: [{
            to: action === 'view'
              ? isDisabled ? '' : page.url
              : isDisabled ? '' : [page.donationUrl, donationUrlParams].filter(Boolean).join(''),
            label: action === 'view'
              ? isDisabled ? 'Project Funded' : 'View Page'
              : isDisabled ? 'Project Funded' : 'Donate Now'
          }]
        }
      })
      .filter(page => {
        return filter
          ? page.name.toLowerCase().indexOf(filter.toLowerCase()) !== -1
          : true
      })

    return (
      <Section
        alignment={alignment}
        backgroundImage={backgroundImage}
        background={background}
        foreground={foreground}
        size={size}
        spacing={spacing}>

        <Content
          copy={copy}
          copyAlignment={copyAlignment}
          subtitle={subtitle}
          title={title}
          titleForeground={titleForeground}>

          {status === 'fetching' && <Loading />}
          {status === 'failed' && <Error>There was an error loading pages.</Error>}
          {status === 'fetched' && (
            <div>
              {includeFilter === 'yes' && (
                <Filter
                  onChange={filter => this.setState({ filter })}
                />
              )}
              <Pagination max={pageSize || limit} toPaginate={data}>
                {({ canNext, canPrev, currentPage, isPaginated, next, prev }) => (
                  <div>
                    <ProgressTiles
                      action={action}
                      barColor={barColor}
                      buttonBackground={buttonBackground}
                      buttonForeground={buttonForeground}
                      data={currentPage}
                      metric={metric}
                      titleForeground={titleForeground}
                      titleSize={titleSize}
                    />
                    {isPaginated && (
                      <Grid spacing={1} justify='center'>
                        <PaginationLink onClick={prev} direction='prev' disabled={!canPrev} />
                        <PaginationLink onClick={next} direction='next' disabled={!canNext} />
                      </Grid>
                    )}
                  </div>
                )}
              </Pagination>
            </div>
          )}
        </Content>
      </Section>
    )
  }
}

PageProgressTiles.defaultProps = {
  limit: 20
}

const mapState = (state) => ({
  ...selectAPIParams(state),
  ...selectCustomSupporterPagePresent(state)
})

export default connect(mapState)(PageProgressTiles)
