import pluralize from 'pluralize'
import { isJustGiving } from '../environment'

export const getFitnessTypes = option => {
  const ride = isJustGiving() ? 'ride' : 'bike'

  switch (option) {
    case 'walk':
      return ['walk']
    case 'run':
      return ['run']
    case 'ride':
      return [ride]
    case 'swim':
      return ['swim']
    case 'hike':
      return ['hike']
    case 'custom':
      return isJustGiving() ? ['walk'] : ['sport']
    case 'run, ride & swim':
      return ['run', ride, 'swim']
    case 'walk, run & ride':
      return ['walk', 'run', ride]
    case 'walk & run':
      return ['walk', 'run']
    case 'walk & hike':
      return ['walk', 'hike']
    default:
      return ['walk', 'run', ride, 'swim', 'manual']
  }
}

export const formatCustomFitnessType = (singular, plural, amount = 0) => {
  const pluralType = plural || pluralize(singular)
  const amountIsSingular = parseInt(amount) === 1

  return amountIsSingular ? singular : pluralType
}
