import React from 'react'
import findLast from 'lodash/findLast'
import { compose } from 'redux'
import { Link, withRouter } from 'react-router'
import withToggle from 'constructicon/with-toggle'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import Buttons from '../../ui/Buttons'
import HeaderContainer from './HeaderContainer'
import Icon from 'constructicon/icon'
import Image from '../../ui/Image'
import Navigation from '../Navigation'

const Header = ({
  background = 'light',
  bannerOverlay,
  classNames,
  ctas,
  foreground = 'dark',
  logo = {},
  logoLink = {},
  logoSize = 'small',
  navigation,
  onToggle,
  onToggleOff,
  shadow,
  sticky,
  poweredBy,
  router,
  styles,
  toggled
}) => {
  const logoHeight = logoSizes[logoSize]
  const isFRP = router.location.pathname.indexOf('/fundraising') === 0
  const filteredCTAs = ctas.filter(cta => {
    if (!isFRP && cta.linkType === 'donate') return cta.url
    return cta.label
  })
  const findCTAByType = type => findLast(filteredCTAs, cta => cta.linkType === type)
  const primaryCTA = isFRP
    ? findCTAByType('donate') || findCTAByType('signup')
    : findCTAByType('signup') || findCTAByType('donate')

  return (
    <HeaderContainer
      background={background}
      foreground={foreground}
      hasShadow={shadow === 'yes'}
      height={logoHeight}
      isNavOpen={toggled}
      isOverlaid={bannerOverlay === 'yes'}
      isSticky={sticky === 'yes'}
      poweredBy={poweredBy}
    >
      <div className={classNames.container}>
        {logoLink.url ? (
          <a className={`ssb-logo ${classNames.logo}`} href={logoLink.url}>
            <Image
              maxHeight={logoHeight}
              image={{ url: logo.url, alt: logo.alt || 'Site logo' }}
            />
          </a>
        ) : (
          <Link className={`ssb-logo ${classNames.logo}`} to='/'>
            <Image
              maxHeight={logoHeight}
              image={{ url: logo.url, alt: logo.alt || 'Site logo' }}
            />
          </Link>
        )}

        <nav className={classNames.nav}>
          <Navigation
            items={navigation}
            onClick={onToggleOff}
          />

          <Buttons
            buttons={filteredCTAs}
            onClick={onToggleOff}
          />
        </nav>

        {primaryCTA && (
          <nav className={classNames.mobileCta}>
            <Buttons
              buttons={[primaryCTA]}
              onClick={onToggleOff}
            />
          </nav>
        )}

        <button
          aria-label='Toggle menu'
          aria-expanded={toggled}
          onClick={onToggle}
          className={classNames.toggle}
        >
          <Icon name={toggled ? 'close' : 'menu'} size={1} />
        </button>
      </div>
    </HeaderContainer>
  )
}

const logoSizes = {
  tiny: 1.5,
  small: 2,
  medium: 3,
  large: 4
}

export default compose(
  withToggle,
  withRouter,
  withStyles(styles)
)(Header)
