import React from 'react'
import numbro from 'numbro'
import { isJustGiving } from '../../../../lib/environment'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import BaseMetric from 'constructicon/metric'
import CountdownMetric from '../CountdownMetric'
import Error from '../../../ui/Error'
import TotalDistance from 'supporticon/components/total-distance'
import TotalDonations from 'supporticon/components/total-donations'
import TotalDuration from 'supporticon/components/total-duration'
import TotalElevation from 'supporticon/components/total-elevation'
import TotalFundsRaised from 'supporticon/components/total-funds-raised'
import TotalSupporters from 'supporticon/components/total-supporters'

const Metric = ({
  activity,
  apiParams = {},
  classNames,
  country,
  customIcon = {},
  format,
  groupValue,
  icon,
  label,
  metric,
  multiplier,
  offset,
  styles,
  uom
}) => {
  const metricComponents = {
    countdown: {
      Component: CountdownMetric,
      label: 'Days Remaining'
    },
    distance: {
      Component: TotalDistance,
      format: '0,0',
      label: 'Distance',
      props: {
        activity: activity ? activity.replace(/\s/g, '').split(',') : undefined,
        miles: uom === 'mi',
        units: !multiplier
      }
    },
    donations: {
      Component: TotalDonations,
      format: '0,0',
      label: 'Donations',
      props: {
        country,
        excludeOffline: apiParams.excludeOffline,
        group: groupValue,
        startDate: null,
        endDate: null
      }
    },
    duration: {
      Component: TotalDuration,
      format: '0,0',
      label: 'Hours',
      props: {
        activity: activity ? activity.replace(/\s/g, '').split(',') : undefined,
        units: !multiplier
      }
    },
    manual: {
      Component: BaseMetric,
      props: {
        amount: numbro(offset).format(format || '0,0'),
        styles
      }
    },
    raised: {
      Component: TotalFundsRaised,
      format: '0,0',
      label: 'Funds Raised',
      props: {
        country,
        currency: format ? format.indexOf('$') > -1 : true,
        excludeOffline: apiParams.excludeOffline,
        group: groupValue,
        startDate: null,
        endDate: null
      }
    },
    supporters: {
      Component: TotalSupporters,
      format: '0,0',
      label: 'Supporters',
      props: {
        country,
        search: !!groupValue,
        group: groupValue
      }
    },
    teams: {
      Component: TotalSupporters,
      format: '0,0',
      label: 'Teams',
      props: {
        country,
        group: groupValue,
        search: !!groupValue,
        type: 'team'
      }
    },
    elevation: {
      Component: TotalElevation,
      format: '0,0',
      label: 'Elevation',
      props: {
        activity: activity ? activity.replace(/\s/g, '').split(',') : undefined,
        includeManual: true,
        miles: uom === 'mi',
        units: !multiplier
      }
    }
  }

  const serializedApiParams = isJustGiving() ? {
    campaign: apiParams.campaignIds,
    charity: apiParams.charityIds,
    event: apiParams.eventIds
  } : {}

  const {
    Component,
    props,
    ...defaults
  } = metricComponents[metric] || {}

  if (!metric) {
    return <Error>Unspecified metric type</Error>
  }

  if (!Component) {
    return <Error>{`Unknown metric type "${metric}"`}</Error>
  }

  const iconValue = customIcon.url ? <img className={classNames.icon} src={customIcon.url} /> : icon !== 'none' ? icon : undefined

  return (
    <Component
      campaign={apiParams.campaignIds}
      charity={apiParams.charityIds}
      endDate={apiParams.endDate}
      event={apiParams.event}
      format={format || defaults.format}
      icon={iconValue}
      label={label || defaults.label}
      metric={{ styles }}
      multiplier={multiplier || 1}
      offset={offset}
      refreshInterval={apiParams.refreshInterval}
      startDate={apiParams.startDate}
      {...serializedApiParams}
      {...props}
    />
  )
}

export default withStyles(styles)(Metric)
