import moment from 'moment'
import find from 'lodash/find'
import first from 'lodash/first'
import isEmpty from 'lodash/isEmpty'
import { prismicRichTextAsHTML } from 'prismic-utils'
import { deserializePage, fetchPage, fetchPages } from 'supporticon/api/pages'
import { deserializeLeaderboard, fetchLeaderboard } from 'supporticon/api/leaderboard'
import { fetchCampaignData } from '../../../lib/campaigns'
import { isJustGiving } from '../../../lib/environment'
import c from '../constants'

export const fetchProjects = ({ items }, campaign) => {
  const projects = filterActiveProjects(items)

  return isJustGiving()
    ? fetchJustgivingProjects(projects, campaign)
    : fetchEverydayheroProjects(projects)
}

// JustGiving
const fetchJustgivingProjects = (projects, campaign) => dispatch =>
  Promise.all(
    projects.map(({ type, uid }) =>
      type === 'campaign'
        ? fetchCampaignData(uid)
        : fetchPage(uid).then(deserializePage)
    )
  )
    .then(data => mergeProjectData(data, projects))
    .then(data => dispatch({ type: c.SUCCESS, payload: { data } }))
    .catch(error => {
      dispatch({ type: c.FAILURE })
      return Promise.reject(error)
    })

// Everydayhero
export const fetchEverydayheroProjects = projects => dispatch => {
  const projectIDs = projects.map(project => project.uid)

  if (projects.map(({ type }) => type).indexOf('campaign') > -1) {
    throw new Error('Projects on Everydayhero must be based on pages only')
  }

  return fetchPages({ allPages: true, ids: projectIDs.join(',') })
    .then(pages => fetchEverydayheroDonations(pages))
    .then(pages => mergeProjectData(pages, projects))
    .then(data => dispatch({ type: c.SUCCESS, payload: { data, projectIDs } }))
    .catch((error) => {
      dispatch({ type: c.FAILURE })
      return Promise.reject(error)
    })
}

const fetchEverydayheroDonations = pages => {
  const firstPage = first(pages)
  const isTeam = firstPage && firstPage.owner_type === 'Team'

  const ids = isTeam
    ? pages.map(page => page.team_member_uids.join(','))
    : pages.map(page => page.id)

  const params = {
    group_by: isTeam ? 'teams' : 'pages',
    page_ids: ids.filter(Boolean).join(',')
  }

  return fetchLeaderboard(params)
    .then(leaders => pages.map(page => {
      const leader = find(leaders, leader => (leader.page ? leader.page.id : leader.team.team_page_id) === page.id)
      return {
        ...leader && deserializeLeaderboard(leader),
        ...deserializePage(page),
        raised: page.amount.cents / 100,
        target: page.target_cents / 100
      }
    }))
}

const mergeProjectData = (pages, projects) =>
  projects.map(project => {
    const page = find(pages, page => project.uid === String(page.id) || project.uid === page.slug)

    return {
      ...project,
      ...page,
      target: project.overrideTarget || page.target,
      totalDonations: page.totalDonations || 0,
      name: project.overridePageName || page.name,
      story: isEmpty(project.overridePageStory)
        ? page.story || page.summary
        : prismicRichTextAsHTML(project.overridePageStory),
      image: isEmpty(project.overridePageImage)
        ? page.image
        : project.overridePageImage.url,
      status: 'fetched',
      url: project.url || page.url
    }
  })

const filterActiveProjects = (projects = []) => (
  projects.filter(({
    endDate,
    startDate
  }) => {
    const isStarted = !startDate || moment(startDate).isBefore()
    const isNotFinished = !endDate || moment(endDate).endOf('day').isAfter()
    return isStarted && isNotFinished
  })
)
