import React from 'react'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

const SuccessMessage = ({
  children,
  classNames,
  visible
}) => {
  if (!visible) return null

  return (
    <div className={classNames.root}>
      {children}
    </div>
  )
}

export default withStyles(styles)(SuccessMessage)
