import React from 'react'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import Button from '../../../ui/Button'
import LinkHandler from '../../../utils/LinkHandler'
import Title from '../../../ui/Title'

const Resource = ({
  button,
  buttonBackground,
  buttonForeground,
  classNames,
  styles,
  title,
  resource = {}
}) => (
  <div>
    <LinkHandler to={resource.url}>
      <div className={classNames.tile}>
        <Title styles={styles.title}>{title}</Title>
      </div>
    </LinkHandler>
    <Button
      background={buttonBackground}
      foreground={buttonForeground}
      to={resource.url}>
      {button || 'Download'}
    </Button>
  </div>
)

export default withStyles(styles)(Resource)
