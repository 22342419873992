import lodashGet from 'lodash/get'

export default (props, traits) => {
  const banner = lodashGet(props, 'customSupporterPage.banner.url')
  const { mediaQuery, rhythm } = traits

  return {
    banner: {
      maxHeight: rhythm(20),
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    },

    image: {
      flexShrink: 0,
      width: '100%'
    },

    sidebar: {
      a: {
        maxWidth: '20em',
        width: '100%'
      },
      marginTop: banner && rhythm(-3),
      [mediaQuery('md')]: {
        marginTop: banner && rhythm(-7)
      },
      [mediaQuery('lg')]: {
        marginTop: banner && rhythm(-10)
      },

      '> div > * + *': {
        margin: rhythm([1.2, 0])
      },

      img: {
        maxWidth: '20em',
        margin: 'auto'
      }
    },

    main: {
      '> div > * + *': {
        margin: rhythm([2, 0])
      }
    }
  }
}
