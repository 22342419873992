export default (props, traits) => {
  const { rhythm } = traits

  return {
    select: {
      minHeight: rhythm(10),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column'
    },

    form: {
      submit: {
        display: 'inline-block',
        width: 'auto',

        '& svg': {
          display: 'none'
        }
      }
    }
  }
}
