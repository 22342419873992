import React from 'react'
import withStyles from 'constructicon/with-styles'
import styles from './styles'
import Container from 'constructicon/container'
import Content from '../../ui/Content'

const DynamicBanner = ({
  backgroundImage = {},
  classNames,
  copy,
  items,
  logo = {},
  subtitle,
  title,
  titleForeground,
  video = {}
}) => {
  const hasValue = obj => (
    Object.keys(obj).some(item => !!obj[item])
  )

  return (
    <div className={classNames.root}>
      <Container width={20}>
        <div className={classNames.overlay}>
          {hasValue(video) && (
            <div className={classNames.videoContainer}>
              <video
                autoPlay
                className={classNames.video}
                loop
                muted
                playsInline
                poster={backgroundImage.url}
              >
                <source src={video.mp4} type='video/mp4' />
                <source src={video.webm} type='video/webm' />
                <source src={video.ogv} type='video/ogg' />
              </video>
            </div>
          )}
          <div className={classNames.content}>
            {logo.url && (
              <img
                src={logo.url}
                alt={logo.alt || 'Banner logo'}
                className={classNames.logo}
              />
            )}
            <Content
              buttons={items}
              copy={copy}
              subtitle={subtitle}
              title={title}
              titleForeground={titleForeground}
            />
          </div>
        </div>
      </Container>
    </div>
  )
}

export default withStyles(styles)(DynamicBanner)
