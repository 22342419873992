import React from 'react'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import LinkHandler from '../../../utils/LinkHandler'

const Links = ({
  classNames,
  items = []
}) => (
  <nav>
    <ul className={classNames.root}>
      {items.map((item, i) => (
        <li key={i}>
          <LinkHandler
            linkType={item.linkType}
            to={item.url}>
            {item.label}
          </LinkHandler>
        </li>
      ))}
    </ul>
  </nav>
)

export default withStyles(styles)(Links)
