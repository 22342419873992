import React from 'react'
import isEmpty from 'lodash/isEmpty'
import { connect } from 'react-redux'
import { isJustGiving } from '../../../lib/environment'
import { selectAPIParams, selectLocalisation } from '../../../store/site'

import Content from '../../ui/Content'
import Metric from './Metric'
import MetricGroup from '../../ui/MetricGroup'
import Section from '../../ui/Section'

const Metrics = ({
  activity,
  alignment,
  amountColor,
  amountFont,
  apiParams,
  backgroundImage,
  background,
  campaign,
  charity,
  copy,
  copyAlignment,
  country,
  direction,
  event,
  foreground,
  groupValue,
  iconColor,
  iconSize,
  items = [],
  labelColor,
  layout,
  metricAlignment,
  numberStyle,
  size,
  spacing,
  subtitle,
  title,
  titleForeground,
  uom
}) => (
  <Section
    alignment={alignment}
    backgroundImage={backgroundImage}
    background={background}
    foreground={foreground}
    size={size}
    spacing={spacing}>

    <Content
      copy={copy}
      copyAlignment={copyAlignment}
      layout={layout}
      subtitle={subtitle}
      title={title}
      titleForeground={titleForeground}>

      {!isEmpty(items) && (
        <MetricGroup
          direction={direction}
          metricAlignment={metricAlignment}
        >
          {items.map((item, index) => (
            <Metric
              activity={item.activity}
              amountColor={amountColor}
              amountFont={amountFont}
              apiParams={{
                campaign: campaign || apiParams.campaignIds,
                campaignIds: campaign ? campaign.split(',') : apiParams.campaignIds,
                charity: charity || apiParams.charityIds,
                charityIds: charity ? charity.split(',') : apiParams.charityIds,
                endDate: apiParams.endDate,
                event: event || apiParams.event,
                eventIds: event ? event.split(',') : apiParams.eventIds,
                excludeOffline: apiParams.excludeOffline,
                refreshInterval: apiParams.refreshInterval,
                startDate: apiParams.startDate
              }}
              country={isJustGiving() ? country : undefined}
              customIcon={item.customIcon}
              format={item.format}
              groupValue={groupValue}
              icon={item.icon}
              iconColor={iconColor}
              iconSize={iconSize}
              key={index}
              label={item.label}
              labelColor={labelColor}
              metric={item.metric}
              offset={item.offset}
              multiplier={item.multiplier}
              numberStyle={numberStyle}
              uom={uom}
            />
          ))}
        </MetricGroup>
      )}

    </Content>
  </Section>
)

const mapState = (store) => ({
  ...selectAPIParams(store),
  ...selectLocalisation(store)
})

export default connect(mapState)(Metrics)
