import get from 'lodash/get'
import find from 'lodash/find'
import first from 'lodash/first'

const doesPageSatisfySegmentation = (segmentation = [], page) => {
  const matched = segmentation.filter(segment => {
    if (!segment.key) return true

    return page.segmentation[segment.key] === segment.value
  })

  return matched.length === segmentation.length
}

export const getSegmentedCustomSupporterPage = (customSupporterPages, page) => {
  const matchedDocument = find(
    customSupporterPages,
    supporterPage =>
      get(supporterPage, 'segmentation.isSegmented') &&
      doesPageSatisfySegmentation(get(supporterPage, 'segmentation.segments', []), page)
  )

  return matchedDocument || getDefaultCustomSupporterPage(customSupporterPages)
}

export const getDefaultCustomSupporterPage = (customSupporterPages) => {
  const defaultDocument = find(
    customSupporterPages,
    supporterPage => !get(supporterPage, 'segmentation.isSegmented')
  )

  return defaultDocument || first(customSupporterPages)
}
