import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { selectCustomSupporterPagePresent } from '../../../../store/customSupporterPages'
import { selectProjects, selectProjectsStatus } from '../../../../store/projects'
import { selectButtonDefaults } from '../../../../store/site'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import Error from '../../../ui/Error'
import Loading from '../../../ui/Loading'
import SearchForm from 'constructicon/search-form'
import SearchResult from '../SearchResult'
import SearchResults from 'constructicon/search-results'

class ProjectSearch extends Component {
  constructor () {
    super()
    this.state = {
      query: ''
    }
  }

  filterProjects () {
    const { projects = [] } = this.props
    const { query } = this.state

    return projects.filter((project) => (
      project.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    ))
  }

  render () {
    const {
      action,
      buttonBackground,
      buttonForeground,
      buttonProps,
      classNames,
      hasCustomSupporterPages,
      label,
      placeholder,
      status,
      styles
    } = this.props

    const { query } = this.state
    const projects = this.filterProjects()

    return (
      <div>
        {status === 'fetching' && <Loading />}
        {status === 'failed' && <Error>There was an error loading current projects.</Error>}
        {status === 'fetched' && (
          <div className={classNames.root}>
            <SearchForm
              button={{
                ...buttonProps,
                background: buttonBackground,
                foreground: buttonForeground
              }}
              expanded
              onChange={(q) => this.setState({ query: q })}
              placeholder={placeholder || 'Search for a project here'}
              title={label || 'Find a project'}
              styles={styles.searchForm}
            />
            {query && (
              <div className={classNames.results}>
                <SearchResults empty={!projects.length}>
                  {projects.map((project, index) => {
                    const url = project.type === 'page' && hasCustomSupporterPages ? `/fundraising/${project.slug}` : project.url

                    return (
                      <SearchResult
                        action={action}
                        buttonBackground={buttonBackground}
                        buttonForeground={buttonForeground}
                        donationUrl={project.donateUrl || project.donationUrl}
                        key={index}
                        name={project.name}
                        url={url}
                      />
                    )
                  })}
                </SearchResults>
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}

const mapState = (state) => ({
  projects: selectProjects(state),
  status: selectProjectsStatus(state),
  buttonProps: selectButtonDefaults(state),
  ...selectCustomSupporterPagePresent(state)
})

export default compose(
  connect(mapState),
  withStyles(styles)
)(ProjectSearch)
