import React from 'react'
import moment from 'moment'
import flatten from 'lodash/flatten'
import { isRichTextEmpty } from '../../../lib/richText'
import { isJustGiving } from '../../../lib/environment'
import { getFitnessTypes } from '../../../lib/fitness'
import { deserializeDonation, fetchDonationFeed } from 'supporticon/api/feeds'
import { deserializeFitnessActivity, fetchFitnessActivities, deleteFitnessActivity } from 'supporticon/api/fitness-activities'
import { deserializePost, fetchPosts, deletePost } from 'supporticon/api/posts'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import Activity from './Activity'
import BaseSection from 'constructicon/section'
import Button from '../../ui/Button'
import ButtonGroup from 'constructicon/button-group'
import CreateFitness from './CreateFitness'
import CreatePost from './CreatePost'
import Error from '../../ui/Error'
import Loading from '../../ui/Loading'
import Pagination from 'constructicon/pagination'
import RichText from '../../ui/RichText'
import Section from '../../ui/Section'

class Feed extends React.Component {
  constructor () {
    super()
    this.handleRemove = this.handleRemove.bind(this)
    this.state = {
      data: [],
      status: 'fetching'
    }
  }

  componentDidMount () {
    this.handleFetchActivities()
  }

  handleFetchActivities () {
    this.fetchActivities()
      .then(data => this.sortActivities(data))
      .then(data => this.setState({ status: 'fetched', data }))
      .catch(error => {
        this.setState({ status: 'failed' })
        return Promise.reject(error)
      })
  }

  handleRemove (id, type, options) {
    const { fundraisingPage, user: { token } } = this.props

    const deleteMethod = () =>
      type === 'fitness'
        ? deleteFitnessActivity({ id, page: fundraisingPage.slug, token, ...options })
        : deletePost({ id, token })

    return deleteMethod()
      .then(() =>
        this.setState({
          data: this.state.data.filter(item => (item.id || item.legacyId) !== id)
        })
      )
  }

  fetchActivities () {
    const { fitnessTypes, fundraisingPage: { id, slug } } = this.props

    const fetchers = {
      donations: () =>
        fetchDonationFeed({ page: id, pageShortName: slug, includeOffline: true }).then(data =>
          data
            .map(activity => ({
              ...deserializeDonation(activity),
              offline: activity.kind === 'offline_donation',
              activityType: 'donation'
            }))
        ),
      fitness: () =>
        fetchFitnessActivities({
          page: isJustGiving() ? slug : id,
          include_manual: true,
          cache_clear: new Date().toISOString(),
          useLegacy: isJustGiving()
        })
          .then(data =>
            data.map(activity => ({
              ...deserializeFitnessActivity(activity),
              activityType: 'fitness'
            }))
          )
          .then(data =>
            data.filter(
              activity =>
                getFitnessTypes(fitnessTypes).indexOf(
                  activity.type.toLowerCase()
                ) > -1
            )
          ),
      posts: () =>
        fetchPosts({ page: id, slug, allPosts: true }).then(data =>
          data.map(post => ({
            ...deserializePost(post),
            activityType: 'post'
          }))
        )
    }

    return Promise.all(
      this.activityTypes().map(type => fetchers[type]())
    ).then(flatten)
  }

  sortActivities (data) {
    return data.sort((a, b) =>
      moment(a.createdAt).isBefore(b.createdAt)
        ? 1
        : moment(b.createdAt).isBefore(a.createdAt)
        ? -1
        : 0
    )
  }

  activityTypes () {
    switch (this.props.types) {
      case 'donations':
        return ['donations']
      case 'fitness':
        return ['fitness']
      case 'posts':
        return ['posts']
      case 'donations & fitness':
        return ['donations', 'fitness']
      case 'donations & posts':
        return ['donations', 'posts']
      case 'fitness & posts':
        return ['fitness', 'posts']
      default:
        return ['donations', 'fitness', 'posts']
    }
  }

  render () {
    const { data, status } = this.state
    const {
      alignment,
      background,
      classNames,
      createFitness,
      createPost,
      customFitnessType,
      customFitnessTypePlural,
      fitnessTypes,
      foreground,
      fundraisingPage,
      isPageOwner,
      pageSize,
      spacing,
      styles,
      title,
      user
    } = this.props

    const isCustom = fitnessTypes === 'custom'

    return (
      <Section
        alignment={alignment}
        background={background}
        foreground={foreground}
        spacing={spacing}
      >
        <header className={classNames.header}>
          {!isRichTextEmpty(title) && (
            <RichText styles={styles.title}>
              {title}
            </RichText>
          )}
          {isPageOwner && (
            <nav>
              {createFitness && (
                <CreateFitness
                  customFitnessType={customFitnessType}
                  customFitnessTypePlural={customFitnessTypePlural}
                  fitnessTypes={getFitnessTypes(fitnessTypes)}
                  isCustom={isCustom}
                  onSuccess={() => this.handleFetchActivities()}
                  page={fundraisingPage}
                  user={user}
                />
              )}
            </nav>
          )}
        </header>
        {status === 'fetching' && <Loading />}
        {status === 'failed' && (
          <Error>There was an error loading the feed</Error>
        )}
        {status === 'fetched' && (
          <div>
            {data.length > 0 ? (
              <Pagination max={pageSize || 10} toPaginate={data}>
                {paginated => (
                  <div key={paginated.pageNumber}>
                    <ol className={classNames.activities}>
                      {paginated.currentPage.map(item => (
                        <Activity
                          {...item}
                          key={item.id || item.legacyId}
                          customFitnessType={customFitnessType}
                          customFitnessTypePlural={customFitnessTypePlural}
                          isCustom={isCustom}
                          isPageOwner={isPageOwner}
                          onRemove={this.handleRemove}
                          page={fundraisingPage}
                          user={user}
                        />
                      ))}
                    </ol>
                    {paginated.isPaginated && (
                      <ButtonGroup align='center'>
                        <Button
                          onClick={paginated.prev}
                          background='tertiary'
                          styles={{
                            opacity: paginated.canPrev ? '1' : '0.5',
                            cursor: paginated.canPrev ? 'pointer' : 'initial'
                          }}
                        >
                          Prev
                        </Button>
                        <Button
                          onClick={paginated.next}
                          background='tertiary'
                          styles={{
                            opacity: paginated.canNext ? '1' : '0.5',
                            cursor: paginated.canNext ? 'pointer' : 'initial'
                          }}
                        >
                          Next
                        </Button>
                      </ButtonGroup>
                    )}
                  </div>
                )}
              </Pagination>
            ) : (
              <div className={classNames.footer}>
                <BaseSection spacing={{ b: 0.75 }}>
                  {this.activityTypes().indexOf('donations') > -1
                    ? `No one has donated to ${isPageOwner ? 'you' : fundraisingPage.name} yet.`
                    : `${isPageOwner ? 'You have' : [fundraisingPage.name, ' has'].join()} no activities yet.`
                  }
                </BaseSection>
                <Button
                  href={fundraisingPage.donationUrl}
                  tag='a'
                  target='_blank'
                >
                  Donate to {isPageOwner ? 'your page' : fundraisingPage.name}
                </Button>
              </div>
            )}
          </div>
        )}
        {createPost && isPageOwner && (
          <CreatePost
            page={fundraisingPage}
            user={user}
            onSuccess={() => this.handleFetchActivities()}
          />
        )}
      </Section>
    )
  }
}

export default withStyles(styles)(Feed)
