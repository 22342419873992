import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { stringify } from 'querystringify'
import { selectReportsParams } from '../../../store/site'
import { clearAdminUser, selectAdminUser } from '../../../store/session'
import client from 'supporticon/utils/client'
import withStyles from 'constructicon/with-styles'
import styles from './styles'
import withForm from 'constructicon/with-form'
import form from './form'

import Button from '../../ui/Button'
import Heading from 'constructicon/heading'
import InputDate from 'constructicon/input-date'
import InputField from 'constructicon/input-field'
import Section from 'constructicon/section'

const AdminReports = ({
  classNames,
  clearAdminUser,
  children,
  eventId,
  form = {},
  router,
  styles,
  user = {}
}) => {
  const [event, setEvent] = useState()

  useEffect(() => {
    if (!user.token) {
      router && router.push('/admin/login')
    }

    if (eventId) {
      client.get(`v1/event/${eventId}`)
        .then(setEvent)
    }
  }, [user])

  const reportUrl = (type = 'csv') => {
    const params = stringify({
      access_token: user.token,
      start_date: form.values.startDate,
      end_date: form.values.endDate,
      active: form.values.activeOnly
    })

    return `/admin/reports/registration.${type}?${params}`
  }

  return (
    <Section spacing={{ t: 0, x: 1, b: 1.5 }} styles={styles.root}>
      <Heading size={0}>{event && event.name} Event Report</Heading>

      <form className={classNames.form} onSubmit={e => e.preventDefault()}>
        <InputDate
          {...form.fields.startDate}
          min={form.values.endDate && moment(form.values.endDate).subtract(3, 'months').format(moment.HTML5_FMT.DATE)}
        />
        <InputDate
          {...form.fields.endDate}
          max={form.values.startDate && moment(form.values.startDate).add(3, 'months').format(moment.HTML5_FMT.DATE)}
        />
        <InputField {...form.fields.activeOnly} styles={styles.checkbox} />
      </form>

      <nav className={classNames.buttons}>
        <Button tag='a' href={reportUrl('csv')} block target='_blank'>
          Download CSV
        </Button>
        <Button tag='a' href={reportUrl('json')} block target='_blank'>
          Download JSON
        </Button>
      </nav>

      <Button background='transparent' foreground='dark' onClick={() => clearAdminUser()}>Log out</Button>
    </Section>
  )
}

const mapState = (state) => ({
  ...selectReportsParams(state),
  user: selectAdminUser(state)
})

const mapDispatch = { clearAdminUser }

export default compose(
  connect(mapState, mapDispatch),
  withStyles(styles),
  withForm(form)
)(AdminReports)
