import React from 'react'
import Button from '../../../ui/Button'

const SelfDonate = ({
  fundraisingPage
}) => (
  <div>
    <Button
      href={fundraisingPage.donationUrl}
      tag='a'>
      Donate to your page
    </Button>
  </div>
)

export default SelfDonate
