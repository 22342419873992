import React from 'react'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import FlatModal from '../../../ui/FlatModal'
import RichText from 'constructicon/rich-text'

const cookies = [
  { name: '_hp2_id.APP_ID ', provider: 'Heap', purpose: 'User cookie (stores user_id, identity, other ids)', expiry: '13 months' },
  { name: '_hp2_ses_props.APP_ID', provider: 'Heap', purpose: 'Session properties cookie (stores timestamp and cookie domain/path)', expiry: '30 minutes' },
  { name: '_hp2_props.APP_ID', provider: 'Heap', purpose: 'Event properties cookie (stores properties set by addEventProperties API)', expiry: '13 months' },
  { name: '_hp2_hld.*', provider: 'Heap', purpose: 'Used to determine which domain a cookie can be set on (since public suffix domains block setting cookies on the top level)', expiry: 'Should not persist' },
  { name: '_ga', provider: 'Google', purpose: 'Used to distinguish users.', expiry: '2 years' },
  { name: '_gid', provider: 'Google', purpose: 'Used to distinguish users.', expiry: '24 hours' },
  { name: '_ga_<container-id>', provider: 'Google', purpose: 'Used to persist session state.', expiry: '2 years' }
]

const Preferences = ({
  classNames,
  router,
  styles
}) => {
  return (
    <FlatModal
      contentLabel='Powered by JustGiving Cookie Policy'
      isOpen
      onRequestClose={() => router.push('/')}
      styles={styles.modal}>
      <div className={classNames.root}>
        <RichText>
          <h1>Powered by JustGiving Cookie Policy</h1>
          <h3>What is a cookie?</h3>
          <p>A cookie is a small file, typically of letters and numbers, stored by your internet browser. Cookies help us provide you with a good experience on our site and allow us to make it more efficient.</p>
          <p>In accordance with the Privacy and Electronic Communications Regulations, we’re asking you to consent to the way we use cookies.</p>
          <h3>Our Policy</h3>
          <p>Cookies are small text files that can be used by websites to make a user's experience more efficient.</p>
          <p>The law states that we can store cookies on your device if they are strictly necessary for the operation of this site. For all other types of cookies we need your permission.</p>
          <p>This site uses different types of cookies. Some cookies are placed by third party services that appear on our pages.</p>
          <p>You can at any time change or withdraw your consent from the Cookie Declaration by visiting <a href='/?cookies=preferences'>My Preferences</a> next to the cookie policy at the bottom of this site.</p>
          <p>Your consent only applies to the Powered by JustGiving website that you are visiting. We will ask for your consent each time you visit a Powered by JustGiving website. You can change your preferences on each of our sites as outlined above. The <a href='www.justgiving.com' target='_blank'>www.justgiving.com</a> website uses a different cookie declaration. You can find out more on how we use cookies on JustGiving and how you can withdraw your consent <a href='https://www.justgiving.com/info/cookies' target='_blank'>here</a>.</p>
          <p>Learn more about who we are, how you can contact us and how we process personal data in our Privacy Policy.</p>
          <h3>How we use cookies</h3>
          <p><strong>Marketing</strong>: Marketing cookies are used to track visitors across websites. The intention is to display ads that are relevant and engaging for the individual user and thereby more valuable for publishers and third party advertisers.</p>
          <p><strong>Performance:</strong>: Statistic cookies help website owners to understand how visitors interact with websites by collecting and reporting information.</p>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Provider</th>
                <th>Purpose</th>
                <th>Expiry</th>
              </tr>
            </thead>
            <tbody>
              {cookies.map(cookie => (
                <tr key={cookie.name}>
                  <td>{cookie.name}</td>
                  <td>{cookie.provider}</td>
                  <td>{cookie.purpose}</td>
                  <td>{cookie.expiry}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </RichText>
      </div>
    </FlatModal>
  )
}

export default withStyles(styles)(Preferences)
