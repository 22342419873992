export default (props, { colors, rhythm, mediaQuery }) => ({
  posts: {
    margin: rhythm([0, -0.75]),
    display: 'flex',
    flexWrap: 'wrap'
  },
  post: {
    padding: rhythm(0.75),
    width: '100%',
    maxWidth: rhythm(18),
    marginLeft: 'auto',
    marginRight: 'auto',
    [mediaQuery('sm')]: {
      width: '50%'
    },
    [mediaQuery('md')]: {
      width: '33%'
    },
    img: {
      display: 'block',
      width: '100%'
    }
  },
  card: {
    textAlign: 'left',
    padding: rhythm(1),
    background: colors.light,
    color: colors.dark,
    wordBreak: 'break-word'
  },
  caption: {
    paddingTop: rhythm(0.5)
  }
})
