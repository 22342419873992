import React from 'react'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import Button from '../../../../ui/Button'
import Section from 'constructicon/section'

const TeamDetails = ({
  styles,
  team
}) => (
  <Section
    styles={styles.root}
    spacing={{ y: 3 }}>
    <p>You are a part of <strong>{team.name}</strong></p>
    <Section spacing={{ t: 0.5 }}>
      <Button to={`/teams/${team.slug}`}>
        {team.name}
      </Button>
    </Section>
  </Section>
)

export default withStyles(styles)(TeamDetails)
