import { createSelector } from 'reselect'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'
import orderBy from 'lodash/orderBy'

import { selectDataRequirements } from '../../site/selectors'

export const selectProjectsStatus = (state) => state.projects.status
export const selectProjectIDs = (state) => state.projects.projectIDs
export const selectProjects = (state) => state.projects.data
export const selectInitialProjects = (state) => selectDataRequirements(state)

export const selectInitialProjectsContent = createSelector(
  [ selectInitialProjects ],
  (data) => get(data, 'projects.items', [])
)

// projects in the order they were entered in the CMS
export const selectProjectsByOrder = createSelector(
  [ selectProjects, selectProjectIDs ],
  (projects, projectIDs = []) => sortBy(projects, (project) => (
    projectIDs.indexOf(project.id ? project.id.toString() : project.slug)
  ))
)

// projects in order of funds raised
export const selectProjectsByRaised = createSelector(
  [ selectProjects ],
  (projects) => orderBy(projects, [ 'raised' ], [ 'desc' ])
)

// projects in order of number of donations
export const selectProjectsByDonations = createSelector(
  [ selectProjects ],
  (projects) => orderBy(projects, [ 'totalDonations' ], [ 'desc' ])
)
