import React, { useEffect, useState } from 'react'
import { deserializeTeam, fetchTeam, joinTeam } from 'supporticon/api/teams'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import Button from '../../ui/Button'
import Heading from 'constructicon/heading'
import Loading from '../../ui/Loading'
import Modal from '../../ui/FlatModal'
import Section from 'constructicon/section'

const ConfirmTeamModal = ({
  campaign,
  classNames,
  modal,
  pageId,
  pageSlug,
  onSuccess,
  styles,
  teamId,
  token
}) => {
  const [team, setTeam] = useState()
  const [status, setStatus] = useState()

  useEffect(() => {
    fetchTeam(teamId)
      .then(team => deserializeTeam(team))
      .then(team => setTeam(team))
  }, [teamId])

  return (
    <Modal
      contentLabel='Create team'
      styles={styles.modal}
      {...modal}>
      <Heading>Join a team</Heading>
      {team ? (
        <div>
          <div className={classNames.message}>You indicated you would like to join the following team</div>
          <div className={classNames.team}>
            {team.image && (
              <div>
                <img src={team.image} alt={team.name} />
              </div>
            )}
            <div>
              {team.name}
            </div>
          </div>
          <Button
            disabled={status === 'fetching'}
            onClick={() => Promise.resolve()
              .then(() => setStatus('fetching'))
              .then(() => joinTeam({
                campaign,
                pageId,
                pageSlug,
                token,
                id: team.owner || team.id,
                page: pageId,
                teamId: team.id,
                teamSlug: team.slug
              }))
              .then(() => onSuccess(team))
              .then(() => setStatus('fetched'))}>
            {status === 'fetching' ? 'Joining' : 'Confirm'}
          </Button>
        </div>
      ) : (
        <Section spacing={2}>
          <Loading />
        </Section>
      )}
    </Modal>
  )
}

export default withStyles(styles)(ConfirmTeamModal)
