import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { selectLocalisation } from '../../../store/site'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import MetricGroup from '../../ui/MetricGroup'
import Distance from './Distance'
import Duration from './Duration'
import Elevation from './Elevation'
import Raised from './Raised'
import Section from '../../ui/Section'
import Target from './Target'

const Metrics = ({
  alignment,
  amountColor,
  background,
  direction,
  foreground,
  fundraisingPage,
  iconColor,
  iconSize,
  items,
  labelColor,
  metricAlignment,
  numberStyle,
  spacing,
  uom
}) => {
  return (
    <Section
      alignment={alignment}
      background={background}
      foreground={foreground}
      spacing={spacing}
    >
      <MetricGroup
        direction={direction}
        metricAlignment={metricAlignment}>
        {items.map((item, index) => {
          const metrics = {
            distance: Distance,
            duration: Duration,
            elevation: Elevation,
            raised: Raised,
            target: Target
          }

          const Metric = metrics[item.metric]

          const icon = item.icon !== 'none' ? item.icon : undefined
          const iconValue = item.customIcon.url ? <img {...item.customIcon} /> : icon

          return (
            <Metric
              key={index}
              amountColor={amountColor}
              customIcon={item.customIcon}
              format={item.format}
              fundraisingPage={fundraisingPage}
              icon={iconValue}
              iconColor={iconColor}
              iconSize={iconSize}
              label={item.label}
              labelColor={labelColor}
              offset={item.offset}
              multiplier={item.multiplier}
              numberStyle={numberStyle}
              uom={uom}
            />
          )
        })}
      </MetricGroup>
    </Section>
  )
}

const mapStateToProps = (state) => ({
  ...selectLocalisation(state)
})

export default compose(
  connect(mapStateToProps),
  withStyles(styles)
)(Metrics)
