export default (props, {
  colors,
  rhythm
}) => ({
  root: {
    padding: `${rhythm(0.5)} ${rhythm(1)}`,

    '&:nth-child(even)': {
      backgroundColor: colors.shade
    }
  },

  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexPack: 'justify',
    margin: rhythm(-0.5),

    '& > *': {
      margin: rhythm(0.5)
    }
  }
})
