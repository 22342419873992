import { deserializeCampaign, fetchCampaign } from 'supporticon/api/campaigns'
import { fetchDonationTotals, deserializeDonationTotals } from 'supporticon/api/donation-totals'
import { isJustGiving } from '../environment'

export const fetchCampaignData = campaign =>
  isJustGiving()
    ? Promise.all([
      fetchCampaign(campaign).then(deserializeCampaign),
      fetchDonationTotals({ campaign, includeOffline: true }).then(
        deserializeDonationTotals
      )
    ]).then(([campaignData, campaignTotals]) => ({
      ...campaignData,
      ...campaignTotals
    }))
    : fetchCampaign(campaign).then(deserializeCampaign)
