import React from 'react'
import { connect } from 'react-redux'
import { selectPage, selectPageMeta } from '../../store/pages'

import NotFound from '../../components/container/NotFound'
import Head from '../../components/utils/Head'
import SliceProcessor from '../../components/utils/SliceProcessor'
import Theme from '../../components/utils/Theme'
import UserMenu from '../../components/container/UserMenu'

const Page = ({
  page = {},
  meta = { title: 'Not Found' }
}) => (
  <Theme>
    <div>
      <Head {...meta} />
      {page.content
        ? (
          <SliceProcessor
            meta={meta}
            page={page.slug}
            slices={page.content}
          />
        ) : <NotFound />}
      <UserMenu />
    </div>
  </Theme>
)

const mapState = (store, props) => ({
  page: selectPage(store, props),
  meta: selectPageMeta(store, props)
})

export default connect(mapState)(Page)
