import * as validators from 'constructicon/lib/validators'

export default () => {
  return {
    fields: {
      image: {
        validators: [
          validators.required('Please select an image')
        ]
      }
    }
  }
}
