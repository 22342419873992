import React from 'react'
import Content from '../../ui/Content'
import JuicerFeed from './JuicerFeed'
import Section from '../../ui/Section'

const SocialFeedJuicer = ({
  alignment,
  backgroundImage,
  background,
  foreground,
  limit,
  size,
  spacing,
  subtitle,
  title,
  titleForeground,
  feedSlug
}) => (
  <Section
    alignment={alignment}
    backgroundImage={backgroundImage}
    background={background}
    foreground={foreground}
    size={size}
    spacing={spacing}>

    <Content
      subtitle={subtitle}
      title={title}
      titleForeground={titleForeground}>

      <JuicerFeed
        limit={limit}
        slug={feedSlug}
      />

    </Content>
  </Section>
)

export default SocialFeedJuicer
