import React, { Component } from 'react'
import kebabCase from 'lodash/kebabCase'
import find from 'lodash/find'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { selectCustomSupporterPagePresent } from '../../../store/customSupporterPages'
import { selectAPIParams, selectLocalisation } from '../../../store/site'
import { selectCustomTagPages } from '../../../store/customTagPages'
import { generateAvatar } from '../../../lib/avatar'
import { deserializeFitnessLeaderboard } from 'supporticon/api/fitness-leaderboard'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import Content from '../../ui/Content'
import FitnessLeaderboard from 'supporticon/components/fitness-leaderboard'
import Section from '../../ui/Section'

export class FitnessLeaderboards extends Component {
  constructor () {
    super()
    this.deserializeLeaderboard = this.deserializeLeaderboard.bind(this)
    this.state = {
      tab: 0
    }
  }

  deserializeLeaderboard (item, index, { groupUrl, tagId, type }) {
    const deserialized = deserializeFitnessLeaderboard(item, index)

    if (type === 'group' && groupUrl) {
      const { customTagPages = [] } = this.props
      const customTagPage = find(customTagPages, ({ slug }) => slug === tagId)

      return {
        ...deserialized,
        image: customTagPage ? generateAvatar(kebabCase(deserialized.name), customTagPage.avatarOption) : null,
        url: [groupUrl, kebabCase(deserialized.name)].join('')
      }
    } else if (this.props.hasCustomSupporterPages) {
      return {
        ...deserialized,
        url: `/${type === 'team' ? 'teams' : 'fundraising'}/${deserialized.slug}`
      }
    }

    return deserialized
  }

  render () {
    const {
      activity,
      alignment,
      apiParams,
      background,
      backgroundImage,
      campaign,
      charity,
      classNames,
      copy,
      copyAlignment,
      country,
      endDate,
      foreground,
      hideCharity,
      includeFilter = 'Yes',
      includeManual,
      items = [{ type: 'individual' }],
      layout,
      pageSize = 20,
      size,
      sortBy,
      spacing,
      startDate,
      subtitle,
      title,
      titleForeground,
      uom
    } = this.props

    const leaderboardProps = { columns: layout === 'default' ? { sm: 2 } : {} }
    const leaderboardItemProps = hideCharity === 'Yes' ? { subtitle: null } : undefined
    const activityTypes = activity && activity.replace(/\s/g, '').split(',')
    const selectedItem = items[this.state.tab]

    return (
      <Section
        alignment={alignment}
        backgroundImage={backgroundImage}
        background={background}
        foreground={foreground}
        size={size}
        spacing={spacing}>

        <Content
          copy={copy}
          copyAlignment={copyAlignment}
          layout={layout}
          subtitle={subtitle}
          title={title}
          titleForeground={titleForeground}>

          {items.length > 1 && (
            <div className={classNames.toggles}>
              {items.map((item, index) => {
                const changeTab = () => this.setState({ tab: index })
                const isSelected = this.state.tab === index

                return (
                  <div
                    onClick={changeTab}
                    onKeyDown={changeTab}
                    role='button'
                    key={index}
                    tabIndex={index}
                    aria-label={`Show ${item.label} leaderboard`}
                    aria-pressed={isSelected}
                    className={isSelected ? classNames.toggleOn : classNames.toggleOff}>
                    {item.label}
                  </div>
                )
              })}
            </div>
          )}

          <div className={classNames.leaderboard}>
            <FitnessLeaderboard
              activity={activityTypes}
              campaign={campaign || apiParams.campaignIds}
              charity={charity || apiParams.charityIds}
              country={country}
              deserializeMethod={(item, index) => this.deserializeLeaderboard(item, index, selectedItem)}
              endDate={endDate || apiParams.endDate}
              excludePageIds={selectedItem.excludePageIds}
              filter={includeFilter === 'Yes'}
              group={selectedItem.group}
              groupID={selectedItem.groupID}
              includeManual={includeManual === 'yes'}
              leaderboard={leaderboardProps}
              leaderboardItem={leaderboardItemProps}
              limit={selectedItem.limit ? parseInt(selectedItem.limit) : 20}
              miles={uom === 'mi'}
              multiplier={selectedItem.multiplier || 1}
              offset={selectedItem.offset}
              pageSize={parseInt(pageSize)}
              refreshInterval={apiParams.refreshInterval}
              sortBy={sortBy}
              startDate={startDate || apiParams.startDate}
              tagId={selectedItem.tagId}
              tagValue={selectedItem.tagValue}
              type={selectedItem.type}
              units={selectedItem.units}
            />
          </div>
        </Content>
      </Section>
    )
  }
}

const mapState = (store) => ({
  ...selectAPIParams(store),
  ...selectLocalisation(store),
  ...selectCustomSupporterPagePresent(store),
  customTagPages: selectCustomTagPages(store)
})

export default compose(
  withStyles(styles),
  connect(mapState)
)(FitnessLeaderboards)
