import React from 'react'
import get from 'lodash/get'
import { connect } from 'react-redux'
import { prismicRichTextAsHTML } from 'prismic-utils'
import { selectCustomSupporterPagePresent } from '../../../store/customSupporterPages'
import { selectInitialProjectsContent, selectProjectsByOrder, selectProjectsStatus } from '../../../store/projects'

import Content from '../../ui/Content'
import Error from '../../ui/Error'
import Loading from '../../ui/Loading'

import ProgressTiles from '../../ui/ProgressTiles'
import Section from '../../ui/Section'

const ProjectTiles = ({
  action,
  alignment,
  backgroundImage,
  background,
  barColor,
  buttonBackground,
  buttonForeground,
  copy,
  copyAlignment,
  foreground,
  fundedAction,
  hasCustomSupporterPages,
  metric,
  projects = [],
  projectsInitialContent,
  projectsLoadStatus,
  size,
  spacing,
  status,
  subtitle,
  title,
  titleForeground,
  titleSize
}) => {
  const formatProject = project => {
    const isFunded = metric === 'donations'
      ? project.totalDonations >= project.targetDonations
      : project.raised >= project.target

    const isDisabled = isFunded && fundedAction === 'disable'
    const url = project.type === 'page' && hasCustomSupporterPages ? `/fundraising/${project.slug}` : project.url

    return {
      ...project,
      actions: [{
        to: action === 'view'
          ? isDisabled ? '' : url
          : isDisabled ? '' : project.donationUrl || project.donateUrl,
        label: action === 'view'
          ? isDisabled ? 'Project Funded' : 'View Project'
          : isDisabled ? 'Project Funded' : 'Donate Now'
      }],
      name: project.overridePageName || project.name,
      story: (project.overridePageStory && prismicRichTextAsHTML(project.overridePageStory)) || project.story,
      image: get(project, 'overridePageImage.url') || project.image
    }
  }

  const initialState = projectsInitialContent.map(project => formatProject(project))
  const projectData = projects.map(project => formatProject(project))

  return (
    <Section
      alignment={alignment}
      backgroundImage={backgroundImage}
      background={background}
      foreground={foreground}
      size={size}
      spacing={spacing}>

      <Content
        copy={copy}
        copyAlignment={copyAlignment}
        subtitle={subtitle}
        title={title}
        titleForeground={titleForeground}>

        {status === 'fetching' && <Loading />}
        {status === 'failed' && <Error>There was an error loading current projects.</Error>}
        {status === 'fetched' && (
          <ProgressTiles
            action={action}
            barColor={barColor}
            buttonBackground={buttonBackground}
            buttonForeground={buttonForeground}
            data={projectsLoadStatus === 'fetched' ? projectData : initialState}
            initialState={initialState}
            metric={metric}
            titleForeground={titleForeground}
            titleSize={titleSize}
          />
        )}

      </Content>
    </Section>
  )
}

const mapState = (state) => ({
  projects: selectProjectsByOrder(state),
  projectsInitialContent: selectInitialProjectsContent(state),
  projectsLoadStatus: selectProjectsStatus(state),
  status: get(state, 'site.status', 'fetching'),
  ...selectCustomSupporterPagePresent(state)
})

export default connect(mapState)(ProjectTiles)
