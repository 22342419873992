import React, { useState } from 'react'

import Button from '../Button'
import ButtonGroup from 'constructicon/button-group'
import Heading from 'constructicon/heading'
import FlatModal from '../FlatModal'

const Confirm = ({
  cancelLabel,
  confirmLabel,
  onConfirm,
  message,
  tag: Tag,
  width,
  ...props
}) => {
  const [open, setOpen] = useState(false)

  return (
    <div>
      <Tag
        onClick={() => setOpen(true)}
        {...props}
      />
      <FlatModal
        closeIcon={false}
        contentLabel={message}
        isOpen={open}
        onRequestClose={() => setOpen(false)}
        spacing={1.5}
        width={width}
        textAlign='center'
      >
        <Heading spacing={{ b: 1 }}>{message}</Heading>
        <ButtonGroup>
          <Button onClick={onConfirm}>{confirmLabel}</Button>
          <Button background='shade' onClick={() => setOpen(false)}>{cancelLabel}</Button>
        </ButtonGroup>
      </FlatModal>
    </div>
  )
}

Confirm.defaultProps = {
  confirmLabel: 'Yes',
  cancelLabel: 'No',
  message: 'Are you sure?',
  tag: Button,
  width: 12
}

export default Confirm
