import React from 'react'
import withStyles from 'constructicon/with-styles'
import Container from 'constructicon/container'

const SiteContainer = ({
  children,
  classNames,
  container,
  styles
}) => {
  const ContainerElement = container === 'yes' ? Container : 'div'
  const containerProps = container === 'yes' && {
    shadow: 'full',
    background: 'light'
  }

  return (
    <div id='root' className={classNames.root}>
      <ContainerElement {...containerProps}>
        {Array.isArray(children)
          ? <div>{children}</div>
          : children}
      </ContainerElement>
    </div>
  )
}

const styles = ({
  background,
  backgroundImage,
  containerSpace
}, {
  colors,
  measures,
  mediaQuery,
  rhythm,
  treatments
}) => ({
  root: {
    webkitFontSmoothing: 'antialiased',
    mozOsxFontSmoothing: 'grayscale',
    backgroundColor: colors[background],
    lineHeight: measures.medium,
    ...treatments.body,

    [mediaQuery('lg')]: {
      ...containerSpace === 'yes' && {
        paddingTop: rhythm(3),
        paddingBottom: rhythm(3)
      },
      ...backgroundImage && {
        '&:before': {
          content: '""',
          position: 'fixed',
          zIndex: -1,
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          backgroundImage: backgroundImage.url && `url(${backgroundImage.url})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover'
        }
      }
    }
  }
})

export default withStyles(styles)(SiteContainer)
