import React from 'react'
import get from 'lodash/get'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import RichText from '../../ui/RichText'
import Section from '../../ui/Section'

const Story = ({
  alignment,
  background,
  foreground,
  fundraisingPage,
  spacing,
  styles,
  title
}) => (
  <Section
    alignment={alignment}
    background={background}
    foreground={foreground}
    spacing={spacing}
  >
    <RichText styles={styles.title}>
      {title}
    </RichText>
    <RichText>
      {get(fundraisingPage, 'story', '').replace(/\/n/gm, '<br />')}
    </RichText>
  </Section>
)

export default withStyles(styles)(Story)
