export default (props, { rhythm }) => {
  return {
    root: {
      textAlign: 'center'
    },
    logo: {
      display: 'block',
      margin: '0 auto 1.5rem',
      width: rhythm(7)
    }
  }
}
