import React, { Component } from 'react'
import kebabCase from 'lodash/kebabCase'
import find from 'lodash/find'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { isJustGiving } from '../../../lib/environment'
import { selectCustomSupporterPagePresent } from '../../../store/customSupporterPages'
import { selectAPIParams, selectLocalisation } from '../../../store/site'
import { selectCustomTagPages } from '../../../store/customTagPages'
import { generateAvatar } from '../../../lib/avatar'
import { deserializeLeaderboard } from 'supporticon/api/leaderboard'
import { currencyCode } from 'supporticon/utils/currencies'
import { getAmountByCurrency } from 'supporticon/utils/totals'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import Content from '../../ui/Content'
import Leaderboard from 'supporticon/components/leaderboard'
import Section from '../../ui/Section'

class FundraisingLeaderboards extends Component {
  constructor () {
    super()
    this.deserializeLeaderboard = this.deserializeLeaderboard.bind(this)
    this.state = {
      tab: 0
    }
  }

  deserializeLeaderboard (item, index, { groupUrl, tagId, type }) {
    const deserialized = deserializeLeaderboard(item, index)

    if (type === 'group') {
      const { customTagPages = [] } = this.props
      const customTagPage = find(customTagPages, ({ slug }) => slug === tagId)

      return {
        ...deserialized,
        image: customTagPage ? generateAvatar(kebabCase(deserialized.name), 'staticImage', customTagPage.staticAvatarOption) : null,
        raised: isJustGiving()
          ? getAmountByCurrency(item.amounts, currencyCode(this.props.country))
          : deserialized.raised,
        url: groupUrl
          ? [groupUrl, kebabCase(deserialized.name)].join('')
          : null
      }
    } else if (this.props.hasCustomSupporterPages) {
      return {
        ...deserialized,
        url: `/${type === 'team' ? 'teams' : 'fundraising'}/${deserialized.slug}`
      }
    }

    return deserialized
  }

  subtitleMethod (item = {}) {
    if (typeof item.owner === 'string') {
      return item.owner
    }

    return item.subtitle
  }

  render () {
    const {
      alignment,
      allPages,
      apiParams,
      background,
      backgroundImage,
      campaign,
      charity,
      classNames,
      copy,
      copyAlignment,
      country,
      endDate,
      event,
      excludeOffline,
      foreground,
      hideCharity,
      includeFilter = 'Yes',
      items = [{ type: 'individual' }],
      layout = 'default',
      pageSize = 20,
      size,
      spacing,
      startDate,
      subtitle,
      title,
      titleForeground
    } = this.props

    const leaderboardProps = { columns: layout === 'default' ? { sm: 2 } : {} }
    const leaderboardItemProps = hideCharity === 'Yes' ? { subtitle: null } : undefined
    const selectedItem = items[this.state.tab]

    return (
      <Section
        alignment={alignment}
        backgroundImage={backgroundImage}
        background={background}
        foreground={foreground}
        size={size}
        spacing={spacing}>

        <Content
          copy={copy}
          copyAlignment={copyAlignment}
          layout={layout}
          subtitle={subtitle}
          title={title}
          titleForeground={titleForeground}>

          {items.length > 1 && (
            <div className={classNames.toggles}>
              {items.map((item, index) => {
                const changeTab = () => this.setState({ tab: index })
                const isSelected = this.state.tab === index

                return (
                  <div
                    onClick={changeTab}
                    onKeyDown={changeTab}
                    role='button'
                    key={index}
                    tabIndex={index}
                    aria-label={`Show ${item.label} leaderboard`}
                    aria-pressed={isSelected}
                    className={isSelected ? classNames.toggleOn : classNames.toggleOff}>
                    {item.label}
                  </div>
                )
              })}
            </div>
          )}

          <div className={classNames.leaderboard}>
            <Leaderboard
              allPages={allPages === 'Yes' && selectedItem.type === 'individual'}
              campaign={campaign || apiParams.campaignIds}
              charity={charity || apiParams.charityIds}
              country={country}
              deserializeMethod={(item, index) => this.deserializeLeaderboard(item, index, selectedItem)}
              endDate={endDate}
              event={event ? event.split(',') : apiParams.eventIds}
              excludeOffline={excludeOffline === 'Yes' || apiParams.excludeOffline === 'Yes'}
              excludePageIds={selectedItem.excludePageIds}
              filter={includeFilter === 'Yes'}
              group={selectedItem.group}
              groupID={selectedItem.groupID}
              leaderboard={leaderboardProps}
              leaderboardItem={leaderboardItemProps}
              limit={selectedItem.limit ? parseInt(selectedItem.limit) : 20}
              multiplier={selectedItem.multiplier || 1}
              offset={selectedItem.offset}
              pageSize={parseInt(pageSize)}
              refreshInterval={apiParams.refreshInterval}
              startDate={startDate}
              subtitleMethod={this.subtitleMethod}
              tagId={selectedItem.tagId}
              tagValue={selectedItem.tagValue}
              type={selectedItem.type}
            />
          </div>
        </Content>
      </Section>
    )
  }
}

const mapState = (store) => ({
  ...selectAPIParams(store),
  ...selectLocalisation(store),
  ...selectCustomSupporterPagePresent(store),
  customTagPages: selectCustomTagPages(store)
})

export default compose(
  withStyles(styles),
  connect(mapState)
)(FundraisingLeaderboards)
