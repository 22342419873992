import React from 'react'
import moment from 'moment'
import Content from '../../ui/Content'
import CountdownTimer from 'react-countdown-now'
import Section from '../../ui/Section'
import Time from './Time'
import 'string.prototype.repeat'

const Countdown = ({
  alignment,
  backgroundImage,
  background,
  completedCopy,
  copy,
  copyAlignment,
  endTime,
  foreground,
  items,
  layout,
  size,
  spacing,
  subtitle,
  tileBackground,
  tileForeground,
  title,
  titleForeground
}) => (
  <Section
    alignment={alignment}
    backgroundImage={backgroundImage}
    background={background}
    foreground={foreground}
    size={size}
    spacing={spacing}>
    <CountdownTimer
      date={moment(endTime).toDate()}
      renderer={(time) => {
        if (time.completed) {
          return (
            <Content
              copy={completedCopy}
              copyAlignment={copyAlignment}
            />
          )
        } else {
          return (
            <Content
              buttons={items}
              copy={copy}
              copyAlignment={copyAlignment}
              layout={layout}
              subtitle={subtitle}
              title={title}
              titleForeground={titleForeground}>
              <Time
                {...time}
                tileBackground={tileBackground}
                tileForeground={tileForeground}
              />
            </Content>
          )
        }
      }}
    />
  </Section>
)

export default Countdown
