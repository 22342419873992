import React, { useEffect, useState } from 'react'
import { deserializePage, fetchPage } from 'supporticon/api/pages'

import EditPageForm from './EditPageForm'
import Loading from '../../../ui/Loading'
import Section from 'constructicon/section'

const ManagePage = ({
  pageId,
  ...props
}) => {
  const [status, setStatus] = useState('fetching')
  const [page, setPage] = useState(null)

  useEffect(() => {
    fetchPage(pageId)
      .then(page => deserializePage(page))
      .then(page => setPage(page))
      .then(() => setStatus('fetched'))
  }, [])

  switch (status) {
    case 'fetched':
      return (
        <EditPageForm
          {...props}
          fundraisingPage={page}
          pageId={pageId}
        />
      )
    default:
      return (
        <Section spacing={{ x: 1, y: 5 }}>
          <Loading />
        </Section>
      )
  }
}

export default ManagePage
