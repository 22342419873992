import React from 'react'
import withStyles from 'constructicon/with-styles'
import styles from './styles'
import { getAvatar } from '../../../lib/avatar'

import Section from '../../ui/Section'

const ProfilePicture = ({
  classNames,
  defaultImage = {},
  fundraisingPage,
  spacing
}) => {
  const image = getAvatar(fundraisingPage.image, defaultImage.url)
    .replace('CrowdfundingOwnerAvatar', 'FundraisingPageGalleryL')

  return (
    <Section spacing={spacing}>
      <div className={classNames.wrapper}>
        <div className={classNames.image}>
          <img
            alt={fundraisingPage.name}
            src={image}
          />
        </div>
      </div>
    </Section>
  )
}

export default withStyles(styles)(ProfilePicture)
