import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { fetchAdminUser, selectAdminUser } from '../../store/session'

import Login from '../../components/container/AdminLogin'

const AdminLogin = ({
  fetchAdminUser,
  router,
  user = {}
}) => {
  useEffect(() => {
    if (user.token) router.push('/admin/reports')
  }, [user])

  return <Login onSuccess={fetchAdminUser} />
}

const mapState = (state) => ({
  user: selectAdminUser(state)
})

const mapDispatch = { fetchAdminUser }

export default connect(mapState, mapDispatch)(AdminLogin)
