import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { selectAPIParams, selectButtonDefaults } from '../../../store/site'
import { preselectCharity } from '../../../store/session'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import CharitySearchForm from 'supporticon/components/charity-search'
import ContentSection from '../../ui/ContentSection'
import SelectedCharity from './SelectedCharity'

class CharitySearch extends React.Component {
  constructor () {
    super()
    this.state = { selected: null }
  }

  componentDidMount () {
    const { charityId } = this.props.location.query
    if (charityId) this.setState({ selected: charityId })
  }

  render () {
    const {
      alignment,
      apiParams,
      backgroundImage,
      background,
      buttonBackground,
      buttonForeground,
      buttonProps,
      campaign,
      classNames,
      copy,
      copyAlignment,
      foreground,
      items = [],
      preselectCharity,
      size,
      spacing,
      subtitle,
      title,
      titleForeground
    } = this.props

    const { selected } = this.state

    return (
      <ContentSection
        alignment={alignment}
        backgroundImage={backgroundImage}
        background={background}
        copy={copy}
        copyAlignment={copyAlignment}
        foreground={foreground}
        size={size}
        spacing={spacing}
        subtitle={subtitle}
        title={title}
        titleForeground={titleForeground}>
        {selected ? (
          <div className={classNames.content}>
            <SelectedCharity
              ctas={items}
              key={selected}
              id={selected}
              onCancel={() => this.setState({ selected: null })}
              onLoad={charity => preselectCharity(charity)}
            />
          </div>
        ) : (
          <CharitySearchForm
            button={{
              ...buttonProps,
              background: buttonBackground,
              foreground: buttonForeground
            }}
            campaign={campaign || apiParams.campaignIds}
            inputProps={{
              placeholder: 'Search for a charity'
            }}
            onChange={charity => this.setState({ selected: charity.id })}
          />
        )}
      </ContentSection>
    )
  }
}

const mapState = (state) => ({
  buttonProps: selectButtonDefaults(state),
  ...selectAPIParams(state)
})

const mapDispatch = { preselectCharity }

export default compose(
  withRouter,
  connect(mapState, mapDispatch),
  withStyles(styles)
)(CharitySearch)
