import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { refreshToken } from 'supporticon/api/authentication'
import { isJustGiving } from '../../../lib/environment'
import { clearSession, refreshUser, selectUser } from '../../../store/session'

class Reconnect extends React.Component {
  constructor () {
    super()
    this.refreshTokenIfNeeded = this.refreshTokenIfNeeded.bind(this)
  }

  componentDidMount () {
    this.refreshTokenIfNeeded()
    this.interval = setInterval(this.refreshTokenIfNeeded, 30000)
  }

  componentWillUnmount () {
    clearInterval(this.interval)
  }

  refreshTokenIfNeeded () {
    const { clearSession, refreshUser, user = {} } = this.props

    const shouldRefreshToken = isJustGiving() && user.refreshToken &&
      moment().isSameOrAfter(user.tokenExpiry, 'second')

    if (shouldRefreshToken) {
      refreshToken(user)
        .then(({ expiresAt, refreshToken, token }) => refreshUser({
          authType: 'Bearer',
          refreshToken,
          token,
          tokenExpiry: expiresAt
        }))
        .catch(error => {
          clearSession()
          return Promise.reject(error)
        })
    }
  }

  render () {
    return this.props.children
  }
}

const mapStateToProps = state => ({
  user: selectUser(state)
})

const mapDispatchToProps = { clearSession, refreshUser }

export default connect(mapStateToProps, mapDispatchToProps)(Reconnect)
