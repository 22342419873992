export const selectStatus = (store) => store.session.status

export const selectUser = (store) => store.session.user

export const selectPage = (store) => store.session.page

export const selectTeam = (store) => store.session.team

export const selectPreselectedCharity = (store) => store.session.charity

export const selectPreselectedTeam = (store) => store.session.preselectedTeam

export const selectAdminUser = (store) => store.session.adminUser
