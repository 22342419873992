import get from 'lodash/get'
import { createSelector } from 'reselect'
import { getDefaultCustomSupporterPage } from '../../../lib/segmentation'

export const selectCustomSupporterPages = store => store.customSupporterPages.data
export const selectCustomSupporterPageStatus = store => store.customSupporterPages.status

export const selectCustomSupporterPage = createSelector(
  [ selectCustomSupporterPages ],
  customSupporterPages => getDefaultCustomSupporterPage(customSupporterPages)
)

export const selectCustomSupporterPagePresent = createSelector(
  [ selectCustomSupporterPage ],
  customSupporterPage => ({ hasCustomSupporterPages: get(customSupporterPage, 'id', false) })
)
