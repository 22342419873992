import React from 'react'
import SearchForm from './SearchForm'
import Section from '../../ui/Section'

const ProjectSearch = ({
  action,
  backgroundImage,
  background,
  buttonBackground,
  buttonForeground,
  buttonProps,
  foreground,
  label,
  placeholder,
  size,
  spacing
}) => (
  <Section
    backgroundImage={backgroundImage}
    background={background}
    foreground={foreground}
    size={size}
    spacing={spacing}>

    <SearchForm
      action={action}
      buttonBackground={buttonBackground}
      buttonForeground={buttonForeground}
      label={label || 'Find a Project'}
      placeholder={placeholder || 'Search for a project here...'}
    />

  </Section>
)

export default ProjectSearch
