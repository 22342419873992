export default (props, traits) => {
  const { rhythm } = traits

  return {
    root: {
      content: {
        padding: rhythm(1.5),
        textAlign: 'center',
        maxWidth: rhythm(20)
      }
    },

    skip: {
      marginTop: rhythm(0.5),
      textDecoration: 'underline',
      opacity: 0.5
    },

    stepHeading: {
      marginBottom: rhythm(1),
      opacity: 0.5
    }
  }
}
