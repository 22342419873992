import * as validators from 'constructicon/lib/validators'
import { stripTags } from 'supporticon/utils/html'

export default ({ fitnessOptions, fundraisingPage }) => {
  return {
    fields: {
      name: {
        label: 'Page Title',
        type: 'text',
        required: true,
        initial: fundraisingPage.name,
        maxLength: 255,
        validators: [validators.required('Please enter a page title')]
      },
      target: {
        label: 'Fundraising Target',
        type: 'number',
        required: true,
        initial: fundraisingPage.target ? parseInt(fundraisingPage.target) : undefined,
        validators: [
          validators.required('Please enter an amount'),
          validators.number('Must be a number'),
          val => parseInt(val) <= 0 && 'Please enter an amount greater than 0',
          val => parseInt(val) > 10000000 && 'Please enter an amount less than 10,000,000'
        ]
      },
      fitnessGoal: {
        label: fitnessOptions.label || 'Fitness Target',
        type: 'number',
        required: true,
        initial: fundraisingPage.fitnessGoal
      },
      story: {
        label: 'Story',
        type: 'textarea',
        initial: stripTags(fundraisingPage.story, true),
        placeholder: 'This copy will appear on your fundraising page.'
      },
      image: {
        label: 'Profile Picture',
        required: true,
        initial: fundraisingPage.image,
        validators: []
      }
    }
  }
}
