import React, { Component } from 'react'

export const withMobile = ComponentToWrap =>
  class DetectMobileDevice extends Component {
    constructor (props) {
      super(props)
      this.state = { mobile: false }
    }

    componentDidMount () {
      this.setState({
        mobile: navigator.userAgent.match(/Android|iPhone|iPad|iPod/i)
      })
    }

    render () {
      return (
        <ComponentToWrap
          isMobile={this.state.mobile}
          {...this.props}
        />
      )
    }
  }

export default withMobile
