import React from 'react'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

const Image = ({
  classNames,
  image = {},
  styles
}) => (
  image.url ? (
    <img
      src={image.url}
      alt={image.alt || 'Image'}
      aria-hidden={!image.alt}
      className={classNames.root}
    />
  ) : null
)

export default withStyles(styles)(Image)
