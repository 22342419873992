import React from 'react'
import withMobile from '../../utils/withMobile'
import ButtonGroup from 'constructicon/button-group'
import ButtonShare from 'constructicon/button-share'

const SocialShare = ({
  email,
  facebook,
  isMobile,
  linkedin,
  messenger,
  pinterest,
  reddit,
  sms,
  twitter,
  url,
  whatsapp
}) => (
  <ButtonGroup>
    {facebook === 'yes' ? <ButtonShare type='facebook' share url={url} /> : null}
    {twitter === 'yes' ? <ButtonShare type='twitter' share url={url} /> : null}
    {linkedin === 'yes' ? <ButtonShare type='linkedin' share url={url} /> : null}
    {messenger === 'yes' && isMobile ? <ButtonShare type='messenger' share url={url} /> : null}
    {whatsapp === 'yes' && isMobile ? <ButtonShare type='whatsapp' share url={url} /> : null}
    {pinterest === 'yes' ? <ButtonShare type='pinterest' share url={url} /> : null}
    {reddit === 'yes' ? <ButtonShare type='reddit' share url={url} /> : null}
    {email === 'yes' ? <ButtonShare type='mail' share url={url} /> : null}
    {sms === 'yes' ? <ButtonShare background='secondary' type='sms' share url={url} /> : null}
  </ButtonGroup>
)

export default withMobile(SocialShare)
