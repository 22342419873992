import React, { useState } from 'react'
import withForm from 'constructicon/with-form'
import form from './form'
import { replyToDonation } from 'supporticon/api/donations'

import Button from '../../../ui/Button'
import FlatModal from '../../../ui/FlatModal'
import Form from 'constructicon/form'
import Heading from 'constructicon/heading'
import InputField from 'constructicon/input-field'

const ReplyToDonation = ({
  donationId,
  donorName,
  form,
  onSuccess,
  pageId,
  tag: Tag,
  token,
  width,
  ...props
}) => {
  const [open, setOpen] = useState(false)
  const [status, setStatus] = useState(null)
  const [errors, setErrors] = useState([])

  const handleSubmit = (e) => {
    e.preventDefault()

    form.submit()
      .then(data =>
        Promise.resolve()
          .then(() => setErrors([]))
          .then(() => setStatus('fetching'))
          .then(() => ({ ...data, donationId, pageId, token }))
          .then(data => replyToDonation(data))
          .then(() => setStatus('fetched'))
          .then(() => onSuccess(data.caption))
          .catch(error => {
            setStatus('failed')
            setErrors([{ message: 'There was an error replying to the donation' }])
            return Promise.reject(error)
          })
      )
  }

  return (
    <div>
      <Tag
        onClick={() => setOpen(true)}
        {...props}
      />
      <FlatModal
        contentLabel='Reply to donation'
        isOpen={open}
        onRequestClose={() => setOpen(false)}
        spacing={1.5}
        width={width}
        textAlign='center'
      >
        <Heading spacing={{ b: 1 }}>
          {`Reply to ${donorName}`}
        </Heading>
        <Form
          errors={errors}
          icon={false}
          isLoading={status === 'fetching'}
          noValidate
          onSubmit={handleSubmit}
          submit='Reply'
          styles={{
            submit: {
              display: 'inline-block',
              width: 'auto'
            }
          }}
        >
          <InputField {...form.fields.caption} />
        </Form>
      </FlatModal>
    </div>
  )
}

ReplyToDonation.defaultProps = {
  donorName: 'donor',
  onSuccess: () => {},
  tag: Button,
  width: 20
}

export default withForm(form)(ReplyToDonation)
