export default (props, traits) => {
  const { rhythm } = traits

  return {
    modal: {
      content: {
        maxWidth: rhythm(16),
        padding: rhythm(1),
        textAlign: 'center'
      }
    },

    copy: {
      marginBottom: rhythm(1)
    }
  }
}
