import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { acceptConsent, denyConsent, selectConsent } from '../../../store/consent'
import { selectCookieConsentParams } from '../../../store/site'
import { withRouter } from 'react-router'

import Banner from './Banner'
import Policy from './Policy'
import Preferences from './Preferences'

const CookieConsent = props => {
  const { consent, content, location } = props

  const displayBanner = !consent.touched && content.display === 'Yes'
  const displayPreferences = location.query.cookies === 'preferences' && content.display === 'Yes'
  const displayPolicy = location.query.cookies === 'policy'

  return (
    <div>
      {displayBanner && <Banner {...props} />}
      {displayPreferences && <Preferences {...props} />}
      {displayPolicy && <Policy {...props} />}
    </div>
  )
}

const mapStateToProps = (store) => ({
  consent: selectConsent(store),
  content: selectCookieConsentParams(store)
})

const mapDispatchToProps = { acceptConsent, denyConsent }

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(CookieConsent)
