export default ({
  styles
}, {
  scale
}) => ({
  root: {
    wordBreak: 'break-word',
    ...styles,

    '& h1': { fontSize: scale(5) },
    '& h2': { fontSize: scale(4) },
    '& h3': { fontSize: scale(3) },
    '& h4': { fontSize: scale(2) },
    '& h5': { fontSize: scale(1.5) },
    '& h6': { fontSize: scale(1) },

    '& > span > :last-child': {
      marginBottom: 0
    }
  }
})
