import React from 'react'
import shuffle from 'lodash/shuffle'
import Content from '../../ui/Content'
import Grid from 'constructicon/grid'
import GridColumn from 'constructicon/grid-column'
import Image from '../../ui/Image'
import Section from '../../ui/Section'

class GridContent extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      columns: props.items.map((item, key) => ({ ...item, key }))
    }
  }

  componentDidMount () {
    const { randomiseColumns } = this.props
    const { columns } = this.state

    if (randomiseColumns === 'yes') {
      this.setState({
        columns: shuffle(columns)
      })
    }
  }

  render () {
    const {
      alignment,
      backgroundImage,
      background,
      buttonBackground,
      buttonForeground,
      buttonStyle,
      copy,
      copyAlignment,
      foreground,
      gridColumns = 3,
      gridCopyAlignment,
      layout,
      size,
      spacing,
      subtitle,
      title,
      titleForeground
    } = this.props

    const calculateColumnSize = (gridColumns, factor) => 12 / Math.ceil(gridColumns * factor)

    return (
      <Section
        alignment={alignment}
        backgroundImage={backgroundImage}
        background={background}
        foreground={foreground}
        size={size}
        spacing={spacing}>
        <Content
          copy={copy}
          copyAlignment={copyAlignment}
          layout={layout}
          subtitle={subtitle}
          title={title}
          titleForeground={titleForeground}>

          <Grid
            justify='flex-start'
            spacing={1}>
            {this.state.columns.map(item => {
              const buttons = [item.button, item.buttonSecondary].map(button => ({
                ...button,
                background: buttonBackground,
                block: buttonStyle === 'full width',
                foreground: buttonForeground
              }))

              return (
                <GridColumn
                  key={item.key}
                  sm={calculateColumnSize(gridColumns, 0.5)}
                  md={calculateColumnSize(gridColumns, 1)}>
                  <Image
                    image={item.image}
                    margin={{ b: 1 }}
                  />
                  <Content
                    align={gridCopyAlignment}
                    buttons={buttons.filter((button) => button.label)}
                    copy={item.copy}
                    subtitle={item.subtitle}
                    title={item.title}
                    titleForeground={titleForeground}
                  />
                </GridColumn>
              )
            })}
          </Grid>

        </Content>
      </Section>
    )
  }
}

export default GridContent
