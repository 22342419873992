import React from 'react'
import ContentSection from '../../ui/ContentSection'
import GalleryItem from './GalleryItem'
import Grid from 'constructicon/grid'
import GridColumn from 'constructicon/grid-column'

const Gallery = ({
  background,
  divider = 'none',
  foreground,
  gridColumns = 2,
  items = [],
  size,
  spacing,
  title
}) => {
  const calculateColumnSize = (gridColumns, factor) => 12 / Math.ceil(gridColumns * factor)

  return (
    <ContentSection
      background={background}
      foreground={foreground}
      size={size}
      spacing={spacing}
      title={title}>

      <Grid
        align='center'
        justify='center'
        spacing={parseFloat(dividerSizes[divider])}>

        {items.map((item, i) => (
          <GridColumn
            key={i}
            sm={calculateColumnSize(gridColumns, 0.5)}
            md={calculateColumnSize(gridColumns, 1)}>
            <GalleryItem {...item} />
          </GridColumn>
        ))}
      </Grid>
    </ContentSection>
  )
}

const dividerSizes = {
  none: 0,
  small: 0.125,
  medium: 0.5,
  thick: 1
}

export default Gallery
