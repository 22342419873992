import React, { useState } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { selectTagDefinitions, selectCampaign } from '../../../store/site'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import DefinitionForm from './DefinitionForm'
import Heading from 'constructicon/heading'
import Icon from 'constructicon/icon'
import Modal from 'constructicon/modal'
import RichText from 'constructicon/rich-text'
import Section from 'constructicon/section'

const AdminLeaderboardDefinitions = ({
  campaign = {},
  children,
  classNames,
  styles,
  tagDefinitions = []
}) => {
  if (!tagDefinitions.length) return <p>No tag definitions found</p>
  if (!campaign.uid) return <p>No campaign config found</p>

  const [activeDefinition, setActiveDefinition] = useState(tagDefinitions[0])
  const [modalOpen, setModalOpen] = useState(false)

  return (
    <Section spacing={{ t: 1, x: 1, b: 0 }}>
      <header className={classNames.header}>
        <Heading spacing={0} size={1}>Configure Leaderboard definitions</Heading>
        <button onClick={() => setModalOpen(true)}>
          Help?
        </button>
      </header>
      {tagDefinitions.length > 1 && (
        <nav className={classNames.nav}>
          {tagDefinitions.map(definition => (
            <button
              title={definition.label}
              key={definition.id}
              onClick={() => setActiveDefinition(definition)}
              className={[classNames.tab, activeDefinition.id === definition.id ? classNames.active : undefined].join(' ')}
            >
              {definition.label}
            </button>
          ))}
        </nav>
      )}
      {activeDefinition && (
        <DefinitionForm
          {...activeDefinition}
          key={activeDefinition.id}
          campaign={campaign.uid}
        />
      )}
      <Modal
        contentLabel='Help'
        isOpen={modalOpen}
        onRequestClose={() => setModalOpen(false)}
      >
        <Heading size={1.5}>Tag Leaderboard definitions</Heading>
        <RichText>
          <p>Leaderboard definitions for each tag are created automatically (e.g. leaderboard of tag values) when sites are published, but definitions for values within a tag need to be setup manually here (e.g. leaderboard of pages with <em>Tag XYZ</em>).</p>
          <p>Tag leaderboard definitions need to be created <strong>before</strong> pages will appear in related leaderboards.</p>
          <p>You can check existing definitions for each <em>measurement domain</em> (e.g. distance walked, total number of donations) by clicking the <Icon name='info' color='grey' /> icons next to each one. The <em>Fetch all existing</em> button will fetch all existing definitions, but this can take a long time depending on how many possible values there are for each tag.</p>
          <p>A summary of which definitions already exist (<Icon name='check' color='success' />) will be shown after fetching the available data.</p>
          <p>If/when new possible tag values are added, the related definitions will need to be created for each one.</p>
          <p>A <Icon name='warning' color='orange' /> icon represents a <em>measurement domain</em> which has had definitions created in the past, but which is missing definitions for some tag values. You can create the new definitions as usual, existing ones will be skipped.</p>
        </RichText>
      </Modal>
    </Section>
  )
}

const mapState = (state) => ({
  campaign: selectCampaign(state),
  tagDefinitions: selectTagDefinitions(state)
})

export default compose(
  connect(mapState),
  withStyles(styles)
)(AdminLeaderboardDefinitions)
