export default (props, traits) => {
  const { rhythm } = traits

  return {
    form: {
      submit: {
        display: 'inline-block',
        width: 'auto'
      }
    },

    image: {
      root: {
        margin: `0 auto ${rhythm(1)}`
      }
    }
  }
}
