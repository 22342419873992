export default ({
  height = 'small',
  backgroundImage = {},
  background = 'dark',
  foreground = 'light',
  backgroundOpacity
}, {
  colors,
  rhythm
}) => {
  const paddings = {
    large: '25vh 0',
    medium: '15vh 0',
    small: '2rem'
  }

  const backgrounds = {
    black: '#000000',
    white: '#FFFFFF',
    dark: colors.dark,
    light: colors.light,
    primary: colors.primary,
    secondary: colors.secondary,
    tertiary: colors.tertiary,
    'primary-secondary gradient': `linear-gradient(-225deg, ${colors.primary} 0%, ${colors.secondary} 100%)`,
    'primary-dark gradient': `linear-gradient(-225deg, ${colors.primary} 0%, ${colors.dark} 100%)`,
    'secondary-tertiary gradient': `linear-gradient(-225deg, ${colors.secondary} 0%, ${colors.tertiary} 100%)`
  }

  return ({
    root: {
      position: 'relative',
      padding: `${rhythm(2)} ${rhythm(1)}`,
      backgroundImage: backgroundImage.url && `url(${backgroundImage.url})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat'
    },

    overlay: {
      zIndex: 1,

      '&:before': {
        content: '""',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        background: backgrounds[background],
        opacity: backgroundOpacity / 100
      }
    },

    content: {
      position: 'relative',
      padding: paddings[height],
      color: colors[foreground],
      textAlign: 'center'
    },

    logo: {
      maxWidth: rhythm(8),
      maxHeight: rhythm(3),
      marginBottom: rhythm(1)
    },

    videoContainer: {
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      overflow: 'hidden'
    },

    video: {
      position: 'absolute',
      margin: 'auto',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      visibility: 'visible',
      zIndex: 0,
      opacity: 1,
      width: 'auto',
      minHeight: '100%',
      minWidth: '100%'
    }
  })
}
