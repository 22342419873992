import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { provideHooks } from 'redial'
import { selectCustomSupporterPage } from '../../store/customSupporterPages'
import { clearPage } from '../../store/fundraisingPage'
import { fetchTeam, selectTeam, selectTeamStatus } from '../../store/team'
import { selectCampaign, selectMaintenanceMode } from '../../store/site'

import NotFound from '../../components/container/NotFound'
import Status from '../../components/container/Status'
import TeamPage from '../../components/container/TeamPage'
import Theme from '../../components/utils/Theme'

const CustomTeamPage = ({
  customSupporterPage,
  router,
  status = 'fetching'
}) => (
  <Theme>
    <Status status={status}>
      {customSupporterPage
        ? (
          <TeamPage
            customSupporterPage={customSupporterPage}
            key={router.params.slug}
          />
        ) : <NotFound />}
    </Status>
  </Theme>
)

const mapState = (state, props) => ({
  customSupporterPage: selectCustomSupporterPage(state, props),
  status: selectTeamStatus(state, props)
})

const hooks = {
  fetch: ({ dispatch, params, state }) => {
    const campaign = selectCampaign(state)
    const customSupporterPage = selectCustomSupporterPage(state)
    const team = selectTeam(state)
    const status = selectTeamStatus(state)

    const maintenanceMode = selectMaintenanceMode(state)

    if (maintenanceMode || !customSupporterPage) {
      return Promise.resolve()
    } else if (status === 'fetched' && team.slug === params.slug) {
      return Promise.resolve(team)
    } else {
      return Promise.all([
        dispatch(clearPage()),
        dispatch(fetchTeam(params.slug, campaign))
      ])
    }
  }
}

export default compose(
  connect(mapState),
  provideHooks(hooks)
)(CustomTeamPage)
