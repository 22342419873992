import React, { Component } from 'react'
import axios from 'axios'
import Icon from 'constructicon/icon'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

const fetchFeed = (feed, limit) =>
  axios
    .get(`https://www.juicer.io/api/feeds/${feed}`, { params: { per: 48 } })
    .then(response => response.data.posts.items)
    .then(items => items.map(({
      full_url: url,
      image,
      message,
      video,
      poster_name: name,
      source: { source },
      ...rest
    }) => ({
      image,
      video,
      message: parseMessage(message),
      url,
      name,
      source: source.toLowerCase()
    })))
    .then(posts => posts.filter(post => !post.video))
    .then(posts => posts.slice(0, limit || 12))

const parseMessage = str => {
  const parsed = str.replace(/<\/?[^>]+(>|$)/g, '')
  if (parsed.length > 250) {
    return parsed.slice(0, 150) + '...'
  } else return parsed
}

class JuicerFeed extends Component {
  constructor () {
    super()
    this.state = { posts: [] }
  }

  componentDidMount () {
    const { slug, limit } = this.props

    if (slug) {
      return fetchFeed(slug, limit).then(posts => this.setState({ posts }))
    }
  }

  render () {
    const { posts } = this.state
    const { classNames } = this.props

    return (
      <div className={classNames.posts}>
        {posts && posts.map(({ image, message, source, url }, index) => (
          <a className={classNames.post} href={url} target='_blank' key={index}>
            <img src={image} />
            <div className={classNames.card}>
              <Icon name={source} color={source} />
              <div className={classNames.caption}>
                {message}
              </div>
            </div>
          </a>
        ))}
      </div>
    )
  }
}

export default withStyles(styles)(JuicerFeed)
