import React from 'react'
import compact from 'lodash/compact'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import Carousel from 'constructicon/carousel'
import Content from '../../ui/Content'
import Image from '../../ui/Image'
import Section from '../../ui/Section'

const CarouselSection = ({
  alignment,
  autoplay,
  autoplaySpeed,
  background,
  backgroundImage,
  buttonBackground,
  buttonForeground,
  classNames,
  copy,
  copyAlignment,
  foreground,
  items = [],
  layout,
  mode = 'content',
  size,
  spacing,
  speed,
  styles,
  subtitle,
  title,
  titleForeground,
  transition
}) => (
  <Section
    alignment={alignment}
    backgroundImage={backgroundImage}
    background={background}
    foreground={foreground}
    size={size}
    spacing={spacing}>

    <Carousel
      autoplay={autoplay === 'yes'}
      autoplaySpeed={autoplaySpeed || 3000}
      fade={transition === 'fade'}
      speed={speed || 500}
      styles={styles.carousel}>
      {items.map((item, index) => {
        const buttons = compact([{
          background: buttonBackground,
          foreground: buttonForeground,
          label: item.buttonLabel,
          url: item.buttonURL
        }])

        return mode === 'image' ? (
          <div key={index}>
            <Image
              image={item.image}
              styles={styles.staticImage}
            />
          </div>
        ) : (
          <div
            className={classNames.slide}
            key={index}>
            <Content
              buttons={buttons}
              copy={item.copy}
              copyAlignment={copyAlignment}
              key={index}
              subtitle={item.subtitle}
              title={item.title}
              titleForeground={titleForeground}>
              <Image
                image={item.image}
                styles={styles.image}
              />
            </Content>
          </div>
        )
      })}
    </Carousel>

  </Section>
)

export default withStyles(styles)(CarouselSection)
