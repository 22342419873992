import React from 'react'
import Content from '../../ui/Content'
import Head from '../../utils/Head'
import Section from '../../ui/Section'

const NotFound = () => (
  <div>
    <Head title='Not Found' />
    <Section
      alignment='center'
      copyAlignment='center'>
      <Content
        title='<h1>404</h1>'
        subtitle='<h6>Not Found</h6>'
        copy='<p>Sorry, we were unable to locate the page you requested!</p>'
        buttons={[
          {
            label: 'Return Home',
            url: '/'
          }
        ]}
      />
    </Section>
  </div>
)

export default NotFound
