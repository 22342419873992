export default (props, traits) => {
  const { rhythm, scale } = traits

  return {
    clear: {
      fontSize: scale(-1),
      textDecoration: 'underline',
      marginBottom: rhythm(1)
    },

    image: {
      maxWidth: rhythm(6)
    }
  }
}
