import React from 'react'
import numbro from 'numbro'
import Icon from 'constructicon/icon'
import Metric from 'constructicon/metric'

const TotalProjects = ({
  label,
  format,
  funded,
  metric,
  multiplier,
  offset,
  notFunded,
  projects = [],
  status,
  ...props
}) => {
  const getAmount = () => {
    switch (status) {
      case 'fetching':
        return <Icon name='loading' spin />
      case 'failed':
        return <Icon name='warning' />
      default:
        const count = getCount()
        return numbro((count + offset) * multiplier).format(format)
    }
  }

  const getCount = () => {
    if (notFunded) {
      return projects.filter((project) => project.raised >= project.target).length
    }

    if (funded) {
      return projects.filter((project) => project.raised < project.target).length
    }

    return projects.length
  }

  return (
    <Metric
      amount={getAmount()}
      label={label}
      {...metric}
      {...props}
    />
  )
}

export default TotalProjects
