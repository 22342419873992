import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { selectUrl } from '../../../store/site'
import { selectCustomTagPage } from '../../../store/customTagPages'
import { selectTagPage } from '../../../store/tagPage'
import { withInjectParams } from '../../utils/InjectParams'
import { withRouter } from 'react-router'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import Container from 'constructicon/container'
import Grid from 'constructicon/grid'
import GridColumn from 'constructicon/grid-column'
import Head from '../../utils/Head'
import Image from 'constructicon/image'
import Slices from '../../supporters/Slices'

const TagPage = ({
  classNames,
  customTagPage,
  tagPage,
  injectParams,
  location,
  router,
  siteUrl,
  styles
}) => {
  const { banner, meta = {} } = customTagPage
  const { params } = router

  return (
    <div>
      <Head
        title={meta.title || '<%= name %>'}
        description={meta.description || '<%= story %>'}
        ogType='profile'
        ogTitle={meta.ogTitle || '<%= name %>'}
        ogDescription={meta.ogDescription || '<%= story %>'}
        ogImage={{ url: tagPage.image }}
        ogUrl={{ url: `${siteUrl}/${params.tag}/${params.slug}` }}
      />
      {banner.url && (
        <div className={classNames.banner}>
          <Image
            alt={banner.alt || 'Page banner'}
            src={banner.url}
            styles={styles.image}
          />
        </div>
      )}
      <Container spacing={{ y: 2, x: 1 }}>
        {customTagPage.sidebar.length > 0 ? (
          <Grid spacing={1}>
            <GridColumn md={4}>
              <aside className={classNames.sidebar}>
                <Slices
                  type='tag'
                  customSupporterPage={customTagPage}
                  slices={customTagPage.sidebar}
                />
              </aside>
            </GridColumn>
            <GridColumn md={8}>
              <main className={classNames.main}>
                <Slices
                  type='tag'
                  customSupporterPage={customTagPage}
                  slices={customTagPage.main}
                />
              </main>
            </GridColumn>
          </Grid>
        ) : (
          <main className={classNames.main}>
            <Slices
              type='tag'
              customSupporterPage={customTagPage}
              slices={customTagPage.main}
            />
          </main>
        )}
      </Container>
    </div>
  )
}

const mapState = (state, props) => ({
  customTagPage: selectCustomTagPage(state, props),
  tagPage: selectTagPage(state, props),
  siteUrl: selectUrl(state)
})

export default compose(
  withInjectParams,
  withRouter,
  withStyles(styles),
  connect(mapState)
)(TagPage)
