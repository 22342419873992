import React from 'react'
import Helmet from 'react-helmet'

const GoogleFonts = ({
  fonts = []
}) => {
  const formatFontName = (font) => font.primary.name.split(' ').join('+')
  const formatFontWeights = (font) => font.items.map((item) => item.weight).join(',')
  const formatFont = (font) => `${formatFontName(font)}:${formatFontWeights(font)}`
  const formatFonts = (fonts) => fonts.map(formatFont).join('|')

  return (
    <Helmet
      link={[{
        rel: 'stylesheet',
        href: `https://fonts.googleapis.com/css?family=${formatFonts(fonts)}`
      }]}
    />
  )
}

export default GoogleFonts
