import * as validators from 'constructicon/lib/validators'

export default ({ user }) => {
  return {
    fields: {
      firstName: {
        label: 'First Name',
        type: 'text',
        initial: user.firstName,
        validators: [
          validators.alphaNumericSpecial('Please enter a valid first name'),
          validators.required('Please enter a valid first name')
        ]
      },
      lastName: {
        label: 'Last Name',
        type: 'text',
        initial: user.lastName,
        validators: [
          validators.alphaNumericSpecial('Please enter a valid last name'),
          validators.required('Please enter a valid last name')
        ]
      },
      email: {
        label: 'Email',
        type: 'email',
        initial: user.email,
        validators: [
          validators.email('Please enter a valid email address'),
          validators.required('Please enter a valid email address')
        ]
      }
    }
  }
}
