export default (props, traits) => {
  const { rhythm } = traits

  return {
    root: {
      position: 'relative',
      paddingBottom: rhythm(0.5),
      margin: rhythm([-1, -0.5]),

      '& > *': {
        display: 'inline-block',
        margin: rhythm([1, 0.5])
      }
    }
  }
}
