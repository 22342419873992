import axios from 'axios'
import moment from 'moment'
import { servicesAPI } from 'supporticon/utils/client'

const client = axios.create({
  baseURL: `https://dataapi.justgiving.com/${process.env.JG_DATA_API_KEY}`,
  headers: { accept: 'application/json' }
})

const authHeaders = headers => ({
  headers: {
    authorization: headers['authorization'] || headers['Authorization']
  }
})

const handleFetchData = (baseURL, startDate, endDate, headers) => {
  const end = endDate || moment().format('YYYY-MM-DD')
  const start = startDate || moment(end).subtract(1, 'month').format('YYYY-MM-DD')

  return client.get(`/v1/charity/${baseURL}/${start};${end}`, authHeaders(headers))
    .then(response => response.data)
}

export const authenticateUser = (charityId, token) =>
  servicesAPI.post(
    `/v1/justgiving/reports/${charityId}/authenticate`,
    {},
    {
      headers: {
        Authorization: `Basic ${token}`
      }
    }
  )

export const fetchFundraiserData = (
  charityId,
  eventId,
  startDate,
  endDate,
  token
) => {
  const end = endDate || moment().format('YYYY-MM-DD')
  const start = startDate || moment(end).subtract(1, 'month').format('YYYY-MM-DD')

  return handleFetchData(`${charityId}/events/${eventId}/pages/created`, start, end, {
    Authorization: `Basic ${token}`
  })
}
