import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { v1 as uuid } from 'uuid'
import { selectUser } from '../../../store/session'
import { selectAnalyticsParams, selectBasicAPIParams, selectLocalisation, selectReportsParams, selectSiteRegistration } from '../../../store/site'
import { selectCustomSupporterPagePresent } from '../../../store/customSupporterPages'
import { selectPagesRegistration } from '../../../store/pages'
import { withRouter } from 'react-router'
import { setConfig, setUser } from '../../../lib/analytics'
import { isStaging } from '../../../lib/environment'
import { selectConsent } from '../../../store/consent'
import get from 'lodash/get'
import mapValues from 'lodash/mapValues'
import Helmet from 'react-helmet'

class Analytics extends Component {
  constructor () {
    super()
    this.setupHeapConfig = this.setupHeapConfig.bind(this)
  }

  componentDidMount () {
    this.interval = setInterval(this.setupHeapConfig, 500)
    if (this.shouldSendEvent(this.props)) this.sendGATrackingEvent(this.props)
    if (this.shouldSetUser(this.props)) this.setUser(this.props)
  }

  componentWillUnmount () {
    clearInterval(this.interval)
  }

  componentWillReceiveProps (nextProps) {
    if (this.shouldSendEvent(nextProps)) this.sendGATrackingEvent(nextProps)
    if (this.shouldSetUser(nextProps)) this.setUser(nextProps)
  }

  shouldSetUser (props, prevProps = {}) {
    const user = get(props, 'user.id')
    const prevUser = get(prevProps, 'user.id')

    return user && user !== prevUser
  }

  shouldSendEvent ({ track }) {
    return track === 'On'
  }

  setupHeapConfig () {
    if (window.heap && window.heap.loaded) {
      clearInterval(this.interval)

      return setConfig({
        'Blackbaud-sites': 'true',
        'Region': this.props.country === 'gb' ? 'uk' : this.props.country,
        charity_id: this.props.charity || this.props.charityId,
        campaign_id: this.props.campaign || this.props.campaignId,
        event_id: this.props.event || this.props.eventId,
        custom_registration: this.props.hasHomeRegistrationForm || this.props.hasPageRegistrationForm,
        custom_registration_type: this.props.hasHomeRegistrationForm ? 'home_page' : this.props.hasPageRegistrationForm ? 'internal_page' : 'none',
        custom_fundraising_pages: !!this.props.hasCustomSupporterPages,
        custom_fields: this.props.customFields,
        include_address: this.props.includeAddress,
        include_event_date: this.props.includeEventDate,
        include_fitness_goal: this.props.includeFitnessGoal,
        include_name: this.props.includeName,
        include_target: this.props.includeTarget,
        include_self_donate: this.props.includeSelfDonate,
        include_team: this.props.includeTeam,
        oauth_method: this.props.jgOauthMethod
      })
    }
  }

  setUser (props) {
    const { id, countryCode } = props.user || {}
    setUser(id, { countryCode })
  }

  sendGATrackingEvent ({ sku, affiliation, router }) {
    const params = mapValues(router.location.query, (param) => decodeURIComponent(param))
    const donationUuid = params.origin && params.origin.split('/')[1]
    const { amount = 0 } = params

    const payload = {
      'transactionId': uuid(),
      'transactionAffiliation': affiliation || params.event_id,
      'transactionTotal': parseFloat(amount),
      'transactionTimestamp': Date.now(),
      'transactionFirstName': params.first_name,
      'transactionLastName': params.last_name,
      'transactionAnswer1': params.answer_1,
      'transactionAnswer2': params.answer_2,
      'transactionAnswer3': params.answer_3,
      'transactionProducts': [{
        'sku': sku || donationUuid,
        'name': 'Donation',
        'price': parseFloat(amount),
        'quantity': 1
      }],
      'event': 'DonationComplete'
    }

    window.dataLayer = window.dataLayer || []

    return window.dataLayer.push(payload)
  }

  render () {
    if (!this.props.consent.allowed) return null

    return (
      <Helmet>
        <script>
          {`window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};
          heap.load("${isStaging() ? process.env.HEAP_ANALYTICS_STAGING_ID : process.env.HEAP_ANALYTICS_PROD_ID}");`}
        </script>
      </Helmet>
    )
  }
}

const mapStateToProps = (store) => ({
  ...selectLocalisation(store),
  ...selectAnalyticsParams(store),
  ...selectBasicAPIParams(store),
  ...selectCustomSupporterPagePresent(store),
  ...selectReportsParams(store),
  ...selectSiteRegistration(store),
  ...selectPagesRegistration(store),
  consent: selectConsent(store),
  user: selectUser(store)
})

export default compose(
  connect(mapStateToProps),
  withRouter
)(Analytics)
