import get from 'lodash/get'
import { createSelector } from 'reselect'

export const selectFundraisingPage = state => state.fundraisingPage.data
export const selectFundraisingPageStatus = state => state.fundraisingPage.status
export const selectLoggedInUser = state => state.session.user

// @todo might need to figure out a better way than "user.name"
export const selectIsPageOwner = createSelector(
  [ selectFundraisingPage, selectLoggedInUser ],
  (page = {}, user = {}) => {
    if (page.owner === user.id) return true
    if (page.owner === user.name) return true

    return false
  }
)

export const selectCurrentFundraisingPage = (state, props) => {
  const fundraisingPage = state.fundraisingPage.data
  const currentSlug = get(props, 'router.params.slug')

  return fundraisingPage && fundraisingPage.slug === currentSlug
    ? fundraisingPage
    : null
}
