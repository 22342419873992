import moment from 'moment'

export default () => {
  return {
    fields: {
      startDate: {
        label: 'From',
        type: 'date',
        initial: moment().subtract(1, 'month').format('YYYY-MM-DD')
      },
      endDate: {
        label: 'Until',
        type: 'date',
        initial: moment().format('YYYY-MM-DD')
      },
      activeOnly: {
        label: 'Exclude inactive pages?',
        type: 'checkbox',
        initial: true
      }
    }
  }
}
