export default (
  { content },
  { colors, mediaQuery, rhythm, scale }
) => ({
  root: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    padding: rhythm(1),
    backgroundColor: colors[content.background || 'primary'],
    color: colors[content.foreground || 'light'],
    zIndex: 998,
    [mediaQuery('sm')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  },
  message: {
    marginBottom: rhythm(1),
    [mediaQuery('sm')]: {
      marginBottom: 0,
      marginRight: rhythm(1)
    }
  },
  link: {
    textDecoration: 'underline'
  },
  buttons: {
    whiteSpace: 'nowrap'
  }
})
