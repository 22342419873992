import React, { Component } from 'react'
import numbro from 'numbro'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { isJustGiving } from '../../../lib/environment'
import { selectAPIParams, selectLocalisation } from '../../../store/site'
import { selectCustomSupporterPagePresent } from '../../../store/customSupporterPages'
import { fetchLeaderboard, deserializeLeaderboard } from 'supporticon/api/leaderboard'
import { fetchPages, deserializePage } from 'supporticon/api/pages'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import ContentSection from '../../ui/ContentSection'
import Error from '../../ui/Error'
import Flippy from 'constructicon/flippy'
import Grid from 'constructicon/grid'
import GridColumn from 'constructicon/grid-column'
import Loading from '../../ui/Loading'
import Link from '../../utils/LinkHandler'

class SupporterTiles extends Component {
  constructor () {
    super()
    this.deserializePage = this.deserializePage.bind(this)
    this.state = {
      data: [],
      status: 'fetching'
    }
  }

  componentDidMount () {
    switch (this.props.type) {
      case 'featured':
        return this.fetchFeatured()
      case 'all':
        return this.fetchAll()
      default:
        return this.fetchLeaderboard()
    }
  }

  deserializePage (data) {
    const page = deserializePage(data)

    if (this.props.hasCustomSupporterPages) {
      return {
        ...page,
        url: `/${page.type === 'team' ? 'teams' : 'fundraising'}/${page.slug}`
      }
    }

    return page
  }

  fetchFeatured () {
    const { items = [] } = this.props
    const ids = items.map((item) => item.pageID).join(',')

    const params = {
      allPages: true,
      ids
    }

    fetchPages(params)
      .then((data) => data.map(this.deserializePage))
      .then((data) => this.setState({ status: 'fetched', data }))
      .catch((e) => this.setState({ status: 'failed' }))
  }

  fetchAll () {
    const {
      apiParams,
      campaign,
      charity,
      event,
      excludePageIds,
      group,
      limit
    } = this.props

    const params = {
      allPages: isJustGiving(),
      campaign: campaign || apiParams.campaignIds,
      charity: charity || apiParams.charityIds,
      event: event || apiParams.event,
      group,
      limit: (limit || 12) * 2
    }

    fetchPages(params)
      .then((data) => data.map(this.deserializePage))
      .then((data) => data.filter(page => page.active))
      .then((data) => this.removeExcludedPages(data, excludePageIds))
      .then((data) => this.setState({ status: 'fetched', data }))
      .catch(e => this.setState({ status: 'failed' }))
  }

  removeExcludedPages (pages, excludePageIds) {
    if (!excludePageIds) return pages
    return pages.filter(page => excludePageIds.indexOf(page.id) === -1 && excludePageIds.indexOf(page.slug) === -1)
  }

  fetchLeaderboard () {
    const {
      apiParams,
      campaign,
      charity,
      country,
      event,
      excludePageIds,
      group,
      limit
    } = this.props

    const params = {
      campaign: campaign || apiParams.campaignIds,
      charity: charity || apiParams.charityIds,
      country,
      endDate: apiParams.endDate,
      event: event ? event.split(',') : apiParams.eventIds,
      excludePageIds,
      group,
      limit: (limit || 12) * 2,
      page: 1,
      startDate: apiParams.startDate
    }

    fetchLeaderboard(params)
      .then((data) => data.map(deserializeLeaderboard))
      .then((data) => this.setState({ status: 'fetched', data }))
      .catch(() => this.setState({ status: 'failed' }))
  }

  calculateColumnSize (gridColumns, factor) {
    return 12 / Math.ceil(gridColumns * factor)
  }

  render () {
    const {
      background,
      foreground,
      size,
      spacing,
      title,
      titleForeground
    } = this.props

    return (
      <ContentSection
        background={background}
        foreground={foreground}
        size={size}
        spacing={spacing}
        title={title}
        titleForeground={titleForeground}>
        {this.state.status === 'fetching' && this.renderFetching()}
        {this.state.status === 'fetched' && this.renderFetched()}
        {this.state.status === 'failed' && this.renderFailed()}
      </ContentSection>
    )
  }

  renderFetching () {
    const { classNames } = this.props

    return (
      <div className={classNames.root}>
        <Loading />
      </div>
    )
  }

  renderFailed () {
    const { classNames } = this.props

    return (
      <div className={classNames.root}>
        <Error />
      </div>
    )
  }

  renderFetched () {
    const {
      divider = 'none',
      flippyBackground,
      flippyForeground,
      gridColumns = 6,
      limit,
      styles
    } = this.props

    return (
      <Grid
        align='center'
        justify='center'
        spacing={parseFloat(dividerSizes[divider])}>

        {this.state.data.slice(0, limit || 12).map(page => (
          <GridColumn
            key={page.slug}
            sm={this.calculateColumnSize(gridColumns, 0.5)}
            md={this.calculateColumnSize(gridColumns, 1)}>

            <Link to={page.url}>
              <Flippy
                front={this.renderFront(page)}
                back={this.renderBack(page)}
                background={flippyBackground}
                foreground={flippyForeground}
                styles={styles.flippy}
              />
            </Link>
          </GridColumn>
        ))}
      </Grid>
    )
  }

  renderFront (page) {
    return (
      <div
        className={this.props.classNames.front}
        style={{ backgroundImage: `url(${page.image})` }}
      />
    )
  }

  renderBack (page) {
    const { classNames } = this.props

    return (
      <div className={classNames.detail}>
        <div className={classNames.name}>{page.name}</div>
        <div className={classNames.amount}>{numbro(page.raised).formatCurrency('0,0')}</div>
      </div>
    )
  }
}

const dividerSizes = {
  none: 0,
  small: 0.125,
  medium: 0.5,
  thick: 1
}

const mapState = (store) => ({
  ...selectAPIParams(store),
  ...selectLocalisation(store),
  ...selectCustomSupporterPagePresent(store)
})

export default compose(
  withStyles(styles),
  connect(mapState)
)(SupporterTiles)
