import React from 'react'
import withStyles from 'constructicon/with-styles'
import styles from './styles'
import { Link } from 'react-router'

import Button from '../../ui/Button'
import Container from 'constructicon/container'
import Icon from 'constructicon/icon'

const InactivePage = ({ classNames }) => (
  <Container spacing={{ y: 6, x: 2 }}>
    <div className={classNames.root}>
      <Icon name='info' size={2} />
      <div className={classNames.message}>The page you are trying to view is no longer active</div>
      <Button
        tag={Link}
        to='/'>
        Back
      </Button>
    </div>
  </Container>
)

export default withStyles(styles)(InactivePage)
