import React from 'react'
import template from 'lodash/template'
import { deserializeTeam, fetchTeam } from 'supporticon/api/teams'
import { isRichTextEmpty } from '../../../lib/richText'

import BaseSection from 'constructicon/section'
import Button from '../../ui/Button'
import Loading from '../../ui/Loading'
import RichText from '../../ui/RichText'
import Section from '../../ui/Section'
import Title from '../../ui/Title'

class TeamDetails extends React.Component {
  constructor () {
    super()
    this.state = {
      data: null,
      status: 'fetching'
    }
  }

  componentDidMount () {
    const { fundraisingPage } = this.props

    if (fundraisingPage.teamPageId) {
      return fetchTeam(fundraisingPage.teamPageId)
        .then(data => deserializeTeam(data))
        .then(data => this.setState({ data, status: 'fetched' }))
        .catch(error => {
          this.setState({ status: 'failed' })
          return Promise.reject(error)
        })
    }
  }

  render () {
    const { data, status } = this.state

    const {
      alignment,
      background,
      buttonBackground,
      buttonForeground,
      copy,
      cta,
      foreground,
      fundraisingPage,
      spacing,
      title
    } = this.props

    if (!fundraisingPage.teamPageId) {
      return null
    }

    if (status !== 'fetched') {
      return <Loading />
    }

    return (
      <Section
        alignment={alignment}
        background={background}
        foreground={foreground}
        spacing={spacing}
      >
        {!isRichTextEmpty(title) && (
          <Title injectData={data}>
            {title}
          </Title>
        )}
        {!isRichTextEmpty(copy) && (
          <BaseSection spacing={{ b: cta ? 1 : 0 }}>
            <RichText injectData={data}>
              {copy}
            </RichText>
          </BaseSection>
        )}
        {cta && data.slug && (
          <Button
            background={buttonBackground}
            foreground={buttonForeground}
            to={`/teams/${data.slug}`}>
            {template(cta)(data)}
          </Button>
        )}
      </Section>
    )
  }
}

export default TeamDetails
