import React from 'react'
import queryString from 'querystringify'
import Url from 'url-parse'
import { withRouter, Link } from 'react-router'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { selectUrl } from '../../../store/site'
import { selectCurrentFundraisingPage } from '../../../store/fundraisingPage'
import { clearSession, selectPage, selectUser } from '../../../store/session'
import { selectCustomSupporterPagePresent } from '../../../store/customSupporterPages'

const LinkHandler = ({
  clearSession,
  currentPage,
  linkType,
  location = {},
  hasCustomSupporterPages,
  onClick,
  page = {},
  params,
  router,
  routes,
  siteUrl,
  user = {},
  to,
  ...props
}) => {
  const isButton = () => !to && onClick
  const isExternalLink = () => to && /^((ftp|http|https):\/\/)?(www\.)?[-a-zA-Z0-9@:;%._+~#=]{2,256}\.[a-z]{2,24}\b([-a-zA-Z0-9@:;%_+.,~#?&//=]*)$/.test(to)
  const isMailtoLink = () => to && to.indexOf('mailto') === 0
  const isAnchorLink = () => to && to.replace('/', '').indexOf('#') === 0
  const isModalLink = () => to && to.indexOf('?modal=') > -1
  const isQueryParam = () => to && to.indexOf('?') === 0

  const isCurrent = to === location.pathname || to === `${siteUrl}${location.pathname}`

  if (linkType === 'login' && user.token) {
    return <button {...props} onClick={clearSession}>Logout</button>
  }

  if (linkType === 'signup' && page.id) {
    return (
      <Link
        {...props}
        onClick={onClick}
        to={hasCustomSupporterPages ? `/fundraising/${page.slug}` : page.url}
        target={hasCustomSupporterPages ? '_top' : '_blank'}
      >
        View Page
      </Link>
    )
  }

  if (linkType === 'donate' && currentPage) {
    return <a {...props} href={currentPage.donationUrl} target='_blank' />
  }

  if (isButton()) {
    return <button onClick={onClick} {...props} />
  }

  if (isExternalLink()) {
    if (to.indexOf(siteUrl) > -1) {
      return <a {...props} aria-current={isCurrent} href={to} />
    }

    if (siteUrl.indexOf(to) > -1) {
      const href = to.replace(siteUrl, '') || '/'
      return <Link onClick={onClick} to={href} {...props} />
    }

    return <a {...props} href={to} target='_blank' />
  }

  if (isMailtoLink()) {
    return <a href={to} {...props} />
  }

  if (isAnchorLink()) {
    return <a href={to} onClick={onClick} {...props} />
  }

  if (isModalLink()) {
    const handleModalClick = e => {
      e.preventDefault()

      const { pathname, hash, query } = new Url(to)

      router.push(`${pathname}?modal=${queryString.parse(query).modal}${hash}`)

      if (typeof onClick === 'function') onClick()
    }

    return <button onClick={handleModalClick} {...props} />
  }

  if (isQueryParam()) {
    return <Link onClick={onClick} to={`${location.pathname}${to}`} aria-current={isCurrent} {...props} />
  }

  return <Link onClick={onClick} to={to} aria-current={isCurrent} {...props} />
}

const mapState = (state, props) => ({
  ...selectCustomSupporterPagePresent(state),
  currentPage: selectCurrentFundraisingPage(state, props),
  page: selectPage(state),
  siteUrl: selectUrl(state),
  user: selectUser(state)
})

const mapDispatch = { clearSession }

export default compose(
  withRouter,
  connect(mapState, mapDispatch)
)(LinkHandler)
