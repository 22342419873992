export default (props, { rhythm }) => ({
  root: {
    textAlign: props.align
  },

  label: {
    marginBottom: rhythm(0.25)
  },

  logo: {
    maxHeight: rhythm(1.5)
  },

  cookies: {
    marginTop: rhythm(0.5),

    '& > a + a': {
      marginLeft: rhythm(1)
    }
  }
})
