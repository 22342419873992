import React from 'react'
import get from 'lodash/get'
import keys from 'lodash/keys'
import last from 'lodash/last'
import { connect } from 'react-redux'
import { selectUser, selectPage, selectTeam } from '../../../store/session'

class DocumentClassNames extends React.Component {
  constructor () {
    super()
    this.state = {
      classNames: []
    }
  }

  componentDidMount () {
    this.handlePathChange(this.props)
  }

  componentWillReceiveProps (props) {
    this.handlePathChange(props)
  }

  handlePathChange ({ page, path, team, user }) {
    this.removeClassNames(this.state.classNames)
    this.applyClassNames([
      ...this.generateClassNamesFromPath(path),
      ...this.generateClassNamesFromSession({ user, page, team })
    ])
  }

  removeClassNames (classNames) {
    classNames.forEach(className => {
      document.documentElement.classList.remove(className)
    })
  }

  applyClassNames (classNames) {
    classNames.forEach(className => {
      document.documentElement.classList.add(className)
    })
    this.setState({ classNames })
  }

  generateClassNamesFromPath (path) {
    let pathArray = path.substring(1).split('/')
      .filter(pathString => pathString !== '')

    if (pathArray.length > 0) {
      pathArray.push(`${last(pathArray)}-index`)
      return pathArray
    } else return ['home']
  }

  generateClassNamesFromSession (session) {
    return keys(session)
      .filter(key => Boolean(get(session[key], 'id')))
      .map(key => `has-${key}`)
  }

  render () {
    return null
  }
}

const mapState = store => ({
  page: selectPage(store),
  team: selectTeam(store),
  user: selectUser(store)
})

export default connect(mapState)(DocumentClassNames)
