export default (props, traits) => {
  const {
    colors,
    rhythm,
    scale,
    treatments
  } = traits

  const {
    tileBackground = 'primary',
    tileForeground = 'light'
  } = props

  return {
    root: {
      margin: rhythm(-0.125),
      textAlign: 'center'
    },

    segment: {
      display: 'inline-block',
      margin: rhythm(0.125)
    },

    label: {
      ...treatments.button
    },

    tile: {
      position: 'relative',
      padding: `${rhythm(1)} ${rhythm(0.5)}`,
      minWidth: rhythm(5),
      backgroundColor: colors[tileBackground],
      color: colors[tileForeground],
      fontSize: scale(8),
      ...treatments.head,

      '&:before': {
        content: tileBackground !== 'transparent' && '""',
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        height: '50%',
        backgroundImage: 'linear-gradient(rgba(0,0,0,0.075), rgba(0,0,0,0))',
        borderTop: `1px solid rgba(255,255,255,0.075)`
      },

      '& span': {
        position: 'relative'
      }
    }
  }
}
