import React from 'react'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

const StaticBanner = ({
  classNames,
  image = {}
}) => (
  <img
    src={image.url}
    alt={image.alt}
    className={classNames.root}
  />
)

export default withStyles(styles)(StaticBanner)
