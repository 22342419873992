import thunk from 'redux-thunk'
import moment from 'moment'
import unlessFetched from '../lib/unlessFetched'
import { applyMiddleware, combineReducers, createStore } from 'redux'
import { createLogger } from 'redux-logger'
import { getLocalStorageItem, setLocalStorageItem } from '../lib/localStorage'

import consent from './consent'
import customSupporterPages, { fetchCustomSupporterPages } from './customSupporterPages'
import customTagPages, { fetchCustomTagPages } from './customTagPages'
import fundraisingPage from './fundraisingPage'
import pages, { fetchPages } from './pages'
import prismic from './prismic'
import projects from './projects'
import session from './session'
import site, { fetchSite } from './site'
import tagPage from './tagPage'
import team from './team'

const middleware = process.env.NODE_ENV === 'production'
  ? [thunk]
  : [thunk, createLogger()]

export const configureStore = (initialState = {}) => (
  createStore(
    combineReducers({
      consent,
      customSupporterPages,
      customTagPages,
      fundraisingPage,
      pages,
      prismic,
      projects,
      session,
      site,
      tagPage,
      team
    }),
    initialState,
    applyMiddleware(...middleware)
  )
)

export const configureClientStore = (initialState = {}) => {
  const storedState = getLocalStorageItem('app-state')

  const finalInitialState = {
    ...initialState,
    ...storedState
  }

  const store = configureStore(
    filterPreviewToken(finalInitialState)
  )

  store.subscribe(() => {
    const { consent, prismic, session } = store.getState()
    setLocalStorageItem('app-state', { consent, prismic, session })
  })

  return store
}

export const fetchSiteContent = (repo, uid, previewToken) => (dispatch, getState) => {
  const state = getState()
  const alwaysFetch = !!previewToken

  return Promise.resolve()
    .then(() => unlessFetched(state.site, () => dispatch(fetchSite(repo, uid, { previewToken })), alwaysFetch))
    .then(doc => Promise.all([
      unlessFetched(state.pages, () => dispatch(fetchPages(repo, doc.id, { previewToken })), alwaysFetch).catch(() => {}),
      unlessFetched(state.customSupporterPages, () => dispatch(fetchCustomSupporterPages(repo, doc.id, { previewToken })), alwaysFetch).catch(() => {}),
      unlessFetched(state.customTagPages, () => dispatch(fetchCustomTagPages(repo, doc.id, { previewToken })), alwaysFetch).catch(() => {})
    ]))
}

const filterPreviewToken = (state) => {
  const valid = state.prismic && state.prismic.previewToken && moment(state.prismic.expiry).isAfter(moment())
  return valid ? state : { ...state, prismic: {} }
}
