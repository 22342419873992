/*
  See https://github.com/BenjaminVanRyseghem/numbro/blob/3059b0e1756747e673dbbf09d469df6f20bc53fc/languages/en-IE.js
*/

export default {
  delimiters: {
    thousands: ',',
    decimal: '.'
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't'
  },
  ordinal: number => {
    const b = number % 10
    return ~~((number % 100) / 10) === 1
      ? 'th'
      : b === 1
        ? 'st'
        : b === 2
          ? 'nd'
          : b === 3
            ? 'rd'
            : 'th'
  },
  currency: {
    symbol: '€',
    code: 'EUR',
    position: 'prefix'
  }
}
