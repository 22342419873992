import React from 'react'
import groupBy from 'lodash/groupBy'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

import Accordion from 'constructicon/accordion'
import Content from '../../ui/Content'
import RichText from '../../ui/RichText'
import Section from '../../ui/Section'
import Title from '../../ui/Title'

const FAQs = ({
  alignment,
  backgroundImage,
  background,
  classNames,
  copy,
  copyAlignment,
  foreground,
  items = [],
  layout,
  size,
  spacing,
  subtitle,
  title,
  titleForeground
}) => {
  const categorisedFAQs = groupBy(items, 'category')
  const categories = Object.keys(categorisedFAQs)

  return (
    <Section
      alignment={alignment}
      backgroundImage={backgroundImage}
      background={background}
      foreground={foreground}
      size={size}
      spacing={spacing}>

      <Content
        copy={copy}
        copyAlignment={copyAlignment}
        layout={layout}
        subtitle={subtitle}
        title={title}
        titleForeground={titleForeground}>

        {categories.map((category, index) => {
          const faqs = categorisedFAQs[category]

          return (
            <div key={index} className={classNames.category}>
              {category !== 'null' && <Title color={titleForeground}>{category}</Title>}
              {faqs.map((faq, index) => {
                const question = <RichText>{faq.question}</RichText>
                const answer = <RichText>{faq.answer}</RichText>

                return (
                  <Accordion
                    key={index}
                    title={question}>
                    {answer}
                  </Accordion>
                )
              })}
            </div>
          )
        })}

      </Content>
    </Section>
  )
}

export default withStyles(styles)(FAQs)
