import escape from 'lodash/escape'
import unescape from 'lodash/unescape'

export const parseJSON = (string) => (
  JSON.parse(string, (key, value) => (
    typeof value === 'string' ? unescape(value) : value
  ))
)

export const stringifyJSON = (json) => (
  JSON.stringify(json, (key, value) => (
    typeof value === 'string' ? escape(value) : value
  ))
)
