import React from 'react'
import Status from '../../components/container/Status'
import Theme from '../../components/utils/Theme'

class StravaConnect extends React.Component {
  componentDidMount () {
    const { location, router } = this.props

    const pagePath = location.pathname.replace('/connect', '')
    const popup = window.opener

    setTimeout(() => {
      if (popup) {
        window.opener.postMessage('strava connected', '*')
        window.close()
      } else {
        window.postMessage('strava connected', '*')
        router.push(pagePath)
      }
    }, 1000)
  }

  render () {
    return (
      <Theme>
        <Status status='fetching' />
      </Theme>
    )
  }
}

export default StravaConnect
