export default (props, traits) => {
  const { toggled } = props
  const { colors, mediaQuery, radiuses, rhythm, shadows } = traits

  return {
    root: {
      display: 'flex',
      position: 'fixed',
      left: rhythm(0.666),
      bottom: rhythm(0.666),
      boxShadow: '0 0 30px rgba(0,0,0,0.25)',
      borderRadius: '50%',
      background: 'gray',
      alignItems: 'center',
      zIndex: 1,
      [mediaQuery('sm')]: {
        left: rhythm(1),
        bottom: rhythm(1)
      }
    },

    user: {
      position: 'relative',
      cursor: 'pointer',
      '&:before': {
        content: '""',
        position: 'absolute',
        top: rhythm(-0.75),
        right: rhythm(-0.75),
        bottom: rhythm(-0.75),
        left: rhythm(-0.75)
      }
    },

    avatar: {
      position: 'relative',
      width: rhythm(2),
      height: rhythm(2),
      borderRadius: '50%'
    },

    buttons: {
      display: toggled ? 'flex' : 'none',
      flexDirection: 'column',
      position: 'absolute',
      bottom: rhythm(2.5),
      left: 0,
      backgroundColor: colors.light,
      borderRadius: rhythm(radiuses.small),
      boxShadow: shadows.light,
      whiteSpace: 'nowrap',
      '&:after': {
        content: '""',
        position: 'absolute',
        bottom: '-16px',
        left: '16px',
        border: '8px solid transparent',
        borderTopColor: 'white'
      }
    },

    button: {
      '&:not(:first-child)': {
        borderTop: `1px solid ${colors.shade}`
      }
    }
  }
}
