import React, { useEffect, useState } from 'react'
import uniq from 'lodash/uniq'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { selectAPIParams, selectLocalisation } from '../../../store/site'
import { isRichTextEmpty } from '../../../lib/richText'
import { fetchFitnessTotals } from 'supporticon/api/fitness-totals'
import { fetchDonationTotals, deserializeDonationTotals } from 'supporticon/api/donation-totals'
import withStyles from 'constructicon/with-styles'
import styles from './styles'
import isBadgeAchieved from './isBadgeAchieved'

import Badge from './Badge'
import Grid from 'constructicon/grid'
import GridColumn from 'constructicon/grid-column'
import Heading from 'constructicon/heading'
import RichText from '../../ui/RichText'
import Section from '../../ui/Section'

const campaignDefault = { distance: 0, donations: 0, duration: 0, elevation: 0, raised: 0 }

const Badges = ({
  alignment,
  apiParams,
  background,
  country,
  customSupporterPage,
  foreground,
  fundraisingPage,
  items,
  spacing,
  styles,
  title
}) => {
  const [campaignData, setCampaignData] = useState(campaignDefault)
  const { fitness } = customSupporterPage
  const categories = uniq(items.map(item => item.category))
  const campaignBadges = items.filter(item => item.requirement.indexOf('campaign') > -1)

  const fetchCampaignData = () => Promise.all([
    fetchFitnessTotals({ ...apiParams, campaign: apiParams.campaignIds }),
    fetchDonationTotals({ ...apiParams, country, includeOffline: true })
      .then(deserializeDonationTotals)
  ])
    .then(([fitness, totals]) => setCampaignData({ ...fitness, ...totals }))

  useEffect(() => {
    if (campaignBadges.length > 0) fetchCampaignData()
  }, [fundraisingPage])

  return (
    <Section
      alignment={alignment}
      background={background}
      foreground={foreground}
      spacing={spacing}
    >
      {!isRichTextEmpty(title) && (
        <RichText styles={styles.title}>
          {title}
        </RichText>
      )}
      {categories.map((category, x) => {
        const badges = items.filter(item => item.category === category)

        return (
          <div key={x}>
            {category && (
              <Heading size={1} spacing={{ t: 1, b: 0.5 }}>
                {category}
              </Heading>
            )}
            <Grid
              justify='flex-start'
              spacing={0.5}>
              {badges.map((badge, y) => (
                <GridColumn
                  key={y}
                  xs={6}
                  sm={4}
                  lg={3}>
                  <Badge
                    achieved={isBadgeAchieved(badge, fundraisingPage, fitness.goalUom, items, campaignData)}
                    badge={badge}
                    fundraisingPage={fundraisingPage}
                  />
                </GridColumn>
              ))}
            </Grid>
          </div>
        )
      })}
    </Section>
  )
}

const mapState = (store) => ({
  ...selectAPIParams(store),
  ...selectLocalisation(store)
})

export default compose(
  withStyles(styles),
  connect(mapState)
)(Badges)
