import React from 'react'
import { connect } from 'react-redux'
import { selectProjectsByDonations, selectProjectsByRaised, selectProjectsStatus } from '../../../store/projects'

import Content from '../../ui/Content'
import Error from '../../ui/Error'
import Loading from '../../ui/Loading'
import Section from '../../ui/Section'
import Projects from './Projects'

const ProjectLeaderboard = ({
  alignment,
  backgroundImage,
  background,
  copy,
  copyAlignment,
  foreground,
  metric,
  projects,
  size,
  spacing,
  status,
  subtitle,
  title,
  titleForeground
}) => (
  <Section
    alignment={alignment}
    backgroundImage={backgroundImage}
    background={background}
    foreground={foreground}
    size={size}
    spacing={spacing}>

    <Content
      copy={copy}
      copyAlignment={copyAlignment}
      subtitle={subtitle}
      title={title}
      titleForeground={titleForeground}>

      {status === 'fetching' && <Loading />}
      {status === 'failed' && <Error>There was an error loading current projects.</Error>}
      {status === 'fetched' && (
        <Projects
          metric={metric}
          projects={projects}
        />
      )}

    </Content>
  </Section>
)

const mapState = (state, props) => ({
  status: selectProjectsStatus(state),
  projects: props.metric === 'donations'
    ? selectProjectsByDonations(state)
    : selectProjectsByRaised(state)
})

export default connect(mapState)(ProjectLeaderboard)
