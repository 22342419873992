import React from 'react'
import ContentSection from '../../ui/ContentSection'

const reverseLayoutStyle = {
  left: 'right',
  right: 'left',
  default: 'default'
}

const Content = ({
  items = [],
  layout,
  ...props
}) => (
  <ContentSection
    buttons={items}
    {...props}
    layout={reverseLayoutStyle[layout]}
  />
)

export default Content
