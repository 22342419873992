export default (props, {
  rhythm,
  treatments
}) => {
  const toggleStyles = {
    display: 'inline-block',
    paddingLeft: rhythm(0.5),
    paddingRight: rhythm(0.5),
    paddingBottom: rhythm(0.25),
    cursor: 'pointer',
    ...treatments.button
  }

  const activeToggleStyles = {
    borderBottomWidth: 2,
    borderBottomStyle: 'solid'
  }

  return {
    leaderboard: {
      textAlign: 'left'
    },

    toggles: {
      paddingBottom: rhythm(1)
    },

    toggleOn: {
      ...toggleStyles,
      ...activeToggleStyles
    },

    toggleOff: {
      ...toggleStyles
    }
  }
}
