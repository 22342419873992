import React from 'react'
import RichText from '../../ui/RichText'
import Section from '../../ui/Section'

const Content = ({
  alignment,
  background,
  copy,
  foreground,
  spacing
}) => (
  <Section
    alignment={alignment}
    background={background}
    foreground={foreground}
    spacing={spacing}
  >
    <RichText>{copy}</RichText>
  </Section>
)

export default Content
