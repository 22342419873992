import React from 'react'
import withStyles from 'constructicon/with-styles'
import styles from './styles'

const PoweredByHeader = ({ classNames, styles }) => (
  <a
    aria-label='Powered by Justgiving'
    href='https://www.justgiving.com/'
    target='_blank'
    className={classNames.root}
  >
    <div className={classNames.inner} />
  </a>
)

export default withStyles(styles)(PoweredByHeader)
