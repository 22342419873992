import React from 'react'
import { connect } from 'react-redux'
import { selectMeta, selectCountry, selectDesign, selectHeader, selectFooter } from '../../store/site'
import './defaults.css'

import AllowIFrame from '../../components/utils/AllowIFrame'
import Analytics from '../../components/utils/Analytics'
import CookieConsent from '../../components/utils/CookieConsent'
import DataRequirements from '../../components/utils/DataRequirements'
import DocumentClassNames from '../../components/utils/DocumentClassNames'
import DocumentLoadStatus from '../../components/container/DocumentLoadStatus'
import Footer from '../../components/container/Footer'
import GTM from '../../components/utils/GTM'
import Head from '../../components/utils/Head'
import Header from '../../components/container/Header'
import { isJustGiving } from '../../lib/environment'
import Localisation from '../../components/utils/Localisation'
import Login from '../../components/container/Login'
import MaintenanceMode from '../../components/utils/MaintenanceMode'
import PoweredByHeader from '../../components/container/PoweredByHeader'
import PreviewStatus from '../../components/utils/PreviewStatus'
import Reconnect from '../../components/utils/Reconnect'
import SiteContainer from '../../components/container/SiteContainer'
import Theme from '../../components/utils/Theme'
import WebFonts from '../../components/utils/WebFonts'

const Container = ({
  children,
  country,
  meta = {},
  design,
  footer = {},
  header = {},
  location,
  status,
  router,
  webFonts
}) => {
  const poweredBy = isJustGiving() && header.poweredBy === 'yes' && country !== 'us'

  return (
    <DocumentLoadStatus>
      <Theme>
        <MaintenanceMode>
          <Reconnect>
            <SiteContainer {...design}>
              <a href='#main' className='ssb-hidden'>
                Skip to main content
              </a>
              <WebFonts />
              <DocumentClassNames path={router.location.pathname} />
              <Localisation />
              <AllowIFrame />
              <DataRequirements />
              <PreviewStatus />
              <Head {...meta} />
              {poweredBy && <PoweredByHeader {...header} />}
              {header.include === 'yes' && <Header {...header} poweredBy={poweredBy} />}
              {children}
              {footer.include === 'yes' && <Footer {...footer} />}
              <Analytics />
              <CookieConsent />
              <GTM gtmId={meta.gtmId} />
              {!!location.query.login && (
                <Login
                  onDismiss={() => router.push(location.pathname)}
                  onSuccess={() => router.push(location.query.redirect || location.pathname)}
                />
              )}
            </SiteContainer>
          </Reconnect>
        </MaintenanceMode>
      </Theme>
    </DocumentLoadStatus>
  )
}

const mapState = store => ({
  meta: selectMeta(store),
  country: selectCountry(store),
  design: selectDesign(store),
  footer: selectFooter(store),
  header: selectHeader(store)
})

export default connect(mapState)(Container)
