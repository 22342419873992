import React, { Component } from 'react'
import { connect } from 'react-redux'
import { setPrismicToken } from '../../store/prismic'

import Error from '../../components/ui/Error'
import Loading from '../../components/ui/Loading'
import Section from 'constructicon/section'

class Preview extends Component {
  constructor () {
    super()
    this.state = {
      status: 'fetching'
    }
  }

  componentDidMount () {
    const { location, router, setPrismicToken } = this.props
    const { repo, token } = location.query

    setPrismicToken(token, repo)
      .then(() => router.push('/'))
      .catch((error) => {
        this.setState({ status: 'failed' })
        return Promise.reject(error)
      })
  }

  render () {
    return (
      <Section>
        {this.state.status === 'fetching' && <Loading />}
        {this.state.status === 'failed' && <Error />}
      </Section>
    )
  }
}

export default connect(null, { setPrismicToken })(Preview)
